import { Box, Typography } from '@mui/material';
import React from 'react'
import QuantityInputSm from '../Buttons/QuantityButtonSm';
import { formatPrice } from '../../utils/ConvertApp';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeItem, updateCartItemQuantity } from '../../redux/shopCartRedux';
import { useDispatch } from 'react-redux';

interface ProductCheckoutBoxProps {
    productName:string;
    productId:string;
    imgUrl:string;
    quantity:number;
    price:number
}

const ProductCheckoutBox:React.FC<ProductCheckoutBoxProps> = ({productId,productName,imgUrl,quantity, price}) => {
    const dispatch = useDispatch();

    const handleRemoveItem = (id:string) =>{
        dispatch(removeItem(id))
    }

    const handleQuantityChange = (newQuantity: number) => {
      dispatch(updateCartItemQuantity({ id: productId, quantity: newQuantity }));
    };
  

  return (
    <Box className='flex gap-5 justify-start items-start my-2'>
        <div className='w-16 h-22 bg-white p-1'>
            <img className='object-cover w-full h-full' src={imgUrl} alt={productId}/>
        </div>
        <div className="flex flex-row gap-1 justify-between items-end grow h-[90%]">
            <div className='flex flex-col h-full'>
                <Typography fontFamily={'Avenir'} className='grow'>{productName}</Typography>
                <Typography fontFamily={'Avenir'}>$ {formatPrice(price*quantity)}</Typography>
            </div>
            <div className='flex flex-col justify-end items-center h-full'>
                <QuantityInputSm quantity={quantity} onQuantityChange={handleQuantityChange}/>
                <DeleteIcon className='grow' onClick={()=>handleRemoveItem(productId)}/>
            </div>
        </div>
    </Box>
  )
}

export default ProductCheckoutBox