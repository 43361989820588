import React from 'react';
import { Box, Typography, Skeleton, Button } from '@mui/material';

const ProductDetailsSkeleton: React.FC = () => {
  return (
    <Box className='w-full md:w-1/2 px-5 py-0 flex flex-col'>
      {/* Product Name */}
      <Skeleton variant="text" width="80%" height={40} />

      {/* Sliders and Related Products */}
      <Box className='flex flex-col justify-start gap-5 my-5'>
        {/* Capacity Slider */}
        <Skeleton variant="rectangular" width="100%" height={50} />

        {/* Color Slider */}
        <Skeleton variant="rectangular" width="100%" height={50} />

        {/* Related Products */}
        <Box className='flex flex-wrap gap-5'>
          <Skeleton variant="rectangular" width={100} height={150} />
          <Skeleton variant="rectangular" width={100} height={150} />
          <Skeleton variant="rectangular" width={100} height={150} />
        </Box>
      </Box>

      {/* Prices Section */}
      <Box className='flex flex-col my-0 md:my-5 gap-5'>
        {/* "Desde con Plan" Price */}
        <Box className='flex flex-col justify-start gap-5'>
          <Skeleton variant="text" width="40%" height={30} />
          <Box className='flex flex-row justify-start gap-5'>
            <Skeleton variant="text" width="30%" height={30} />
            <Skeleton variant="rectangular" width={150} height={40} />
          </Box>
        </Box>

        {/* "Precio Final" Section */}
        <Box className='flex flex-col justify-start gap-5'>
          <Skeleton variant="text" width="40%" height={30} />
          <Skeleton variant="text" width="30%" height={30} />
        </Box>
      </Box>

      {/* Quantity Section */}
      <Typography variant='h6'><Skeleton width="30%" height={30} /></Typography>
      <Skeleton variant="rectangular" width="100px" height={40} />

      {/* Action Button */}
      <Skeleton variant="rectangular" width="200px" height={50} />
    </Box>
  );
};

export default ProductDetailsSkeleton;