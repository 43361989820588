import React from 'react';
import { Box, Typography, Container, Divider, List, ListItem, ListItemText } from '@mui/material';

const SocialMediaPolicies: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 1 }}>
        {/* Facebook y Messenger Section */}
        <Typography variant="h5" component="h2" gutterBottom>
          1.1 Facebook y Messenger (aplica sólo en México)
        </Typography>
        <Typography variant="body1" paragraph>
          El uso de las aplicaciones móviles oficiales que Meta Platforms, Inc. (Meta) ha liberado, como Facebook, Messenger, y sus dominios oficiales, será sin costo adicional para las siguientes funcionalidades:
        </Typography>
        <List>
          <ListItem><ListItemText primary="Visualización del muro personal o de cualquier persona o grupo." /></ListItem>
          <ListItem><ListItemText primary="Publicar o comentar en el muro." /></ListItem>
          <ListItem><ListItemText primary="Cargar y guardar fotos y videos." /></ListItem>
          <ListItem><ListItemText primary="Enviar mensajes de texto e imágenes por Messenger." /></ListItem>
          <ListItem><ListItemText primary="Recibir notificaciones de las aplicaciones oficiales." /></ListItem>
        </List>
        <Typography variant="body2" paragraph>
          Funcionalidades que generan costo adicional: reproducción de videos, llamadas por Messenger, compartir ubicaciones, entre otras.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* WhatsApp Section */}
        <Typography variant="h5" component="h2" gutterBottom>
          1.2 WhatsApp (aplica en México, Estados Unidos y Canadá)
        </Typography>
        <Typography variant="body1" paragraph>
          El uso de la aplicación oficial de WhatsApp es sin costo adicional para las siguientes funcionalidades:
        </Typography>
        <List>
          <ListItem><ListItemText primary="Envío de textos y notas de voz." /></ListItem>
          <ListItem><ListItemText primary="Compartir fotos, videos y contactos." /></ListItem>
          <ListItem><ListItemText primary="Descargar y guardar fotos y videos." /></ListItem>
          <ListItem><ListItemText primary="Recibir notificaciones." /></ListItem>
        </List>
        <Typography variant="body2" paragraph>
          Funcionalidades que generan costo adicional incluyen: videollamadas, reproducción de videos, y redirección a URLs externas.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Twitter Section */}
        <Typography variant="h5" component="h2" gutterBottom>
          1.3 Twitter (aplica sólo en México)
        </Typography>
        <Typography variant="body1" paragraph>
          El uso de la aplicación oficial de Twitter será sin costo adicional para las siguientes funcionalidades:
        </Typography>
        <List>
          <ListItem><ListItemText primary="Visualizar el timeline, publicar, comentar y retweetear." /></ListItem>
          <ListItem><ListItemText primary="Enviar mensajes directos y recibir notificaciones." /></ListItem>
          <ListItem><ListItemText primary="Cargar y descargar fotos en el timeline." /></ListItem>
        </List>
        <Typography variant="body2" paragraph>
          Funcionalidades que generan costo adicional incluyen: transmisión en vivo, reproducción de videos, y redirección a URLs externas.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Uso fuera de México Section */}
        <Typography variant="h5" component="h2" gutterBottom>
          1.4 Uso de Redes Sociales y Mensajería Fuera de México
        </Typography>
        <Typography variant="body1" paragraph>
          El uso de Facebook, Messenger, y Twitter en Estados Unidos o Canadá se debitará de los MB incluidos en el plan o bajo demanda. Fuera de estas regiones, aplica la tarifa de roaming internacional.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Límite de responsabilidad Section */}
        <Typography variant="h5" component="h2" gutterBottom>
          1.5 Límite de responsabilidad
        </Typography>
        <Typography variant="body1" paragraph>
          TELCEL no es responsable de las aplicaciones oficiales de Facebook, Twitter, y WhatsApp ni de los servicios prestados por ellas. El uso de estas aplicaciones es una relación entre el usuario y dichas plataformas.
        </Typography>
      </Box>
    </Container>
  );
};

export default SocialMediaPolicies;