// PlanServiceTable.tsx
import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { CircularProgress, Box, IconButton, Switch, Stack, Typography } from '@mui/material';
import { PlanServiceInterface } from '../../../constant/ServicesInterfaces';
import { deletePlanService, getAllPlanServices, updatePlanService, updatePlanServiceStatusById } from '../../../services/plansService';
import { useSnackbar } from '../../Alerts/SnackBarProvider'; // Adjust the path
import EditIcon from '@mui/icons-material/Edit';
import InfinityIcon from '@mui/icons-material/AllInclusive'; 
import DeleteIcon from '@mui/icons-material/Delete';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SmsIcon from '@mui/icons-material/Sms';
import DeletePlanModal from '../Modal/PlanService/DeletePlanModal';
import { formatPrice } from '../../../utils/ConvertApp';
import PlanDetailsModal from '../Modal/PlanService/PlanDetailsModal';
import { CustomToolbar } from './CustomToolBar';

interface DashboardDataProps {
  updateDashboardData: (total: number, activePersonal: number, activeExclusive: number) => void;
}

const PlanServiceTable: React.FC<DashboardDataProps> = ({updateDashboardData}) => {
  const [planServices, setPlanServices] = useState<PlanServiceInterface[]>([]);
  const [currentPlanService, setCurrentPlanService] = useState<PlanServiceInterface | null>(null);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [loading, setLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPlanServices();
  }, []);

  const fetchPlanServices = async () => {
    try {
      setLoading(true);
      const data = await getAllPlanServices();
      setPlanServices(data);
      const totalPlans: number = data.length;  // Total companies fetched from API
      const activePersonal: number = data.filter((planService: PlanServiceInterface) => planService.planType == 'Personal').length; 
      const activeExclusive: number = data.filter((planService: PlanServiceInterface) => planService.planType == 'Empresarial').length; 
      // Update the dashboard data with the computed values
      updateDashboardData(totalPlans, activePersonal, activeExclusive);
    } catch (error) {
      console.error('Error fetching smartphones:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlanStatus = async (id: string, isActive: boolean): Promise<void> => {
    try {
      // Toggle the isActive status
      const response = await updatePlanServiceStatusById({ id, isActive: !isActive });
      // Check if the response is valid
      if (response !== null && typeof response === 'object') {
        showSnackbar('Se Actualizo de forma extiosa!', 'success');
        fetchPlanServices();
      } else {
        console.error('Received invalid response:', response);
      }
    } catch (error) {
      console.error('Error updating plan service status:', error);
      showSnackbar('Error al momento de Actualizar', 'error');
    }
  };


  const handleEdit = (planService: PlanServiceInterface) => {
    setCurrentPlanService(planService);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (planService: PlanServiceInterface) => {
    try {
      if(planService.id){
      await deletePlanService(planService.id);
      showSnackbar('Plan Borrado de forma exitosa!', 'success');
      fetchPlanServices();
      setIsDeleteModalOpen(false);
    }
    } catch (error) {
      console.error('Error Borrado Plan:', error);
      showSnackbar('Error Borrado Plan', 'error');
    }
  };
  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setCurrentPlanService(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 20, headerAlign: 'left', align: 'left' },
    { field: 'planName', headerName: 'Nombre', width: 150, headerAlign: 'left', align: 'left' },
    { field: 'planType', headerName: 'Tipo de Plan', width: 150, headerAlign: 'left', align: 'left' },
    {
      field: 'included',
      headerName: 'Incluido',
      width: 300,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Box display="flex" alignItems="center"  width={'30%'}>
            <DataUsageIcon color="primary" />
            <Typography variant="body2" className="ml-1">{params.row.dataIncludedGB} GB</Typography>
          </Box>
          <Box display="flex" alignItems="center" width={'30%'}>
        <AccessTimeIcon color="primary" />
        <Typography variant="body2" className="ml-1">
          {params.row.minutesIncluded > 8888 ? (
            <>
              <InfinityIcon sx={{ color: 'gray', fontSize: '1rem', marginRight: '4px' }} />
              Min
            </>
          ) : (
            <>
              {params.row.minutesIncluded}
              <span style={{ marginLeft: '4px' }}>Min</span>
            </>
          )}
        </Typography>
      </Box>

      {/* SMS Included */}
      <Box display="flex" alignItems="center" width={'30%'}>
        <SmsIcon color="primary" />
        <Typography variant="body2" className="ml-1">
          {params.row.smsIncluded > 8888 ? (
            <>
              <InfinityIcon sx={{ color: 'gray', fontSize: '1rem', marginRight: '4px' }} />
              SMS
            </>
          ) : (
            <>
              {params.row.smsIncluded}
              <span style={{ marginLeft: '4px' }}>SMS</span>
            </>
          )}
        </Typography>
      </Box>
        </Stack>
      ),
    },
    {
      field: 'price',
      headerName: 'Precio',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            {formatPrice(params.row.price)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'isActive', 
      headerName: 'Activado', 
      width: 100, 
      headerAlign: 'center', 
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Switch 
          onChange={() => handlePlanStatus(params.row.id, params.row.isActive)} 
          checked={params.row.isActive}
          color="success"
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton 
            onClick={() => handleEdit(params.row as PlanServiceInterface)} 
            className="hover:bg-blue-100 rounded-full"
            aria-label="Edit Plan"
          >
            <EditIcon />
          </IconButton>
          <IconButton 
            onClick={() => {
              setCurrentPlanService(params.row as PlanServiceInterface);
              setIsDeleteModalOpen(true);
            }} 
            className="hover:bg-red-100 rounded-full"
            aria-label="Delete Plan"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <div style={{ height: 500, width: '100%' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          rows={planServices}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Descargar como Excel',
          }}
          slots={{ toolbar: CustomToolbar }}
          pagination
        />
      )}
       {currentPlanService && isDeleteModalOpen && (
        <DeletePlanModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(currentPlanService)}
          plan={currentPlanService}    
        />
      )}

      {currentPlanService && isEditModalOpen && (
        <PlanDetailsModal
          open={isEditModalOpen}
          onClose={handleModalClose}
          onSave={async (updatedPlanService:PlanServiceInterface) => {
            try {
              if(updatedPlanService.id){
                await updatePlanService(updatedPlanService.id, updatedPlanService);
                console.log(updatedPlanService);
                showSnackbar('Promotion updated successfully!', 'success');
                fetchPlanServices();
                handleModalClose();
              }else{
                showSnackbar('Promoción sin Id!', 'error');
              }
            } catch (error) {
              console.error('Error updating promotion:', error);
              showSnackbar('Error updating promotion', 'error');
            }
          }}
          plan={currentPlanService}
        />
      )}
    </div>
  );
};

export default PlanServiceTable;
