import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description'; // Importing MUI icons
import SettingsIcon from '@mui/icons-material/Settings'; // Importing MUI icons
import InfoIcon from '@mui/icons-material/Info'; // Importing MUI icons

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = React.memo(function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ProductDetailsTabsProps {
  descriptionContent: React.ReactNode;
  specificationsContent?: React.ReactNode; // Made this optional
  additionalInfoContent: React.ReactNode;
}

export default function ProductDetailsTabs(props: ProductDetailsTabsProps) {
  const { descriptionContent, specificationsContent, additionalInfoContent } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

    // Use media query to check if the screen size is mobile
    const isMobile = useMediaQuery('(max-width:768px)');

  const tabs = [
    { label: 'Descripción', content: descriptionContent, id: 0 },
    specificationsContent && { label: 'Especificaciones', content: specificationsContent, id: 1 },
    { label: 'Información Adicional', content: additionalInfoContent, id: specificationsContent ? 2:1 },
  ].filter(Boolean) as { label: string; content: React.ReactNode; id: number }[]; // Filter out falsy values

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth" // Make tabs full width for better mobile experience
      >
          <Tab 
              icon={<DescriptionIcon />} 
              label={!isMobile ? "Descripción" : undefined} // Show label only on desktop
              {...a11yProps(0)} 
          />
          <Tab 
              icon={<InfoIcon />} 
              label={!isMobile ? "Información Adicional" : undefined} // Show label only on desktop
              {...a11yProps(2)} 
          />
      </Tabs>
      </Box>

      {tabs.map((tab) => (
        <CustomTabPanel key={tab.id} value={value} index={tab.id}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}