import React from "react";

function Icon(color) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="25"
      viewBox="0 0 28 25"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-251 -218)">
          <g transform="translate(20 52)">
            <g transform="translate(0 138)">
              <g transform="translate(0 28)">
                <g transform="translate(231.678)">
                  <path d="M26.361 19.14c-3.816-.628-5.527-4.528-5.597-4.694l-.008-.016a1.425 1.425 0 01-.136-1.195c.257-.604 1.105-.873 1.666-1.051.14-.045.273-.087.377-.128 1.016-.401 1.222-.816 1.217-1.094a.882.882 0 00-.667-.724l-.008-.003a1.26 1.26 0 00-.474-.09 1.005 1.005 0 00-.417.082c-.396.2-.828.32-1.271.354a1.094 1.094 0 01-.705-.236c.012-.226.026-.46.042-.706l.005-.087a13.45 13.45 0 00-.32-5.372A6.988 6.988 0 0013.575 0l-.536.006a6.976 6.976 0 00-6.476 4.177 13.434 13.434 0 00-.321 5.367c.017.265.033.53.048.796a1.13 1.13 0 01-.778.237 3.266 3.266 0 01-1.35-.356.766.766 0 00-.326-.065 1.11 1.11 0 00-1.08.709c-.109.573.71.988 1.208 1.185.105.042.237.084.377.128.561.178 1.41.447 1.666 1.051.133.4.083.837-.136 1.196l-.007.015A9.358 9.358 0 014.44 16.66a6.943 6.943 0 01-4.174 2.482.32.32 0 00-.266.333c.003.06.017.118.04.172.235.548 1.41 1 3.398 1.306.186.03.265.332.373.829.044.199.089.405.151.618a.39.39 0 00.425.304c.191-.01.38-.037.566-.08.49-.108.99-.164 1.492-.17.36.001.72.032 1.075.092.751.19 1.45.547 2.044 1.044A5.916 5.916 0 0013.146 25c.045 0 .09-.002.134-.005.054.002.126.005.202.005a5.922 5.922 0 003.583-1.412 5.156 5.156 0 012.042-1.043 6.62 6.62 0 011.076-.091 7.446 7.446 0 011.491.158c.186.04.376.064.566.071h.031a.371.371 0 00.394-.294c.061-.21.107-.41.151-.614.108-.494.187-.796.373-.825 1.99-.307 3.164-.758 3.397-1.303a.512.512 0 00.042-.174.32.32 0 00-.267-.333z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
