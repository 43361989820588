import React from "react";

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="56"
      viewBox="0 0 31 56"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#231F20"
          fillRule="nonzero"
          d="M24.91 0H6.09C2.734 0 0 2.702 0 6.016v43.968C0 53.298 2.735 56 6.09 56h18.82c3.355 0 6.09-2.702 6.09-6.016V6.016C31 2.702 28.276 0 24.91 0zM13.209 2.472h4.672c.543 0 .975.492.975 1.094 0 .601-.432 1.093-.975 1.093h-4.672c-.542 0-.974-.492-.974-1.093 0-.602.432-1.094.974-1.094zm-4.417-.164c.697 0 1.273.558 1.273 1.258S9.488 4.813 8.79 4.813a1.266 1.266 0 01-1.274-1.258c0-.7.565-1.247 1.274-1.247zm6.753 49.875a2.02 2.02 0 01-2.037-2.013 2.02 2.02 0 012.037-2.012 2.02 2.02 0 012.037 2.012 2.02 2.02 0 01-2.037 2.013zm11.913-5.206H3.554V7.284h23.903v39.693z"
        ></path>
      </g>
    </svg>
  );
}

export default PhoneIcon;
