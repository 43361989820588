import React, {useEffect, useState} from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';



type BannerCardProps = {
    imgUrl: string;
    active: boolean;
    onClick: () => void;
};

const StyledCard = styled(Card)<{ active: boolean }>(({ theme, active }) => ({
  width: active ? 360 : 140,
  height: 460,
  cursor: 'pointer',
  transition: 'width 0.5s ease-in-out',
  backgroundSize: 'cover',
  borderRadius: '30px',
  // Add responsive behavior using breakpoints
  [theme.breakpoints.down('sm')]: {
    width: active ? 360 : 60,  // Smaller width on small screens
  },
  [theme.breakpoints.up('md')]: {
    width: active ? 360 : 140,  // Default width on medium and up screens
  },
  '& .MuiCardMedia-root': {
    height: '100%',
  },
}));


const BannerCard:React.FC<BannerCardProps> = ({imgUrl,active, onClick}) => {
    const [imgUrlIsValid, setImgUrlIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    
  
    useEffect(() => {
      const checkUrl = async () => {
        try {
          const response = await fetch(imgUrl);
          if (!response.ok) {
            setImgUrlIsValid(true);
          }
          setIsLoading(false);
      
        } catch (error) {
          setImgUrlIsValid(true);
          setIsLoading(false);
        }
      };
  
      checkUrl();
    }, [imgUrl]);

    return (<>
      <StyledCard active={active} onClick={onClick}>
      {isLoading ? (
          <Skeleton variant="rectangular" width={280} height={200} animation="wave" />
        ) : imgUrlIsValid ? (
            <CardMedia
              image={imgUrl}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement;
                target.src =
                  'https://images.samsung.com/mx/smartphones/galaxy-s24-ultra/buy/product_color_gray.png?imwidth=480';
                setImgUrlIsValid(false); // Set flag to false since the fallback image is used
              }}
              title="Product Image"
            />
        ) : (
            <div className="flex justify-center items-center">
              <img
                src={require('../../assets/images/icons/telefono-inteligente.png')}
                alt="Phone Icon"
                style={{ width: 'auto%', height: '100%', maxHeight: 200, padding: '1em 1em 0 1em' }}
              />
            </div>
        )}

      </StyledCard>    
    </>)
}

export default BannerCard