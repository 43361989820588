// src/PaymentDetailForm.tsx
import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  CardElement,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Box, Button, Card, CardActions, CardContent, FormControlLabel, Radio, RadioGroup, TextField, Typography}  from '@mui/material';
import PaymentElementComponent from './PaymentElementCoponent';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  name: Yup.string().required('Nombre Requerido'),
  email: Yup.string().email('Correo Invalido').required('Correo Requerido'),
});

const PaymentDetailForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedValue, setSelectedValue] = useState<any | null>();
  const naviagte = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: {
          name: values.name,
          email: values.email,
        },
      });

      naviagte('/payment-success');

      if (error) {
        console.error(error);
      } else {
        console.log('PaymentMethod created:', paymentMethod);
      }
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(Number(event.target.value));
  };

  return (
    <Box>
      <Typography variant="h4">Seleccione Metodo de Pago</Typography>
      <RadioGroup row value={selectedValue}  onChange={handleChange} className='flex flex-wrap gap-5 justify-between items-center mt-6'>
        <Card className='border-2 w-full p-5'>
          <CardActions className='flex justify-betwen w-full p-5'>
            <FormControlLabel className='grow' value={'Stripe'}  control={<Radio />} label={<Typography variant='body1' className='font-[Avenir]' fontWeight={'bold'}>
                Tarjeta de Credito
            </Typography>} />
            <Box className='flex gap-2'>
              <img height={'auto'} width={30} src={require('../../assets/images/graphics/credit-cards_mastercard.webp')} alt="" />
              <img height={'auto'} width={30} src={require('../../assets/images/graphics/credit-cards_amex.webp')} alt="" />
              <img height={'auto'} width={30} src={require('../../assets/images/graphics/credit-cards_visa_1.webp')} alt="" />
            </Box>
            
          </CardActions>
          <CardContent>
            <Typography variant='body1' className='font-[Avenir] text-csecondary-200'>
                Transferencia de dinero segura utilizando su cuenta bancaria. Visa, Maester, American Express.
            </Typography>
          </CardContent>
          <CardContent className='p-10'>
            <form onSubmit={formik.handleSubmit}>
              <Box mb={2}>
                <CardElement options={{ hidePostalCode: true }} className='border-2 p-5'/>
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
              <Button color="primary" variant="contained" type="submit" disabled={!stripe}>
                Pagar
              </Button>
            </form>
          </CardContent>
        </Card>
        <Card className='border-2 w-full p-5'>
        <CardActions className='flex justify-betwen w-full'>
            <FormControlLabel className='grow' value={'Paypal'}  control={<Radio />} label={<Typography variant='body1' className='font-[Avenir]' fontWeight={'bold'}>
                PayPal
            </Typography>} />
            <Box className='flex gap-2'>
              <img height={'auto'} width={80} src={require('../../assets/images/graphics/paypal.webp')} alt="" />
            </Box>
            
          </CardActions>
          <CardContent>
            <Typography variant='body1' className='font-[Avenir] text-csecondary-200'>
                Serás redirigido al sitio web de PayPal para completar tu compra de forma segura..
            </Typography>
          </CardContent>
        </Card>
      </RadioGroup>
    </Box>
  );
};

export default PaymentDetailForm;
