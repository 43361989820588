import React, { useState, useEffect } from 'react';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Skeleton,
  DialogTitle,
  DialogActions,
  Alert,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WebSliderInterface } from '../../../../constant/WebPageInterface';
import useImageValidation from '../../../../utils/ImageValidator';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Use Dayjs adapter
import dayjs, { Dayjs } from 'dayjs';
import { Options } from '../../../../constant/WebPageInterface';
import EditIcon from '@mui/icons-material/Edit';
import {Title, Category, CalendarToday, CheckCircle, Cancel } from '@mui/icons-material';
import CheckboxTile from '../../Cards/CheckboxTile';
import { uploadImage } from '../../../../services/gcpService';
import { useSnackbar } from '../../../Alerts/SnackBarProvider';


interface WebPageDetailsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (webSlider: WebSliderInterface) => void;
  product: WebSliderInterface;
}

const WebPageDetailsModal: React.FC<WebPageDetailsModalProps> = ({
  open,
  onClose,
  onSave,
  product,
}) => {
  // State management
  const [editMode, setEditMode] = useState(false);
  const [updatedProduct, setUpdatedProduct] = useState<WebSliderInterface>(product);
  const { imgUrlIsValid, isLoading } = useImageValidation(updatedProduct.imgUrl);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(updatedProduct.category);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Estado para manejar el segundo diálogo
  const [imageFile, setImageFile] = useState<File | null>(null); // State for the uploaded image file


  // Handle the input change for text fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedProduct((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleCategoryChange = (category: string, checked: boolean) => {
    // Allow only one checkbox to be selected at a time
    if (checked) {
      setSelectedCategory(category);  // Select the new category
      setUpdatedProduct(prevState => ({ ...prevState, category })); // Update the category in the product
    } else {
      setSelectedCategory(null);  // Deselect if it's unchecked
    }
  };


  // Handle changes for dates using Dayjs
  const handleDateChange = (name: keyof WebSliderInterface, newValue: Dayjs | null) => {
    setUpdatedProduct((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue.toISOString() : prevState[name],
    }));
  };

  // Handle image upload
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdatedProduct((prevState) => ({ ...prevState, imgUrl: reader.result as string }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Toggle the active state
  const handleActiveToggle = () => {
    setUpdatedProduct((prevState) => ({ ...prevState, isActive: !prevState.isActive }));
  };

  const handleSave = async () => {
    try {
      let imgUrl = '';
      
      // Upload image only if an image file is selected
      if (imageFile) {
        imgUrl = await uploadImage(imageFile, 'Products'); // Ensure uploadImage is defined and works
      }
  
      // Create the updated product object with the new image URL if available
      const updatedProductData = {
        ...updatedProduct, // Use the current state of updatedProduct
        imgUrl: imgUrl || updatedProduct.imgUrl, // Use the new URL if available, otherwise retain the old URL
      };
      
      // Call the onSave function to save the updated product
      await onSave(updatedProductData);
  
      // Reset the edit mode
      setEditMode(false);
    } catch (error) {
      console.error('Failed to save the product:', error);
      // Optionally, show an error message to the user here
    }
  };

  const handleUpdateConfirmation = () => {
    setConfirmDialogOpen(true); // Abrir el segundo diálogo
  };

  const handleEditToggle = () => {
    setEditMode(true);
  };

  // Keep updatedProduct in sync with the passed product prop when modal reopens
  useEffect(() => {
    if (product) {
      setUpdatedProduct(product);
    }
  }, [product]);

  return (<>
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Card>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="60%"
              height={200}
              animation="wave"
              sx={{ borderRadius: '4px' }}
            />
          ) : imgUrlIsValid ? (
            <CardMedia
              component="img"
              sx={{
                width: 'fit-content%', // Set width to 70%
                height: 'auto', // Allow height to adjust automatically
                display: 'block',
                margin: 'auto',
                objectFit: 'fill',
              }}
              image={updatedProduct.imgUrl}
              title={updatedProduct.title}
          />
          ) : (
            <div className="flex justify-center items-center">
              <img
                src={'../assets/icons/DashboardAdmin/folder.png'}
                alt="Fallback Icon"
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '200px',
                  padding: '1em',
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
        </Card>

        {/* Image Upload Input */}
        {editMode && (
          <Box className='relative'>
            <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}> {/* Positioning for the edit icon */}
            <input
              accept="image/*"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              id="upload-image"
            />
            <label htmlFor="upload-image">
            <IconButton
              component="span"
              sx={{
                backgroundColor: 'white', // Set background color to white
                borderRadius: '50%', // Make it round
                boxShadow: 2, // Add shadow for elevation
                '&:hover': {
                  boxShadow: 4, // Increase shadow on hover for effect
                },
                padding: 1, // Add padding to make it look better
              }}
            >
                <EditIcon />
              </IconButton>
            </label>
          </Box>
         </Box>
        )}

        <Box sx={{ mt: 2 }}>
          {editMode ? (
            <>
              <TextField
                label="Título"
                name="title"
                fullWidth
                value={updatedProduct.title}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box className="flex justify-between items-center">
                  <DatePicker
                    label="Fecha Inicio"
                    value={updatedProduct.startDay ? dayjs(updatedProduct.startDay) : null} // Use Dayjs
                    onChange={(newValue) => handleDateChange('startDay', newValue)}
                    sx={{ mb: 2 }}
                  />
                  <DatePicker
                    label="Fecha Termino"
                    value={updatedProduct.endDay ? dayjs(updatedProduct.endDay) : null} // Use Dayjs
                    onChange={(newValue) => handleDateChange('endDay', newValue)}
                    sx={{ mb: 2 }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                      onChange={handleActiveToggle}
                      checked={updatedProduct.isActive}
                      color="success"
                    />
                    }
                    label="Promoción Activa"
                  />
                </Box>
              </LocalizationProvider>
              <Box className="flex flex-wrap gap-5 justify-center my-5">
                {Options &&
                  Options.map((option, index) => {
                    return (
                      <CheckboxTile
                        key={index}
                        label={option.title}
                        iconSrc={option.icon}
                        isChecked={selectedCategory === option.title}
                        onChange={(checked) => handleCategoryChange(option.title, checked)}
                      />
                    );
                  })}
              </Box>
            </>
          ) : (
            <Box className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
              <div className="flex items-center">
                <Title className="mr-2" />
                <Typography variant="body1">
                  <strong>Título:</strong> {updatedProduct.title}
                </Typography>
              </div>
              
              <div className="flex items-center">
                <Category className="mr-2" />
                <Typography variant="body1">
                  <strong>Categoría:</strong> {updatedProduct.category}
                </Typography>
              </div>
              <div className="flex items-center">
                {updatedProduct.isActive ? (
                  <CheckCircle className="text-green-500 mr-2" />
                ) : (
                  <Cancel className="text-red-500 mr-2" />
                )}
                <Typography variant="body1">
                  <strong>Promoción Activa:</strong> {updatedProduct.isActive ? 'Sí' : 'No'}
                </Typography>
              </div>

              <div className="flex items-center">
                <CalendarToday className="mr-2" />
                <Typography variant="body1">
                  <strong>Fecha Inicio:</strong> {new Date(updatedProduct.startDay).toLocaleDateString()}
                </Typography>
              </div>

              <div className="flex items-center">
                <CalendarToday className="mr-2" />
                <Typography variant="body1">
                  <strong>Fecha Termino:</strong> {new Date(updatedProduct.endDay).toLocaleDateString()}
                </Typography>
              </div>

            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={onClose} color="error" variant="contained">
            Cerrar
          </Button>

          {editMode ? (
            <Box className='flex flex-wrap gap-5'>
            <Button onClick={()=> setEditMode(false)} color="error" variant="contained">
              Cancelar
            </Button>
            <Button onClick={handleUpdateConfirmation} color="primary" variant="contained">
              Guardar
            </Button>
            </Box>
          ) : (
            <Button onClick={handleEditToggle} color="primary" variant="contained">
              Editar
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
       <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
       <DialogTitle className="text-xl font-bold">Confirmar Actualización</DialogTitle>
       <DialogContent>
         <Alert severity="warning">Esta acción no se puede deshacer.</Alert>
         <Typography className='mt-5'>
           ¿Estás absolutamente seguro de que deseas actualizar <b>{product.title}</b>?
         </Typography>
       </DialogContent>
       <DialogActions>
         <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
         <Button onClick={handleSave} color="success" variant="contained">
           Confirmar
         </Button>
       </DialogActions>
     </Dialog>
     </>
  );
};

export default WebPageDetailsModal;