import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import PromotionDetailsModal from '../Modal/PromotionDetailsModal';
import useImageValidation from '../../utils/ImageValidator';
import { Scale } from '@mui/icons-material';

type PromotionCardProps = {
    imgUrl: string;
    title:string;
    description:string;
    shortDesc:string;
    fetching?:boolean;
};



const StyledCard = styled(Card)<{ img: string }>(({ img }) => ({
  position:'relative',
  width: 360,
  height: 450,
  borderRadius: '20px',
  backgroundImage: `url(${img})`,
  backgroundSize: 'cover', // Ensures the image covers the entire card
  backgroundPosition: 'center', // Centers the image within the card
  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  overflow: 'hidden',
  cursor:'pointer',
  transition: 'transform 0.3s ease-in-out',

  // Hover effect for scaling the card
  '&:hover': {
    transform: 'scale(1.05)', // Scale up the card on hover
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: '-100%', // Initially hide the content by positioning it outside the card
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Optional: Add a semi-transparent background for contrast
  color: 'white', // Optional: Text color for visibility
  padding: theme.spacing(2),
  transition: 'bottom 0.3s ease-in-out', // Smooth transition for the sliding effect
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center', // Center the content
  textAlign: 'center', // Center the text

  // When the card is hovered, slide the content up
  '.MuiCard-root:hover &': {
    bottom: 0, // Bring the content to the bottom
  },

  // Mobile: No hover, position is controlled by state
  [theme.breakpoints.down('sm')]: {
    bottom: 0, // Make sure it's visible by default on mobile
  },
}));


const DescriptionTypography = styled(Typography)(({ theme }) => ({
    position:'relative',
    width:'90%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color:'#ffffff'
}));
  


const PromotionCard:React.FC<PromotionCardProps> = ({imgUrl,title,description,shortDesc,fetching=false}) => {
    const { imgUrlIsValid, isLoading } = useImageValidation(imgUrl);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const telcelImg ='https://cdn-3.expansion.mx/dims4/default/a582a2c/2147483647/strip/true/crop/1280x883+0+0/resize/1200x828!/format/webp/quality/60/?url=https%3A%2F%2Fcdn-3.expansion.mx%2Ffc%2Fe1%2Fe6b0c82e4cb28d9f9dcaf59d193b%2Fimg-20220222-wa0011.jpg';

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };


    return (<>

      {fetching || isLoading ? 
      (<StyledCard img=''>
        <Skeleton variant="rectangular" width={'100%'} height={500} animation="wave" />
       </StyledCard>
      ):(  
      <StyledCard img={imgUrlIsValid? encodeURI(imgUrl):telcelImg}>
          <StyledCardContent onClick={handleOpenModal}>
          {fetching || isLoading ? (
            <div>
              <Skeleton variant="text" width={180} animation="wave" />
              <Skeleton variant="text" width={180} animation="wave" />
            </div>
          ) : (
            <div className='flex gap-2 flex-col justify-center items-center text-center'>
              <Typography sx={{ fontWeight: 'bold' }} variant="h5">{title}</Typography>
              <DescriptionTypography variant="h6">{shortDesc}</DescriptionTypography>
            </div>
          )}
         <Button className='w-fit' onClick={handleOpenModal} sx={{borderRadius:'10px', padding:'5px 50px'}} variant="contained">Ver Detalle</Button>
      </StyledCardContent>
      <PromotionDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={title}
        shortDes={shortDesc}
        description={description}
        img={imgUrl}
      />
      </StyledCard>
    )}
    
    </>);
}

export default PromotionCard