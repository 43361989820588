import { Box, Card, CardContent, CardMedia, Container, Skeleton, Typography } from '@mui/material'
import React, {useState,useEffect} from 'react'
import { RootState } from '../redux/store'
import PromotionBanners from '../components/Gallery/PromotionBanners'
import PromotionCard from '../components/Card/PromotionCard'
import Pagination from '@mui/material/Pagination';
import PromotionPrincipal from '../components/Gallery/PromotionPrincipal'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import { getAllExclusivePromotions, getAllPromotions, getAllPromotionsNoExclusive, getExclusivePromotions } from '../services/promotions';
import { PromotionInterface } from '../constant/PromotionInterface';
import { SkeletonPromotionCardList } from '../components/Card/SkeletonCard';
import {useSelector, useDispatch } from 'react-redux';
import { getCompanyById } from '../services/companies'
import UserProfileCard from '../components/Admin/Cards/UserProfileCard'
import PromotionCountCard from '../components/Admin/Cards/PromotionCountCard'
import BrandBox from '../components/Product/BrandBox'

interface Promotion {
    imgUrl: string;
    title: string;
    description: string;
    shortDesc: string;
    active: boolean;
}
  
const PromotionExclusivePage = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [promotionList,setPromotionList] = useState<PromotionInterface[]>([])
    const [companyInfo,setCompanyInfo]=useState<any|null>(null);
    const [imgUrlIsValid,setImgUrlIsValid]=useState(false);
    const itemsPerPage = 9; 
    const [isLoading,setIsLoading]=useState(true);
    const [error, setError] = useState<string | null>(null); // Error state
    const userInfo =  useSelector((state: RootState) => state.user.user);
  
    useEffect(()=>{
      if(userInfo?.companyId){
        getCompanyInformation(userInfo?.companyId.toString());
      }
      fetchPromotions();
    },[])

  const fetchPromotions = async () => {
    try {
      setIsLoading(true);
      setError(null); // Reset error state before trying again

      let response = null;

      if(userInfo?.companyId){
        response = await getAllExclusivePromotions();
      }else{
        response = await getAllPromotionsNoExclusive();
      }
       
      

      // Helper function to check valid date
      const isValidDate = (date: any): date is Date =>
        date instanceof Date && !isNaN(date.getTime());

      const filterList = response.filter((item: PromotionInterface) => {
        const now = new Date();
        const startDay = item.startDay ? new Date(item.startDay) : null;
        const endDay = item.endDay ? new Date(item.endDay) : null;

        return (
          item.isActive &&
          startDay &&
          isValidDate(startDay) &&
          endDay &&
          isValidDate(endDay) &&
          now >= startDay &&
          now <= endDay
        );
      });
      setPromotionList(filterList);
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (err) {
      setError('An error occurred while fetching promotions. Please try again.');
      console.error("Error fetching promotions:", err);
    } finally {
      setIsLoading(false);
    }
  };

    const getCompanyInformation = async (companyId:string) => {
        try {
            console.log(companyId);
            setIsLoading(true);
            const company = await getCompanyById(companyId)
            console.log(company);
            if(company?.companyLogo){
                setImgUrlIsValid(false);
                checkUrl(company?.companyLogo)
            }
            setCompanyInfo(company)
        } catch (error) {
            console.error("Error fetching company:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setCurrentPage(newPage);
    };

    const checkUrl = async (imgUrl:string) => {
        try {
          const response = await fetch(imgUrl);
          if (!response.ok) {
            setImgUrlIsValid(false);
          }
          setIsLoading(false);
          setImgUrlIsValid(true);
      
        } catch (error) {
          setImgUrlIsValid(false);
          setIsLoading(false);
        }
      };

    const totalPages = Math.ceil(promotionList.length / itemsPerPage);

    const currentPromotions = promotionList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

  return (
    <div>
        <Box className='h-24 bg-cblue-300 w-full mb-10 flex px-10 justify-end items-center gap-5'>
            <Box className='flex gap-2 text-white jusitfy-center items-center'>
                <AccountCircleIcon/>
                {isLoading ? 
                    <Skeleton variant="rounded" width={210} height={20} animation="wave" />:
                    <Typography> {userInfo?.name + ' ' + userInfo?.lastName }</Typography>
                } 
            </Box>
            {userInfo?.companyId && (
                <Box className='flex gap-2 text-white jusitfy-center items-center'>
                    <BusinessIcon/>
                    {isLoading ? 
                        <Skeleton variant="rounded" width={80} height={20} animation="wave" />:
                        <Typography> {companyInfo?.companyName} </Typography>
                    }
                </Box>
            )}

        </Box>
        <Container maxWidth='xl'>
            <Box justifyContent={'center'} className='flex gap-5 jusitfy-center items-center'>
                <Typography textAlign='center' variant='h2' component={'h2'}>Promociones Exclusivas</Typography>
                {companyInfo && imgUrlIsValid &&(
                    <img src={companyInfo.companyLogo} alt="company-logo"  width={250}/>
                )}
            </Box>
            <Box className='flex flex-col md:flex-row justify-center items-center gap-5 p-5 md:p-10'>
              {userInfo && (
              <UserProfileCard
                username={userInfo.email}
                name={userInfo.name}
                lastName={userInfo.lastName}
                email={userInfo.email}
                companyId={'Bosch'}
              />)}

              <Box className='flex flex-col gap-5'>
                {isLoading ? (
                    <Skeleton variant="rectangular" width={200} height={200} animation="wave" />
                  ) : companyInfo ? ( // Check if companyInfo exists
                    <Card
                    className="flex flex-col justify-center items-center cursor-pointer"
                    sx={{
                      width: {xs:330, md:300},
                      borderRadius: '0.5rem',
                      ':hover': {
                        boxShadow: 20,
                      },
                    }}
                  >
                    <CardMedia
                      sx={{
                        width:'90%',  // Smaller image on mobile, larger on medium screens
                        height: { xs: 100, md: 150 }, // Adjust height accordingly
                        padding: {xs:'0', md:'1em 1em 0 1em'},
                        backgroundSize: 'contain',
                      }}
                      image={companyInfo.companyLogo} // Only show if companyInfo exists
                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        const target = e.target as HTMLImageElement;
                        target.src =
                          'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/814px-Apple_logo_black.svg.png';
                        setImgUrlIsValid(false);
                      }}
                      title="Product Image"
                      /> 
                    </Card>
                  ) : null      
                }
                <PromotionCountCard promotionCount={promotionList.length}/>
              </Box>
       
              
         
            </Box>
            <section id='promotionsList'>
            <Typography variant='h3' component='h4' className='uppercase text-center'>
              Encuentra la mejor para ti
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Box marginY={5} display="flex" flexWrap="wrap" gap={5} justifyContent="center">
            {isLoading ? (
                <SkeletonPromotionCardList quantity={3} />
                ) : error ? (
                <div>
                    <p>{error}</p>
                    <button onClick={fetchPromotions}>Try Again</button> {/* Retry button */}
                </div>
                ) : currentPromotions.length > 0 ? (
                currentPromotions.map((promotion, index) => (
                    <PromotionCard
                    key={index}
                    imgUrl={promotion.imgUrl}
                    title={promotion.title}
                    description={promotion.description}
                    shortDesc={promotion.shortDesc}
                    />
                ))
                ) : (
                <p>No hay promociones por el momento.</p>
                )}
            </Box>
            <Pagination 
                count={totalPages} 
                page={currentPage} 
                onChange={handleChangePage} 
                color="primary" 
                sx={{ my: 5 }}
            />
        </Box>
        </section>
        </Container>
    </div>

  )
}

export default PromotionExclusivePage

/*
interface UserProfileProps {
  username: any;
  name: any;
  lastName: any;
  email: any;
  companyId: any | null;
}

*/