import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './CapacitySliderStyle.css'

// import required modules
import { Navigation } from 'swiper/modules';
import CapacityBox from '../Product/CapacityBox';
import { Typography } from '@mui/material';

interface CapacitySliderProps{
    capacites:number[];
}

const CapacitySlider:React.FC<CapacitySliderProps> = ({capacites}) => {
    const [currentCapacity,setCurrentCapacity]=useState(capacites[0]);
    const handleCurrentImage = (capacity: number) => {
        setCurrentCapacity(capacity);
        // You can perform any actions here with the received imgUrl
    };
  return (<>
        <div>
        <Typography variant='h6'>
            Capacidad: <b> {currentCapacity/1048 >= 1 ? `${(currentCapacity/1048).toFixed(0)} TB`:`${currentCapacity} GB`}</b>
          </Typography>
            <Swiper
            slidesPerView={5}
            spaceBetween={10}
            pagination={{
            clickable: true,
            }}
            modules={[Navigation]}
            className="CapacitySwiper"
        >
            {capacites.map((capacity,index)=>
                <SwiperSlide key={index} className='relative'>
                    <CapacityBox capacity={capacity} onClick={handleCurrentImage}/>
                </SwiperSlide>
            )}

        </Swiper>
    </div>
    </>
  )
}

export default CapacitySlider