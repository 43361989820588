import React, { useState } from 'react';
import {
    Button, TextField, Typography, IconButton, InputAdornment, CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { accessCompanyUser, sendPasswordResetEmail } from '../../services/companyUsers';
import { setUser } from '../../redux/userSlice';

interface LoginFormProps {
    handleToggleForm: () => void;
}

interface FormValues {
    email: string;
    password: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ handleToggleForm }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [openDialog, setOpenDialog] = useState(false); // Dialog state for "Forgot Password"
    const [resetEmail, setResetEmail] = useState(''); // State for email input in dialog
    const [resetMessage, setResetMessage] = useState<string | null>(null); // Message after sending reset email
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        setLoading(true);
        setResponseMessage(null);

        try {
            const res = await accessCompanyUser(values);
            setResponseMessage(res.message);
            dispatch(setUser(res.user));
            navigate('/promotions/company/admin');
        } catch (error: any) {
            const message = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
            setResponseMessage(message);
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    const handlePasswordReset = async () => {
        setResetMessage(null);
        setLoading(true);
        try {
            const res = await sendPasswordResetEmail(resetEmail); // Call to send reset email
            setResetMessage(res.message || 'Correo enviado exitosamente. Revisa tu bandeja.');
        } catch (error: any) {
            const message = error.response?.data?.message || 'Error al enviar el correo. Inténtalo de nuevo.';
            setResetMessage(message);
        } finally {
            setLoading(false);
        }
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Email inválido').required('El email es obligatorio'),
        password: Yup.string().required('La contraseña es obligatoria')
    });

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form className='flex flex-col justify-center items-center'>
                        <Typography variant="h5" mb={2}>Iniciar Sesión</Typography>
                        
                        <Field
                            as={TextField}
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            margin="normal"
                            error={touched.email && !!errors.email}
                            helperText={<ErrorMessage name="email" />}
                        />

                        <Field
                            as={TextField}
                            name="password"
                            label="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            margin="normal"
                            error={touched.password && !!errors.password}
                            helperText={<ErrorMessage name="password" />}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleShowPassword} edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        {responseMessage && (
                            <Typography
                                color={responseMessage.includes('error') ? 'error' : 'success'}
                                variant="body2"
                                mt={2}
                            >
                                {responseMessage}
                            </Typography>
                        )}

                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            fullWidth 
                            sx={{ mt: 2 }}
                            disabled={isSubmitting || loading} // Disable button while submitting
                            startIcon={loading && <CircularProgress size={20} />} // Add spinner inside button
                        >
                            {loading ? 'Iniciando...' : 'Iniciar Sesión'}
                        </Button>

                        <Button 
                            type="button" 
                            variant="text" 
                            color="primary" 
                            fullWidth 
                            sx={{ mt: 2 }}
                            onClick={handleToggleForm}
                        >
                            Registrarse
                        </Button>

                        {/* "Forgot Password?" Button */}
                        <Button
                            type="button"
                            variant="text"
                            fullWidth
                            size='small'
                            sx={{ mt: 1, fontSize:'0.75rem', color:'#000000' }}
                            
                            onClick={() => setOpenDialog(true)}
                        >
                            ¿Olvidaste tu contraseña?
                        </Button>
                    </Form>
                )}
            </Formik>

            {/* Forgot Password Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Recuperar Contraseña</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Ingresa tu email"
                        type="email"
                        fullWidth
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                    />
                    {resetMessage && (
                        <Typography color={resetMessage.includes('error') ? 'error' : 'success'} variant="body2" mt={2}>
                            {resetMessage}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="error">Cancelar</Button>
                    <Button onClick={handlePasswordReset} color="primary" disabled={loading || !resetEmail}>
                        {loading ? <CircularProgress size={20} /> : 'Enviar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LoginForm;