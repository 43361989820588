import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button,
  Box,
  DialogActions,
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CategoryIcon from '@mui/icons-material/Category';
import BadgeIcon from '@mui/icons-material/Badge';
import { CustomDataPlansAttributes } from '../../../../../constant/ServicesInterfaces';
import PlanTypeInput from '../../Inputs/PlanTypeInput';

interface EditCustomDataPlansModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (plan: CustomDataPlansAttributes) => void;
  plan: CustomDataPlansAttributes;
}

const EditCustomDataPlansModal: React.FC<EditCustomDataPlansModalProps> = ({
  open,
  onClose,
  onSave,
  plan,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<CustomDataPlansAttributes>(plan);
  const [updatedPlan, setUpdatedPlan] = useState<CustomDataPlansAttributes>(plan);

  useEffect(() => {
    if (plan) {
      setUpdatedPlan(plan);
    }
  }, [plan]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedPlan((prevState) => ({
      ...prevState,
      [name]: name === 'dataInclude' || name === 'pricePerMonth' ? parseFloat(value) : value,
    }));
  };

  const handleSave = () => {
    onSave(updatedPlan);
    setEditMode(false);
  };

  const handlePlanTypeChange = (newPlanType: string) => {
    setUpdatedPlan((prevState) => ({ ...prevState, planType: newPlanType }));
  };

  const handleEditToggle = () => {
    setEditMode(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          {editMode ? (
            <>
              <TextField
                label="ID"
                name="id"
                type="number"
                value={updatedPlan.id ?? ''}
                onChange={handleInputChange}
                disabled
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Datos Incluidos (GB)"
                name="dataInclude"
                type="number"
                value={updatedPlan.dataInclude}
                onChange={handleInputChange}
                inputProps={{ step: 0.5, min: 0 }} // Allows decimal input with a step of 0.5
                fullWidth
                sx={{ mb: 2 }}
                />
              <TextField
                label="Precio por Mes ($)"
                name="pricePerMonth"
                type="number"
                value={updatedPlan.pricePerMonth}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <PlanTypeInput planType={updatedPlan.planType} onChange={handlePlanTypeChange} />
            </>
          ) : (
            <Box  className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
                <Box className="flex items-center gap-2">
                <BadgeIcon />
                <Typography variant="body1">
                  <strong>ID:</strong> {updatedPlan.id ?? 'N/A'}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                <StorageIcon />
                <Typography variant="body1">
                  <strong>Datos Incluidos (GB):</strong> {updatedPlan.dataInclude}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                <MonetizationOnIcon />
                <Typography variant="body1">
                  <strong>Precio por Mes ($):</strong> {updatedPlan.pricePerMonth}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                <CategoryIcon />
                <Typography variant="body1">
                  <strong>Tipo de Plan:</strong> {updatedPlan.planType}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <DialogActions>
          <Button onClick={onClose} color="error" variant="contained">
            Cerrar
          </Button>
          {editMode ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button onClick={() => { setEditMode(false); setUpdatedPlan(currentPlan); }} color="error" variant="outlined">
                Cancelar
              </Button>
              <Button onClick={handleSave} color="primary" variant="contained">
                Guardar
              </Button>
            </Box>
          ) : (
            <Button onClick={handleEditToggle} color="primary" variant="contained">
              Editar
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditCustomDataPlansModal;