import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button, Box, Rating, Chip, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack, Modal } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Smartphone, SmartphoneDetailsInterface, SmartphoneInterface, SmartphonePriceInterface } from '../../../constant/PhoneModel';
import { createMultipleSmarthphones, createMultipleSmartphonesDetails, getAllSmarthphones } from '../../../services/products';
import { createMultipleSmartphonePrices } from '../../../services/smartphonePriceService';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteProductModal from '../Modal/Products/DeleteProducModal';
import { formatPrice } from '../../../utils/ConvertApp';
import { useSnackbar } from '../../Alerts/SnackBarProvider';

const defaultPriceId = 1; // Example placeholder
const defaultImgUrl = 'https://storage.googleapis.com/4-evolucion-cloud-storage/Smartphones/telefono-inteligente.png'; // Example placeholder
const defaultShortDesc = 'No description available'; // Example placeholder
const defaultInStock = true; // Example placeholder
const defaultRating = 4.0; // Example placeholder

interface ExcelReaderProps {
  existingPhones: SmartphoneInterface[];
}

const ExcelReader: React.FC<ExcelReaderProps> = ({existingPhones}) => {
  const [data, setData] = useState<any[]>([]);
  const [smartphones, setSmartphones] = useState<SmartphoneInterface[]>([]); // State for smartphones
  const [duplicateSmartphones, setDuplicateSmartphones] = useState<any[]>([]); // State for smartphones
  const [updateExistingPhone,setUpdateExistingPhones] = useState<SmartphoneInterface[]>(existingPhones);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSmartphone, setSelectedSmartphone] = useState<SmartphoneInterface | null>(null); // Smartphone to be deleted
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const expectedHeaders = [
    "MODALIDAD", "EQUIPO", "SAP", "COLORES", "CAPACIDAD", 
    "RAM", "CAMARA", "GENERACION", "PANTALLA", "ANDROID", 
    "INF. ADICIONAL", "IMAGEN", "PRECIO"
  ];

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target?.result as string;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        // Validate that the file contains at least one sheet
        if (!workbook.SheetNames.length) {
          showSnackbar('The Excel file headers do not match the expected format.', 'error');
          return;
        }
        // Read the first sheet and convert it to JSON
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // Ensure the file has rows
        if (!jsonData.length || !Array.isArray(jsonData[0])) {
          showSnackbar('The Excel file headers do not match the expected format.', 'error');
          return;
        }
        // Extract the headers (first row)
        const headers = jsonData[0]; // This is the header row
        console.log('List of Headers:', headers);

        // Validate headers
        const isValidHeader = validateHeaders(headers, expectedHeaders);
        if (!isValidHeader) {
          showSnackbar('The Excel file headers do not match the expected format.', 'error');
          return;
        }

        const dataWithoutHeader = jsonData.slice(1); // This skips the first row 
        const list: any[] = dataWithoutHeader.map((row) => transformDataToSmartphones(row));
        console.log('list of Headers')
        console.log(list);

        const flattenedList = list.reduce((acc, innerArray) => {
          return [...acc, ...innerArray];
        }, []);
       handleExistingPhone(flattenedList);
       console.log(data);
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsBinaryString(file);
    }
  };

  // Utility function to validate the headers
  const validateHeaders = (headers: any[], expectedHeaders: string[]): boolean => {
    // Check if the number of headers matches
    if (headers.length !== expectedHeaders.length) {
      return false;
    }

    // Check if each header matches the expected header (in the same order)
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].toString().trim() !== expectedHeaders[i]) {
        return false;
      }
    }

    return true;
  };

  const handleExistingPhone = (phonesData:any[]) => {
  
    // Check if updateExistingPhone is valid
    if (!updateExistingPhone || !Array.isArray(updateExistingPhone)) {
      console.error('updateExistingPhone is not an array or is undefined.');
      return;
    }

    const existingPhoneIds = updateExistingPhone.map((phone: SmartphoneInterface) => phone.id);

    console.log('Existing Phone IDs:', existingPhoneIds)
  
    const uniqueSmartphones: any[] = [];
    const duplicateSmartphones: any[] = [];
  
    // Iterate over data and check if each smartphone exists in updateExistingPhone
    phonesData.forEach((smartphone: any) => {
      const exists = existingPhoneIds.includes(smartphone.id.toString());

      if (exists) {
        duplicateSmartphones.push(smartphone);  // If the phone exists, push to duplicateSmartphones
      } else {
        uniqueSmartphones.push(smartphone);  // If the phone doesn't exist, push to uniqueSmartphones
      }
    });

    const updatedPhones = updateExistingPhone.map((phone) => {
      // Find the matching duplicate phone with the same id
      const duplicate = duplicateSmartphones.find((dupPhone) => 
        dupPhone.id.toString() === phone.id.toString()
      );
    
      // If a matching phone exists and the prices are different, update the price
      if (duplicate && Number(phone.price) !== duplicate.price) {
        console.log(`Updating phone ID ${phone.id} from price ${phone.price} to ${duplicate.price}`);
        return { ...phone, price: duplicate.price };  // Update the price in the phone object
      }
    
      // If no update is needed, return null (indicating no change)
      return null;
    }).filter((updatedPhone) => updatedPhone !== null);  // Filter out the phones that haven't changed
    
    // Log the updated phones list
    console.log('Updated Phones:', updatedPhones);
    
     
    setData(uniqueSmartphones);
    setSmartphones(uniqueSmartphones);
    setDuplicateSmartphones(updatedPhones);
  };



  const handleClearInput = () => {
    // Clear the input field by accessing the ref and setting its value to ''
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input
    }
  };


  const transformDataToSmartphones = (row: any): any[] => {
    // Validate the row length and log errors if any required fields are missing
    if (row.length < 6) {
      console.error('Row does not contain enough data:', row);
      return [];
    }
  
    const sapIdsString = row[2] !== undefined ? row[2].toString() : 'Desconocido SAP';
    const sapIds = sapIdsString.includes('/') 
      ? sapIdsString.split('/').map((id: string) => id.trim() || 'Desconocido SAP') 
      : [sapIdsString.trim() || 'Desconocido SAP'];
  
    const colors = (row[3] || 'Desconocido Color')
  .split(/\s*,\s*|\s+Y\s+/) // Split based on ',' or ' Y '
  .map((color: string) => color.trim() || 'Desconocido Color');
  
    return sapIds.map((sapId: string, index: number) => {
      const color = colors[index] || 'Desconocido Color'; // Get the corresponding color based on the index
    
      return {
        id: Number(sapId),
        modalidad:row[0],
        productName:row[1], // Use SAP ID as the unique identifier (ensure it's a number)
        smartphoneId: sapId, // Assuming sapId is the smartphone ID
        price: Number(row[12]) || 0, // Assuming PRECIO is in the thirteenth column, defaulting to 0
        capacity: parseInt(String(row[4] || '').replace(/\s*GB/i, '').replace(/\D/g, '').trim()) || 0, // Assuming CAPACIDAD is in the fifth column, defaulting to 0
        screenSize: Number(String(row[8] || '').replace(/["”]/g, '').trim()) || 0,  // Assuming PANTALLA is in the ninth column, defaulting to 0
        opSystem: row[9] || ' ', // Assuming ANDROID is in the tenth column
        camera: row[6] || ' ', // Assuming CAMARA is in the seventh column
        color: color, // Add color to the smartphone object
        connectivity: row[7], // Optional property, set as null or provide a default if applicable
        memoryRam: row[5] || ' ', // Assuming RAM is in the sixth column
        features: {}, // Assuming no features provided, default to an empty object
        description: ' ', // Assuming EQUIPO is in the third column
        productImages: [defaultImgUrl], // Assuming IMAGEN is in the twelfth column, default to an array with one image
        rating: Number(defaultRating) || null, // Default rating
      };
    });
  };

  const mapDataToInterfaces = (dataArray: any[]): {
    smartphonePrices: SmartphonePriceInterface[],
    smartphones: SmartphoneInterface[],
    smartphoneDetails: SmartphoneDetailsInterface[]
  } => {
    const smartphonePrices: SmartphonePriceInterface[] = [];
    const smartphones: SmartphoneInterface[] = [];
    const smartphoneDetails: SmartphoneDetailsInterface[] = [];

    dataArray.forEach((item) => {
      const defaultImgUrl = item.productImages[0] || ''; // Get the first image URL
      if (item.smartphoneId === 'Desconocido SAP') {
        return; // Skip to the next iteration
      }
      
      // Map to SmartphonePriceInterface
      smartphonePrices.push({
        id: 0,
        capacity: item.capacity,
        color: item.color,
        contractMode: item.modalidad, // Set this to the appropriate value if available
        price: item.price,
        smartphoneId: item.smartphoneId
      });

      // Map to SmartphoneInterface
      smartphones.push({
        id: item.smartphoneId,
        brand: '', // Placeholder, adjust as necessary
        productName: item.productName,
        shortDesc: 'No description', // Placeholder, adjust as necessary
        imgUrl: defaultImgUrl,
        rating: item.rating,
        inStock: true, // Set this to the appropriate value if available
        price: item.price,
        capacity: item.capacity
      });

      // Map to SmartphoneDetailsInterface
      smartphoneDetails.push({
        id: item.id || 0,
        smartphoneId: item.smartphoneId,
        price: item.price,
        capacity: item.capacity,
        screenSize: item.screenSize,
        opSystem: item.opSystem.toString(), // Convert to string if necessary
        camera: item.camera,
        color: item.color,
        connectivity: item.connectivity,
        memoryRam: item.memoryRam,
        features: item.features || {}, // Default to an empty object if no features
        description: item.description,
        productImages: item.productImages,
        rating: item.rating
      });
    });

    return {
      smartphonePrices,
      smartphones,
      smartphoneDetails
    };
  };


  const handleMappingToPhone = async () => {
    setLoading(true); // Start loading
    try {
      const { smartphonePrices, smartphones, smartphoneDetails } = mapDataToInterfaces(data);

      // Concurrent requests
      await Promise.all([
        createMultipleSmarthphones(smartphones),
        createMultipleSmartphonesDetails(smartphoneDetails),
        createMultipleSmartphonePrices(smartphonePrices)
      ]);

      setOpenDialog(true); // Open success dialog on successful creation
    } catch (error) {
      console.error("Error while mapping and creating smartphones:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Optionally, reset data or do other actions on close
  };

  const handleDelete = async (id: string) => {
    try {
      const updatedSmartphones = data.filter(
        (smartphone:any) => smartphone.id !== id
      );
      setIsDeleteModalOpen(false);
      setData(updatedSmartphones);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      //showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
    setSelectedSmartphone(null);
  };


  // Clears the uploaded data
  const clearData = () => {
    setData([]);
    setSmartphones([]); // Clear smartphones data as well
  };

  const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'SAP ID', 
      width: 150 
    },
    { 
      field: 'modalidad', 
      headerName: 'Modalidad', 
      width: 150 
    },
    { 
      field: 'productName', // Updated to match the interface
      headerName: 'Telefono', 
      width: 250, 
      renderCell: (params) => (
        <Typography variant="body2" fontWeight="bold">
          {params.value}
        </Typography>
      )
    },
    { 
      field: 'brand', 
      headerName: 'Brand', 
      width: 150 
    },
    { 
      field: 'color', 
      headerName: 'Color', 
      width: 150 
    },
    { 
      field: 'capacity', 
      headerName: 'Capacity (GB)', 
      width: 130 
    },
    { 
      field: 'memoryRam', // Updated to match the interface
      headerName: 'RAM', 
      width: 100 
    },
    { 
      field: 'camera', 
      headerName: 'Camera', 
      width: 130 
    },
    { 
      field: 'screenSize', // Updated to match the interface
      headerName: 'Screen Size', 
      width: 120 
    },
    { 
      field: 'opSystem', // Updated to match the interface
      headerName: 'Android Version', 
      width: 130 
    },
    { 
      field: 'price', 
      headerName: 'Price (MXN)', 
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            {formatPrice(params.row.price)}
          </Typography>
        </Stack>
      ), 
    },
    { 
      field: 'rating', 
      headerName: 'Rating', 
      width: 120, 
      renderCell: (params) => (
        <Rating value={params.value || 0} precision={0.5} readOnly />
      )
    },
    { 
      field: 'productImages', // Assuming this is an array of images, displaying the first image
      headerName: 'Image', 
      width: 150, 
      renderCell: (params) => (
        <img src={params.value[0] || defaultImgUrl} alt={params.row.description} width={50} height={50} />
      )
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
           <IconButton
            onClick={() => {
              setSelectedSmartphone(params.row as SmartphoneInterface); // Set the smartphone to be deleted
              setIsDeleteModalOpen(true); // Open confirmation modal
            }}
      >
            <DeleteIcon className='text-red-500'/>
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsModifyPrice: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'SAP ID', 
      width: 150 
    },
    { 
      field: 'productName', // Updated to match the interface
      headerName: 'Telefono', 
      width: 250, 
      renderCell: (params) => (
        <Typography variant="body2" fontWeight="bold">
          {params.value}
        </Typography>
      )
    },
    { 
      field: 'price', 
      headerName: 'Price (MXN)', 
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            {formatPrice(params.row.price)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
           <IconButton
            onClick={() => {
              setSelectedSmartphone(params.row as SmartphoneInterface); // Set the smartphone to be deleted
              setIsDeleteModalOpen(true); // Open confirmation modal
            }}
      >
            <DeleteIcon className='text-red-500'/>
          </IconButton>
        </Stack>
      ),
    },
  ];



  return (
    <Box p={4}>
      <h1 className="text-xl font-bold mb-4">Excel File Reader</h1>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} className="mb-4" />
      <Box display="flex" justifyContent="flex-end" mb={2}>
      <Box display="flex" justifyContent="flex-end" mb={2} className='gap-5'>
        <Button variant="contained" color="primary" onClick={handleMappingToPhone} disabled={loading || data.length <= 1}>
          {loading ? <CircularProgress size={24} /> : 'Guardar'}
        </Button>
        <Button variant="contained" color="error" onClick={clearData} disabled={data.length === 0}>
          Remover
        </Button>
      </Box>
      </Box>
      {data.length > 0 && ( // Change here to display smartphones
        <div style={{ height: 400, width: '100%' }} className='py-10'>
          <DataGrid
            rows={data} // Directly use smartphones as rows
            columns={columns}
          />
        </div>
      )}

      {duplicateSmartphones.length > 0 && ( // Change here to display smartphones
        <div style={{ height: 400, width: '100%' }} className='py-10'>
          <DataGrid
            rows={duplicateSmartphones} // Directly use smartphones as rows
            columns={columnsModifyPrice}
          />
        </div>
      )}

            {/* Dialog for success message */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Operación Exitosa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Los teléfonos  se han guardado correctamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {selectedSmartphone && isDeleteModalOpen && (
        <DeleteProductModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(selectedSmartphone?.id)}
          product={selectedSmartphone}
        />
      )}
    </Box>
  );
};

export default ExcelReader;