import React from 'react';
import { Modal, Box, Typography, Button, Stack } from '@mui/material';


interface AdminUserDeleteModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  user: User;
}
interface User {
    id: number;
    name: string;
    lastname: string;
    email: string;
    password: string;
    createdAt: string;
    updatedAt: string;
  }

const AdminUserDeleteModal: React.FC<AdminUserDeleteModalProps> = ({ open, onClose, onDelete, user }) => {
  const handleDelete = async () => {
    await onDelete();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ p: 4, width: 400, bgcolor: 'background.paper', margin: '100px auto' }}>
        <Typography variant="h6" gutterBottom>
          Borrar Admin User
        </Typography>
        <Typography variant="body1" gutterBottom>
          Estas seguro que deseas eliminar el usuario: <strong>{user.name} {user.lastname}</strong>?
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AdminUserDeleteModal;