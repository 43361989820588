import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TerminosYCondiciones from './Accordions/TermnsNConditions';
import ComoFacturar from './Accordions/Facturar';
import PlanesYServicios from '../Promotions/Accordions/PlansNServices';

const AdditionalInformation: React.FC = () => {
  return (
    <Box className="p-6 bg-gray-100 rounded-lg shadow-md">
      <Typography variant="h6" sx={{marginBottom:'1rem'}} className="text-gray-700 mb-4">
        Información Adicional
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Términos y Condiciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TerminosYCondiciones />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Cómo Facturar con 4 Evolución</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ComoFacturar />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Planes y Servicios</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PlanesYServicios />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AdditionalInformation;
