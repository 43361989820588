export interface CartItem {
    id: string;
    name: string;
    price: number;
    quantity: number;
    imgUrl:string;
  }
  
export enum ShippingType {Free,Express};

  export interface CartState {
    items: CartItem[];
    subtotal:number;
    shipping:ShippingType;
  }
  