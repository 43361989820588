import {Box, Button, Container, Grid, createTheme, ThemeProvider } from '@mui/material'
import CardBoxGrow from '../../components/Admin/Cards/CardBoxGrow'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import PlanServiceTable from '../../components/Admin/Tables/PlansServiceTable'
import AddPlanModal from '../../components/Admin/Modal/PlanService/AddPlanModal'
import { useState } from 'react'
import { useSnackbar } from '../../components/Alerts/SnackBarProvider'
import { PlanServiceInterface } from '../../constant/ServicesInterfaces'
import { createPlanService } from '../../services/plansService'
import { DashboardCardType1 } from '../../components/Admin/Cards/DashboardCards/DashboardCardType1'
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomPlanTable from '../../components/Admin/Tables/CustomPlanTable'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const PlanServiceAdminPage = () => {
  const [modalOpen,setModalOpen] = useState(false)
  const { showSnackbar } = useSnackbar();
  const [totalPlans, setTotalPlans] = useState<number | null>(null);
  const [activePersonalPlans, setActivePersonalPlans] = useState<number | null>(null);
  const [activeExclusivePlans, setActiveExclusivePlans] = useState<number | null>(null);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);


  // Update these states via child component actions
  const updateDashboardData = (total: number, activePersonal: number, activeExclusive: number) => {
    setTotalPlans(total);
    setActivePersonalPlans(activePersonal);
    setActiveExclusivePlans(activeExclusive);
    setLoading(false); // Assume data is fetched and loading is finished
  };
  


  const handleSavePlanService = async (planService:PlanServiceInterface) => {
    // Handle saving the promotion data here
    try {
      console.log(planService)
      const res = await createPlanService(planService);
      showSnackbar('Se Agregado de forma extiosa!', 'success');
    } catch (error) {
      console.log(error);
      showSnackbar('Hubo un error durante la creación', 'error');
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (<>
  <section className='relative flex flex-col md:flex-row'>
      <div className="hidden md:block">
        <VerticalNavBar />
      </div>
      <div className="block md:hidden">
        <MobileAdminNavBar />
      </div>
    <Container className='flex flex-col gap-5 h-[100vh] overflow-scroll' maxWidth='xl'>
    <Grid container spacing={3} className="p-6">
      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Total de Planes"
          value={totalPlans}
          description="El número total de planes registradas."
          icon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Planes Personales"
          value={activePersonalPlans}
          description="Planes Personal activos."
          icon={<GroupsIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Planes Exlusivos"
          value={activeExclusivePlans}
          description="Planes de Negocios activos."
          icon={<BusinessIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>
    </Grid>
        <Box className='flex gap-5 justify-end py-5 p-5'>
          <Button 
            color='primary' 
            variant="contained" 
            className='!rounded-full' 
            onClick={() => setModalOpen(true)}
            >
            Agregar Plan
          </Button>
        </Box>
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Planes Telefonicos" {...a11yProps(0)} />
            <Tab label="Cotizador" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <PlanServiceTable updateDashboardData={updateDashboardData}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CustomPlanTable updateDashboardData={updateDashboardData}/>
        </CustomTabPanel>
      </Box>
        <AddPlanModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSavePlanService}
        />
    </Container>
</section>
  </>
  )
}

export default PlanServiceAdminPage