import { WebSliderInterface } from "../constant/WebPageInterface";
import { publicRequest,userRequestByParams } from "../requestMethods/requestMethods";
import { AxiosResponse } from 'axios';

interface GetPrincipalBannerParams {
  category: string;
}

interface UpdateWebPageStatusParams {
  id: string;
  isActive: boolean;
}

interface WebPage {
  id: number;
  imgUrl: string;
  title: string;
  category: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
}

class WebPageServiceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'WebPageServiceError';
  }
}


export const getAllWebPage = async () => {
  try {
    const response = await publicRequest.get('/webpages');
    return response.data;
  } catch (error) {
    console.error('Error fetching webpages:', error);
    throw error;
  }
};

export const createWebSlider = async (webSlider:WebSliderInterface) => {
  try {
    const response = await publicRequest.post('/webpages',webSlider);
    return response.data;
  } catch (error) {
    console.error('Error fetching webpages:', error);
    throw error;
  }
};


export const updateWebPages = async (id: number, webSlider: WebSliderInterface) => {
  try {
    const response = await publicRequest.put(`/webpages/${id}`, webSlider);
    return response.data;
  } catch (error) {
    console.error('Error updating promotion:', error);
    throw error;
  }
};

export const updateWebPagesStatusById = async ({ id, isActive }: UpdateWebPageStatusParams): Promise<any> => {
  try {
    const params = {isActive:isActive}
    const response = await userRequestByParams(params).put(`/webpages/update-status/${id}`, {
    });

    return response.data;
  } catch (error) {
    console.error('Error updating web page status:', error);
    throw error; // Re-throw the error if you want to handle it in the calling function
  }
};

export const getWebPagesByCategory = async (category:string) => {
  try {
    const response = await publicRequest.get('/webpages/category', {
      headers: {
        category: category, // Set the category header
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching web pages:', error);
  }
};

// Delete a slider
export const deleteWebSiteSlider= async (id:number) => {
  try {
    const response = await publicRequest.delete(`/webpages/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting promotion:', error);
    throw error;
  }
};