// src/redux/userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserLoginInterface, UserAdminState, UserAdminLoginInterface } from '../constant/UserInterfaces';


const initialState: UserAdminState = {
  adminUser: null,
};

const userAdminSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserAdminLoginInterface>) => {
      state.adminUser = action.payload;
    },
    clearUser: (state) => {
      state.adminUser = null;
    },
  },
});

export const { setUser, clearUser } = userAdminSlice.actions;

export default userAdminSlice.reducer;
