import React, { useState, useEffect } from 'react';
import { Box, Card, CardMedia } from '@mui/material';
import { SmartphoneInterface } from '../../constant/PhoneModel';

interface ProductRelatedBoxProps {
    smartphoneRelated: SmartphoneInterface[];
    onPhoneSelect: (phoneId: string) => void;
}

const ProductRelatedBox: React.FC<ProductRelatedBoxProps> = ({ smartphoneRelated, onPhoneSelect }) => {
    const [clickedCard, setClickedCard] = useState<string | null>(null);

     // Function to handle card click
     const handleCardClick = (id: string) => {
        setClickedCard(id);
        onPhoneSelect(id); // Pass the clicked phone ID to the parent
    };

    // Reset clicked card after animation duration
    useEffect(() => {
        if (clickedCard) {
            const timer = setTimeout(() => {
                setClickedCard(null);
            }, 300); // Duration should match your Tailwind transition duration
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [clickedCard]);

    return (
        <Box className="flex flex-wrap gap-5">
            {Array.isArray(smartphoneRelated) && 
                smartphoneRelated.map(phone => {
                    const { id, imgUrl, productName } = phone; // Destructure phone object

                    return (
                        <Card
                            key={id}
                            className={`w-24 w-14 cursor-pointer transition-transform duration-300 ${clickedCard === id ? 'scale-110' : 'scale-100'}`} // Apply scaling classes from Tailwind
                            onClick={() => handleCardClick(id)}
                        >
                            <CardMedia
                                component="img"
                                image={imgUrl}
                                alt={productName}
                            />
                        </Card>
                    );
                })}
        </Box>
    );
};

export default ProductRelatedBox;