import React from "react";

function RedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
    >
      <g fill="#000" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path d="M28 42.667c-4.287 0-8.36-1.876-11.147-5.134l-.266-.32a14.667 14.667 0 010-18.373l.32-.387a14.667 14.667 0 0122.28 0l.266.32a14.667 14.667 0 010 18.387l-.32.373A14.667 14.667 0 0128 42.667zM28 16a12 12 0 00-9.12 4.2l-.293.347a12 12 0 000 14.96l.24.28a12 12 0 0018.253 0l.293-.347a12 12 0 000-14.947l-.24-.293A12 12 0 0028 16zM5.693 25.333h-.226A1.333 1.333 0 014.373 23.8 23.84 23.84 0 019.253 13l.507-.6a24 24 0 0114.04-8 1.352 1.352 0 11.453 2.667A21.333 21.333 0 0011.787 14.2l-.48.573a20.8 20.8 0 00-4.294 9.547 1.333 1.333 0 01-1.32 1.013zM32 51.64a1.338 1.338 0 11-.227-2.667A21.333 21.333 0 0044.24 41.84l.48-.573a20.8 20.8 0 004.293-9.547 1.352 1.352 0 012.667.453 23.84 23.84 0 01-4.88 10.8l-.507.6a24 24 0 01-14.04 8L32 51.64zm-8 0h-.227a24 24 0 01-14.04-8l-.4-.613A23.827 23.827 0 014.427 32.2a1.352 1.352 0 112.666-.453 20.787 20.787 0 004.24 9.586l.454.547a21.333 21.333 0 0012.466 7.133 1.338 1.338 0 11-.226 2.667L24 51.64zm26.307-26.307a1.334 1.334 0 01-1.334-1.106 20.787 20.787 0 00-4.306-9.56l-.454-.547a21.333 21.333 0 00-12.466-7.133 1.333 1.333 0 01-1.08-1.52 1.333 1.333 0 011.546-1.094 24 24 0 0114.04 8l.48.574a23.827 23.827 0 014.907 10.826 1.333 1.333 0 01-1.107 1.56h-.226z"></path>
        <path d="M28 42.667c-4.287 0-8.36-1.876-11.147-5.134a1.333 1.333 0 010-1.733 14.667 14.667 0 0122.294 0 1.333 1.333 0 010 1.733A14.667 14.667 0 0128 42.667zm-8.307-6c4.643 4.453 11.971 4.453 16.614 0-4.643-4.453-11.971-4.453-16.614 0zM28 25.333c-4.287 0-8.36-1.875-11.147-5.133a1.333 1.333 0 010-1.733 14.667 14.667 0 0122.294 0 1.333 1.333 0 010 1.733A14.667 14.667 0 0128 25.333zm-8.307-6c4.643 4.453 11.971 4.453 16.614 0-4.643-4.453-11.971-4.453-16.614 0z"></path>
        <path d="M28 42.667c-2.28 0-4.213-2.107-5.44-5.934A29.147 29.147 0 0121.333 28c-.035-2.96.379-5.91 1.227-8.747 1.227-3.813 3.16-5.92 5.44-5.92 2.28 0 4.213 2.107 5.44 5.934A29.147 29.147 0 0134.667 28c.035 2.96-.379 5.91-1.227 8.747-1.227 3.813-3.16 5.92-5.44 5.92zM28 16c-.787 0-2 1.253-2.893 4.067A26.667 26.667 0 0024 28a26.48 26.48 0 001.107 7.92C26 38.747 27.213 40 28 40c.787 0 2-1.253 2.893-4.067A26.667 26.667 0 0032 28a26.48 26.48 0 00-1.107-7.92C30 17.253 28.787 16 28 16zm0-5.333A5.333 5.333 0 1128 0a5.333 5.333 0 010 10.667zm0-8A2.667 2.667 0 1028 8a2.667 2.667 0 000-5.333zM28 56a5.333 5.333 0 110-10.667A5.333 5.333 0 0128 56zm0-8a2.667 2.667 0 100 5.333A2.667 2.667 0 0028 48zm22.667-14.667a5.333 5.333 0 110-10.666 5.333 5.333 0 010 10.666zm0-8a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334zm-45.334 8a5.333 5.333 0 110-10.666 5.333 5.333 0 010 10.666zm0-8a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334z"></path>
        <path d="M28 16a1.333 1.333 0 01-1.333-1.333V9.333a1.333 1.333 0 112.666 0v5.334c0 .736-.597 1.333-1.333 1.333zm18.667 13.333h-5.334a1.333 1.333 0 110-2.666h5.334a1.333 1.333 0 110 2.666zM28 48a1.333 1.333 0 01-1.333-1.333v-5.334a1.333 1.333 0 112.666 0v5.334c0 .736-.597 1.333-1.333 1.333zM14.667 29.333H9.333a1.333 1.333 0 110-2.666h5.334a1.333 1.333 0 110 2.666z"></path>
      </g>
    </svg>
  );
}

export default RedIcon;
