import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

type BrandBoxProps = {
  brandName: string;
  quantity: number;
  imgUrl: string;
  onClick: () => void;
};

const BrandBox: React.FC<BrandBoxProps> = ({ brandName, quantity, imgUrl, onClick }) => {
  const [imgUrlIsValid, setImgUrlIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUrl = async () => {
      try {
        const response = await fetch(imgUrl);
        setImgUrlIsValid(response.ok);
      } catch {
        setImgUrlIsValid(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkUrl();
  }, [imgUrl]);

  return (
    <Card
      className="flex flex-col justify-center items-center cursor-pointer"
      sx={{
        width: {xs:120, md:180},
        borderRadius: '0.5rem',
        ':hover': {
          boxShadow: 20,
        },
      }}
      onClick={onClick}
    >
      {isLoading ? (
        <Skeleton variant="rectangular" width={200} height={200} animation="wave" />
      ) : imgUrlIsValid ? (
        <CardMedia
          sx={{
            width: { xs: 50, md: 75 },  // Smaller image on mobile, larger on medium screens
            height: { xs: 100, md: 150 }, // Adjust height accordingly
            padding: {xs:'0', md:'1em 1em 0 1em'},
            backgroundSize: 'contain',
          }}
          image={imgUrl}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const target = e.target as HTMLImageElement;
            target.src =
              'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/814px-Apple_logo_black.svg.png';
            setImgUrlIsValid(false);
          }}
          title="Product Image"
        />
      ) : (
        <div className="flex justify-center items-center">
          <img
            src={require('../../assets/images/icons/telefono-inteligente.png')}
            alt="Phone Icon"
            style={{ width: 'auto%', height: '100%', maxHeight: 200, padding: '1em 1em 0 1em' }}
          />
        </div>
      )}
      <CardContent className="hidden md:flex  justify-between p-2">
        {isLoading ? (
          <div>
            <Skeleton variant="text" width={80} animation="wave" />
            <Skeleton variant="text" width={80} animation="wave" />
          </div>
        ) : (
          <div className="hidden md:flex flex-col justify-center items-center">
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {brandName}
            </Typography>
            <Typography variant="body1">{quantity} Productos</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default BrandBox;
