import React from 'react';
import { Card, CardContent, Typography, Avatar, Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import { stringAvatar } from '../../../utils/ConvertApp';

interface UserProfileProps {
  username: string;
  name: string;
  lastName: string;
  email: string;
  companyId: string | null;
}

const UserProfileCard: React.FC<UserProfileProps> = ({
  username,
  name,
  lastName,
  email,
  companyId,
}) => {
  return (
    <Card sx={{ maxWidth: 345, padding: 2, textAlign: 'center' }}>
      <CardContent>
      <Avatar {...stringAvatar(name)} className='!bg-cblue-500' sx={{ width: 100, height: 100, margin: '0 auto 20px', fontSize: '2.5rem'}} />
        <Typography variant="h5" gutterBottom>
          {name} {lastName}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
          <AccountCircleIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Username: {username}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
          <MailOutlineIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Email: {email}
          </Typography>
        </Box>
        {companyId && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <BusinessIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Company: {companyId ? companyId : 'N/A'}
          </Typography>
        </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default UserProfileCard;