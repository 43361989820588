import React from "react";

function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="55.97"
      viewBox="0 0 56 55.97"
    >
      <g fill="#010101" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path d="M34.091 22.982c-1.442-7.463-8.141-13.06-15.943-13.06S3.647 15.519 2.205 22.982C.933 23.32 0 24.423 0 25.865v.254c0 1.612 1.357 2.969 2.968 2.969h1.696c.509 0 .848-.34.848-.848v-4.495c0-.509-.339-.848-.848-.848h-.678c1.441-6.445 7.378-11.194 14.162-11.194 6.784 0 12.72 4.749 14.162 11.194h-.678c-.51 0-.848.34-.848.848v4.58c0 .508.339.847.848.847h.763l-.254.764a4.775 4.775 0 01-4.495 3.137h-.254c1.526-2.12 2.459-4.664 2.459-7.378 0-6.36-5.258-11.533-11.703-11.533-6.445 0-11.703 5.173-11.703 11.533 0 6.615 5.427 12.467 11.703 12.467 2.968 0 5.682-1.272 7.802-3.393h1.696c2.714 0 5.173-1.696 6.021-4.325l.509-1.441c1.187-.34 2.12-1.527 2.12-2.799v-.254c0-1.527-.933-2.629-2.205-2.968zm-30.275 4.41h-.848c-.678 0-1.272-.594-1.272-1.273v-.254c0-.763.594-1.272 1.272-1.272h.848v2.799zm14.332 8.989c-5.343 0-10.007-5.004-10.007-10.77 0-5.428 4.495-9.838 10.007-9.838s10.007 4.41 10.007 9.838c0 2.798-1.103 5.427-2.884 7.378h-4.24v-.51h-1.696v2.714h1.696v-.424h2.29c-1.527 1.018-3.307 1.612-5.173 1.612zM34.6 26.119c0 .679-.594 1.273-1.272 1.273h-.848v-2.799h.848c.678 0 1.272.594 1.272 1.272v.254zm-8.735 12.975H10.431c-5.088 0-9.329 4.156-9.329 9.329v6.7c0 .508.34.847.848.847h32.31c.51 0 .849-.339.849-.848v-6.784c.084-5.088-4.07-9.244-9.244-9.244zm7.632 15.18H2.883v-5.936c0-4.155 3.392-7.632 7.633-7.632H25.95c4.155 0 7.632 3.392 7.632 7.632v5.936h-.085zM44.437 0l-.594 1.611c8.311 3.053 12.72 12.806 9.244 22.134l1.611.594C58.514 14.162 53.596 3.392 44.437 0z"></path>
        <path d="M42.91 3.901l-.593 1.611c6.53 2.375 9.668 10.007 7.039 16.876l1.61.594c2.884-7.633-.508-16.283-8.055-19.081z"></path>
        <path d="M41.469 7.887l-.594 1.611c4.495 1.696 6.615 6.954 4.834 11.618l1.611.594c2.036-5.597-.424-11.873-5.851-13.823z"></path>
        <path d="M40.027 12.89l-.593 1.611c1.95.679 2.798 2.969 2.035 4.919l1.611.594c1.018-2.799-.254-6.021-3.053-7.124z"></path>
      </g>
    </svg>
  );
}

export default ContactIcon;
