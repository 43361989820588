import { publicRequest } from "../requestMethods/requestMethods";


export const getAllContactMessage = async () => {
    try {
      const response = await publicRequest.get('/contact-message');
      return response.data;
    } catch (error) {
      console.error('Error fetching smartphones:', error);
      throw error;
    }
};