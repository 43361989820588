import React, {useEffect , useState} from 'react';
// Import Swiper React components and modules
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation, Pagination } from 'swiper/modules';
import { getWebPagesByCategory } from '../../services/webPage';
import { WebSliderInterface } from '../../constant/WebPageInterface';
import { Skeleton } from '@mui/material';
import 'swiper/css'; // core Swiper
import 'swiper/css/effect-fade'; // fade effect
import 'swiper/css/pagination'; // pagination module
import './PrincipalSlidertStyle.css'; // corrected file name if it was a typo

const PrincipalSlider: React.FC = () => {
  const [sliderList,setSliderList] = useState<WebSliderInterface[]>()
  const [isLoading,setIsLoading]=useState(true);
  

  useEffect(()=>{
    getSliderInformations();
  },[]) 


  const getSliderInformations = async ()=>{
    try {
      const sliders =  await getWebPagesByCategory('Slider Principal');
      setSliderList(sliders);
    } catch (error) {
      console.log(error);
    }finally{
      setIsLoading(false)
    }
  }

  return (
    <div style={{ height:'auto', width: 'auto' }}>
      {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={600} />
        ) : (
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {sliderList &&
              sliderList
                .filter((item) => {
                  const now = new Date();
                  const startDay = new Date(item.startDay);
                  const endDay = new Date(item.endDay);

                  return item.isActive && now >= startDay && now <= endDay;
                })
                .map((item, index) => (
                  <SwiperSlide className="relative" key={index}>
                    <img src={item.imgUrl} alt={item.title || 'Slide image'} loading="lazy"/>
                  </SwiperSlide>
                ))}
          </Swiper>
      )}
    </div>
  );
};

export default PrincipalSlider;
