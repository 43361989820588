import { Card, CardActionArea, CardMedia, IconButton, Box, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeImage } from '../../../../../redux/imageSlice';

interface ImageUploadGridProps {
  validImageList: string[]; // Assuming validImageList is an array of image URLs
  setValidImageList: React.Dispatch<React.SetStateAction<string[]>>;
  handleImageSelectEdit: (event: React.ChangeEvent<HTMLInputElement>) => void; // Define the type for the event handler
}

const ImageUploadGrid: React.FC<ImageUploadGridProps> = ({ validImageList, setValidImageList, handleImageSelectEdit }) => {
  const [imageValidation, setImageValidation] = useState(false); // Add your validation logic here
  const [addedImages,setAddedImages]=useState<string[]>()

  return (
    <Grid item xs={12}>
      {/* Container for Adding Images */}
      <Box className="flex flex-wrap gap-5 justify-center my-5">
        {/* Displaying Valid Images */}
        {!imageValidation && validImageList.length > 0 &&
          validImageList.map((imageUrl: string, index: number) => (
            <Box key={index} sx={{ position: 'relative', width: '128px', height: '140px' }}>
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  image={imageUrl}
                  alt={`Image ${index + 1}`}
                  sx={{ height: '100%', objectFit: 'cover' }}
                />
                {/* Add any additional styles for the Card here if needed */}
              </Card>
              {/* Remove Icon */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '-20px', // Adjust as needed
                  right: '-20px', // Adjust as needed
                  zIndex: 1, // Ensure it's above other elements
                }}
              >
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent triggering the CardActionArea on click
                    setValidImageList(prevList => prevList.filter(url => url !== imageUrl)); // Remove image from validImageList
                  }}
                  aria-label="remove-image"
                  sx={{
                    backgroundColor: 'red', // Background for better visibility
                    borderRadius: '50%', // Make it circular
                    '&:hover': {
                      backgroundColor: '#f0f0f0', // Add hover effect
                    },
                  }}
                >
                  <RemoveIcon fontSize="small" className='text-white'/>
                </IconButton>
              </Box>
            </Box>
          ))}

        {/* Add Image Card */}
        <Card sx={{ width: '128px', height: '140px' }}>
          <CardActionArea
            onClick={() => document.getElementById('imageInput')?.click()} // Trigger file input on card click
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#f0f0f0' }}
          >
            <IconButton aria-label="add-image">
              <AddIcon fontSize="large" />
            </IconButton>
          </CardActionArea>
        </Card>

        {/* Hidden Image Input */}
        <input
          type="file"
          id="imageInput"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleImageSelectEdit} // Trigger the image selection handler
        />
      </Box>
    </Grid>
  );
};

export default ImageUploadGrid;