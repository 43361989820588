import React, { useState, useEffect } from 'react';
import { Card, CardContent, Divider,     Typography, Button, Skeleton, CardActions } from '@mui/material';
import styled from '@emotion/styled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import SnapChatIcon from '../../assets/images/icons/snapchat';
import MessengerIcon from '../../assets/images/icons/message'
import UberIcon from '../../assets/images/icons/uberIcon'
import XIcon from '../../assets/images/icons/xIcon'
import { PlanServiceInterface } from '../../constant/ServicesInterfaces';
import ServiceDetailsModal from '../Modal/ServiceDetailsModal';
import { getIconByName } from '../../constant/ServicesInterfaces';
import { formatPrice } from '../../utils/ConvertApp';

const StyledCard = styled(Card)(() => ({
  width: 320,
  height: 'auto',
  cursor: 'pointer',
  padding: '0rem 0',
  transition: 'width 0.5s ease-in-out',
  backgroundSize: 'cover',
  borderRadius: '30px',
  '& .MuiCardMedia-root': {
    height: '100%',
  },
}));

interface ServiceCardInterface{
  planService:PlanServiceInterface;
}


const ServiceCard: React.FC<ServiceCardInterface> = ({planService}) => {
  const[isLoading,setIsLoading]=useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
    <StyledCard>
      <CardContent className="flex flex-col gap-5 items-center justify-center p-2">
        {isLoading ? (
          <div>
            <Skeleton variant="text" width={80} animation="wave" />
            <Skeleton variant="text" width={80} animation="wave" />
          </div>
        ) : (
          <div className='flex w-full md:w-10/12 gap-1 flex-col justify-start items-start text-left'>
            <Typography sx={{ fontWeight: 'bold' }} className='text-cblue-500' variant="h5">{planService.planName}</Typography>
            <Typography variant="body2">Datos Incluidos:</Typography>
            <Typography variant="h6">{planService.dataIncludedGB}GB</Typography>
            <Typography variant="body2">Minutos/SMS:</Typography>
            <Typography variant="h6">{planService.minutesIncluded > 6000 ? 'Ilimitados':planService.minutesIncluded}</Typography>
            <Typography sx={{ fontWeight: 'bold' }} className='text-cblue-500' variant="h6">{formatPrice(planService.price)}</Typography>
            <div>
                <Typography variant="body2">Redes Sociales Ilimitadas:</Typography>
              <div className="flex justify-start space-x-2">
                {planService.socialMediaIncluded.map((available, index) => (
                  <div key={index}>
                    {getIconByName(available.name)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </CardContent>
        <Divider variant="middle"/>
      <CardActions className='flex flex-col gap-5 items-center justify-center py-[1rem] px-2 h-[60px]'>
        <Button onClick={handleOpenModal} className='w-fit' sx={{ borderRadius: '10px', padding: '5px 50px' }} variant="contained">Ver Detalle</Button>
      </CardActions>
      <ServiceDetailsModal
         open={isModalOpen}
         onClose={handleCloseModal}
         title={planService.planName}
         service={planService}
         description={planService.specifications}
         img={planService.coverImgUrl ? planService.coverImgUrl:'https://storage.googleapis.com/4-evolucion-cloud-storage/WebSite/7417f1-service-01.webp'}/>
    </StyledCard>
  );
}

export default ServiceCard;
