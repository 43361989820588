import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
  Card,
  CardMedia,
  Skeleton,
  Switch,
  FormControlLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { PlanServiceInterface, socialMediaIcons } from '../../../../constant/ServicesInterfaces';
import useImageValidation from '../../../../utils/ImageValidator';
import { uploadImage } from '../../../../services/gcpService';
import SpecificationsInput from '../Inputs/SpecificationsInput';
import PlanTypeInput from '../Inputs/PlanTypeInput';
import CheckboxTile from '../../Cards/CheckboxTile';

interface AddPlanModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (newPlan: PlanServiceInterface) => void;
}

const AddPlanModal: React.FC<AddPlanModalProps> = ({ open, onClose, onSave }) => {
  const [newPlan, setNewPlan] = useState<PlanServiceInterface>({
    planName: '',
    planType: '',
    dataIncludedGB: 0,
    minutesIncluded: 0,
    smsIncluded: 0,
    price: 0,
    specifications: {},
    socialMediaIncluded: [],
    premiumSocialMediaIncluded: [],  // If this is also part of the initial state
    isActive: true,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { imgUrlIsValid, isLoading } = useImageValidation(newPlan.coverImgUrl || '');
  const [minutesIncluded, setMinutesIncluded] = useState<number>(0);
  const [smsIncluded, setSmsIncluded] = useState<number>(0);
  const [isMinutesUnlimited, setIsMinutesUnlimited] = useState<boolean>(false);
  const [isSmsUnlimited, setIsSmsUnlimited] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewPlan((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleToggleUnlimited = (field: 'minutes' | 'sms') => {
    if (field === 'minutes') {
      setIsMinutesUnlimited((prev) => !prev);
      setNewPlan((prev) => ({
        ...prev,
        minutesIncluded: prev.minutesIncluded === 9999 ? 0 : 9999, // Toggle between 9999 and 0
      }));
    } else {
      setIsSmsUnlimited((prev) => !prev);
      setNewPlan((prev) => ({
        ...prev,
        smsIncluded: prev.smsIncluded === 9999 ? 0 : 9999, // Toggle between 9999 and 0
      }));
    }
  };

  const handleSpecificationChange = (newSpecs: Record<string, any>) => {
    setNewPlan((prevState) => ({
      ...prevState,
      specifications: newSpecs,
    }));
  };

  const handlePlanTypeChange = (newPlanType: string) => {
    setNewPlan((prevState) => ({ ...prevState, planType: newPlanType }));
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setNewPlan((prevState) => {
      const { socialMediaIncluded } = prevState;

      if (checked) {
        return {
          ...prevState,
          socialMediaIncluded: [...socialMediaIncluded, { name }],
        };
      } else {
        return {
          ...prevState,
          socialMediaIncluded: socialMediaIncluded.filter((item) => item.name !== name),
        };
      }
    });
  };

  const handleSave = async () => {
    try {
      let coverImgUrl = '';
  
      // If there is an image file, upload it and set the URL
      if (imageFile) {
        try {
          coverImgUrl = await uploadImage(imageFile, 'WebSite');
        } catch (uploadError) {
          console.error('Failed to upload image:', uploadError);
          // Optionally handle image upload failure (e.g., showing an error message to the user)
          return;
        }
      }
  
      // Prepare the new plan data with or without the image URL
      const newPlanData = {
        ...newPlan,
        coverImgUrl, // If no image uploaded, this will default to the empty string
      };
  
      // Call the onSave function and pass the updated plan data
      await onSave(newPlanData);
  
      // Close the modal after successful save
      onClose();
    } catch (error) {
      console.error('Failed to save the plan:', error);
      // Optionally handle save failure (e.g., showing an error message to the user)
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Añadir Nuevo Plan</DialogTitle>
      <DialogContent>
        <Box className="relative">
            <Card>
              {imagePreview ? (
                <CardMedia
                  component="img"
                  sx={{
                    width: 'fit-content',
                    height: 'auto',
                    maxHeight: 400,
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'contain',
                  }}
                  image={imagePreview}
                  title="Uploaded Image"
                />
              ) : (
                <div className="flex justify-center items-center">
                  <img
                    src={'../assets/icons/DashboardAdmin/folder.png'}
                    alt="Fallback Icon"
                    style={{
                      width: 'auto',
                      height: 'auto',
                      maxHeight: '200px',
                      padding: '1em',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              )}
            </Card>
            <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
              <input
                accept="image/*"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <IconButton
                  component="span"
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: 2,
                    '&:hover': {
                      boxShadow: 4,
                    },
                    padding: 1,
                  }}
                >
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
          </Box>

        <Box sx={{ mt: 2 }}>
         <Box className="flex flex-wrap justify-between gap-5 w-full"> 
            <TextField
                label="Nombre del Plan"
                name="planName"
                value={newPlan.planName}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
            />
            <PlanTypeInput planType={newPlan.planType} onChange={handlePlanTypeChange} />
          </Box>
          <Box className='flex flex-wrap gap-5'>
          <TextField
            label="Datos Incluidos (GB)"
            name="dataIncludedGB"
            type="number"
            value={newPlan.dataIncludedGB}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Costo Adicional por KB"
            name="additionalCostPerKB"
            type="number"
            value={newPlan.additionalCostPerKB}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            inputProps={{ min: 0 }}
          />
          </Box>
          <TextField
            label="Precio"
            name="price"
            type="number"
            value={newPlan.price}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            inputProps={{ min: 0 }}
          />
          <Box>
          <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          label="Minutos Incluidos"
          name="minutesIncluded"
          type="number"
          value={isMinutesUnlimited ? 9999 : newPlan.minutesIncluded}
          onChange={(e) => !isMinutesUnlimited && setNewPlan((prev) => ({
            ...prev,
            minutesIncluded: Number(e.target.value),
          }))}
          disabled={isMinutesUnlimited} // Disable field if unlimited
          sx={{ mb: 2, width: '60%' }} // Adjust width as needed
          inputProps={{ min: 0 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isMinutesUnlimited}
              onChange={() => handleToggleUnlimited('minutes')}
              color="primary"
            />
          }
          label="Unlimited"
        />
      </Box>

      <Box display="flex" alignItems="center">
        <TextField
          label="SMS Incluidos"
          name="smsIncluded"
          type="number"
          value={isSmsUnlimited ? 9999 : newPlan.smsIncluded}
          onChange={(e) => !isSmsUnlimited && setNewPlan((prev) => ({
            ...prev,
            smsIncluded: Number(e.target.value),
          }))}
          disabled={isSmsUnlimited} // Disable field if unlimited
          sx={{ mb: 2, width: '60%' }} // Adjust width as needed
          inputProps={{ min: 0 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isSmsUnlimited}
              onChange={() => handleToggleUnlimited('sms')}
              color="primary"
            />
          }
          label="Unlimited"
        />
      </Box>
    </Box>
    </Box>
          <Typography variant="body1">
            <strong>Redes Sociales Incluidas:</strong>
          </Typography>
          <Box className="flex flex-wrap gap-5 my-5">
            {socialMediaIcons.map((option, index) => (
              <CheckboxTile
                key={index}
                label={option.name}
                iconSrc={option.icon}
                isChecked={newPlan.socialMediaIncluded.some((item) => item.name === option.name)}
                onChange={(checked) => handleCheckboxChange(option.name, checked)}
              />
            ))}
          </Box>
          <SpecificationsInput
            specifications={newPlan.specifications || {}}
            onChange={handleSpecificationChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancelar
        </Button>
        <Button onClick={handleSave} variant='contained' color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlanModal;