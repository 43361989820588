import React from 'react';
import Chip from '@mui/material/Chip';

type FilterPillProps = {
  label: string;
  onRemove: () => void;
};

const FilterPill: React.FC<FilterPillProps> = ({ label, onRemove }) => {
  return (
    <Chip
      label={label}
      onDelete={onRemove}
      color="primary"
      className="m-1"
    />
  );
};

export default FilterPill;
