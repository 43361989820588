import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css'; // core Swiper
import 'swiper/css/effect-fade'; // fade effect
import 'swiper/css/pagination'; // pagination module
import './PrincipalSlidertStyle.css'; // corrected file name if it was a typo

// import required modules
import {Autoplay, Navigation, Pagination } from 'swiper/modules';
import CapacityBox from '../Product/CapacityBox';
import { Typography } from '@mui/material';
import { PlanServiceInterface } from '../../constant/ServicesInterfaces';
import ServiceCard from '../Card/ServiceCard';
import ServicePromoCard from '../Card/ServicePromoCard';
import { PromotionInterface } from '../../constant/PromotionInterface';
import { getAllPromotionsNoExclusive } from '../../services/promotions';
import { SkeletonPromotionCardList } from '../Card/SkeletonCard';
import PromotionCard from '../Card/PromotionCard';


const ServicePromCardSlider = () => {


  const [currentPage, setCurrentPage] = useState(1);
  const [promotionList,setPromotionList] = useState<PromotionInterface[]>([])
  const [error, setError] = useState<string | null>(null); // Error state
  const [isLoading,setIsLoading]=useState(true);
  const itemsPerPage = 9; 



  useEffect(()=>{
    fetchPromotions();
  },[])
  
  const fetchPromotions = async () => {
    try {
      setIsLoading(true);
      setError(null); // Reset error state before trying again

      const response = await getAllPromotionsNoExclusive();

      // Helper function to check valid date
      const isValidDate = (date: any): date is Date =>
        date instanceof Date && !isNaN(date.getTime());

      const filterList = response.filter((item: PromotionInterface) => {
        const now = new Date();
        const startDay = item.startDay ? new Date(item.startDay) : null;
        const endDay = item.endDay ? new Date(item.endDay) : null;

        return (
          item.isActive &&
          startDay &&
          isValidDate(startDay) &&
          endDay &&
          isValidDate(endDay) &&
          now >= startDay &&
          now <= endDay
        );
      });
      setPromotionList(filterList);
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (err) {
      setError('An error occurred while fetching promotions. Please try again.');
      console.error("Error fetching promotions:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const currentPromotions = promotionList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
);


    return (<>
        <div style={{ height: 'auto', width: 'auto', padding:'20px'}}>
            <Swiper
            modules={[Navigation,Pagination]}
            centeredSlides={true}
            pagination={{
                clickable: true,
            }}
            breakpoints={{
                450: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                  centeredSlides: false,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                  centeredSlides: false,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                  centeredSlides: false,
                },
              }}
        >
              {isLoading ? (
                <SkeletonPromotionCardList  quantity={3} />
                ) : error ? (
                <div>
                    <p>{error}</p>
                    <button onClick={fetchPromotions}>Try Again</button> {/* Retry button */}
                </div>
                ) : currentPromotions.length > 0 ? (
                currentPromotions.map((promotion, index) => (
                  <SwiperSlide  className='relative pb-10'  key={index}>
                    <PromotionCard
                    imgUrl={promotion.imgUrl}
                    title={promotion.title}
                    description={promotion.description}
                    shortDesc={promotion.shortDesc}
                    />
                  </SwiperSlide>
                ))
                ) : (
                null
              )}
        </Swiper>
    </div>
    </>
  )
}
export default ServicePromCardSlider