import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SvgIcon, useMediaQuery } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description'; // Importing MUI icons
import SettingsIcon from '@mui/icons-material/Settings'; // Importing MUI icons
import InfoIcon from '@mui/icons-material/Info'; // Importing MUI icons

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}> {/* Adjust padding for mobile */}
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface ProductDetailsTabsProps {
    descriptionContent: React.ReactNode;
    specificationsContent?: React.ReactNode;
    additionalInfoContent: React.ReactNode;
}

export default function ServiceDetailsTabs(props: ProductDetailsTabsProps) {
    const { descriptionContent, specificationsContent, additionalInfoContent } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // Use media query to check if the screen size is mobile
    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth" // Make tabs full width for better mobile experience
                >
                    <Tab 
                        icon={<DescriptionIcon />} 
                        label={!isMobile ? "Descripción" : undefined} // Show label only on desktop
                        {...a11yProps(0)} 
                    />
                     {specificationsContent && (
                    <Tab 
                        icon={<SettingsIcon />} 
                        label={!isMobile ? "Especificaciones" : undefined} // Show label only on desktop
                        {...a11yProps(1)} 
                    />)}
                    <Tab 
                        icon={<InfoIcon />} 
                        label={!isMobile ? "Información Adicional" : undefined} // Show label only on desktop
                        {...a11yProps(2)} 
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {descriptionContent}
            </CustomTabPanel>
            {specificationsContent && (<CustomTabPanel value={value} index={1}>
                {specificationsContent}
            </CustomTabPanel>)}
            <CustomTabPanel value={value} index={2}>
                {additionalInfoContent}
            </CustomTabPanel>
        </Box>
    );
}