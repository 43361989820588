import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ComoFacturar: React.FC = () => {
  return (
    <Box p={2}>
      <Typography variant="body1">
        Aquí van las instrucciones sobre cómo facturar con 4 Evolution...
      </Typography>
    </Box>
  );
};

export default ComoFacturar;
