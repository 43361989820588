import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface WarningAlertProps {
  openSnack: boolean;
  content: string;
  onClose: () => void; // Callback function to handle Snackbar close
}

const WarningAlert: React.FC<WarningAlertProps> = ({ openSnack, content, onClose }) => {
  const [open, setOpen] = React.useState<boolean>(openSnack);

  React.useEffect(() => {
    setOpen(openSnack); // Update open state when openSnack prop changes
  }, [openSnack]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose(); 
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          {content}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WarningAlert;
