import React, { useState } from 'react';
import { IconButton, TextField, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  setSearchQuery: (query: string) => void;
}

const SearchBar: React.FC<Props> = ({ setSearchQuery }) => {
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchText(inputValue);
    // Start searching only if the input length is greater than or equal to 4
    if (inputValue.length >= 4 || inputValue === '') {
      setLoading(true);
      // Simulating an API call with setTimeout
      setTimeout(() => {
        setSearchQuery(inputValue);
        setLoading(false);
      }, 1000); // Adjust the timeout as needed
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Search immediately if the input length is already >= 4
    if (searchText.length >= 4) {
      setLoading(true);
      // Simulating an API call with setTimeout
      setTimeout(() => {
        setSearchQuery(searchText);
        setLoading(false);
      }, 1000); // Adjust the timeout as needed
    }
  };

  const handleReset = () => {
    setSearchText('');
    setSearchQuery('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="search-bar"
        className="text"
        sx={{width:'100%'}}
        value={searchText}
        onChange={handleInputChange}
        label=""
        variant="outlined"
        placeholder="Buscar..."
        size="small"
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {searchText && (
                <IconButton onClick={handleReset} aria-label="clear">
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton type="submit" aria-label="search" disabled={loading || searchText.length < 4}>
                {loading ? <CircularProgress size={24} /> : <SearchIcon style={{ fill: 'gray' }} />}
              </IconButton>
            </React.Fragment>
          ),
        }}
      />
    </form>
  );
};

export default SearchBar;
