// SnackbarProvider.tsx
import { useState, useCallback, createContext, useContext, ReactNode } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { SnackbarProvider as NotistackProvider } from 'notistack';

interface SnackbarContextProps {
  showSnackbar: (message: string, severity?: 'success' | 'info' | 'warning' | 'error') => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('success');

  const showSnackbar = useCallback(
    (message: string, severityType: 'success' | 'info' | 'warning' | 'error' = 'success') => {
      setSnackBarMessage(message);
      setSeverity(severityType);
      setSnackBarOpen(true);
    },
    []
  );

  const handleCloseSnackBar = useCallback(() => {
    setSnackBarOpen(false);
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <NotistackProvider maxSnack={3}>
        {children}
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </NotistackProvider>
    </SnackbarContext.Provider>
  );
};
