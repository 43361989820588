import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Badge, Menu, MenuItem, useScrollTrigger, Box, Fab, Zoom, Avatar } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch,useSelector } from 'react-redux';
import { MenuItems } from './MenuData';
import { RootState } from '../../redux/store';
import { Link, useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { clearUser } from '../../redux/userSlice';
import { stringAvatar } from '../../utils/ConvertApp';


const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorAccount, setAnchorAccount] = React.useState<null | HTMLElement>(null);
  const cartItemCount = useSelector((state: RootState) => state.shopCart.items.length);
  const dispatch = useDispatch();
  const userInfo =  useSelector((state: RootState) => state.user.user);
  const open = Boolean(anchorEl);
  const navgiate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccount(event.currentTarget);
    navgiate('/');
  };

  const handleWhatsAppClick = () => {
    // Número de teléfono con el que se quiere contactar (incluye el código de país sin el signo +)
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    // Abrir el enlace de WhatsApp
    window.open(whatsappUrl, '_blank');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAccount = () => {
    setAnchorAccount(null);
  };

  const handleLogout = () => {
    dispatch(clearUser()); // Dispatch clearUser action
    handleClose(); // Close the menu after logging out
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleBackToTop = () => {
    const anchor = document.querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <AppBar position="sticky" sx={{background:'white', color:'black', padding:'1rem'}}>
        <Toolbar>
          <Typography variant="h6" component="div">
            <Link to='/'>
            <img src={require("../../assets/images/icons/Logo.webp")} alt="Logo" style={{ height: 60 }} />
            </Link>
          </Typography>

          <div className='hidden lg:flex grow justify-end'>
          {MenuItems.map((menuItem, index) => {
            if (menuItem.title === 'Mi Cuenta' && userInfo) {
              return (
                <div key={index}>
                  <IconButton
                    onClick={handleClickAccount}
                    color="inherit"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                  >
                    <Avatar {...stringAvatar(userInfo.name)} />
                  </IconButton>
                  <Menu
                    id="account-menu"
                    anchorEl={anchorAccount}
                    open={Boolean(anchorAccount)}
                    onClose={handleCloseAccount}
                  >
                    <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                 </Menu>
              </div>
            );
        }

        // Render "Mis Promociones" only if userInfo is not null
        if (menuItem.title === 'Mis Promociones' && userInfo) {
          return (
            <MenuItem key={index} onClick={handleCloseAccount}>
              <a href={menuItem.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                {menuItem.title}
              </a>
            </MenuItem>
          );
        }
        if(menuItem.title !== 'Mis Promociones'){
          return (
            <MenuItem key={index} onClick={handleClose}>
              <a href={menuItem.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                {menuItem.title}
              </a>
            </MenuItem>
          );
        } 
          })}
           {/* <Link to="/checkout">
              <IconButton color="inherit" aria-label="shopping cart">
                  <Badge badgeContent={cartItemCount} color="error">
                  <AddShoppingCartIcon  className='text-cblue-500 text-2xl'/>
                    </Badge>
              </IconButton>
            </Link>*/}
          </div>
        <div className="grow flex lg:hidden justify-end">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
             {MenuItems.map((menuItem, index) => {
        if (menuItem.title === 'Mi Cuenta' && userInfo) {
          return (
            <div key={index}>
              <IconButton
                onClick={handleClickAccount}
                color="inherit"
                aria-controls="account-menu"
                aria-haspopup="true"
              >
                <Avatar {...stringAvatar(userInfo.name)} />
              </IconButton>
              <Menu
                id="account-menu"
                anchorEl={anchorAccount}
                open={Boolean(anchorAccount)}
                onClose={handleCloseAccount}
              >
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </Menu>
            </div>
          );
        }

        // Render "Mis Promociones" only if userInfo is not null
        if (menuItem.title === 'Mis Promociones' && userInfo) {
          return (
            <MenuItem key={index} onClick={handleCloseAccount}>
              <a href={menuItem.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                {menuItem.title}
              </a>
            </MenuItem>
          );
        }
        if(menuItem.title !== 'Mis Promociones'){
          return (
            <MenuItem key={index} onClick={handleClose}>
              <a href={menuItem.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                {menuItem.title}
              </a>
            </MenuItem>
          );
        } 
          })}
           {/* <IconButton color="inherit" aria-label="shopping cart">
              <Badge badgeContent={cartItemCount} color="error">     
                  <AddShoppingCartIcon  className='text-cblue-500 text-2xl'/>
              </Badge>
            </IconButton>*/}
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Back to Top Button */}
      <Zoom in={trigger}>
        <Box
          onClick={handleBackToTop}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <Fab color="primary" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Zoom>
      <Zoom in={trigger}>
        <Box
          onClick={handleBackToTop}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, left: 16 }}
        >
          <Fab
            color="success"
            aria-label="whatsapp"
            onClick={handleWhatsAppClick}
            className="bg-[#25D366] hover:bg-green-600 text-white"

          >
            <WhatsAppIcon/>
          </Fab>
        </Box>
      </Zoom>
      {/* Anchor for Scroll to Top */}
      <Toolbar id="back-to-top-anchor" className='!min-h-0 '/>
    </>
  );
};

export default NavBar;
