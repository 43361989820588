import './App.css';
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

// Components
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import WhatsAppButton from './components/Buttons/WhatsAppButton';
import { SnackbarProvider } from './components/Alerts/SnackBarProvider';

// Pages
import FrontPage from './pages/FrontPage';
import ProductsPage from './pages/ProductsPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import PromotionPage from './pages/PromotionPage';
import SerivicesPage from './pages/SerivicesPage';
import PromotionExclusivePage from './pages/PromotionExclusivePage';
import LoginPage from './pages/LoginPage';
import ContactPage from './pages/ContactPage';
import CheckoutPage from './pages/CheckoutPage';
import FAQPage from './pages/FAQPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import NotFoundPage from './pages/NotFoundPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';

// Admin Pages
import AdminLogin from './pages/Admin/AdminLogin';
import DashboardPage from './pages/Admin/DashboardPage';
import ProductAdminPage from './pages/Admin/ProductAdminPage';
import PlanServiceAdminPage from './pages/Admin/PlanServiceAdminPage';
import CompaniesAdminPage from './pages/Admin/CompaniesAdminPage';
import PromotionAdminPage from './pages/Admin/PromotionAdminPage';
import CompanyUserAdminPage from './pages/Admin/CompanyUsersAdminPage';
import PromotionExclusiveAdminPage from './pages/Admin/PromotionExclusiveAdminPage';
import ContactMessageAdminPage from './pages/Admin/ContactMessageAdminPage';
import WebAdminPage from './pages/Admin/WebAdminPage';
import AdminUserPage from './pages/Admin/AdminUserPage';
import ServicesCustomPage from './pages/ServicesCustomPage';
import MobileNavBar from './components/Admin/NavBar/MobileNavBar';
import { useMediaQuery } from '@mui/material';
import ResetPasswordPage from './pages/ResetPasswordPage';

const RoutesConfig = {
  public: [
    { path: "/", element: <FrontPage /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/contact", element: <ContactPage /> },
    { path: "/checkout", element: <CheckoutPage /> },
    { path: "/services", element: <SerivicesPage /> },
    { path: "/services-build", element: <ServicesCustomPage/> },
    { path: "/promotions", element: <PromotionPage /> },
    { path: "/promotions/company/:id", element: <PromotionExclusivePage /> },
    { path: "/products", element: <ProductsPage /> },
    { path: "/product/:id", element: <ProductDetailsPage /> },
    { path: "/faq", element: <FAQPage /> },
    { path: "/reset-password", element: <ResetPasswordPage /> },
    { path: "/payment-success", element: <PaymentSuccessPage /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "*", element: <NotFoundPage /> },
    { path: "/admin", element: <AdminLogin /> },
  ],
  admin: [
    { path: "/dashboard", element: <DashboardPage /> },
    { path: "/admin-products", element: <ProductAdminPage /> },
    { path: "/admin-plan-service", element: <PlanServiceAdminPage /> },
    { path: "/admin-promotion", element: <PromotionAdminPage /> },
    { path: "/admin-promotion-exclusive", element: <PromotionExclusiveAdminPage /> },
    { path: "/admin-messages", element: <ContactMessageAdminPage /> },
    { path: "/admin-companies", element: <CompaniesAdminPage /> },
    { path: "/admin-companies/users", element: <CompanyUserAdminPage /> },
    { path: "/admin-webpage", element: <WebAdminPage /> },
    { path: "/admin-admin-users", element: <AdminUserPage /> },
  ],
};

const App = () => {
  const location = useLocation();
  const adminUser = useSelector((state) => state.adminUser.adminUser);
  const isMobile = useMediaQuery('(max-width:768px)');

  // Determine if NavBar and Footer should be hidden
  const hiddenNavFooterPaths = ['/admin', '/dashboard', '/admin-login']; // Include additional admin paths if needed
  const shouldShowNavFooter = !hiddenNavFooterPaths.some((path) => location.pathname.startsWith(path));

  // Render Admin Routes
  const renderAdminRoutes = () => {
    return RoutesConfig.admin.map(({ path, element }) => {
      return (
        <Route
          key={path}
          path={path}
          element={adminUser ? <SnackbarProvider>{element}</SnackbarProvider> : <NotFoundPage />}
        />
      );
    });
  };

  return (
    <>
      {shouldShowNavFooter && isMobile ? <MobileNavBar /> : shouldShowNavFooter && <NavBar />}
      <Routes>
        {RoutesConfig.public.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        {renderAdminRoutes()}
      </Routes>
      {shouldShowNavFooter && <Footer />}
    </>
  );
};

export default App;