// src/components/ProductTable.tsx
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarExport } from '@mui/x-data-grid';
import { IconButton, Modal, Typography, Skeleton, Box, TextField,Stack, Rating } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteProduct, getAllSmarthphones, getSmarthphonePriceById, updateSmartphone, updateSmartphoneDetails } from '../../../services/products';
import ExcelReader from '../Excel/ExcelReader';
import { margin } from '@mui/system';
import DeleteProductModal from '../Modal/Products/DeleteProducModal';
import EditProductModal from '../Modal/Products/EditProductModal';
import { SmartphoneDetailsInterface, SmartphoneInterface, SmartphonePriceInterface } from '../../../constant/PhoneModel';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import { formatPrice } from '../../../utils/ConvertApp';
import { createSmarthphonePrice, getPriceById, updateSmarthphonePrice } from '../../../services/smartphonePriceService';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { CustomToolbar } from './CustomToolBar';


interface ProductTableProps{
  onSendExisitingPhones: (data: SmartphoneInterface[]) => void;
}

const ProductTable: React.FC<ProductTableProps> = ({onSendExisitingPhones}) => {
  const [smartphones, setSmartphones] = useState<SmartphoneInterface[]>([]);
  const [currrentSmartphones, setCurrentSmartphone] = useState<SmartphoneInterface | null>();
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchSmartphones();
  }, []);

  const fetchSmartphones = async () => {
    try {
      setLoading(true);
      const data = await getAllSmarthphones();
      setSmartphones(data);
      onSendExisitingPhones(data);
    } catch (error) {
      console.error('Error fetching smartphones:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredSmartphones = smartphones.filter((smartphone) =>
    smartphone.productName.toLowerCase().includes(searchText.toLowerCase()) ||
    smartphone.brand.toLowerCase().includes(searchText.toLowerCase()) ||
    smartphone.shortDesc?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDelete = async (id: string) => {
    try {
      await deleteProduct(id);
      fetchSmartphones();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      //showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleEdit = (phone: SmartphoneInterface) => {
    setCurrentSmartphone(phone);
    setIsEditModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setCurrentSmartphone(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { 
      field: 'imgUrl', 
      headerName: 'Imagen', 
      width: 150,
      renderCell: (params) => (
        <img src={params.value} alt="Smartphone" style={{ height: 60 }} />
      ),
    },
    { field: 'productName', headerName: 'Telefono', width: 150 },
    { field: 'brand', headerName: 'Marca', width: 150 },
    {
      field: 'price',
      headerName: 'Precio',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            ${formatPrice(params.row.price)}
          </Typography>
        </Stack>
      ),
    },
    { field: 'inStock', headerName: 'In Stock', width: 100, type: 'boolean' },
    { 
      field: 'rating', 
      headerName: 'Rating', 
      width: 150, 
      renderCell: (params) => (
        <Rating value={params.value || 0} precision={0.5} readOnly />
      )
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => handleEdit(params.row as SmartphoneInterface)}>
            <EditIcon className='text-cblue-500'/>
          </IconButton>
          <IconButton onClick={() => {
            setCurrentSmartphone(params.row as SmartphoneInterface);
            setIsDeleteModalOpen(true);
          }}>
            <DeleteIcon className='text-red-500'/>
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box style={{ height: 'auto', width: '100%', margin:'1rem 0' }}>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 16 }}
        value={searchText}
        onChange={handleSearch}
      />
      {loading ? (
        <Skeleton variant="rectangular" height={600} />
      ) : (
        <DataGrid
          rows={filteredSmartphones}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          localeText={{
            toolbarExport: 'Exportar',
             toolbarExportLabel: 'Exportar',
             toolbarExportCSV: 'Descargar como CSV',
             toolbarExportPrint: 'Imprimir',
             toolbarExportExcel: 'Descargar como Excel',
         }}
         slots={{ toolbar: CustomToolbar }}
          pageSizeOptions={[5, 10, 20]}
          pagination
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      {currrentSmartphones && isEditModalOpen && (
        <EditProductModal
          open={isEditModalOpen}
          onClose={handleModalClose}
          smartphone={currrentSmartphones}
          onSave={async (
            updatedSmartphone: SmartphoneInterface,
            updatedDetails: SmartphoneDetailsInterface,
            updatePrices: SmartphonePriceInterface[]
          ) => {
            try {
              // Update smartphone and smartphone details concurrently
              if (!isDeepEqual(currrentSmartphones, updatedSmartphone)) {
                // If they are different, trigger the update
                await Promise.all([
                  updateSmartphone(updatedSmartphone.id, updatedSmartphone),
                  updateSmartphoneDetails(updatedDetails.id, updatedDetails),
                ]);
              }else{
                console.log('are Equal');
              }
          
              console.log(updatePrices);
        
              // Handle price updates using Promise.all for better performance
              await Promise.all(
                updatePrices.map(async (price) => {
                  try {
                    console.log(price);
                    const res = await getPriceById(price.id);
                    console.log('getSmarthphonePriceById:')
                    console.log(res);
                    if (!res) {
                      //await createSmarthphonePrice(price);
                      console.log('no exsit');
                    } else {
                      console.log('exsit');
                      console.log(price.id);
                      const res = await updateSmarthphonePrice(price.id, price);
         
                    }
                  } catch (error) {
                    console.error(`Error processing price for smartphone: ${price.contractMode}`, error);
                    throw new Error(`Error actualizando el  precio: ${price.contractMode}`);
                  }
                })
              );
          
              // Fetch updated smartphone data and close modal
              await fetchSmartphones();
              handleModalClose();
          
            } catch (error) {
              console.error('Error updating smartphone or prices:', error);
              showSnackbar('Error updating smartphone or prices', 'error');
            }
          }}
        />
      )}
      {currrentSmartphones && isDeleteModalOpen && (
        <DeleteProductModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(currrentSmartphones.id)}
          product={currrentSmartphones}
        />
      )}
    </Box>
  );
};

export default ProductTable;
