import React from 'react';
import PromotionCard from './PromotionCard'; // Ensure correct import path

interface PromotionsListProps {
  quantity:number;
}

export const SkeletonPromotionCardList: React.FC<PromotionsListProps> = ({quantity}) => {
  
  const renderPlaceholderCards = (count: number) => (
    Array.from({ length: count }, (_, index) => (
      <PromotionCard
        key={index}
        imgUrl={''}
        title={''}
        description={''}
        shortDesc={''}
        fetching={true}
      />
    ))
  );

  return (<>{renderPlaceholderCards(quantity)}</>);
};
