import React, { useState } from 'react';
import {
  Alert,
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Skeleton,
} from '@mui/material';
import useImageValidation from '../../../../utils/ImageValidator';

interface Promotion {
  id: number;
  imgUrl: string;
  title: string;
  description: string;
  shortDesc?: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
  isExclusive: boolean;
}

interface DeletePromotionModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  promotion: Promotion; // Adjusted to include coverImgUrl
}

const DeletePromotionModal: React.FC<DeletePromotionModalProps> = ({
  open,
  onClose,
  onDelete,
  promotion,
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(true); // State to track image loading
  const { imgUrlIsValid, isLoading } = useImageValidation(promotion.imgUrl);

  const handleDeleteConfirmation = () => {
    setConfirmDialogOpen(true);
  };

  const handleDelete = () => {
    setConfirmDialogOpen(false);
    onDelete(); // Execute the delete action
  };

  const handleImageLoad = () => {
    setImageLoading(false); // Image has loaded successfully
  };

  const handleImageError = () => {
    setImageLoading(false); // Handle error case
  };

  return (
    <>
      {/* Main dialog for delete action */}
      <Dialog open={open} onClose={onClose}>
        <Box className="flex flex-col justify-center items-center gap-5 p-5">
          <Card>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="60%"
              height={200}
              animation="wave"
              sx={{ borderRadius: '4px' }}
            />
          ) : imgUrlIsValid ? (
            <CardMedia
              component="img"
              sx={{
                width: 'fit-content%', // Set width to 70%
                height: 'auto', // Allow height to adjust automatically
                maxHeight: '200px',
                display: 'block',
                margin: 'auto',
                objectFit: 'fill',
              }}
              image={promotion.imgUrl}
              title={promotion.title}
          />
          ) : (
            <div className="flex justify-center items-center">
              <img
                src={'../assets/icons/DashboardAdmin/folder.png'}
                alt="Fallback Icon"
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '200px',
                  padding: '1em',
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
          </Card>
        </Box>

        <DialogTitle className="text-xl text-center font-bold">
          Eliminar Promoción {promotion.title}
        </DialogTitle>
        <DialogContent className="flex flex-col gap-5">
          <Alert severity="warning">Esta acción es irreversible.</Alert>
          <Typography>
            ¿Estás seguro que deseas eliminar <b>{promotion.title}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation dialog for deletion */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle className="text-xl font-bold">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Alert severity="error">Esta acción no se puede deshacer.</Alert>
          <Typography>
            ¿Estás absolutamente seguro de que deseas eliminar permanentemente <b>{promotion.title}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeletePromotionModal;