import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import useImageValidation from '../../utils/ImageValidator';

type SmallProductBoxProps = {
  productId:string;
  productName: string | null;
  price: number | null;
  imgUrl: string;
  shortDesc: string | null;
  rating: number | null;
};

const SmallProductBox: React.FC<SmallProductBoxProps> = ({ productName, price, imgUrl,productId, shortDesc, rating }) => {
  const { imgUrlIsValid, isLoading } = useImageValidation(imgUrl);

  const formattedPrice = price?.toLocaleString('en', { minimumFractionDigits: 2 });

  return (
    <Card className='p-2' sx={{ width: 140, height: 250, borderRadius: '0.5rem' }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={130} height={140} animation="wave" />
      ) : imgUrlIsValid ? (
        <Link to={`/product/${productId}`}>
          <CardMedia
            sx={{ width: 110, height: 140, padding: '1em 1em 0 1em', backgroundSize: 'contain', backgroundPosition:'center' }}
            image={imgUrl}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              const target = e.target as HTMLImageElement;
              target.src =
                'https://images.samsung.com/mx/smartphones/galaxy-s24-ultra/buy/product_color_gray.png?imwidth=480'; // Set flag to false since the fallback image is used
            }}
            title="Product Image"
          />
         </Link>
      ) : (
        <Link to={`/product/${productName}`}>
          <div className="flex justify-center items-center">
            <img
              src={require('../../assets/images/icons/telefono-inteligente.png')}
              alt="Phone Icon"
              style={{ width: 'auto%', height: '100%', maxHeight: 200, padding: '1em 1em 0 1em' }}
            />
          </div>
        </Link>
      )}
      <CardContent className='!px-2 py-5 h-24 flex flex-col justiy-center items-center'>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={100} animation="wave" />
          </>
        ) : (
          <>
            <Typography  variant="body1" component="p">
              <b>{productName}</b>
            </Typography>
          </>
        )}
        {isLoading ? (
          <Skeleton variant="text" width={80} animation="wave" />
        ) : (
          <Typography sx={{color:'#757575'}} variant="body1">${formattedPrice}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default SmallProductBox;
