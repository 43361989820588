import { userRequestByParams } from "../requestMethods/requestMethods";

export const getProductImagesUrl = async (productName: string) => {
  try {
    // Construct query parameters for the request
    const params = { productName };

    // Send the GET request using userRequestByParams with the given parameters
    const response = await userRequestByParams(params).get('/gms/product-images');

    // Ensure that response data exists before returning
    if (response?.data) {
      return response.data;
    } else {
      throw new Error('No data received from the server.');
    }
  } catch (error:any) {
    // Log a detailed error message
    console.error(`Error fetching product images for "${productName}":`, error.message || error);
    throw error;
  }
};