import React from 'react'
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import StarBorderIcon from '@mui/icons-material/StarBorder';

type RatingChipProps = {
    className:string;
    rating : number | null;
    color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning';
};


const RatingChip: React.FC<RatingChipProps> = ({rating=1}) => {
  return ( <Box
    sx={{
      width: 60,
      height: 30,
      borderRadius: 1,
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      alignContent:'center',
      gap:0.5,
      bgcolor: 'primary.main',
      '&:hover': {
        bgcolor: 'primary.dark',
      },
      color:'white'
    }}
  >
    <Typography variant='body1'>
        {rating}
    </Typography>
    <StarBorderIcon fontSize='small'/>
  </Box>
  )
}

export default RatingChip