import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button,
  Box,
  DialogActions,
} from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import { CustomPlansAttributes } from '../../../../../constant/ServicesInterfaces';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PercentIcon from '@mui/icons-material/Percent';

interface EditCustomDurationPlansModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (plan: CustomPlansAttributes) => void;
  plan: CustomPlansAttributes;
}

const EditCustomDurationPlansModal: React.FC<EditCustomDurationPlansModalProps> = ({
  open,
  onClose,
  onSave,
  plan,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<CustomPlansAttributes>(plan);
  const [updatedPlan, setUpdatedPlan] = useState<CustomPlansAttributes>(plan);

  useEffect(() => {
    if (plan) {
      setUpdatedPlan(plan);
    }
  }, [plan]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedPlan((prevState) => ({
      ...prevState,
      [name]: name === 'dataInclude' || name === 'pricePerMonth' ? parseFloat(value) : value,
    }));
  };

  const handleSave = () => {
    onSave(updatedPlan);
    setEditMode(false);
  };

  const handlePlanTypeChange = (newPlanType: string) => {
    setUpdatedPlan((prevState) => ({ ...prevState, planType: newPlanType }));
  };

  const handleEditToggle = () => {
    setEditMode(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          {editMode ? (
            <>
              <TextField
                label="ID"
                name="id"
                type="number"
                value={updatedPlan.id ?? ''}
                onChange={handleInputChange}
                disabled
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Duración (Meses)"
                name="duration"
                type="number"
                value={updatedPlan.duration}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Descuento(%)"
                name="salePercentage"
                type="number"
                value={updatedPlan.salePercentage}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </>
          ) : (
            <Box  className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
                <Box className="flex items-center gap-2">
                <BadgeIcon />
                <Typography variant="body1">
                  <strong>ID:</strong> {updatedPlan.id ?? 'N/A'}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                <AccessTimeIcon />
                <Typography variant="body1">
                  <strong>Duración (Meses):</strong> {updatedPlan.duration}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                <PercentIcon />
                <Typography variant="body1">
                  <strong>Descuento:</strong> {updatedPlan.salePercentage} %
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <DialogActions>
          <Button onClick={onClose} color="error" variant="contained">
            Cerrar
          </Button>
          {editMode ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button onClick={() => { setEditMode(false); setUpdatedPlan(currentPlan); }} color="error" variant="outlined">
                Cancelar
              </Button>
              <Button onClick={handleSave} color="primary" variant="contained">
                Guardar
              </Button>
            </Box>
          ) : (
            <Button onClick={handleEditToggle} color="primary" variant="contained">
              Editar
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditCustomDurationPlansModal;