import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AdditionalInformation from '../Promotions/AdditionalInformation';
import ProductDetailsTabs from '../Tabs/ProductDeatilsTab';
import PromotionCardV2 from '../Card/PromotionCardV2';
import TermsAndConditionsPage from '../../pages/TermsAndConditionPage';
import { height } from '@mui/system';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height:'90%',
  overflow:'auto',
  maxWidth: 1300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
};

interface TermnAndConditionModalProps {
  title: string;
  open: boolean;
  img:string;
  onClose: () => void;
}

const closeButtonStyle = {
    bgcolor: '#0061AD',
    '&:hover': {
      bgcolor: '#285177',
    },
    transition: 'all 300ms ease-out',
  };

const TermsAndConditionModal: React.FC<TermnAndConditionModalProps> = ({ title, open, onClose, img }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={headerStyle}>
          <Typography id="modal-modal-title" variant="h3" component="h3">
            <b>{title}</b>
          </Typography>
          <IconButton className='bg-cblue-500' onClick={onClose} size='large' sx={closeButtonStyle}>
            <CloseIcon className='text-white' />
          </IconButton>
        </Box>
        <Box className='flex justify-between'>
            <Box sx={{ width:'auto'}}>
               <TermsAndConditionsPage/>
            </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionModal;
