import React, { useState } from 'react';
import { Button, TextField, Typography, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { accessAdminUser } from '../../../services/adminUsers';
import { setUser } from '../../../redux/userAdminSlice';


interface FormValues {
    email: string;
    password: string;
}

const LoginAdminForm= () => {

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    const navgiate = useNavigate();
    const dispatch = useDispatch();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        setLoading(true); // Set loading state to true
        setResponseMessage(null); // Clear previous response messages

        try {
            // Replace with actual API call
            const res = await accessAdminUser(values);
            // On success
            setResponseMessage(res.message);
            dispatch(setUser(res.user));
            navgiate('/dashboard');
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setResponseMessage(error.response.data.message);
            } else {
                setResponseMessage('An unexpected error occurred. Please try again.');
            }
        } finally {
            setLoading(false); // Set loading state to false
            setSubmitting(false); // Set submitting state to false
        }
    };


    const validationSchema = Yup.object({
        email: Yup.string().email('Email inválido').required('El email es obligatorio'),
        password: Yup.string().required('La contraseña es obligatoria')
    });

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
        >
            {({ errors, touched }) => (
                <Form className='flex flex-col justify-center items-center'>
                    <Typography variant="h5" mb={2}>Iniciar Sesión</Typography>
                    <Field
                        as={TextField}
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        error={touched.email && !!errors.email}
                        helperText={<ErrorMessage name="email" />}
                    />
                    <Field
                        as={TextField}
                        name="password"
                        label="Contraseña"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        error={touched.password && !!errors.password}
                        helperText={<ErrorMessage name="password" />}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                       {loading && <CircularProgress />} {/* Show loading spinner while submitting */}

                        {responseMessage && (
                            <Typography
                                color={responseMessage.includes('error') ? 'error' : 'success'}
                                variant="body2"
                                mt={2}
                            >
                                {responseMessage}
                            </Typography>
                        )}
                                            <Button 
                        type="submit" 
                        variant="contained" 
                        disabled={loading}
                        color="primary" 
                        fullWidth 
                        sx={{ mt: 2 }}
                    >
                        Iniciar Sesión
                    </Button>
                </Form>
            )}
        </Formik>
    );
};



export default LoginAdminForm;
