import React, {useEffect, useState} from 'react'
import {Box, Button, Container } from '@mui/material'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import ProductTable from '../../components/Admin/Tables/ProductTable'
import ExcelReader from '../../components/Admin/Excel/ExcelReader'
import AddProductModal from '../../components/Admin/Modal/Products/AddProductModal'
import ExcelUploadModal from '../../components/Admin/Modal/Products/ExcelUploadModal'
import { Smartphone, SmartphoneDetailsInterface, SmartphonePriceInterface } from '../../constant/PhoneModel'
import { createSmarthphone, createSmarthphoneDetails, deleteProduct } from '../../services/products'
import { SnackbarProvider, useSnackbar } from '../../components/Alerts/SnackBarProvider'
import { SmartphoneInterface } from '../../constant/PhoneModel'
import { createSmarthphonePrice } from '../../services/smartphonePriceService'
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'

const ProductAdminPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [existingPhone,setExistingPhones] = useState<SmartphoneInterface[]>();
  const { showSnackbar } = useSnackbar();

  const handleSaveProduct = async (
    product: SmartphoneInterface,
    details: SmartphoneDetailsInterface,
    phonePrice: SmartphonePriceInterface,
    contractPrice: SmartphonePriceInterface,
    ) => {
    // Handle saving the smartphone data
    try {
      console.log('Saving product:', product);
      console.log('Saving details:', details);
  
      // Create the smartphone and details in parallel for better performance
      const [resProduct, resDetails] = await Promise.all([
        createSmarthphone(product),
        createSmarthphoneDetails(details)
      ]);

      if(phonePrice?.price > 0){
        const resPhonePrice = createSmarthphonePrice(phonePrice);
        console.log('Saved Product Response:', resPhonePrice);
      }

      if(contractPrice?.price > 0){
        const resContractPrice = createSmarthphonePrice(contractPrice);
        console.log('Saved Details Response:', resContractPrice);
      }
  
      console.log('Saved Product Response:', resProduct);
      console.log('Saved Details Response:', resDetails);
 
  
      // Show success notification
      showSnackbar('¡Teléfono agregado de forma exitosa!', 'success');
    } catch (error) {
      console.error('Error saving product and details:', error);
      // Show error notification to the user
      showSnackbar('Error al agregar el teléfono. Por favor, inténtelo de nuevo.', 'error');
    }
  };

   // This function will be passed to the child and called when needed
   const handleExistingPhone = (data: SmartphoneInterface[]) => {
    setExistingPhones(data); // Update the state with the data from child
  };


  return (<>
     <section className='relative flex flex-col md:flex-row'>
      <div className="hidden md:block">
              <VerticalNavBar />
            </div>
            <div className="block md:hidden">
              <MobileAdminNavBar />
            </div>
    <Container className='flex flex-col gap-5'>
    <SnackbarProvider>
        <Box className='flex justify-center items-center gap-5 my-5'>
            <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" className='!rounded-full'>
              Agregar Telefono
            </Button>
            <AddProductModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSave={handleSaveProduct}
            />
            {existingPhone && <ExcelUploadModal existingPhones={existingPhone}/>}
        </Box>
        <Box>
          <ProductTable onSendExisitingPhones={handleExistingPhone}/>
        </Box>

        </SnackbarProvider>
    </Container> 
    
</section>
  </>
  )
}

export default ProductAdminPage