import React, { useEffect, useState } from 'react';
import {
  Typography,
  Skeleton,
  Box,
  IconButton,
  Switch,
  Stack
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import {
  updatePromotion,
  getAllPromotions,
  getExclusivePromotions,
  getNonExclusivePromotions,
  updatePromotionExclusiveStatusById,
  updatePromotionStatusById,
 deletePromotion
} from '../../../services/promotions';
import EditPromotionModal from '../Modal/Promotions/EditPromotionModal';
import DeletePromotionModal from '../Modal/Promotions/DeletePromotionModal';
import { CustomToolbar } from './CustomToolBar';

interface Promotion {
  id: number;
  imgUrl: string;
  title: string;
  description: string;
  shortDesc?: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
  isExclusive: boolean;
}

interface PromotionTableProps {
  exclusive: boolean;
  all?: boolean;
  updateDashboardData?: (total: number, activePersonal: number, activeExclusive: number) => void;
}

const PromotionTable: React.FC<PromotionTableProps> = ({ exclusive, all = false, updateDashboardData }) => {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [currentPromotion, setCurrentPromotion] = useState<Promotion | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPromotions();
  }, [exclusive, all]);

  const fetchPromotions = async () => {
    setLoading(true);
    try {
      const fetchFunction = all ? getAllPromotions : (exclusive ? getExclusivePromotions : getNonExclusivePromotions);
      const data = await fetchFunction();
      setPromotions(data);

      let total: number = data.length;  // Total companies fetched from API
      let activePersonal: number = data.filter((promotion: Promotion) => promotion.isExclusive == false).length; 
      let activeExclusive: number = data.filter((promotion: Promotion) => promotion.isExclusive).length; 
      if(exclusive){
        total=activeExclusive;
        activeExclusive=data.filter((promotion: Promotion) => promotion.isExclusive && promotion.isActive).length;
        activePersonal= data.filter((promotion: Promotion) => promotion.isExclusive && promotion.isActive == false).length;
      }

      // Update the dashboard data with the computed values
      updateDashboardData && updateDashboardData(total, activePersonal, activeExclusive);
    } catch (error) {
      console.error('Error fetching promotion data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePromotionStatus = async (id: number, isActive: boolean) => {
    try {
      const response = await updatePromotionStatusById(id, !isActive);
      if (response) {
        showSnackbar('Promotion status updated successfully!', 'success');
        fetchPromotions();
      } else {
        console.error('Received invalid response:', response);
      }
    } catch (error) {
      console.error('Error updating promotion status:', error);
      showSnackbar('Error updating promotion status', 'error');
    }
  };

  const handlePromotionExclusiveStatus = async (id: number, isExclusive: boolean) => {
    try {
      const response = await updatePromotionExclusiveStatusById(id, !isExclusive);
      if (response) {
        showSnackbar('Promotion exclusivity updated successfully!', 'success');
        fetchPromotions();
      } else {
        console.error('Received invalid response:', response);
      }
    } catch (error) {
      console.error('Error updating promotion exclusivity:', error);
      showSnackbar('Error updating promotion exclusivity', 'error');
    }
  };

  const handleEdit = (promotion: Promotion) => {
    setCurrentPromotion(promotion);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (promotion: Promotion) => {
    try {
      await deletePromotion(promotion.id);
      showSnackbar('Promotion deleted successfully!', 'success');
      fetchPromotions();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setCurrentPromotion(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'imgUrl',
      headerName: 'Image',
      width: 130,
      renderCell: (params) => (
        <img src={params.value} alt="Promotion" style={{ height: 60 }} />
      ),
    },
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'startDay', headerName: 'Start Date', width: 150 },
    { field: 'endDay', headerName: 'End Date', width: 150 },
    {
      field: 'isActive',
      headerName: 'Active',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <Switch
          onChange={() => handlePromotionStatus(params.row.id, params.row.isActive)}
          checked={params.row.isActive}
          color="success"
        />
      ),
    },
    {
      field: 'isExclusive',
      headerName: 'Exclusive',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <Switch
          onChange={() => handlePromotionExclusiveStatus(params.row.id, params.row.isExclusive)}
          checked={params.row.isExclusive}
          color="primary"
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => handleEdit(params.row as Promotion)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => {
            setCurrentPromotion(params.row as Promotion);
            setIsDeleteModalOpen(true);
          }}>
            <DeleteIcon color='error'/>
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '90%',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={600} />
      ) : (
        <DataGrid
          rows={promotions}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Descargar como Excel',
          }}
          slots={{ toolbar: CustomToolbar }}
          pagination
        />
      )}

      {currentPromotion && isEditModalOpen && (
        <EditPromotionModal
          open={isEditModalOpen}
          onClose={handleModalClose}
          promotion={currentPromotion}
          onSave={async (updatedPromotion: Promotion) => {
            try {
              await updatePromotion(updatedPromotion.id, updatedPromotion);
              showSnackbar('Promotion updated successfully!', 'success');
              fetchPromotions();
              handleModalClose();
            } catch (error) {
              console.error('Error updating promotion:', error);
              showSnackbar('Error updating promotion', 'error');
            }
          }}
        />
      )}
      {currentPromotion && isDeleteModalOpen && (
        <DeletePromotionModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(currentPromotion)}
          promotion={currentPromotion}
        />
      )}
    </Box>
  );
};

export default PromotionTable;