import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton, List, ListItem, ListItemText, Drawer, Avatar, Typography, Button, AppBar, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { MenuItems } from '../../NavBar/MenuData';
import { clearUser } from '../../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store'; // Assuming you have this set up for Redux
import { stringAvatar } from '../../../utils/ConvertApp';
import { fontSize } from '@mui/system';

const MobileNavBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo =  useSelector((state: RootState) => state.user.user);

  // Toggle the drawer open/close
  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  // Handle user logout
  const handleLogout = () => {
    console.log('logOut');
    dispatch(clearUser());
    console.log(userInfo);
     // Close the drawer
    navigate('/');         // Navigate to the home page
  };

  // Effect to watch for userInfo changes and close the drawer if needed
  useEffect(() => {
    if (!userInfo) {
      toggleDrawer(false); // Close the drawer when user logs out
    }
  }, [userInfo]);

  return (
    <AppBar position="sticky" sx={{background:'white', color:'black', padding:'1rem'}}>
       <Toolbar className='flex justify-between'>
      {/* Logo */}
      <Link to="/">
        <img src={require("../../../assets/images/icons/Logo.webp")} alt="Logo" style={{ height: 30 }} />
      </Link>

      {/* Menu Icon (Burger Menu) */}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => toggleDrawer(true)}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right" // Drawer appears from the right
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        {/* Drawer Content */}
        <Box sx={{ width: 250 }} role="presentation">
          {/* Close Icon */}
          <Box display="flex" justifyContent="flex-end" p={2}>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon/>
            </IconButton>
          </Box>

          {/* User Info (if logged in) */}
          {userInfo && (
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <Avatar {...stringAvatar(userInfo.name)} />
              <Typography variant="body1"  mt={1}>
                {userInfo.name}
              </Typography>
            </Box>
          )}

          {/* Menu Items */}
          <List>
            {MenuItems.map((item, index) => {
              // Handle 'Mi Cuenta' differently based on login status
              if (item.title === 'Mi Cuenta' && userInfo) {
                return null;
              }

              // Render "Mis Promociones" only if userInfo is not null
              // Render "Mis Promociones" only if userInfo is not null
              if (item.title === 'Mis Promociones' && !userInfo) {
                return null; // Do not render 'Mis Promociones' if the user is not logged in
              }

              return (
                <Link to={item.url} key={index} style={{ textDecoration: 'none', color: 'inherit'}}>
                  <ListItem button onClick={() => toggleDrawer(false)}>
                    <ListItemText primary={item.title} primaryTypographyProps={{ style: { fontSize: '24px', fontWeight:'bold' } }} />
                  </ListItem>
                </Link>
              );
            })}
          </List>

          {/* Logout Button (If user is logged in) */}
          {userInfo && (
            <Box mt={2} textAlign="center" className='flex justify-center items-center'>
              <Button
                variant="contained"
                color="error"
                startIcon={<ExitToAppIcon />}
                onClick={handleLogout}
              >
                Cerrar Sesion
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default MobileNavBar;