import React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';

interface CheckboxTileProps {
  iconSrc: string | React.ReactNode ;
  label: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;  // Callback to pass the checked state to the parent
}

const CustomCheckbox = styled(Checkbox)(() => ({
  clip: 'rect(0 0 0 0)',
  WebkitClipPath: 'inset(100%)',
  clipPath: 'inset(100%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}));

const CheckboxTile: React.FC<CheckboxTileProps> = ({ iconSrc, label, isChecked, onChange }) => {

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className="checkbox-wrapper-16">
      <label
        className={clsx(
          'checkbox-wrapper relative flex flex-col items-center justify-center w-28 min-h-28 p-4 rounded-lg border-2 bg-white shadow-md transition-all duration-150 ease-in-out cursor-pointer',
          isChecked ? 'border-blue-500 shadow-lg' : 'border-gray-300'
        )}
      >
        <CustomCheckbox
          className="checkbox-input"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          className={clsx(
            'checkbox-tile flex flex-col items-center justify-center w-full h-full transition-all duration-150',
            isChecked ? 'text-blue-500' : 'text-gray-600'
          )}
        >
          <span className="checkbox-icon">
          {typeof iconSrc === 'string' ? (
              <img src={iconSrc} alt={label} className="w-12 h-12 object-contain" />
            ) : (
              // Render as a React node or require
              iconSrc
            )}
          </span>
          <span className="checkbox-label mt-2 text-center">
            {label}
          </span>
        </span>

        {isChecked && (
          <span className="absolute top-1 left-1 bg-blue-500 rounded-full w-5 h-5 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 256"
              className="w-3 h-3 text-white"
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
            >
              <polyline points="216 72.005 104 184 48 128.005" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>
        )}
      </label>
    </div>
  );
};

export default CheckboxTile;