import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
  background: {
    backgroundImage:
      'url(https://storage.googleapis.com/4-evolucion-cloud-storage/WebSite/f982f5-_DSC0685-Edit.jpg)', // Replace with your background image URL
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFFFFF',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    clipPath: 'polygon(35% 0%, 100% 0%, 100% 100%, 10% 100%)',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },
  paper: {
    padding: '1rem',
    textAlign: 'center',
    background: '#fff',
    width: '600px',
    maxWidth: '600px',
  },
  form: {
    '& .MuiTextField-root': {
      margin: '1rem',
      width: '90%',
    },
  },
  submit: {
    marginTop: '1rem',
  },
}));

const ContactPage: React.FC = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    message: '',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Simulate sending an email
      // You can integrate a real email sending service here
      await fakeEmailService(formData);
      setSuccess(true);
      setFormData({
        name: '',
        company: '',
        email: '',
        message: '',
      });
    } catch (err) {
      setError(true);
    }
  };

  // Mock email sending function
  const fakeEmailService = (data: typeof formData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        if (data.email) resolve('Email sent');
        else reject('Error sending email');
      }, 1000);
    });

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={6} className={classes.formContainer}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Contáctanos
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              required
              id="name"
              label="Nombre"
              value={formData.name}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              required
              id="company"
              label="Empresa"
              value={formData.company}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              required
              id="email"
              label="Correo Electrónico"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              multiline
              rows={4}
              required
              id="message"
              label="Mensaje"
              value={formData.message}
              onChange={handleChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Enviar Mesaje
            </Button>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.background} />

      {/* Snackbar for success message */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          ¡Tu mensaje ha sido enviado exitosamente!
        </Alert>
      </Snackbar>

      {/* Snackbar for error message */}
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          Hubo un error al enviar tu mensaje. Inténtalo de nuevo.
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ContactPage;
