import { Box, Typography } from '@mui/material';
import React from 'react'
import { faqQuestions } from './Data/faqQuestions';
import NetworkIcon from '../../assets/images/icons/Faq/network';


interface FaqBoxProps{
    title:string;
    content:string;
    icon?: React.ComponentType | string | null;
}

const FaqBox:React.FC<FaqBoxProps> = (props) => {
  return (
  <Box className='flex gap-5 justify-start w-full md:w-1/2 max-w-[650px]'>
    <Box className='flex justify-center items-center w-[140px] h-[60px] p-2 border-2 border-cblue-500 rounded-md overflow-hidden'>
        {props.icon ? (
        typeof props.icon  === 'string' ? (
            <img src={props.icon } alt={props.title} className="faq-icon" />
        ) : (
            React.createElement(props.icon)
        )
        ) : (
        <img src={''} alt={props.title} className="faq-icon" />
        )}
    </Box>
    <Box className='text-justify'>
        <Typography variant='h5' className='font-bold font-[Avenir]' fontWeight={'bold'}>
            {props.title}
        </Typography>
        <Typography variant='body2' className='font-bold font-[Avenir] text-csecondary-200'>
            {props.content}
        </Typography>
    </Box>
  </Box>
  )
}

export default FaqBox