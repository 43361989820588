import React, { useState } from 'react';
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import {CompanyUserInterface } from '../../../../constant/CompanyInterface';

interface DeleteCompanyUserModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  user: CompanyUserInterface; // Ajustar este tipo según tus necesidades
}

const DeleteCompanyUserModal: React.FC<DeleteCompanyUserModalProps> = ({ open, onClose, onDelete, user }) => {
  const uri = ''
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Estado para manejar el segundo diálogo

  const handleDeleteConfirmation = () => {
    setConfirmDialogOpen(true); // Abrir el segundo diálogo
  };

  const handleDelete = () => {
    setConfirmDialogOpen(false); // Cerrar el segundo diálogo
    onDelete(); // Ejecutar la acción de eliminación
  };

  return (
    <>
      {/* Primer diálogo para eliminar */}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className="text-xl text-center font-bold">Eliminar {user.email}</DialogTitle>
        <DialogContent className="flex flex-col gap-5">
          <Alert severity="warning">Esta acción es irreversible.</Alert>
          <Typography>
            ¿Estás seguro que deseas eliminar <b>{user.email}</b>? 
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Segundo diálogo para confirmar la eliminación */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle className="text-xl font-bold">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Alert severity="error">Esta acción no se puede deshacer.</Alert>
          <Typography>
            ¿Estás absolutamente seguro de que deseas eliminar permanentemente <b>{user.email}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteCompanyUserModal;
