import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AdditionalInformation from '../Promotions/AdditionalInformation';
import ProductDetailsTabs from '../Tabs/ProductDeatilsTab';
import PromotionCardV2 from '../Card/PromotionCardV2';
import ServicePromoCard from '../Card/ServicePromoCard';
import { Button } from '@mui/material';
import { handleWhatsAppClick } from '../../utils/WhatsAppUtils';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  maxWidth: 1300,
  maxHeight: 700,
  overflowY:'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '1rem', // Default padding for larger screens
  '@media (max-width: 640px)': { // Target small screens
    padding: '0.25rem', // Smaller padding for mobile screens
    maxHeight:500,
    
  },
};
const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '1rem', // Default padding for larger screens
  '@media (max-width: 640px)': { // Target small screens
    padding: '1.75rem 0.75rem', // Smaller padding for mobile screens
  },
};

interface PromotionDetailModalProps {
  title: string;
  shortDes: string;
  description: string;
  open: boolean;
  img:string;
  onClose: () => void;
}

const closeButtonStyle = {
    bgcolor: '#0061AD',
    '&:hover': {
      bgcolor: '#285177',
    },
    transition: 'all 300ms ease-out',
  };

const PromotionDetailsModal: React.FC<PromotionDetailModalProps> = ({ title, shortDes, description, open, onClose, img }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={headerStyle}>
          <Typography id="modal-modal-title" variant="h3" component="h3" sx={{fontSize:{xs:'1.7rem', md:'3rem'}}}>
            <b>{title}</b>
          </Typography>
          <IconButton className='bg-cblue-500' onClick={onClose} size='large' sx={closeButtonStyle}>
            <CloseIcon className='text-white' />
          </IconButton>
        </Box>
        <Box className='flex flex-wrap-reverse justify-center'>
            <Box sx={{ width: { xs: '100%', md: '70%' }, margin: '0 auto' }}>
              <Box className='flex flex-wrap gap-5 xl:gap-0 justify-center xl:justify-between'>
                <Typography id="modal-modal-description" sx={{ mt: 2}}>
                    {shortDes}
                </Typography>
                <Button 
                  onClick={handleWhatsAppClick} 
                  className='w-fit' 
                  sx={{borderRadius:'30px', padding:'10px 30px'}} 
                  color='success' 
                  variant="contained">
                    Contrata
                </Button>
              </Box>
                <ProductDetailsTabs
                    descriptionContent={description}
                    additionalInfoContent={<AdditionalInformation />}
                />
            </Box>
            <div className='block'>
              <ServicePromoCard img={encodeURI(img)}/>
            </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default PromotionDetailsModal;
