import React, { useState } from 'react';
import { Modal, Box, Button, TextField, Checkbox, FormControlLabel, IconButton, Card, CardMedia, Switch, InputAdornment, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { uploadImage } from '../../../../services/gcpService';
import { SmartphoneInterface, SmartphoneDetailsInterface, SmartphonePriceInterface } from '../../../../constant/PhoneModel';
import SpecificationsInput from '../Inputs/SpecificationsInput';
import CapacityIcon from '@mui/icons-material/Storage'; // Example icon for capacity
import ScreenSizeIcon from '@mui/icons-material/ScreenShare'; // Example icon for screen size
import OpSystemIcon from '@mui/icons-material/Computer'; // Example icon for operating system
import CameraIcon from '@mui/icons-material/CameraAlt'; // Example icon for camera
import ColorLensIcon from '@mui/icons-material/ColorLens'; // Example icon for color
import MemoryIcon from '@mui/icons-material/Memory'; // Example icon for RAM
import ImagesInput from './ImagesInput/ImagesInput';
import CellTowerIcon from '@mui/icons-material/CellTower';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: 700,
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AddProductModal: React.FC<{ 
  open: boolean; onClose: () => void; 
  onSave: (product: SmartphoneInterface, 
    details: SmartphoneDetailsInterface, 
    phonePrice:SmartphonePriceInterface,
    contractPrice:SmartphonePriceInterface  ) => void; 
  }> = ({ open, onClose, onSave }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [selectedImages, setSelectedImages] = useState<string[]>([]); 
  const [loading, setLoading] = useState(false); 
  const [isPriceEnabled, setIsPriceEnabled] = useState<boolean>(true);
  const [isContractPriceEnabled, setIsContractPriceEnabled] = useState<boolean>(true);

  const handlePriceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPriceEnabled(event.target.checked);
    // If disabling, set the value to 0
    if (!event.target.checked) {
      formik.setFieldValue('price', 0);
    }
  };
  
  const handleContractPriceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsContractPriceEnabled(event.target.checked);
    // If disabling, set the value to 0
    if (!event.target.checked) {
      formik.setFieldValue('contractPrice', 0);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      productName: '',
      brand: '',
      capacity: 0,
      rating: 1.0,
      price: 0,
      contractPrice:0,
      shortDesc: '',
      inStock: true,
      screenSize: 0,
      opSystem: '',
      camera: '',
      color: '',
      memoryRam: '',
      connectivity:'',
      description:'',
      features: {}
    },
    validationSchema: Yup.object({
      id: Yup.string().required('El ID es requerido'),
      
      productName: Yup.string()
        .required('El nombre del producto es requerido'),
        
      brand: Yup.string()
        .required('La marca es requerida'),
        
      capacity: Yup.number()
        .required('La capacidad es requerida')
        .positive('La capacidad debe ser un número positivo')
        .integer('La capacidad debe ser un número entero'),
        
      rating: Yup.number()
        .required('La calificación es requerida')
        .min(0, 'La calificación no puede ser menor que 0')
        .max(5, 'La calificación no puede ser mayor que 5'),
      
      shortDesc: Yup.string()
        .required('La descripción corta es requerida'),
      
      screenSize: Yup.number()
        .required('El tamaño de la pantalla es requerido')
        .positive('El tamaño de la pantalla debe ser un número positivo'),
      
      opSystem: Yup.string()
        .required('El sistema operativo es requerido'),
      
      camera: Yup.string()
        .required('Los detalles de la cámara son requeridos'),
      
      color: Yup.string()
        .required('El color es requerido'),
      
      memoryRam: Yup.number() // Assuming memory is a numeric value, change to string if it's an actual string
        .required('La memoria RAM es requerida')
        .positive('La memoria RAM debe ser un número positivo'),
      
      connectivity: Yup.string()
        .required('La conectividad es requerida'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let imgUrl = '';
        if (imageFile) {
          imgUrl = await uploadImage(imageFile, 'Products');
        }

        const smartphoneData: SmartphoneInterface = {
          id: values.id,
          productName: values.productName,
          brand: values.brand,
          shortDesc: values.shortDesc,
          imgUrl: imgUrl,
          rating: values.rating,
          inStock: values.inStock,
          price: values.price,
          capacity: values.capacity,
        };

        const smartphoneDetails: SmartphoneDetailsInterface = {
          id: 0, // This will be auto-incremented in the backend
          smartphoneId: values.id,
          price: values.price,
          capacity: values.capacity,
          screenSize: values.screenSize,
          opSystem: values.opSystem,
          camera: values.camera,
          color: values.color,
          description: values.description,
          memoryRam: values.memoryRam,
          connectivity:values.connectivity,
          features: values.features, // Convert comma-separated string to an array
          productImages: selectedImages, // You could modify this to handle multiple images if needed
        };

        const phonePrice:SmartphonePriceInterface = {
          id:0,
          capacity:values.capacity,
          color:values.color,
          price:values.price,
          contractMode:'AMIGO KIT',
          smartphoneId:values.id
        }

        const contractPrice:SmartphonePriceInterface = {
            id:0,
            capacity:values.capacity,
            color:values.color,
            price:values.contractPrice,
            contractMode:'TARIFARIOS',
            smartphoneId:values.id
        }

        onSave(
           smartphoneData,
           smartphoneDetails,
           phonePrice,
           contractPrice
          );
        onClose();
      } catch (error) {
        console.error('Error during submission:', error);
      } finally {
        setLoading(false);  // Reset loading to false after submission
      }
    },
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageSelect = (isChecked: boolean, imgSrc: string) => {
    setSelectedImages((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, imgSrc]; // Add image if checked
      } else {
        return prevSelected.filter((img) => img !== imgSrc); // Remove image if unchecked
      }
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit}>
           {/* Image Upload and Preview */}
           <Box className="relative">
            <Card>
              {imagePreview ? (
                <CardMedia
                  component="img"
                  sx={{
                    width: 'fit-content',
                    height: 'auto',
                    maxHeight: 400,
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'contain',
                  }}
                  image={imagePreview}
                  title="Uploaded Image"
                />
              ) : (
                <div className="flex justify-center items-center">
                  <img
                    src={'../assets/icons/DashboardAdmin/folder.png'}
                    alt="Fallback Icon"
                    style={{
                      width: 'auto',
                      height: 'auto',
                      maxHeight: '200px',
                      padding: '1em',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              )}
            </Card>
            <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
              <input
                accept="image/*"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <IconButton
                  component="span"
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: 2,
                    '&:hover': {
                      boxShadow: 4,
                    },
                    padding: 1,
                  }}
                >
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
          </Box>
          <Box className='flex flex-wrap justify-between'>
            <TextField
              margin="normal"
              id="id"
              name="id"
              label="ID de Producto"
              value={formik.values.id}
              onChange={formik.handleChange}
              error={formik.touched.id && Boolean(formik.errors.id)}
              helperText={formik.touched.id && formik.errors.id}
            />
            <TextField
              margin="normal"
              id="productName"
              name="productName"
              label="Nombre de Producto"
              value={formik.values.productName}
              onChange={formik.handleChange}
              error={formik.touched.productName && Boolean(formik.errors.productName)}
              helperText={formik.touched.productName && formik.errors.productName}
            />
            <TextField
              margin="normal"
              id="brand"
              name="brand"
              label="Marca"
              value={formik.values.brand}
              onChange={formik.handleChange}
              error={formik.touched.brand && Boolean(formik.errors.brand)}
              helperText={formik.touched.brand && formik.errors.brand}
            />
            <TextField
              
              margin="normal"
              id="rating"
              name="rating"
              label="Rating (0-5)"
              type="number"
              value={formik.values.rating}
              onChange={formik.handleChange}
              error={formik.touched.rating && Boolean(formik.errors.rating)}
              helperText={formik.touched.rating && formik.errors.rating}
            />
          </Box>
          <Box className='flex flex-wrap justify-between'>
      <Box display="flex" alignItems="center">
        <TextField
          margin="normal"
          id="price"
          name="price"
          label="Precio (AmigoKit)"
          type="number"
          value={formik.values.price}
          onChange={formik.handleChange}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
          }}
          disabled={!isPriceEnabled} // Disable field based on switch state
        />
        <FormControlLabel
          control={
            <Switch
              checked={isPriceEnabled}
              onChange={handlePriceSwitchChange}
              color="primary"
            />
          }
          label="Habilitar Precio"
          style={{ marginLeft: 10 }} // Adjust margin for spacing
        />
      </Box>

      <Box display="flex" alignItems="center">
        <TextField
          margin="normal"
          id="contractPrice"
          name="contractPrice"
          label="Precio Tarifado"
          type="number"
          value={formik.values.contractPrice}
          onChange={formik.handleChange}
          error={formik.touched.contractPrice && Boolean(formik.errors.contractPrice)}
          helperText={formik.touched.contractPrice && formik.errors.contractPrice}
          InputProps={{
            endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
          }}
          disabled={!isContractPriceEnabled} // Disable field based on switch state
        />
        <FormControlLabel
          control={
            <Switch
              checked={isContractPriceEnabled}
              onChange={handleContractPriceSwitchChange}
              color="primary"
            />
          }
          label="Habilitar Tarifado"
          style={{ marginLeft: 10 }} // Adjust margin for spacing
        />
      </Box>
    </Box>
          <Box className='flex flex-wrap justify-between'>
            <TextField
              margin="normal"
              id="capacity"
              name="capacity"
              label="Capacidad (GB)"
              type="number"
              value={formik.values.capacity}
              onChange={formik.handleChange}
              error={formik.touched.capacity && Boolean(formik.errors.capacity)}
              helperText={formik.touched.capacity && formik.errors.capacity}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CapacityIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              id="screenSize"
              name="screenSize"
              label="Tamaño de Pantalla"
              type="number"
              value={formik.values.screenSize}
              onChange={formik.handleChange}
              error={formik.touched.screenSize && Boolean(formik.errors.screenSize)}
              helperText={formik.touched.screenSize && formik.errors.screenSize}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ScreenSizeIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              id="opSystem"
              name="opSystem"
              label="Sistema Operativo"
              value={formik.values.opSystem}
              onChange={formik.handleChange}
              error={formik.touched.opSystem && Boolean(formik.errors.opSystem)}
              helperText={formik.touched.opSystem && formik.errors.opSystem}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <OpSystemIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              id="camera"
              name="camera"
              label="Cámara"
              value={formik.values.camera}
              onChange={formik.handleChange}
              error={formik.touched.camera && Boolean(formik.errors.camera)}
              helperText={formik.touched.camera && formik.errors.camera}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CameraIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              id="color"
              name="color"
              label="Color"
              value={formik.values.color}
              onChange={formik.handleChange}
              error={formik.touched.color && Boolean(formik.errors.color)}
              helperText={formik.touched.color && formik.errors.color}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ColorLensIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              id="memoryRam"
              name="memoryRam"
              label="Memoria RAM"
              value={formik.values.memoryRam}
              onChange={formik.handleChange}
              error={formik.touched.memoryRam && Boolean(formik.errors.memoryRam)}
              helperText={formik.touched.memoryRam && formik.errors.memoryRam}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MemoryIcon />
                  </InputAdornment>
                ),
              }}
            />
              <TextField
              margin="normal"
              id="connectivity"
              name="connectivity"
              label="Conectividad"
              value={formik.values.connectivity}
              onChange={formik.handleChange}
              error={formik.touched.connectivity && Boolean(formik.errors.connectivity)}
              helperText={formik.touched.connectivity && formik.errors.connectivity}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CellTowerIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextField
            fullWidth
            margin="normal"
            id="shortDesc"
            name="shortDesc"
            label="Descripción Corta"
            value={formik.values.shortDesc}
            helperText={formik.touched.shortDesc && formik.errors.shortDesc ? formik.errors.shortDesc : ""}
            error={formik.touched.shortDesc && Boolean(formik.errors.shortDesc)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
            <FormControlLabel
              control={ 
              <Switch
                        checked={formik.values.inStock}
                        onChange={formik.handleChange} 
                        color="success"
                        name="inStock"
                      />}
              label="In Stock"
            />
          <SpecificationsInput
            specifications={formik.values.features || {}}
            onChange={(newSpecs) => formik.setFieldValue('features', newSpecs)}
          />
          <ImagesInput productName={formik.values.productName} onChange={handleImageSelect} />
          <Box className="flex flex-wrap gap-5 justify-center mt-4">
            <Button onClick={onClose} variant="contained" color="error">
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}  // Disable button while loading
              startIcon={loading ? <CircularProgress size={20} /> : null}  // Show loading spinner
            >
              {loading ? 'Guardando...' : 'Guardar'}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddProductModal;