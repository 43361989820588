import React from 'react';
import { Box, Typography, Container, List, ListItem, Divider } from '@mui/material';

const ProcedimientoCancelacionDevolucion: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 1 }}>
        <Typography variant="body1" paragraph>
          El Call Center de Tienda en línea de Quatro Evolución, S.A. de C.V., solicitará al Cliente que envíe al correo electrónico <strong>masivos@4evolucion.com</strong> fotografías del empaque y del artículo que desea devolver o cancelar, donde se muestre el estado en el que recibió la mercancía.
        </Typography>
        <Typography variant="body1" paragraph>
          Una vez enviado el correo con la evidencia fotográfica, el Cliente deberá imprimir el correo y presentarse en cualquiera de las sucursales de Quatro Evolución, S.A. de C.V., o bien en el Centro de Atención a Clientes Telcel, para realizar el trámite de devolución presentando la factura del equipo.
        </Typography>
        
        <Divider sx={{ my: 2 }} />

        <Typography variant="body1" paragraph>
          El Cliente acepta y reconoce que todas las devoluciones por defectos o fallas, así como las cancelaciones de compra, estarán sujetas a la dictaminación realizada por el Centro de Atención a Clientes de Telcel, aceptando cualquier diagnóstico que pueda impedir la cancelación o devolución.
        </Typography>
        
        <Typography variant="body1" paragraph>
          En caso de que el Centro de Atención a Clientes de Telcel dictamine que procede la cancelación o devolución, ésta se solicitará a través de la Tienda en línea de Quatro Evolución, S.A. de C.V. El reembolso del importe pagado por el Cliente podrá aplicarse en un periodo de hasta 30 días naturales, dependiendo del banco emisor de la tarjeta de crédito.
        </Typography>
      </Box>
    </Container>
  );
};

export default ProcedimientoCancelacionDevolucion;