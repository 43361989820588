import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const PlanesYServicios: React.FC = () => {
  return (
    <Box p={2}>
      <Typography variant="body1">
        Aquí van los detalles sobre los planes y servicios disponibles...
      </Typography>
    </Box>
  );
};

export default PlanesYServicios;
