import * as yup from 'yup';

export const userDetailsSchema = yup.object().shape({
  firstName: yup.string().required('Nombre es requerido'),
  lastName: yup.string().required('Apellido es requerido'),
  email: yup.string().email('Correo inválido').required('Correo es requerido'),
  address: yup.string().required('Dirección es requerida'),
  state: yup.string().required('Estado es requerido'),
  city: yup.string().required('Ciudad es requerida'),
  postalCode: yup.string().required('Código Postal es requerido'),
  phone: yup.string().matches(/^[0-9]{10}$/, 'Teléfono inválido').required('Teléfono es requerido'),
});
