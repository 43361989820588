import { Home, Person, Settings, ExitToApp } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import React from 'react';

export interface CardCategoriesInterface {
    title: string;
    category: string;
    icon: any;
    color: string;
}

export const CardCategories:CardCategoriesInterface[] = [
    {
        title:'Promociones',
        category:'Promotion',
        icon: <Home className='text-white' fontSize='large'/>,
        color:'bg-custom-linear-blue-700'
    },
    {
        title:'Usuarios Activos',
        category:'Users',
        icon: <Person className='text-white' fontSize='large'/>,
        color:'bg-custom-linear-green'
    },
    {
        title:'Empresas Registradas',
        category:'Companies',
        icon: <BusinessIcon className='text-white' fontSize='large'/>,
        color:'bg-custom-linear-blue'
    },
    {
        title:'Telefonos',
        category:'Products',
        icon: <SmartphoneIcon className='text-white' fontSize='large'/>,
        color:'bg-custom-linear-red'
    }
    
]