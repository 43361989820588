import React from "react";
import PropTypes from "prop-types";

function Icon({ color= '#000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="56"
      height="56"
      viewBox="0 0 56 56"
    >
      <defs>
        <path
          id="path-1"
          d="M28 0C12.548 0 0 12.548 0 28s12.548 28 28 28 28-12.548 28-28S43.452 0 28 0zm0 2.158c14.286 0 25.846 11.556 25.846 25.842S42.286 53.846 28 53.846 2.154 42.286 2.154 28 13.714 2.158 28 2.158zM17.23 12.923v30.154h23.693V20.97l-7.61-8.047H17.232zm3.232 12.923h17.23c.565 0 1.077.513 1.077 1.077v12.923a1.12 1.12 0 01-1.077 1.077h-17.23a1.12 1.12 0 01-1.077-1.077V26.923c0-.564.512-1.077 1.077-1.077zM21.538 28v10.77h2.154v-4.308h10.813v4.307h2.11V28h-2.11v4.308H23.692V28h-2.154zm4.308 0v2.154H28V28h-2.154zm4.308 0v2.154h2.197V28h-2.197zm-4.308 8.615v2.154H28v-2.154h-2.154zm4.308 0v2.154h2.197v-2.154h-2.197z"
        ></path>
        <filter
          id="filter-2"
          width="107.1%"
          height="107.1%"
          x="-3.6%"
          y="-3.6%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="1.5"
          ></feGaussianBlur>
          <feOffset
            dy="1"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero">
          <g fill={color}>
            <use xlinkHref="#path-1"></use>
            <use filter="url(#filter-2)" xlinkHref="#path-1"></use>
          </g>
        </g>
      </g>
    </svg>
  );
}

Icon.propTypes = {
  color: PropTypes.string
};

Icon.defaultProps = {
  color: "#000" // Default color is black
};

export default Icon;
