import { Home, Dashboard} from '@mui/icons-material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';



export const AdminMenuItems =[
    {
        title:'Inicio',
        category:'Favorites',
        icon: Home,
        url:'/dashboard'
    },
    {
        title:'Sitio Web',
        category:'WebSite',
        icon:Dashboard,
        url:'/admin-webpage'
    },
   /* {
        title:'Usuarios',
        category:'Favorites',
        icon: PersonOutlineOutlinedIcon,
        url:'/'
    },*/
    {
        title:'Compañias',
        category:'Empresas',
        icon:BusinessOutlinedIcon,
        url:'/admin-companies'
    },
    {
        title:'Usuarios',
        category:'Empresas',
        icon: PeopleAltOutlinedIcon,
        url:'/admin-companies/users'
    },
    {
        title:'Promociones Especiales',
        category:'Empresas',
        icon:RequestQuoteOutlinedIcon,
        url:'/admin-promotion-exclusive'
    },
    {
        title:'Smartphones',
        category:'Productos',
        icon:SmartphoneOutlinedIcon,
        url:'/admin-products'
    },
    {
        title:'Promociones',
        category:'Productos',
        icon: LocalOfferOutlinedIcon,
        url:'/admin-promotion'
    },
    {
        title:'Planes Telefonicos',
        category:'Productos',
        icon: CellTowerOutlinedIcon,
        url:'/admin-plan-service'
    },
    /*{
        title:'Ordenes',
        category:'Sales',
        icon:AssignmentOutlinedIcon,
        url:'/'
    },
    {
        title:'Mensajes',
        category:'Options',
        icon:ChatOutlinedIcon,
        url:'/admin-companies'
    },*/{
        title:'Administradores',
        category:'Options',
        icon:AdminPanelSettingsIcon,
        url:'/admin-admin-users'

    },
    {
        title:'Cerrar Sesion',
        category:'Exit',
        icon: LogoutIcon,
        url:'/'
    },
]