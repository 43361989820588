import React, { useState } from 'react';
import { Box, Container, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UserDetailsForm from '../components/CheckoutForms/UserDetailsForm';
import ShippingDetailsForm from '../components/CheckoutForms/ShippingDetailsForm';
import PaymentDetailForm from '../components/CheckoutForms/PaymentDetailForm';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import CheckOutSideBar from '../components/SideBar/CheckOutSideBar';
import WarningAlert from '../components/Alerts/WarningAlert';
import StripeProvider from '../services/StripeProvider';
import SuccessPayment from '../components/CheckoutForms/SuccessPayment';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 1,
  },
}));

const steps = ['Información', 'Envío', 'Selección de Pago'];

const CheckoutPage: React.FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen,setSnackbarOpen] = useState(false);
  const [isUserDetailsValid, setIsUserDetailsValid] = useState(true);
  const [checkValidation,setCheckValidation]=useState(false); // State to track user details form validation

  const handleNext = (valid: boolean) => {
    setCheckValidation(true);
    if (valid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setCheckValidation(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFormValidation = (isValid: boolean) => {
    setCheckValidation(isValid); // Update parent component state with form validity
  };

  return (
    <Container maxWidth="xl" className='min-h-[90vh]'>
      <Box className="flex">
        <Box className='w-3/4 p-10'>
          <Stepper activeStep={activeStep} className="mb-8">
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <div className="text-center">
              <Typography variant="h5" gutterBottom>
              <SuccessPayment/>
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reiniciar
              </Button>
            </div>
          ) : (
            <>
              {activeStep === 0 && <UserDetailsForm onNext={handleFormValidation} />}
              {activeStep === 1 && <ShippingDetailsForm onNext={handleNext} />}
              {activeStep === 2 &&  
                <StripeProvider>
                  <PaymentDetailForm />
                </StripeProvider>}
              <div className="flex justify-between mt-6">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className='cursor-pointer'
                >
                  <KeyboardBackspaceIcon />
                  Regresar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => handleNext(isUserDetailsValid)}
                  className={classes.button}
                  //disabled={!checkValidation && activeStep !== 1 || activeStep!=2}
                >
                  Continuar
                </Button>
              </div>
            </>
          )}
          <Box>

          </Box>
        </Box>
        <CheckOutSideBar />
        <WarningAlert
          openSnack={snackbarOpen}
          content="Error al enviar el formulario. Por favor, inténtalo de nuevo."
          onClose={()=>{}}
        />
      </Box>
    </Container>
  );
};

export default CheckoutPage;
