import React from 'react';
import { Card, CardContent, Typography, Box, Tooltip } from '@mui/material';
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded';

interface PromotionCountCardProps {
  promotionCount: number;
}

const PromotionCountCard: React.FC<PromotionCountCardProps> = ({ promotionCount }) => {
  return (
    <Tooltip title="Number of active promotions" arrow>
      <Card
        sx={{
          maxWidth: 330,
          width:330,
          margin: '20px auto',
          padding: 2,
          textAlign: 'center',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: 3,
          },
        }}
      >
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DiscountRoundedIcon sx={{ fontSize: 60, color: '#4caf50' }} />
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
              Promociones Totales
            </Typography>
            <Typography variant="h4" color="text.primary" sx={{ fontWeight: 'bold' }}>
              {promotionCount}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Tooltip>
  );
};

export default PromotionCountCard;