import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PaymentSuccessPage: React.FC = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 5, height:'65vh' }}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
              </Box>
            </Grid>
            <Grid item xs>
              <Typography variant="h4" gutterBottom>
                Pago Exitoso
              </Typography>
              <Typography variant="h6" gutterBottom>
                ¡Tu pago ha sido procesado con éxito!
              </Typography>
              <Typography variant="body1" paragraph>
                Gracias por tu compra. Tu pedido será entregado en la tienda. Por favor, visita la siguiente dirección para recoger tu pedido:
              </Typography>
              <Typography variant="body1">
                <strong>Dirección de Recogida:</strong>
              </Typography>
              <Typography variant="body1">
                Calle Ejemplo 123, 
                Ciudad de Ejemplo, 
                País de Ejemplo, 
                12345
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PaymentSuccessPage;