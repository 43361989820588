import React, {useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import useImageValidation from '../../utils/ImageValidator';

type PromotionCardProps = {
    imgUrl: string;
    title:string;
    description:string;
};

const StyledCard = styled(Card)(({ theme}) => ({
    width: 310,
    height: 400,
    cursor:'pointer',
    transition: 'width 0.5s ease-in-out',
    backgroundSize: 'cover',
    borderRadius: '5px',
    '& .MuiCardMedia-root': {
      height: '100%',
    },
  }));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color:'#757575'
}));
  


const PromotionCardV2:React.FC<PromotionCardProps> = ({imgUrl,title,description}) => {
  const { imgUrlIsValid, isLoading } = useImageValidation(imgUrl);

    const handleOpenWhatsApp = () => {
        const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER; // Número de teléfono al que quieres enviar el mensaje
        const message = '¡Hola! Estoy interesado en saber más sobre sus promociones de planes de celular.';
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    
        window.open(whatsappUrl, '_blank');
    };

    return (<>
      <StyledCard>
      {isLoading ? (
          <Skeleton variant="rectangular" width={400} height={240} animation="wave" />
        ) : imgUrlIsValid ? (
            <CardMedia
              className='!h-[240px]'
              image={imgUrl}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement;
                target.src =
                  'https://images.samsung.com/mx/smartphones/galaxy-s24-ultra/buy/product_color_gray.png?imwidth=480';
              }}
              title="Product Image"
            />
        ) : (
            <div className="flex justify-center items-center">
              <img
                src={'https://www.eleconomista.com.mx/__export/1644707691680/sites/eleconomista/img/2022/02/11/telcel_conectividad.png_554688468.png'}
                alt="Phone Icon"
                style={{ width: 'auto', height: '190px', maxHeight: 200, padding: '1em 1em 0 1em' }}
              />
            </div>
        )}
        <CardContent className="flex flex-col gap-5 items-center justify-center p-2">
          {isLoading ? (
            <div>
              <Skeleton variant="text" width={80} animation="wave" />
              <Skeleton variant="text" width={80} animation="wave" />
            </div>
          ) : (
            <div className='flex gap-2 flex-col justify-center items-center text-center'>
              <Typography sx={{ fontWeight: 'bold', fontSize:{xs:'1.25rem',md:'1.3rem'} }} variant="h5">{title}</Typography>
              <DescriptionTypography variant="h6">{description}</DescriptionTypography>
            </div>
          )}
         <Button className='w-fit' onClick={handleOpenWhatsApp} sx={{borderRadius:'10px', padding:'5px 50px'}} variant="contained">Contratar</Button>
      </CardContent>
      </StyledCard>
    </>);
}

export default PromotionCardV2