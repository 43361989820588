import React from 'react';
import { Box, styled } from '@mui/material';
import { ServiceItems } from './MenuData';
import { Link } from 'react-router-dom';

// Styled component for better control over MUI and Tailwind integration
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2), // Reduced gap for mobile
  height: 'auto', // Changed to auto for better mobile display
  padding: theme.spacing(2), // Added padding for mobile
  boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.05)',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(4), // Increase gap on larger screens
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(14), // Original gap for medium and up
  },
}));

const ServiceIconNavBar = () => {
  return (
    <StyledBox sx={{ boxShadow: 3 }}>
      {ServiceItems.map((item, index) => (
        <div key={index} className="flex flex-col justify-center items-center text-center cursor-pointer hover:opacity-75">
          <Link to={item.url} className={`${item.title ==='Servicios' ? 'relative left-4':''}`}>
            <img className='w-auto h-12 md:h-20' src={item.icon} alt={item.title} /> {/* Adjust height for larger screens */}
          </Link>
          <h6 className='font-bold text-sm md:text-base'>{item.title}</h6> {/* Adjust text size for better readability */}
        </div>
      ))}
    </StyledBox>
  );
};

export default ServiceIconNavBar;
