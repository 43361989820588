import {Box, Container } from '@mui/material'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import ContactMessagesTable from '../../components/Admin/Tables/ContactMessageTable'

const ContactMessageAdminPage = () => {
  return (<>
  <section className='flex flex-row'>
    <VerticalNavBar/>
    <Container>
        <ContactMessagesTable/>
    </Container>
</section>
  </>
  )
}

export default ContactMessageAdminPage