import { Button, Container } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PromotionBanners from '../components/Gallery/PromotionBanners'
import PromotionCard from '../components/Card/PromotionCard'
import Pagination from '@mui/material/Pagination';
import { PromotionInterface } from '../constant/PromotionInterface'
import { getAllPromotionsNoExclusive } from '../services/promotions'
import { SkeletonPromotionCardList } from '../components/Card/SkeletonCard'
import CreatePromotionModal from '../components/Admin/Modal/Promotions/CreateaPromotionModal'
import { getWebPagesByCategory } from '../services/webPage'

interface Promotion {
  imgUrl: string;
  title: string;
  description: string;
  shortDesc: string;
  active: boolean;
}

const PromotionsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [promotionList,setPromotionList] = useState<PromotionInterface[]>([])
  const itemsPerPage = 9; // Número de elementos por página
  const [error, setError] = useState<string | null>(null); // Error state
  const [isLoading,setIsLoading]=useState(true);
  const [sliderList,setSliderList] = useState();



  useEffect(()=>{
    fetchPromotions();
  },[])

  const fetchPromotions = async () => {
    try {
      setIsLoading(true);
      setError(null); // Reset error state before trying again

      const response = await getAllPromotionsNoExclusive();

      // Helper function to check valid date
      const isValidDate = (date: any): date is Date =>
        date instanceof Date && !isNaN(date.getTime());

      const filterList = response.filter((item: PromotionInterface) => {
        const now = new Date();
        const startDay = item.startDay ? new Date(item.startDay) : null;
        const endDay = item.endDay ? new Date(item.endDay) : null;

        return (
          item.isActive &&
          startDay &&
          isValidDate(startDay) &&
          endDay &&
          isValidDate(endDay) &&
          now >= startDay &&
          now <= endDay
        );
      });
      setPromotionList(filterList);
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (err) {
      setError('An error occurred while fetching promotions. Please try again.');
      console.error("Error fetching promotions:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
};

  const totalPages = Math.ceil(promotionList.length / itemsPerPage);

  const currentPromotions = promotionList.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
  );


  console.log(currentPromotions);
  return (
    <Container maxWidth='xl'>
        <div className='flex flex-wrap justify-around items-center my-10 gap-5 md:gap-0'>
        <Box className='w-full md:w-1/3 flex flex-col gap-5'>
          <Typography variant='h3' component='h2' className='mb-5 uppercase'>
            Promociones
          </Typography>
          <Typography variant='body1' component='p' className='text-justify'>
            ¡No te pierdas nuestras ofertas exclusivas! Descubre las promociones especiales que hemos preparado para ti. 
            Desde descuentos en nuestros productos más populares hasta beneficios adicionales al contratar nuestros servicios, 
            aquí encontrarás lo mejor. Mantente conectado y aprovecha al máximo con nuestras promociones de temporada. 
            ¡Es el momento de actualizarte y llevar lo mejor a tu hogar!
          </Typography>
        </Box>
            <Box></Box>
            <PromotionBanners/>
        </div>
        <div className="flex justify-around mx-auto my-16 hidden">
          <Box>
            <div className="image-container max-w-[600px]">
              <img className='object-cover w-full h-full' src={require('../assets/images/graphics/Group-1.webp')} alt="" />
            </div>
          </Box>
{  /*        <Box className='w-1/2 flex flex-col h-auto justify-center items-end gap-5'>
            <Typography variant='h3' component='h4' className='uppercase'>
              Lo mejor para ti
            </Typography>
            <Typography className='w-3/4 text-justify'>
                Lorem Ipsum is simply dummy text of the 
                printing and typesetting industry. Lorem Ipsum 
                has been the industry's standard dummy text ever 
                since the 1500s, when an unknown printer took a galley 
                of type and scrambled it to make a type specimen book.
            </Typography>
          </Box>*/}
        </div>
        <section id='promotionsList'>
            <Typography variant='h3' component='h4' className='uppercase text-center'>
              Encuentra la mejor para ti
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Box marginY={5} display="flex" flexWrap="wrap" gap={5} justifyContent="center">
            {isLoading ? (
                <SkeletonPromotionCardList quantity={3} />
                ) : error ? (
                <div>
                    <p>{error}</p>
                    <button onClick={fetchPromotions}>Try Again</button> {/* Retry button */}
                </div>
                ) : currentPromotions.length > 0 ? (
                currentPromotions.map((promotion, index) => (
                    <PromotionCard
                    key={index}
                    imgUrl={promotion.imgUrl}
                    title={promotion.title}
                    description={promotion.description}
                    shortDesc={promotion.shortDesc}
                    />
                ))
                ) : (
                <p>No hay promociones por el momento.</p>
                )}
            </Box>
            <Pagination 
                count={totalPages} 
                page={currentPage} 
                onChange={handleChangePage} 
                color="primary" 
                sx={{ my: 5 }}
            />
        </Box>
        </section>
    </Container>
  )
}

export default PromotionsPage