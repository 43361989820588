import React, { useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css'; // core Swiper
import 'swiper/css/effect-fade'; // fade effect
import 'swiper/css/pagination'; // pagination module
import './PrincipalSlidertStyle.css'; // Ensure correct path

// Import required modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import ServiceCard from '../Card/ServiceCard';
import { PlanServiceInterface } from '../../constant/ServicesInterfaces';

interface PlanServiceSliderProps {
  planServices: PlanServiceInterface[];
}

const ServiceCardSlider: React.FC<PlanServiceSliderProps> = ({ planServices }) => {
  const [currentPlan, setCurrentPlan] = useState(planServices[0]);
  console.log(planServices);

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        centeredSlides={true}
        pagination={{ clickable: true }}
        breakpoints={{
          450: {
            slidesPerView: 1,
            spaceBetween: 5,
            centeredSlides: false,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
            centeredSlides: false,
          },
        }}
      >
        {planServices.map((plan, index) => (
          <SwiperSlide key={index} className="relative">
            <ServiceCard
              planService={plan}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ServiceCardSlider;