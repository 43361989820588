import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText, Divider } from '@mui/material';

const PoliticaDeDevolucion: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 1 }}>
        <Typography variant="body1" paragraph>
          El Cliente podrá devolver los artículos adquiridos en la Tienda en línea de Quatro Evolución, S.A. de C.V., cuando presenten defectos de fabricación o en caso de existir alguna equivocación en el artículo enviado, siempre y cuando se conserve el empaque original en buenas condiciones y el producto no haya sido usado ni esté dañado.
        </Typography>
        <Typography variant="body1" paragraph>
          Todo trámite de cancelación y devolución deberá ser realizado en las sucursales de Quatro Evolución, S.A. de C.V., ubicadas en:
        </Typography>

        <List>
          <ListItem>
            <ListItemText
              primary="Calle Guadalupe Victoria No. 916, Local B2, Barrio de Coaxustenco, Metepec, Estado de México."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Avenida Morelos No. 730 B, San Sebastián, Toluca, Estado de México."
            />
          </ListItem>
        </List>
        
        <Divider sx={{ my: 2 }} />

        <Typography variant="body1" paragraph>
          O bien, a través de los Centros de Atención a Clientes Telcel.
        </Typography>

        <Typography variant="body1" paragraph>
          En el caso de mercancía errónea o dañada, Quatro Evolución, S.A. de C.V. podrá solicitar al Cliente evidencia fotográfica que acredite el error o daño en la mercancía entregada.
        </Typography>

        <Typography variant="body1" paragraph>
          No aplicarán cambios ni devoluciones si el equipo fue utilizado y/o presenta muestras de uso y/o maltrato, o si el Cliente no cuenta con el empaque original y todos los accesorios incluidos.
        </Typography>
      </Box>
    </Container>
  );
};

export default PoliticaDeDevolucion;