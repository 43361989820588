import { CompanyUser, CompanyUserInterface } from "../constant/CompanyInterface";
import { publicRequest } from "../requestMethods/requestMethods";

// Define the request payload type
interface LoginPayload {
  email: string;
  password: string;
}

// Define the response type based on what the API returns
interface UserResponse {
  message: string;
  user: {
    id: number;
    username: string;
    name: string;
    lastName: string;
    email: string;
    companyId: number | null;
  };
}

// Define the function with proper type annotations
export const accessCompanyUser = async (accessInfo: LoginPayload): Promise<UserResponse> => {
  try {


    const response = await publicRequest.post('/company-users/login',accessInfo );
    return response.data;
  } catch (error:any) {
      console.error('Unexpected error:', error);
      throw error;
  }
};


export const getAllCompanyUsers = async () => {
    try {
      const response = await publicRequest.get('/company-users');
      return response.data;
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
};

export const getAllCompanyUsersCount = async () => {
  try {
    const response = await publicRequest.get('/company-users/count');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};

export const createCompanyUser = async (user:CompanyUserInterface) =>{
  try {
    const response = await publicRequest.post('/company-users',user);
    return response.data;
  } catch (error) {
    console.error('Error en la creación de usuario:', error);
    throw error;
  }
}


export const sendPasswordResetEmail = async (sendEmail:string) =>{
  try {
    const recoverEmail = {
      email:sendEmail
    }
    const response = await publicRequest.post('/company-users/recover',recoverEmail);
    return response.data;
  } catch (error) {
    console.error('Error en la creación de usuario:', error);
    throw error;
  }
}

export const updatePasswordWithToken = async (newPassword:string, token:string) => {
  try {
    const response = await publicRequest.post(`/company-users/reset-password?token=${token}`, {
      newPassword
    });

    return response.data;
  } catch (error:any) {
    console.error('Error updating password:', error);
    throw error.response?.data?.error || 'Something went wrong';
  }
};

export const updateCompanyUser = async (id:number, user:CompanyUserInterface) =>{
  try {
    const response = await publicRequest.post(`/company-users/${id}`,user);
    return response.data;
  } catch (error) {
    console.error('Error en la creación de usuario:', error);
    throw error;
  }

}

export const deleteCompanyUser = async (id:number) => {
  try {
    const response = await publicRequest.delete(`/company-users/${id}`);;
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};