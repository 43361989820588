// src/components/CompanyUserTable.tsx
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Typography, Skeleton, Box, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteCompanyUser, getAllCompanyUsers, updateCompanyUser } from '../../../services/companyUsers'; // Ensure this service is implemented
import { CompanyUser, CompanyUserInterface} from '../../../constant/CompanyInterface';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CompanyDetailsModal from '../Modal/Company/CompanyDetailsModal';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import CompanyUserEditModal from '../Modal/CompanyUser/CompanyUserEditModal';
import DeleteCompanyUserModal from '../Modal/CompanyUser/DeleteCompanyUserModal';
import { CustomToolbar } from './CustomToolBar';

interface DashboardDataProps {
  updateDashboardData: (total: number, active: number, lastMonth: number) => void;
}

const CompanyUserTable: React.FC<DashboardDataProps> = ({updateDashboardData}) => {
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
  const [currentCompanyUser, setCurrentCompanyUser] = useState<CompanyUserInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchCompanyUsers();
  }, []);

  const fetchCompanyUsers = async () => {
    try {
      setLoading(true);
      const data = await getAllCompanyUsers();
      setCompanyUsers(data);
      const totalCompaniesUser: number = data.length;  // Total companies fetched from API
      const activeCompaniesUser: number = data.filter((user: CompanyUser) => user.companyId).length; 
      
      const currentMonth: number = new Date().getMonth(); // Get the current month (0-11)
      const currentYear: number = new Date().getFullYear(); // Get the current year

      const createdThisMonthCount: number = data.filter((user: CompanyUser) => {
          const createdAt = new Date(user.createdAt); // Convert createdAt to Date object
          return createdAt.getMonth() === currentMonth && createdAt.getFullYear() === currentYear; // Check if month and year match
      }).length; // Count of companies created this month

      // Update the dashboard data with the computed values
      updateDashboardData(totalCompaniesUser, activeCompaniesUser, createdThisMonthCount);
    } catch (error) {
      console.error('Error fetching company users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (companyUser: CompanyUserInterface) => {
    setCurrentCompanyUser(companyUser);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (user: CompanyUserInterface) => {
    try {
      if(user.id){
        await deleteCompanyUser(user.id);
        showSnackbar('Promotion deleted successfully!', 'success');
      }
      fetchCompanyUsers();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setCurrentCompanyUser(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'username', headerName: 'Username', width: 150 },
    { field: 'name', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'Company',
      headerName: 'Company Name',
      width: 200,
      renderCell: ({ row }: GridRenderCellParams) => {
        const companyName = row.Company?.companyName || 'N/A'; // Handle potential null value
  
        return (
          <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
            <Box display="flex" alignItems="center" width="30%">
              <Typography variant="body2" sx={{ marginLeft: 1 }} aria-label={`Company name: ${companyName}`}>
                {companyName}
              </Typography>
            </Box>
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: GridRenderCellParams<CompanyUserInterface>) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton 
            onClick={() => handleEdit(row)} 
            sx={{ '&:hover': { backgroundColor: 'blue.100' }, borderRadius: '50%' }}
            aria-label="Edit Plan"
          >
            <EditIcon />
          </IconButton>
          <IconButton 
              onClick={() => {
                setCurrentCompanyUser(row as CompanyUserInterface);
                setIsDeleteModalOpen(true);
              }} 
            sx={{ '&:hover': { backgroundColor: 'red.100' }, borderRadius: '50%' }}
            aria-label="Delete Plan"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box style={{ height: 600, width: '100%' }}>
      {loading ? (
        <Skeleton variant="rectangular" height={600} />
      ) : (
        <DataGrid
          rows={companyUsers}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Descargar como Excel',
          }}
          slots={{ toolbar: CustomToolbar }}
          pagination
        />
      )}


      {currentCompanyUser && isEditModalOpen && (
        <CompanyUserEditModal
          open={isEditModalOpen}
          onClose={handleModalClose}
          user={currentCompanyUser}
          onSave={async (updatedUser: CompanyUserInterface) => {
            try {
              if(updatedUser.id){
                await updateCompanyUser(updatedUser.id , updatedUser);
                showSnackbar('Promotion updated successfully!', 'success');
                
              }
              fetchCompanyUsers();
              handleModalClose();
            } catch (error) {
              console.error('Error updating promotion:', error);
              showSnackbar('Error updating promotion', 'error');
            }
          }}
        />
      )}
      {currentCompanyUser && isDeleteModalOpen && (
        <DeleteCompanyUserModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(currentCompanyUser)}
          user={currentCompanyUser}
        />
      )}
    </Box>
  );
};

export default CompanyUserTable;
