import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import { convertToMB } from '../../utils/ConvertApp';

type FiltersDrawerProps = {
  filters: {
    priceRange: number[];
    selectedBrands: string[];
    selectedCapacities: number[];
    selectedRatings: number[];
  };
  onFilterChange: (newFilters: any) => void;
};

const FiltersDrawer: React.FC<FiltersDrawerProps> = ({ filters, onFilterChange }) => {
  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    onFilterChange({ ...filters, priceRange: newValue as number[] });
  };

  const handleBrandsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const newSelectedBrands = checked
      ? [...filters.selectedBrands, value]
      : filters.selectedBrands.filter((brand) => brand !== value);
    onFilterChange({ ...filters, selectedBrands: newSelectedBrands });
  };


  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const capacity = Number(value); // Convert value to number
    const newSelectedCapacities = checked
      ? [...filters.selectedCapacities, capacity]
      : filters.selectedCapacities.filter((cap) => cap !== capacity);
    onFilterChange({ ...filters, selectedCapacities: newSelectedCapacities });
  };

  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const newSelectedRatings = checked
      ? [...filters.selectedRatings, Number(value)]
      : filters.selectedRatings.filter((rating) => rating !== Number(value));
    onFilterChange({ ...filters, selectedRatings: newSelectedRatings });
  };

  return (
    <Card id='Fileter-Drawer' sx={{ width: 300, borderRadius: '0.5rem' }} className='boxShadow-custom !overflow-auto md:overflow-hidden'>
      <Box sx={{ padding: 3 }}>
        <Typography variant="subtitle1">Rango de Precio</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>${filters.priceRange[0]?.toLocaleString('en', { minimumFractionDigits: 2 })}</Typography>
          <Typography>${filters.priceRange[1]?.toLocaleString('en', { minimumFractionDigits: 2 })}</Typography>
        </div>
        <Slider
          value={filters.priceRange}
          onChange={handlePriceChange}
          min={0}
          max={60000}
          step={500}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
        <Divider />
        <Typography variant="subtitle1">Marcas</Typography>
        <FormGroup>
          {["Apple", "Samsung", "Xiaomi", "ZTE", "Nokia"].map((brand) => (
            <FormControlLabel
              key={brand}
              control={<Checkbox
                checked={filters.selectedBrands.includes(brand)}
                onChange={handleBrandsChange}
                value={brand}
              />}
              label={brand}
            />
          ))}
        </FormGroup>
        <Divider />
        <Typography variant="subtitle1">Capacidad</Typography>
        <FormGroup>
          {[65536,131072, 262144, 524288, 1048576].map((capacity) => (
            <FormControlLabel
              key={capacity}
              control={<Checkbox
                checked={filters.selectedCapacities.includes(capacity)}
                onChange={handleCapacityChange}
                value={capacity}
              />}
              label={convertToMB(capacity)}
            />
          ))}
        </FormGroup>
        <Divider />
        <Typography variant="subtitle1">Rating</Typography>
        <FormGroup>
          {[5, 4, 3, 2, 1].map((rating) => (
            <FormControlLabel
              key={String(rating)}
              control={<Checkbox
                checked={filters.selectedRatings.includes(rating)}
                onChange={handleRatingChange}
                value={String(rating)}
              />}
              label={
                <Grid container alignItems="center">
                  <Box ml={1}>
                    <Rating
                      name={`rating-${rating}`}
                      value={rating}
                      readOnly
                      size="small"
                      precision={1}
                    />
                  </Box>
                </Grid>
              }
            />
          ))}
        </FormGroup>
      </Box>
    </Card>
  );
};

export default FiltersDrawer;
