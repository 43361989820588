import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PercentIcon from '@mui/icons-material/Percent';
import {CustomPlansAttributes } from '../../../../../constant/ServicesInterfaces';

interface AddCustomDurationModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (newPlan: CustomPlansAttributes) => Promise<void>;
}

const AddCustomDurationModal: React.FC<AddCustomDurationModalProps> = ({ open, onClose, onSave }) => {
  const [newPlan, setNewPlan] = useState<CustomPlansAttributes>({
    salePercentage: 0,
    duration: 0
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewPlan((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePlanTypeChange = (newPlanType: string) => {
    setNewPlan((prevState) => ({ ...prevState, planType: newPlanType }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(newPlan);
      onClose();
    } catch (error) {
      console.error('Error saving plan:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Añadir Nuevo Opción</DialogTitle>
      <DialogContent>
      <Box sx={{ mt: 2 }} className="flex flex-col gap-5">
      <Box className="flex flex-wrap gap-5 items-center">
        <Box className="flex items-center gap-2">
          <AccessTimeIcon color="primary" />
          <TextField
            label="Duración"
            name="duration"
            type="number"
            value={newPlan.duration}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            fullWidth
          />
        </Box>
        <Box className="flex items-center gap-2">
          <PercentIcon color="primary" />
          <TextField
            label="Descuento X Contrato"
            name="salePercentage"
            type="number"
            value={newPlan.salePercentage}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
            inputProps={{ min: 0 }}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" disabled={isSaving}>
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={isSaving}>
          {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Guardar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomDurationModal;