import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure to import AOS styles
import { Company } from '../../constant/CompanyInterface';
import { getAllCompanies } from '../../services/companies';

const logos = Array.from({ length: 20 }, (_, i) => `../../assets/images/logos/${i + 1}.png`);

export default function CompanyLogosGrid() {
  const [validLogos, setValidLogos] = useState<string[]>([]);
  const [companiesList,setCompaniesList] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const data = await getAllCompanies();
      setCompaniesList(data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    AOS.init({
      once:true,
      duration: 1000,
      easing: "ease-out-cubic",
    });  

  }, []);



  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        {companiesList.map((company, index) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
            <Box 
              component="img" 
              src={company.companyLogo} 
              alt={`Company logo ${index + 1}`} 
              data-aos="fade-up" // AOS animation
              sx={{
                width: '100%', 
                height: '150px', // Fixed height to standardize logo sizes
                objectFit: 'contain',
                padding: 1,
                borderRadius: 2,
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.1)', // Slightly larger scale on hover
                },
              }} 
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}