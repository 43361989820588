import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AdminMenuItems } from './MenuData'; // Import your menu items data here
import { clearUser } from '../../../redux/userAdminSlice'; // Assuming you have this for logout
import { useDispatch } from 'react-redux';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const MobileAdminNavBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    navigate('/');
  };
  
  return (
    <Box className='flex justify-between items-center p-5 shadow-custom'>
      <Link to='/'>
            <img src={require("../../../assets/images/icons/Logo.webp")} alt="Logo" style={{ height: 30 }} />
            </Link>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      {/* Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {/* Logo */}
          <Box className="p-4 flex justify-center">
            <img
              src={require("../../../assets/images/icons/Logo.webp")}
              alt="Logo"
              className="h-12"
            />
          </Box>

          {/* List of Menu Items */}
          <List>
            {AdminMenuItems.map((item, index) => (
              <Link to={item.url} key={index}>
                <ListItem button>
                  <ListItemIcon>
                    <item.icon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            ))}
          </List>

          {/* Divider and Logout Button */}
          <Divider />
          <List>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileAdminNavBar;