import React, {useState,useEffect} from 'react';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useImageValidation from '../../utils/ImageValidator';

interface ImageBoxSmProps {
    imgUrl:string;
    name:string;
    onClick: (imgUrl: string) => void;
}


const ImageBoxSm:React.FC<ImageBoxSmProps>  = ({imgUrl,name,onClick}) => {
    const { imgUrlIsValid, isLoading } = useImageValidation(imgUrl);

    if (!imgUrlIsValid) {
        return null;
    }

    const handleImageClick = () => {
        onClick(imgUrl);
    };
    const handleMouseEnter = () => {
        onClick(imgUrl);
    };

  return (
    <Box
    className="flex justify-center items-center p-0.5 cursor-pointer border-2 border-slate-500"
    sx={{
      width: 70,
      height: 70,
      borderRadius: 2,
      bgcolor: '#FFFFFF',
      transition: 'background-color 0.3s ease', // Smooth transition for background-color change
      '&:hover': {
          bgcolor: '#E0E0E0', 
      },
    }}
    onClick={handleImageClick}
    onMouseEnter={handleMouseEnter}
    >
        {isLoading ?  <Skeleton variant="rectangular" width={70} height={70} animation="wave" />
        :(
         <img className='w-auto h-full p-1 object-contain' src={imgUrl} alt={name}/>
        )}
    </Box>
  )
}

export default ImageBoxSm