import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import SnapChatIcon from '../assets/images/icons/snapchat';
import MessengerIcon from '../assets/images/icons/message'
import UberIcon from '../assets/images/icons/uberIcon'
import XIcon from '../assets/images/icons/xIcon'

export type ServiceCardProps = {
    title:string;
    dataInclude:number;
    minutes:number;
    price:number;
    socialMedia:SocialMedia[];
    description?:Record<string, any>;
    imgUrl?:string
};

export enum PlanType {
    Personal = "Personal",
    Business = "Empresarial",
}

export interface SocialMedia {
    name: string;
  }

export interface premiumSocialMedia{

}

export interface Specifications {
    description: string;
}


export interface PlanServiceInterface {
    id?: number;
    planName: string;
    planType: string;
    dataIncludedGB: number;
    minutesIncluded: number;
    smsIncluded: number;
    coverImgUrl?: string;
    additionalCostPerKB?: number;
    price: number;
    specifications?: Record<string, any>;
    isActive: boolean;
    createdAt?: string;
    updatedAt?: string;
    socialMediaIncluded: SocialMedia[];
    premiumSocialMediaIncluded: SocialMedia[];
}

export interface CustomPlansAttributes {
    id?: number;
    duration: number;
    salePercentage: number;
}
export interface CustomDataPlansAttributes {
    id?: number;
    dataInclude: number; // Represents the GB value as a decimal
    pricePerMonth: number; // Represents the price per month as a decimal
    planType:string;
}

export const socialMediaIcons = [
    { name: 'WhatsApp', icon: <WhatsAppIcon /> },
    { name: 'Facebook', icon: <FacebookIcon /> },
    { name: 'Instagram', icon: <InstagramIcon /> },
    { name: 'Snapchat', icon: <SnapChatIcon /> },
    { name: 'Messenger', icon: <MessengerIcon /> },
    { name: 'Uber', icon: <UberIcon /> },
    { name: 'X', icon: <XIcon /> },
  ];

export const getIconByName = (name:string) => {
    const iconObj = socialMediaIcons.find((item) => item.name.toLowerCase() === name.toLowerCase());
    return iconObj ? iconObj.icon : null;
  };