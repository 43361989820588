import React, { useState, useEffect } from 'react';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Skeleton,
  DialogTitle,
  DialogActions,
  Alert,
  CircularProgress, // Import CircularProgress for the loading spinner
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Company } from '../../../../constant/CompanyInterface';
import { uploadImage } from '../../../../services/gcpService'; // Ensure you have this function implemented

interface CompanyDetailsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (company: Company) => void;
  company: Company;
}

const CompanyDetailsModal: React.FC<CompanyDetailsModalProps> = ({
  open,
  onClose,
  onSave,
  company,
}) => {
  // State management
  const [editMode, setEditMode] = useState(false);
  const [updatedCompany, setUpdatedCompany] = useState<Company>(company);
  const [imageFile, setImageFile] = useState<File | null>(null); // State for the uploaded image file
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Confirmation dialog
  const [loading, setLoading] = useState(false); // Loading state for saving

  // Handle input change for text fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedCompany((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle image upload
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdatedCompany((prevState) => ({ ...prevState, companyLogo: reader.result as string }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Save the updated company data
  const handleSave = async () => {
    try {
      setLoading(true); // Start loading
      let companyLogo = '';

      // Upload image if a new file is selected
      if (imageFile) {
        companyLogo = await uploadImage(imageFile, 'Companies');
      }

      // Create the updated company object with the new image URL if available
      const updatedCompanyData = {
        ...updatedCompany,
        companyLogo: companyLogo || updatedCompany.companyLogo,
      };

      // Call onSave function to save the updated company
      await onSave(updatedCompanyData);

      // Reset edit mode
      setEditMode(false);
      setConfirmDialogOpen(false);
    } catch (error) {
      console.error('Failed to save the company:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Confirm update action
  const handleUpdateConfirmation = () => {
    setConfirmDialogOpen(true);
  };

  // Toggle edit mode
  const handleEditToggle = () => {
    setEditMode(true);
  };

  // Sync updatedCompany with the company prop when modal reopens
  useEffect(() => {
    if (company) {
      setUpdatedCompany(company);
    }
  }, [company]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Card>
            {updatedCompany.companyLogo ? (
              <CardMedia
                component="img"
                sx={{
                  width: '60%',
                  height: 'auto',
                  display: 'block',
                  margin: 'auto',
                  objectFit: 'fill',
                }}
                image={updatedCompany.companyLogo}
                title={updatedCompany.companyName}
              />
            ) : (
              <Skeleton variant="rectangular" width="60%" height={200} animation="wave" />
            )}
          </Card>

          {/* Image Upload Input */}
          {editMode && (
            <Box className="relative">
              <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="upload-company-logo"
                />
                <label htmlFor="upload-company-logo">
                  <IconButton
                    component="span"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: 2,
                      '&:hover': { boxShadow: 4 },
                      padding: 1,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </label>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            {editMode ? (
              <>
                <TextField
                  label="Company Name"
                  name="companyName"
                  fullWidth
                  value={updatedCompany.companyName}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Promotion Code"
                  name="promotionCode"
                  fullWidth
                  value={updatedCompany.promotionCode}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
              </>
            ) : (
              <Box className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
                <div className="flex items-center">
                  <Typography variant="body1">
                    <strong>Nombre de Empresa:</strong> {updatedCompany.companyName}
                  </Typography>
                </div>
                <div className="flex items-center">
                  <Typography variant="body1">
                    <strong>Codigo:</strong> {updatedCompany.promotionCode}
                  </Typography>
                </div>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={onClose} color="error" variant="contained">
              Cerrar
            </Button>

            {editMode ? (
              <Box className="flex gap-5">
                <Button onClick={() => setEditMode(false)} color="error" variant="contained">
                  Cancelar
                </Button>
                {loading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={24} /> {/* Loading spinner during save */}
                    <Typography variant="body2">Saving...</Typography>
                  </Box>
                ) : (
                  <Button onClick={handleUpdateConfirmation} color="primary" variant="contained">
                    Gurdar
                  </Button>
                )}
              </Box>
            ) : (
              <Button onClick={handleEditToggle} color="primary" variant="contained">
                Editar
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Confirma Actualización</DialogTitle>
        <DialogContent>
          <Alert severity="warning">Esta acción no se puede deshacer.</Alert>
          <Typography sx={{ mt: 2 }}>
            Estas seguro que quieres actualizar <strong>{updatedCompany.companyName}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleSave} color="success" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanyDetailsModal;