import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getAllContactMessage } from '../../../services/contactMessage';
import ContactMessageModal from '../Modal/ContactMessageModal'; // Import your modal component

// Define the shape of your contact message data
interface ContactMessage {
  id: number;
  name: string;
  company: string;
  email: string;
  message: string;
  createdAt: string; // Use appropriate date type if needed
  updatedAt: string; // Use appropriate date type if needed
}

const ContactMessagesTable: React.FC = () => {
  const [rows, setRows] = useState<ContactMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMessage, setSelectedMessage] = useState<ContactMessage | null>(null);
  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
    page: 0,
    pageSize: 10,
  });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const loadContactMessages = async () => {
      try {
        const data = await getAllContactMessage();
        setRows(data);
      } catch (error) {
        console.error('Failed to fetch contact messages:', error);
      } finally {
        setLoading(false);
      }
    };

    loadContactMessages();
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, message: ContactMessage) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessage(message);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  const handleViewDetails = () => {
    setModalOpen(true);
    handleMenuClose(); // Close the menu after selecting an action
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedMessage(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Nombre', width: 200 },
    { field: 'company', headerName: 'Empresa', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'message', headerName: 'Mensaje', width: 400 },
    { field: 'createdAt', headerName: 'Creado', width: 200 },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={(event) => handleMenuOpen(event, params.row as ContactMessage)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && selectedMessage?.id === params.row.id}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleViewDetails}>View Details</MenuItem>
            <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
            <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 20]}
        loading={loading}
      />

      {selectedMessage && (
        <ContactMessageModal
          open={modalOpen}
          onClose={handleCloseModal}
          message={selectedMessage}
        />
      )}
    </div>
  );
};

export default ContactMessagesTable;
