// src/components/Footer.tsx
import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Facebook, Twitter, Instagram, LinkedIn, SvgIconComponent } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: 'black',
    padding: '20px',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: '5px',
    },
  },
}));

const quickLinks = [
  { href: "/", label: "Inicio" },
  { href: "/promotions", label: "Promociones" },
  { href: "/services", label: "Servicios" },
  { href: "/contact", label: "Contacto" },
  { href: "/privacy-policy", label: "Aviso de Privacidad" },
  { href: "/faq", label: "Preguntas Frecuentes" },
];

const socialIcons: { href: string, label: string, icon: SvgIconComponent }[] = [
  { href: "https://www.facebook.com/quatroevolucionplazalasamericas", label: "Facebook", icon: Facebook },
  { href: "https://www.instagram.com/evolucionquatro", label: "Instagram", icon: Instagram },
];

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={`${classes.footer} bg-gray-800 text-white`}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contactanos
            </Typography>
            <Typography variant="body2">
              Calle Guadalupe Victoria 916, Int. B2, Plaza las Amércias
              <br/>
              C.P. 52140, Metepec, Méx. - Atención Empresarial
              <br />
              <br />
              Av. Morelos Oriente 730-B Entre Leona Vicario y González Arratia
              <br />
              Col. San Sebastián, Toluca, Estado de México CP. 50150 - Atención a Clientes 
              <br />
              Email: tmcontacto2@4evolucion.com
              <br />
              Contacto: (722) 270 8292
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Links
            </Typography>
            <Box className='flex flex-col gap-2'>
                {quickLinks.map((link, index) => (
                <Link key={index} href={link.href} color="inherit">
                    {link.label}
                </Link>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Siguenos en nuestras Redes
            </Typography>
            <Box className={classes.socialIcons}>
              {socialIcons.map((social, index) => (
                <Link key={index} href={social.href} color="inherit" aria-label={social.label}>
                  <social.icon />
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()} Mobile Services. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
