import React, {useEffect, useState} from 'react'
import PromotionTable from '../../components/Admin/Tables/PromotionTable'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import { Box,Button,Container, Grid } from '@mui/material'
import { SnackbarProvider, useSnackbar } from '../../components/Alerts/SnackBarProvider'
import CreatePromotionModal from '../../components/Admin/Modal/Promotions/CreateaPromotionModal'
import { createPromotion } from '../../services/promotions'
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'
import { DashboardCardType1 } from '../../components/Admin/Cards/DashboardCards/DashboardCardType1'
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface CreatePromotion {
  imgUrl: string;
  title: string;
  description: string;
  shortDesc?: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
  isExclusive: boolean;
}

const PromotionExclusiveAdminPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [totalPromotions, setTotalPromotions] = useState<number | null>(null);
  const [activePersonalPromotions, setActivePersonalPromotions] = useState<number | null>(null);
  const [activeExclusivePromotions, setActiveExclusivePromotions] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);


    // Update these states via child component actions
    const updateDashboardData = (total: number, activePersonal: number, activeExclusive: number) => {
      setTotalPromotions(total);
      setActivePersonalPromotions(activePersonal);
      setActiveExclusivePromotions(activeExclusive);
      setLoading(false); // Assume data is fetched and loading is finished
    };
  



  const handleSavePromotion = async (promotion: CreatePromotion) => {
    // Handle saving the promotion data here
    try {
      console.log('handleSavePromotion');
        const res = await createPromotion(promotion);
        console.log(res);
        showSnackbar('Se Agragado Promocion de forma extiosa!', 'success');
    } catch (error) {
      console.log(error);
    }

    console.log('Saved promotion:', promotion);
  };
    return (<>
        <section className=' relative flex flex-col md:flex-row '>
          <div className="hidden md:block">
              <VerticalNavBar />
            </div>
            <div className="block md:hidden">
              <MobileAdminNavBar />
            </div>
          <Container>
          <Grid container spacing={3} className="p-6">
            <Grid item xs={12} sm={6} lg={4}>
              <DashboardCardType1
                title="Total de Promociones"
                value={totalPromotions}
                description="El número total de Promociones Exclusivas."
                icon={<BusinessIcon sx={{ fontSize: 40 }} />}
                loading={loading}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <DashboardCardType1
                title="Promociones Exlusivas"
                value={activeExclusivePromotions}
                description="Promociones Exclusivas activos."
                icon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                loading={loading}
                iconColor='text-green-500'
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <DashboardCardType1
                title="Promociones Exlusivas Inactivas"
                value={activePersonalPromotions}
                description="Promociones Exclusivas inactivos."
                icon={<CancelIcon sx={{ fontSize: 40 }} />}
                loading={loading}
                iconColor='text-red-500'
              />
            </Grid>
          </Grid>
              <Box className='flex w-full justify-end items-center gap-5 p-10'>
                  <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" className='!rounded-full'>
                  Agregar Promocion
                  </Button>
                <CreatePromotionModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={handleSavePromotion}
                  />
              </Box>
                <PromotionTable exclusive={true} updateDashboardData={updateDashboardData}/>
          </Container>
      </section>
        </>
    )
}

export default PromotionExclusiveAdminPage

