import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Grid, Skeleton } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import { useSpring, animated } from '@react-spring/web';

// Define the props interface for the card
interface DashboardCardProps {
  title: string;
  value: number | null;
  description: string;
  icon: React.ReactNode;
  loading: boolean;
  iconColor?: string;
}

// Dashboard card component with animation and skeleton
export const DashboardCardType1: React.FC<DashboardCardProps> = ({ title, value, description, icon, loading, iconColor = 'text-blue-500',  }) => {
  // Animation for the card using react-spring
  const props = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 280, friction: 60 },
  });

  return (
    <animated.div style={props}>
      <Card className="shadow-lg bg-white rounded-lg p-4 h-[170px]">
        <CardContent>
          <Box className="flex items-center space-x-4">
            <Box className={iconColor}>{icon}</Box>
            <Box>
              {loading ? (
                <Skeleton variant="text" width={60} height={30} />
              ) : (
                <Typography variant="h5" className="font-bold text-gray-800">
                  {value}
                </Typography>
              )}
              <Typography variant="body1" className="text-gray-600">{title}</Typography>
            </Box>
          </Box>
          {loading ? (
            <Skeleton variant="text" width="80%" height={20} className="mt-2" />
          ) : (
            <Typography variant="body2" className="hidden md:block text-gray-500">{description}</Typography>
          )}
        </CardContent>
      </Card>
    </animated.div>
  );
};