// src/redux/rootReducer.ts

import { combineReducers } from 'redux';
import shopCartReducer from './shopCartRedux'; 
import userSlice from './userSlice'; // Import your reducers
import userDetailsSlice from './userInfoDetails';
import userAdminSlice from './userAdminSlice';
import authSlice from './authSlice';
import imageSlice from './imageSlice';

const rootReducer = combineReducers({
    shopCart: shopCartReducer,
    user:userSlice,
    userDetails:userDetailsSlice,
    adminUser:userAdminSlice,
    auth:authSlice,
    images:imageSlice
});

export default rootReducer;
