import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Define the shape of your contact message data
interface ContactMessage {
  id: number;
  name: string;
  company: string;
  email: string;
  message: string;
  createdAt: string; // Use appropriate date type if needed
  updatedAt: string; // Use appropriate date type if needed
}

interface ContactMessageModalProps {
  open: boolean;
  onClose: () => void;
  message: ContactMessage;
}

const ContactMessageModal: React.FC<ContactMessageModalProps> = ({ open, onClose, message }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="contact-message-modal-title"
      aria-describedby="contact-message-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="contact-message-modal-title" variant="h6" component="h2">
          Detalle de Mensaje
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1"><strong>Nombre:</strong> {message.name}</Typography>
          <Typography variant="body1"><strong>Empresa:</strong> {message.company}</Typography>
          <Typography variant="body1"><strong>Correo Electronico:</strong> {message.email}</Typography>
          <Typography variant="body1"><strong>Mensaje:</strong> {message.message}</Typography>
          <Typography variant="body2"><strong>Recibido:</strong> {new Date(message.createdAt).toLocaleString()}</Typography>
        </Box>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ContactMessageModal;
