import axios from 'axios';
import { publicRequest } from "../requestMethods/requestMethods";


/**
 * Uploads an image to the server.
 * @param {File} file - The image file to upload.
 * @param {string} folderName - The folder where the image should be stored.
 * @returns {Promise<string>} - The URL of the uploaded image.
 */
export async function uploadImage(file: File, folderName: string): Promise<string> {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('folderName', folderName);

  const response = await publicRequest.post('/cloud/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status !== 200) {
    throw new Error('Failed to upload image');
  }
  console.log(response);
  return response.data.url;
}

export async function deleteFile(folderName: string, fileName: string): Promise<string> {
  try {
    const response = await publicRequest.delete('/cloud/delete-file', {
      data: { folder: folderName, fileName: fileName },
    });

    if (response.status !== 200) {
      throw new Error('Failed to delete file');
    }

    return response.data.message;
  } catch (error:any) {
    console.error('Error deleting file:', error);
    throw new Error(error.response?.data?.message || 'Failed to delete file');
  }
}

export async function listFiles(): Promise<string[]> {
    const response = await publicRequest.get('/cloud/list-files');
    return response.data.files;
  }
