import React, { useState } from 'react';
import {
  Alert,
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Skeleton
} from '@mui/material';
import { PlanServiceInterface } from '../../../../constant/ServicesInterfaces'; // Asegúrate de que esta ruta sea correcta
import useImageValidation from '../../../../utils/ImageValidator';

interface DeletePlanModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: (id: number) => void; // Pasar el ID del plan para eliminar
  plan: PlanServiceInterface; // Usar PlanServiceInterface
}

const DeletePlanModal: React.FC<DeletePlanModalProps> = ({ open, onClose, onDelete, plan }) => {
  const { imgUrlIsValid, isLoading } = useImageValidation(plan.coverImgUrl || ''); // Usar la URL de la imagen del plan
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Estado para manejar el segundo diálogo

  const handleDeleteConfirmation = () => {
    setConfirmDialogOpen(true); // Abrir el segundo diálogo
  };

  const handleDelete = () => {
    setConfirmDialogOpen(false); // Cerrar el segundo diálogo
    if(plan.id){
      onDelete(plan.id);
    }  // Ejecutar la acción de eliminación pasando el ID del plan
  };

  return (
    <>
      {/* Primer diálogo para eliminar */}
      <Dialog open={open} onClose={onClose}>
        <Box className="flex flex-col justify-center items-center gap-5 p-5">
          <Card>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={200}
                animation="wave"
                sx={{ borderRadius: '4px' }}  // Añadir estilo consistente
              />
            ) : imgUrlIsValid ? (
              <CardMedia
                component="img"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '200px',
                  display: 'block',
                  margin: 'auto',
                  objectFit: 'cover',
                }}
                image={plan.coverImgUrl} // Usar la imagen del plan
              />
            ) : (
              <div className="flex justify-center items-center">
                <img
                  src={'../assets/icons/DashboardAdmin/folder.png'}  // Ajustar la ruta de la imagen
                  alt="Fallback Icon"
                  style={{
                    width: 'auto',
                    height: '190px',
                    maxHeight: '200px',
                    padding: '1em',
                    objectFit: 'contain',
                  }}
                />
              </div>
            )}
          </Card>
        </Box>

        <DialogTitle className="text-xl text-center font-bold">Eliminar Plan {plan.planName}</DialogTitle>
        <DialogContent className="flex flex-col gap-5">
          <Alert severity="warning">Esta acción es irreversible.</Alert>
          <Typography>
            ¿Estás seguro que deseas eliminar <b>{plan.planName}</b>? 
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Segundo diálogo para confirmar la eliminación */}
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle className="text-xl font-bold">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Alert severity="error">Esta acción no se puede deshacer.</Alert>
          <Typography>
            ¿Estás absolutamente seguro de que deseas eliminar permanentemente <b>{plan.planName}</b>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeletePlanModal;