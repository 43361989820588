import { publicRequest, userRequestByParams } from "../requestMethods/requestMethods";


export const createSmarthphone = async (phone) => {
  try {
    const response = await publicRequest.post('/smartphones/',phone);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};

export const createSmarthphoneDetails = async (phone) => {
  try {
    const response = await publicRequest.post('/smartphone-details/',phone);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};


export const createMultipleSmarthphones = async (listOfPhones) => {
  try {
    const uniqueData = Array.from(new Set(listOfPhones.map(item => item.id)))
    .map(id => {
      return listOfPhones.find(item => item.id === id);
    });
    console.log(uniqueData);

    const response = await publicRequest.post('/smartphones/create-multiple',uniqueData);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};


export const createMultipleSmartphonesDetails = async (listOfPhones) => {
  try {
    // Create a map to store unique smartphone details by smartphoneId
    const uniqueDataMap = new Map();

    listOfPhones.forEach(phone => {
      if (!uniqueDataMap.has(phone.smartphoneId)) {
        uniqueDataMap.set(phone.smartphoneId, {
          smartphoneId: phone.smartphoneId,
          price: phone.price,
          capacity: phone.capacity,
          screenSize: phone.screenSize,
          opSystem: phone.opSystem,
          camera: phone.camera,
          color: phone.color,
          connectivity: phone.connectivity, // Include if needed
          memoryRam: phone.memoryRam,
          features: phone.features,
          description: phone.description,
          productImages: phone.productImages,
          rating: phone.rating || 0, // Default to 0 if not provided
        });
      }
    });

    // Convert the map values to an array
    const uniqueData = Array.from(uniqueDataMap.values());

    console.log(uniqueData);

    // Send the unique data to the backend
    const response = await publicRequest.post('/smartphone-details/create-multiple', uniqueData);

    return response.data;
  } catch (error) {
    console.error('Error creating multiple smartphone details:', error);
    throw error;
  }
};


export const getAllSmarthphones = async () => {
    try {
      const response = await publicRequest.get('/smartphones');
      return response.data;
    } catch (error) {
      console.error('Error fetching smartphones:', error);
      throw error;
    }
};

export const getAllSmarthphonesCount = async () => {
  try {
    const response = await publicRequest.get('/smartphones/count');
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};

export const getSmarthphonesById = async (id) => {
  try {
    const response = await publicRequest.get(`/smartphones/smartphoneId/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};

export const getSmarthphonesByName = async (productName) => {
  try {
    const response = await publicRequest.get(`/smartphones/byName/${productName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};


export const getSmarthphoneDeatilById = async (phoneId) => {
  try {
    const response = await publicRequest.get(`/smartphone-details/smartphoneId/${phoneId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
}


export const getSmarthphonePriceById = async (phoneId) => {
  
  try {
    const response = await publicRequest.get(`/prices/smartphoneId/${phoneId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
}

export const getTopRatedSmartphones = async (limit) => {
  try {
    const response = await userRequestByParams({ limit }).get('/smartphones/rating-top/top-rated/');

    if (response.status !== 200) {
      throw new Error(`Error fetching smartphones: ${response.status} - ${response.statusText}`);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching top-rated smartphones:', error.message || error);
    throw new Error('Failed to fetch top-rated smartphones. Please try again later.');
  }
};


export const updateSmartphone = async (id, smartphone) => {
  try {
    const response = await publicRequest.put(`/smartphones/${id}`, smartphone);
    return response.data;
  } catch (error) {
    console.error('Error updating promotion:', error);
    throw error;
  }
};

export const updateMultipleSmartphonesPrice = async (smartphones) => {
  try {
    // Create an array of promises for each update
    const updatePromises = smartphones.map((smartphone) => {
      return publicRequest.put(`/smartphones/${smartphone.id}`, {
        price: smartphone.price
      });
    });

    // Wait for all the update requests to complete
    const results = await Promise.all(updatePromises);

    // Return an array of updated smartphones' responses
    return results.map(response => response.data);
  } catch (error) {
    // Improved error handling to provide detailed error messages
    console.error('Error updating smartphones:', error.response ? error.response.data : error.message);
    throw error; // Re-throw the error after logging
  }
};

export const updateSmartphoneDetails = async (id, smartphone) => {
  try {
    const response = await publicRequest.put(`/smartphone-details/${id}`, smartphone);
    return response.data;
  } catch (error) {
    console.error('Error updating promotion:', error);
    throw error;
  }
};



// Delete a promotion
export const deleteProduct= async (id) => {
  try {
    const response = await publicRequest.delete(`/smartphones/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting promotion:', error);
    throw error;
  }
};

// Delete a promotion
export const deleteProductDetailsBySmartphoneId= async (id) => {
  try {
    const response = await publicRequest.delete(`/smartphone-details/smartphoneId/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting promotion:', error);
    throw error;
  }
};