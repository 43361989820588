import React from "react";
import { styled } from "@mui/system";

interface IconProps {
  backgroundColor?: string;
  foregroundColor?: string;
  width?: number;
  height?: number;
  additionalStyles?: React.CSSProperties;
}

// Define a custom styled component with props
const StyledSvg = styled('svg')<IconProps>(({ theme, width = 26, height = 25, additionalStyles }) => ({
  width,
  height,
  viewBox: '0 0 26 25',
  ...additionalStyles, // Spread additional styles
}));

const Icon: React.FC<IconProps> = ({
  backgroundColor = "#FFF",
  foregroundColor = "#000",
  width,
  height,
  additionalStyles
}) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={additionalStyles}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-48 -218)">
          <g transform="translate(20 52)">
            <g transform="translate(0 138)">
              <g transform="translate(0 28)">
                <g transform="translate(28.602)">
                  <rect
                    width="25"
                    height="25"
                    x="0"
                    y="0"
                    fill={backgroundColor}
                    rx="12.5"
                  ></rect>
                  <path
                    fill={foregroundColor}
                    d="M17.714 4.878h2.618l-5.72 6.457 6.73 8.787h-5.269l-4.126-5.33-4.722 5.33h-2.62l6.118-6.907-6.455-8.337H9.67l3.73 4.871 4.314-4.871zm-.918 13.696h1.45L8.882 6.344H7.326l9.47 12.23z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
}

export default Icon;
