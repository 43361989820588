import React from "react";
import { styled } from "@mui/system";

interface IconProps {
  backgroundColor?: string;
  foregroundColor?: string;
}

const StyledSvg = styled('svg')({
  width: 26,
  height: 25,
  viewBox: '0 0 26 25',
});

const Icon: React.FC<IconProps> = ({ backgroundColor = "#000", foregroundColor = "#FFF" }) => {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-211 -218)">
          <g transform="translate(20 52)">
            <g transform="translate(0 138)">
              <g transform="translate(0 28)">
                <g transform="translate(191.063)">
                  <path fill={backgroundColor} d="M0 0L25 0 25 25 0 25z"></path>
                  <g fill={foregroundColor} transform="translate(4.412 10.294)">
                    <path d="M2.542.094v2.029c0 .676-.277.957-.923.957S.696 2.8.696 2.123V.007H.08C.025.007 0 .033 0 .094V2.15C0 3.28.665 3.676 1.625 3.676S3.25 3.281 3.25 2.15V0h-.622c-.055.007-.086.033-.086.094z"></path>
                    <path d="M11.56.59c.048 0 .073-.02.092-.061l.178-.482c.012-.027 0-.047-.025-.047H9.3c-.234 0-.32.08-.32.254v3.148c0 .147.068.214.246.214h2.333c.05 0 .074-.02.093-.06l.178-.482c.012-.027 0-.047-.025-.047H9.67v-.643c0-.221.11-.321.412-.321h.93c.049 0 .073-.02.092-.06l.172-.47c.012-.026 0-.046-.025-.046H9.67V.583h1.89v.006z"></path>
                    <path d="M7.146 1.714c.27-.154.382-.448.382-.79 0-.797-.597-.917-1.219-.917H4.893c-.234 0-.32.08-.32.254V3.41c0 .147.068.214.246.214h1.742c.659 0 1.127-.275 1.127-.998.006-.442-.166-.804-.542-.91zM5.269.583h1.095c.37 0 .468.147.468.462 0 .314-.105.462-.468.462H5.27V.583zm1.225 2.45H5.269v-.662c0-.221.11-.322.412-.322h.813c.387 0 .498.16.498.496.006.335-.104.489-.498.489z"></path>
                    <path d="M16.17 3.556l-.745-1.393c.369-.1.67-.368.67-1.045 0-.877-.498-1.111-1.323-1.111H13.35c-.234 0-.32.08-.32.254v3.275c0 .06.025.087.08.087h.61V2.572c0-.221.11-.322.412-.322h.61l.67 1.32c.019.04.043.06.093.06h.634c.043-.014.043-.054.03-.074zm-1.3-1.875h-1.156V.589h1.157c.43 0 .529.181.529.55 0 .361-.098.542-.53.542z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
}

export default Icon;
