import React, {useEffect,useState} from 'react'
import { getCustomDataPlanServices,deleteCustomDataPlanService,deleteCustomPlanService, getCustomPlanServices, createCustomDataPlanService, updateCustomDataPlanService, createCustomPlanService, updateCustomPlanService } from '../../../services/customPlanServices';
import { CustomDataPlansAttributes, CustomPlansAttributes } from '../../../constant/ServicesInterfaces';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { CircularProgress, Box, IconButton, Switch, Stack, Typography, Card, CardActionArea, CardContent } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useSnackbar } from '../../Alerts/SnackBarProvider'; // Adjust the path
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatPrice } from '../../../utils/ConvertApp';
import DeleteCustomPlanModal from '../Modal/PlanService/DeleteCustomPlanModal';
import EditCustomDataPlansModal from '../Modal/PlanService/CustomPlans/EditCustomDataPlanModal';
import AddCustomPlanModal from '../Modal/PlanService/CustomPlans/AddDataPlanModal';
import AddCustomDurationModal from '../Modal/PlanService/CustomPlans/AddCustomDurationModal';
import EditCustomDurationPlansModal from '../Modal/PlanService/CustomPlans/EditCustomDurationPlanModal';



const CustomButton = styled(Button)<{ active: boolean }>(({ theme, active }) => ({
  borderColor: active ? '#1E88E5' : '#B0BEC5', // Blue border color if active, otherwise gray
  backgroundColor: active ? 'rgba(30, 136, 229, 0.2)' : '#FFFFFF', // Semi-transparent blue background when active
  color: active ? '#1E88E5' : '#37474F', // Blue text color when active, otherwise default
  borderWidth: 2,
  width: 'fit-content',
  padding: '0.5rem 1rem',
  cursor: 'pointer',
  boxShadow: active ? '0 4px 12px rgba(30, 136, 229, 0.2)' : 'none', // Subtle shadow when active
  transition: 'all 0.3s ease', // Smooth transition
  '&:hover': {
    borderColor: '#1E88E5', // Blue border color on hover
    backgroundColor: 'rgba(30, 136, 229, 0.1)', // Lighter transparent blue on hover when inactive
  },
}));



interface DashboardDataProps {
  updateDashboardData: (total: number, activePersonal: number, activeExclusive: number) => void;
}

interface PlansCustomInterface{
  id: number;
  duration: number;
  dataInclude: number; // Represents the GB value as a decimal
  idData:number;
  pricePerMonth: number; // Represents the price per month as a decimal
  salePercentage: number;
}


const CustomPlanTable:React.FC<DashboardDataProps> = ({updateDashboardData}) => {

  const [planServices, setPlanServices] = useState<CustomPlansAttributes[]>([]);
  const [currentPlanService, setCurrentPlanService] = useState<CustomPlansAttributes | null>(null);
  const [planDataServices, setDataPlanServices] = useState<CustomDataPlansAttributes[]>([]);
  const [currentDataPlanService, setCurrentDataPlanService] = useState<CustomDataPlansAttributes | null>(null);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [paginationDurationModel, setPaginationDurationModel] = useState({ page: 0, pageSize: 10 });
  const [loading, setLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isEditDurationModalOpen, setIsEditDurationModalOpen] = useState<boolean>(false);
  const [isAddDataModalOpen, setIsAddDataModalOpen] = useState<boolean>(false);
  const [isAddDurationModalOpen, setIsAddDurationModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPlanServices();
  }, []);

  const fetchPlanServices = async () => {
    try {
      setLoading(true);
      const customPlans = await getCustomPlanServices();
      const customDataPlans = await getCustomDataPlanServices();

      customDataPlans.sort((a:any, b:any) => {
        if (a.planType < b.planType) return -1;
        if (a.planType > b.planType) return 1;
        return a.dataInclude - b.dataInclude;
      });

      customPlans.sort((a:any, b:any) => {
        if (a.duration < b.duration) return -1;
        return a.duration - b.duration;
      });

      setPlanServices(customPlans);
      setDataPlanServices(customDataPlans);
      // Update the dashboard data with the computed values
    } catch (error) {
      console.error('Error fetching smartphones:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 20, headerAlign: 'left', align: 'left' },
    { field: 'dataInclude', headerName: 'Datos', width: 100, headerAlign: 'left', align: 'center',  
      renderCell: (params: GridRenderCellParams) => (
      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
        <Typography variant="body2">
          {params.row.dataInclude} GB
        </Typography>
      </Stack>
    ), },
    {
      field: 'pricePerMonth',
      headerName: 'Precio',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            ${formatPrice(params.row.pricePerMonth)}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'planType',
      headerName: 'Tipo',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
            {params.row.planType == 'Personal' ? <PersonIcon/>:<BusinessCenterIcon/>}
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 70,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" justifyContent="center">
          <IconButton 
            onClick={() => handleEdit(params.row as CustomDataPlansAttributes)} 
            className="hover:bg-blue-100 rounded-full"
            aria-label="Edit Plan"
          >
            <EditIcon className='text-cblue-500'/>
          </IconButton>
          <IconButton 
            onClick={() => {
              setCurrentDataPlanService(params.row as CustomDataPlansAttributes);
              setIsDeleteModalOpen(true);
            }} 
            className="hover:bg-red-100 rounded-full"
            aria-label="Delete Plan"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsDuration: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 20, headerAlign: 'left', align: 'left' },
    { field: 'duration', headerName: 'Duración', width: 100, headerAlign: 'left', align: 'center' },
    {
      field: 'salePercentage',
      headerName: 'Descuento',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            {params.row.salePercentage}%
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton 
            onClick={() => handleDurationEdit(params.row as CustomPlansAttributes)} 
            className="hover:bg-blue-100 rounded-full"
            aria-label="Edit Plan"
          >
            <EditIcon className='text-cblue-500'/>
          </IconButton>
          <IconButton 
            onClick={() => {
              setCurrentPlanService(params.row as PlansCustomInterface);
              setIsDeleteModalOpen(true);
            }} 
            className="hover:bg-red-100 rounded-full"
            aria-label="Delete Plan"
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const handleEdit = (planService: CustomDataPlansAttributes) => {
    setCurrentDataPlanService(planService);
    setIsEditModalOpen(true);
  };


  const handleDurationEdit = (planService: CustomPlansAttributes) => {
    console.log(planService);
    setCurrentPlanService(planService);
    setIsEditModalOpen(true);
  };

  const handleSave = async (planService: CustomDataPlansAttributes) => {
    try {
      await createCustomDataPlanService(planService);
      showSnackbar('Plan Borrado de forma exitosa!', 'success');
      fetchPlanServices();
      setIsAddDataModalOpen(false);
    } catch (error) {
      console.error('Error Borrado Plan:', error);
      showSnackbar('Error Borrado Plan', 'error');
    }
  };

  const handleDelete = async (planService: CustomDataPlansAttributes) => {
    try {
      if(planService.id){
      await deleteCustomDataPlanService(planService.id);
      showSnackbar('Plan Borrado de forma exitosa!', 'success');
      fetchPlanServices();
      setIsDeleteModalOpen(false);
    }
    } catch (error) {
      console.error('Error Borrado Plan:', error);
      showSnackbar('Error Borrado Plan', 'error');
    }
  };

  const handleSaveDuration = async (planService: CustomPlansAttributes) => {
    try {
      await createCustomPlanService(planService);
      showSnackbar('Plan Borrado de forma exitosa!', 'success');
      fetchPlanServices();
      setIsAddDataModalOpen(false);
    } catch (error) {
      console.error('Error Borrado Plan:', error);
      showSnackbar('Error Borrado Plan', 'error');
    }
  };

  const handleDeleteDuration = async (planService: CustomPlansAttributes) => {
    try {
      if(planService.id){
      await deleteCustomPlanService(planService.id);
      showSnackbar('Plan Borrado de forma exitosa!', 'success');
      fetchPlanServices();
      setIsDeleteModalOpen(false);
    }
    } catch (error) {
      console.error('Error Borrado Plan:', error);
      showSnackbar('Error Borrado Plan', 'error');
    }
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsAddDataModalOpen(false);
    setIsAddDurationModalOpen(false);
    setIsAddDurationModalOpen(false);
    setCurrentPlanService(null);

  };

  return (
    <div style={{ height: 500, width: '100%' }}>
      <div className="flex flex-wrap justify-around m-auto gap-10">
      <Box className="flex flex-col items-center justify-between gap-5">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          rows={planDataServices}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Descargar como Excel',
          }}
          className='w-fit max-w-[450px]'
          pagination
        />
      )}
          <Button 
            color='primary' 
            variant="contained" 
            className='!rounded-full w-[200px]'  
            onClick={() => setIsAddDataModalOpen(true)}
            >
            Agregar Opción
          </Button>
      </Box>
    
      <Box className="flex flex-col items-center justify-between gap-5">

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          rows={planServices}
          columns={columnsDuration}
          paginationModel={paginationDurationModel}
          onPaginationModelChange={setPaginationDurationModel}
          pageSizeOptions={[5, 10, 20]}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Descargar como Excel',
          }}
          className='w-fit max-w-[400px] max-h-[600px]'
          pagination
        />
      )}

      <Button 
            color='primary' 
            variant="outlined" 
            className='!rounded-full w-[200px]'  
            onClick={() => setIsAddDurationModalOpen(true)}
            >
            Agregar Opción
          </Button>
      </Box>

    </div>

    {currentDataPlanService && isDeleteModalOpen && (
      <DeleteCustomPlanModal
        open={isDeleteModalOpen}
        onClose={handleModalClose}
        onDelete={() => handleDelete(currentDataPlanService)}
        plan={currentDataPlanService}    
      />
    )}

    {currentPlanService && isDeleteModalOpen && (
      <DeleteCustomPlanModal
        open={isDeleteModalOpen}
        onClose={handleModalClose}
        onDelete={() => handleDeleteDuration(currentPlanService)}
        plan={currentPlanService}    
      />
    )}
    {currentPlanService && isEditModalOpen && (
        <EditCustomDurationPlansModal
          open={isEditModalOpen}
          onClose={handleModalClose}
          onSave={async (updatedPlanService:CustomPlansAttributes) => {
            try {
              if(updatedPlanService.id){
                await updateCustomPlanService(updatedPlanService.id, updatedPlanService);
                showSnackbar('Promotion updated successfully!', 'success');
                fetchPlanServices();
                handleModalClose();
              }else{
                showSnackbar('Promoción sin Id!', 'error');
              }
            } catch (error) {
              console.error('Error updating promotion:', error);
              showSnackbar('Error updating promotion', 'error');
            }
          }}
          plan={currentPlanService}
        />
      )}

    {currentDataPlanService && isEditModalOpen && (
        <EditCustomDataPlansModal
          open={isEditModalOpen}
          onClose={handleModalClose}
          onSave={async (updatedPlanService:CustomDataPlansAttributes) => {
            try {
              if(updatedPlanService.id){
                await updateCustomDataPlanService(updatedPlanService.id, updatedPlanService);
                showSnackbar('Plan updated successfully!', 'success');
                fetchPlanServices();
                handleModalClose();
              }else{
                showSnackbar('Plan sin Id!', 'error');
              }
            } catch (error) {
              console.error('Error updating promotion:', error);
              showSnackbar('Error updating promotion', 'error');
            }
          }}
          plan={currentDataPlanService}
        />
      )}

    {isAddDataModalOpen && (
      <AddCustomPlanModal
        open={isAddDataModalOpen}
        onClose={handleModalClose}
        onSave={handleSave}    
      />
    )}

    {isAddDurationModalOpen && (
      <AddCustomDurationModal
        open={isAddDurationModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveDuration}    
      />
    )}


    </div>
  )
}

export default CustomPlanTable