import React from "react";

function GiftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.473"
      height="31.142"
      viewBox="0 0 25.473 31.142"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          fillRule="nonzero"
          d="M24.912 7.557h-5.504a4.322 4.322 0 001.449-3.219A4.345 4.345 0 0016.513 0c-1.629 0-3.035.914-3.775 2.246C11.996.914 10.59 0 8.957 0a4.346 4.346 0 00-4.342 4.338c0 1.283.568 2.422 1.453 3.219H.562A.564.564 0 000 8.119v4.725c0 .312.254.564.562.564h.383V30.58c0 .312.252.562.562.562h22.457a.56.56 0 00.564-.562V13.408h.383a.562.562 0 00.562-.564V8.119a.561.561 0 00-.561-.562zm-.564 1.126v3.602H13.301V8.683h11.047zm-7.834-7.557a3.218 3.218 0 013.219 3.213 3.22 3.22 0 01-3.215 3.219h-3.217V4.339a3.216 3.216 0 013.213-3.213zM5.738 4.339a3.22 3.22 0 016.438 0v3.219H8.957a3.225 3.225 0 01-3.219-3.219zM1.127 8.683h11.049v3.602H1.127V8.683zm11.049 4.726v7.74H2.072v-7.74h10.104zM2.072 22.274h10.104v7.742H2.072v-7.742zm11.229 7.742v-7.742h10.102v7.742H13.301zm10.101-8.867H13.3v-7.74h10.102v7.74z"
        ></path>
      </g>
    </svg>
  );
}

export default GiftIcon;
