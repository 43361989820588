// src/redux/cartSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartState, CartItem, ShippingType } from '../constant/ShopCartInterfaces';

const initialState: CartState = {
  items: [],
  subtotal:0,
  shipping:ShippingType.Free,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<CartItem>) => {
      const itemIndex = state.items.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        state.items[itemIndex].quantity += action.payload.quantity;
      } else {
        state.items.push(action.payload);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(item => item.id !== action.payload);
    },
    updateCartItemQuantity: (state, action: PayloadAction<{ id: string; quantity: number }>) => {
        const item = state.items.find((item) => item.id === action.payload.id);
        if (item) {
          item.quantity = action.payload.quantity;
        }
    },
    updateSubtotal:(state, action:PayloadAction<{price:number; quantity:number;}>) =>{
        state.subtotal = state.subtotal + (action.payload.price * action.payload.quantity);
    },
    updateShipping:(state, action:PayloadAction<ShippingType>) =>{
        state.shipping = action.payload;
    },
    resetShopCart:(state)=>{
        state.items=[];
    }
  },
});

export const { addItem, removeItem, resetShopCart, updateCartItemQuantity, updateShipping } = cartSlice.actions;

export default cartSlice.reducer;
