import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WifiIcon from '@mui/icons-material/Wifi';
import SmsIcon from '@mui/icons-material/Sms';
import CallIcon from '@mui/icons-material/Call';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getIconByName, SocialMedia } from '../../constant/ServicesInterfaces';
import { PlanServiceInterface } from '../../constant/ServicesInterfaces';
 // Import your social media interface or types

// New ServiceCardInterface for passing the planService object
interface ServiceCardInterface {
  planService: PlanServiceInterface;
}

const ServiceCard: React.FC<ServiceCardInterface> = ({ planService }) => {
  const {
    planName,
    planType,
    dataIncludedGB,
    minutesIncluded,
    smsIncluded,
    coverImgUrl,
    additionalCostPerKB,
    price,
    specifications,
    isActive,
    socialMediaIncluded,
    premiumSocialMediaIncluded,
  } = planService;

  return (
    <Box className="p-6 bg-gray-100 rounded-lg shadow-md">
      <Typography variant="h4" className="text-blue-500 mb-4">{planName}</Typography>
      
      <TableContainer component={Paper} className="mb-6">
        <Table aria-label="plan service details table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Servicios</strong></TableCell>
              <TableCell><strong>Detalles</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell><WifiIcon className="mr-2" /> Data Incluida (GB)</TableCell>
              <TableCell>{dataIncludedGB}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><CallIcon className="mr-2" /> Minutos Incluido</TableCell>
              <TableCell>{minutesIncluded === 9999 ? 'Ilimitado' : minutesIncluded}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><SmsIcon className="mr-2" /> SMS Incluido</TableCell>
              <TableCell>{smsIncluded === 9999 ? 'Ilimitado' : smsIncluded}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><AttachMoneyIcon className="mr-2" /> Precio</TableCell>
              <TableCell>${price.toFixed(2)}/Mes</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Costo Adicional por KB</TableCell>
              <TableCell>{additionalCostPerKB ? `$${additionalCostPerKB}` : 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tiop de Plan</TableCell>
              <TableCell>{planType}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" className="text-gray-700 font-bold mb-2">Especificaciones:</Typography>
      <ul className="list-disc list-inside mb-4">
        {specifications &&
          Object.keys(specifications).map((key, index) => (
            <li key={index}>
              {specifications[key]}
            </li>
          ))}
      </ul>

      <Typography variant="h6" className="text-gray-700 font-bold mt-4 mb-2">Redes Sociales:</Typography>
      <Box className="flex flex-wrap gap-2">
        {socialMediaIncluded.map((media, index) => (
          <Box key={index} className="flex items-center p-2 bg-blue-100 rounded-md">
             {getIconByName(media.name)}
            <Typography>{media.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ServiceCard;