import {Box, Button, Container, Grid } from '@mui/material'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import CompaniesTable from '../../components/Admin/Tables/CompaniesTable'
import CardBoxGrow from '../../components/Admin/Cards/CardBoxGrow'
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'
import AddCompanyModal from '../../components/Admin/Modal/Company/AddCompanyModal'
import { useState } from 'react'
import { useSnackbar } from '../../components/Alerts/SnackBarProvider'
import { Company } from '../../constant/CompanyInterface'
import { createCompany } from '../../services/companies'
import { DashboardCardType1 } from '../../components/Admin/Cards/DashboardCards/DashboardCardType1'
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';

const CompaniesAdminPage = () => {
  const [totalCompanies, setTotalCompanies] = useState<number | null>(null);
  const [activeCompanies, setActiveCompanies] = useState<number | null>(null);
  const [companiesLastMonth, setCompaniesLastMonth] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);


    // Update these states via child component actions
    const updateDashboardData = (total: number, active: number, lastMonth: number) => {
      setTotalCompanies(total);
      setActiveCompanies(active);
      setCompaniesLastMonth(lastMonth);
      setLoading(false); // Assume data is fetched and loading is finished
    };

  return (<>
  <section className='flex flex-col md:flex-row'>
  <div className="hidden md:block">
        <VerticalNavBar />
      </div>
      <div className="block md:hidden">
        <MobileAdminNavBar />
      </div>
    <Container className='flex flex-col justify-center items-center gap-5 p-10'>
    <Grid container spacing={3} className="p-6">
      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Total de Empresas"
          value={totalCompanies}
          description="El número total de empresas registradas."
          icon={<BusinessIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Empresas Activas"
          value={activeCompanies}
          description="Número de empresas activas."
          icon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Empresas Agregadas"
          value={companiesLastMonth}
          description="Número de empresas agregadas el mes."
          icon={<EventIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>
    </Grid>
      <Box className='flex flex-col justify-center items-center'>
       <CompaniesTable updateDashboardData={updateDashboardData}/>
      </Box>
    </Container>
</section>
  </>
  )
}

export default CompaniesAdminPage