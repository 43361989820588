import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, Divider, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { phonesList } from '../../pages/Data/PhonesData';
import { PhoneInterface } from '../../constant/PhoneModel';
import ProductCheckoutBox from '../SideBar/ProductCheckoutBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatPrice } from '../../utils/ConvertApp';
import { ShippingType } from '../../constant/ShopCartInterfaces';

const SuccessPayment = () => {

    const cartItems = useSelector((state: RootState) => state.shopCart.items);
    const [checkoutList,setCheckoutList] = useState<PhoneInterface[]>();
    const [activeCupon,setActiveCupon] = useState<boolean>(false);
    const [subtotal,setSubtotal] =useState<number>(0);
    const shippingType =  useSelector((state: RootState) => state.shopCart.shipping);

    useEffect(()=>{
        const cartItemIds = new Set(cartItems.map(item => item.id));
        const filterPhones=phonesList.filter((phone) => cartItemIds.has(phone.id));
        setCheckoutList(filterPhones);
      },[cartItems])

  return (
    <Box>
        <Card>
            <CardContent className='flex flex-col justify-center items-center'>
                <Typography variant='h3'>
                    Tu Compra ha sido exitso
                </Typography>
                <Typography variant='body1'>
                    Todos los detalles se encuentran
                </Typography>
                <img className='w-16' src={require('../../assets/images/icons/DashboardAdmin/icons8-check.gif')} alt="" />
                <Divider/>
                    {checkoutList?.map((item,index)=>{
                        const cartItem = cartItems.find(cartItem => cartItem.id === item.id);
                        const quantity = cartItem ? cartItem.quantity : 0;
                        return(  
                        <ProductCheckoutBox
                            key={index}
                            productId={item.id}
                            productName={item.productName}
                            imgUrl={item.imgUrl}
                            quantity={quantity}
                            price={item.price}
                        />
                    )})}
            </CardContent>
        </Card>

    </Box>
  )
}

export default SuccessPayment