// src/services/promotionService.ts
import { publicRequest, userRequestByParams } from '../requestMethods/requestMethods';

interface Promotion {
  id: number;
  imgUrl: string;
  title: string;
  description: string;
  shortDesc?: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
  isExclusive: boolean;
}

// Get all promotions
export const getAllPromotions = async () => {
  try {
    const response = await publicRequest.get('/promotions');
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    throw error;
  }
};

export const getAllPromotionsCount = async () => {
  try {
    const response = await publicRequest.get('/promotions/count');
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    throw error;
  }
};

export const getAllPromotionsNoExclusive = async () => {
  try {
    const response = await publicRequest.get('/promotions/non-exclusive');
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    throw error;
  }
};

export const getAllExclusivePromotions = async () => {
  try {
    const response = await publicRequest.get('/promotions/exclusive');
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions:', error);
    throw error;
  }
};

// Get promotion by ID
export const getPromotionById = async (id: number) => {
  try {
    const response = await publicRequest.get(`/promotions/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching promotion:', error);
    throw error;
  }
};

// Get promotions by company ID
export const getCompanyPromotions = async (companyId: number) => {
  try {
    const response = await publicRequest.get(`/companies/${companyId}/promotions`);
    return response.data;
  } catch (error) {
    console.error('Error fetching company promotions:', error);
    throw error;
  }
};

// Fetch all non-exclusive promotions
export const getNonExclusivePromotions = async () => {
  try {
    const response = await publicRequest.get('/promotions/non-exclusive');
    return response.data;
  } catch (error) {
    console.error('Error fetching non-exclusive promotions:', error);
    throw error;
  }
};

// Fetch exclusive promotions
export const getExclusivePromotions = async () => {
  try {
    const response = await publicRequest.get('/promotions/exclusive');
    return response.data;
  } catch (error) {
    console.error('Error fetching exclusive promotions:', error);
    throw error;
  }
};

// Fetch exclusive promotions by companies
export const getExclusivePromotionsByCompanies = async (companyId: number) => {
  try {
    const response = await publicRequest.get(`/companies/${companyId}/promotions/exclusive`);
    return response.data;
  } catch (error) {
    console.error('Error fetching exclusive promotions by companies:', error);
    throw error;
  }
};

// Fetch exclusive promotions for all
export const getExclusivePromotionsForAll = async (companyId: number) => {
  try {
    const response = await publicRequest.get(`/companies/${companyId}/promotions/exclusive/all`);
    return response.data;
  } catch (error) {
    console.error('Error fetching exclusive promotions for all:', error);
    throw error;
  }
};

// Create a new promotion
export const createPromotion = async (promotion: any) => {
  try {
    const response = await publicRequest.post('/promotions', promotion);
    return response.data;
  } catch (error) {
    console.error('Error creating promotion:', error);
    throw error;
  }
};

// Update a promotion
export const updatePromotion = async (id: number, promotion: any) => {
  try {
    const response = await publicRequest.put(`/promotions/promotion/${id}`, promotion);
    return response.data;
  } catch (error) {
    console.error('Error updating promotion:', error);
    throw error;
  }
};


export const updatePromotionStatusById = async (id:number, isActive:boolean): Promise<any> => {
  try {
    const params = {isActive:isActive}
    const response = await userRequestByParams(params).put(`/promotions/update-status/${id}`, {
    });

    return response.data;
  } catch (error) {
    console.error('Error updating web page status:', error);
    throw error; // Re-throw the error if you want to handle it in the calling function
  }
};

export const updatePromotionExclusiveStatusById = async (id:number, isExclusive:boolean): Promise<any> => {
  try {
    const params = {isExclusive:isExclusive}
    const response = await userRequestByParams(params).put(`/promotions/update-exclusive/${id}`, {
    });

    return response.data;
  } catch (error) {
    console.error('Error updating web page status:', error);
    throw error; // Re-throw the error if you want to handle it in the calling function
  }
};


// Delete a promotion
export const deletePromotion = async (id: number) => {
  try {
    const response = await publicRequest.delete(`/promotions/promotion/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting promotion:', error);
    throw error;
  }
};

// Get promotions by company ID
export const getPromotionsByCompany = async (companyId: number) => {
  try {
    const response = await publicRequest.get(`/companies/${companyId}/promotions`);
    return response.data;
  } catch (error) {
    console.error('Error fetching promotions by company:', error);
    throw error;
  }
};

// Get exclusive promotions with companies
export const getExclusivePromotionsWithCompanies = async () => {
  try {
    const response = await publicRequest.get('/promotions/exclusive/with-companies');
    return response.data;
  } catch (error) {
    console.error('Error fetching exclusive promotions with companies:', error);
    throw error;
  }
};

// Get active promotions
export const getActivePromotions = async () => {
  try {
    const response = await publicRequest.get('/promotions/active');
    return response.data;
  } catch (error) {
    console.error('Error fetching active promotions:', error);
    throw error;
  }
};
