import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import BannerCard from '../Card/BannerCard';
import { WebSliderInterface } from '../../constant/WebPageInterface';
import { getWebPagesByCategory } from '../../services/webPage';

const promotions = [
  {
    imgUrl: "https://www.telcel.com/content/dam/telcelcom/promociones/amigo/50-por-ciento-mas-gigas-noviembre-2022-v1-big.jpg",
    title: 'Renueva Promo Oro Pos Agosto',
  },
  {
    imgUrl: "https://www.telcel.com/content/dam/telcelcom/Telefonia/Amigo/saldos-y-recargas/Recarga%20tu%20saldo%20Amigo/recargas-saldo-regalo-promocion-enero-2022-v1-bnm.jpg",
    title: 'Recarga Saldo Regalo Enero',
  },
  {
    imgUrl: "https://www.telcel.com/promociones/planes/cambiate-5g/_jcr_content/principal/mainPar1/generic_image.coreimg.jpeg/1705599174111/cambiate-a-5g-enero-2024-v1-big.jpeg",
    title: 'Cámbiate a 5G Enero',
  }
];

const PromotionBanners = () => {
  const [activeCard, setActiveCard] = useState<number | null>(0);
  const [sliderList, setSliderList] = useState<WebSliderInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSliderInformations();
  }, []);

  const getSliderInformations = async () => {
    try {
      const sliders = await getWebPagesByCategory('Promociones Iniciales');
      setSliderList(sliders);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (id: number) => {
    setActiveCard(id);
  };

  return (
    <Box className="flex overflow-x-auto gap-4 p-4">
      {isLoading ? (
        // Show skeletons while loading
        Array.from(new Array(3)).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={300}
            height={200}
            animation="wave"
            sx={{ borderRadius: 2 }}
          />
        ))
      ) : (
        // Show banner cards when data is loaded
        (sliderList && sliderList.length > 3 ? sliderList : promotions)
          .slice(0, 3)
          .map((promotion, index) => (
            <BannerCard
              key={index}
              imgUrl={promotion.imgUrl}
              active={index === activeCard}
              onClick={() => handleCardClick(index)}
            />
          ))
      )}
    </Box>
  );
};

export default PromotionBanners;