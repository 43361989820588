import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import { useMediaQuery } from '@mui/material';

interface ServicePromoCardProps {
  title?: string | null;
  subtitle?: string | null;
  body?: string | null;
  body2?: string | null;
  img: string;
}

const ServicePromoCard: React.FC<ServicePromoCardProps> = ({ title, img, subtitle, body2, body }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card 
        onClick={handleClickOpen} // Open modal on click
        sx={{ 
          width: isMobile ? 280 : 360, 
          height: isMobile ? 350 : 450, 
          borderRadius: '20px', 
          backgroundImage: `url(${img})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat', 
          overflow: 'hidden',
          cursor: 'pointer' // Add pointer to indicate clickability
        }} 
        className='!shadow-2xl'
      >
        <CardContent>
          <Typography variant='h2' className='font-bold' sx={{ color: 'black', fontSize: 14, fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant='h2' className='font-bold' sx={{ color: 'black', fontSize: 21, fontWeight: 'bold' }}>
            {subtitle}
          </Typography>
          <Typography variant="h5" component="div">
            {title && 'Diseñado para apple intelligence'}
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{body}</Typography>
          <Typography variant="body2">
            {body2}
          </Typography>
        </CardContent>
      </Card>

      {/* Modal (Dialog) to zoom the image */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <img 
          src={img} 
          alt={title || "Zoomed Image"} 
          style={{ 
            width: '100%', 
            height: 'auto', 
            borderRadius: '20px' 
          }} 
        />
      </Dialog>
    </>
  );
};

export default ServicePromoCard;