import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TerminosYCondiciones from './Accordions/TermnsNConditions';
import ComoFacturar from './Accordions/Facturar';
import PlanesYServicios from './Accordions/PlansNServices';
import NetworkConditions from './Accordions/Facturar copy';
import TelcelInfoCard from '../Services/Accordions/TelcelInfoCard';
import SocialMediaPolicies from '../Services/Accordions/SocialMediaPolicies';
import ProcedimientoCancelacionDevolucion from '../Services/Accordions/ProcedimientoDeCancelacion';
import { Divider } from '@mui/material';

const AdditionalInformation: React.FC = () => {
  return (
    <Box className="p-1 md:p-6 bg-gray-100 rounded-lg shadow-md">
      <Typography variant="h6" sx={{marginBottom:'1rem'}} className="text-gray-700 mb-4">
        Información Adicional
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Consideraciones de uso de red 5G</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TelcelInfoCard/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Politicas de Uso de Redes Sociales</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SocialMediaPolicies/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Términos y Condiciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TerminosYCondiciones />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-gray-600">Procedimiento de Cancelacion</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ProcedimientoCancelacionDevolucion/>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ my: 4 }}>
        {/* Leyenda de cambios de precio */}
        <Typography variant="body1" component="h2" gutterBottom>
          Cambios de precio
        </Typography>
        <Typography variant="body2" paragraph>
          Los precios y la disponibilidad de los productos están sujetos a cambios sin previo aviso. Nos reservamos el derecho de modificar los precios en cualquier momento, de acuerdo con las condiciones del mercado y políticas internas.
        </Typography>
        
        <Divider sx={{ my: 2 }} />

        {/* Leyenda de uso de imágenes */}
        <Typography variant="body1" component="h2" gutterBottom>
          Uso de imágenes
        </Typography>
        <Typography variant="body2" paragraph>
          Las imágenes mostradas son únicamente con fines ilustrativos. Los productos pueden variar en cuanto a color, diseño o especificaciones.
        </Typography>
      </Box>
    </Box>
  );
};

export default AdditionalInformation;
