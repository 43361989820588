import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { getWebPagesByCategory } from '../../services/webPage';
import { WebSliderInterface } from '../../constant/WebPageInterface';
import { Skeleton } from '@mui/material';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import './PrincipalSlidertStyle.css';

const ServicesSlider: React.FC = () => {
  const [sliderList, setSliderList] = useState<WebSliderInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSliderInformation = async () => {
      try {
        const sliders = await getWebPagesByCategory('Slider Planes');
        setSliderList(sliders);
      } catch (error) {
        console.error('Error fetching slider information:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSliderInformation();
  }, []);

  const filteredSliders = sliderList.filter((item) => {
    const now = new Date();
    const startDay = new Date(item.startDay);
    const endDay = new Date(item.endDay);
    return item.isActive && now >= startDay && now <= endDay;
  });

  return (
    <div style={{ height: 'auto', width: 'auto', maxHeight:'600px', overflow:'hidden' }}>
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {isLoading ? (
          <SwiperSlide>
            <Skeleton variant="rectangular" width="100%" height={600} />
          </SwiperSlide>
        ) : (
          filteredSliders.map((item, index) => (
            <SwiperSlide className="relative" key={index}>
              <img
                className="w-full h-full object-center object-contain rounded-lg shadow-md"
                src={item.imgUrl}
                alt={item.title || 'Slide image'}
              />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default ServicesSlider;
