import React from 'react';
import { Card, CardContent, Typography, Link, Divider } from '@mui/material';

const TelcelInfoCard: React.FC = () => {
  return (
    <Card sx={{padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Uso de Datos en Tecnologías Telcel
        </Typography>
        
        <Divider sx={{ marginBottom: 2 }} />
        
        <Typography variant="body1" paragraph>
          Los datos incluidos para navegación libre podrán ser utilizados a través de cualquiera de las tecnologías disponibles y ofrecidas por Telcel (2G, 3G y 4G), así como 5G conforme a su disponibilidad de acuerdo a las coberturas comunicadas.
        </Typography>
        
        <Typography variant="body1" paragraph>
          Para hacer uso de los datos incluidos estando conectado a la red 5G de Telcel, se requiere que el equipo terminal utilizado cuente con las capacidades técnicas necesarias, el usuario disponga de un Chip Inteligente (SIM Card) compatible con la tecnología de red 5G y encontrarse dentro de la cobertura garantizada 5G. 
          Consulte la disponibilidad y mapas de cobertura 5G en&nbsp;
          <Link href="https://www.telcel.com/cobertura" target="_blank" rel="noopener">
            www.telcel.com/cobertura
          </Link>.
        </Typography>
        
        <Typography variant="body1" paragraph>
          El usuario podrá contratar y hacer uso del plan con cualquier equipo terminal homologado y compatible con la red de Telcel; sin embargo, si éste no cuenta con las capacidades técnicas necesarias no podrá hacer uso de la red 5G.
        </Typography>
        
        <Typography variant="body1" paragraph>
          No obstante lo anterior, el usuario podrá disponer y hacer uso de la red 5G de Telcel en cualquier momento siempre y cuando se encuentre dentro de la cobertura garantizada 5G y haga uso de un equipo terminal homologado y compatible, sin necesidad de solicitar activación adicional alguna.
        </Typography>
        
        <Typography variant="body1" paragraph>
          El uso de datos estando en Estados Unidos o Canadá con tecnología 5G será posible siempre y cuando la red del operador del país visitado que brinde el servicio lo soporte.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TelcelInfoCard;