import { Avatar, Box, Button, Card, CardContent, Container, Typography } from '@mui/material'
import React from 'react'
import ButtonType2 from '../components/Buttons/ButtonType2'
import { faqQuestions } from '../components/Faq/Data/faqQuestions'
import FaqBox from '../components/Faq/FaqBox'

const FAQPage = () => {
  return (
    <Container maxWidth='xl'>
        <Box>
            <img src={require("../assets/images/icons/Logo.webp")} alt="Logo" style={{ height: 120 }} />
        </Box>
        <Typography variant='h2' className='font-[Avenir] text-2xl'>
            Preguntas Frecuentes
        </Typography>
        <Box className='flex flex-wrap justify-between p-10 gap-5'>
            {faqQuestions.map((item,index)=>{
                return(
                    <FaqBox key={index} title={item.title} content={item.content} icon={item.icon}/>
                )
            })}

        </Box>
        <Card className='shadow-custom m-10 max-w-[1040px]'>
            <CardContent className='flex items-center justify-between p-2'>
                <Avatar sx={{ width: 60, height: 60 }}>
                    <img src={require("../assets/images/avatar/avatar.png")} alt="avatar"/>
                </Avatar>
                <Typography variant='body1' className='grow px-5'>
                    Si tienes alguna otra duda contactanos estamos para servite
                </Typography>
                <Button className='w-fit' sx={{borderRadius:'5px', padding:'10px 30px'}} variant="contained">Contactar</Button>
            </CardContent>
        </Card>

    </Container>
  )
}

export default FAQPage