import { publicRequest, userRequestByParams } from '../requestMethods/requestMethods';
import { CustomPlansAttributes, CustomDataPlansAttributes } from '../constant/ServicesInterfaces';



export const getCustomPlanServices = async () => {
    try {
      const response = await publicRequest.get('/customPlans/plans');
      return response.data;
    } catch (error) {
      console.error('Error fetching plan services:', error);
      throw error;
    }
};

export const getCustomPlanServiceById = async (id: string) => {
    try {
      const response = await publicRequest.get(`/customPlans/plans/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching plan service with id ${id}:`, error);
      throw error;
    }
};
  
  
export const createCustomPlanService= async (plansService: CustomPlansAttributes) => {
    try {
      const response = await publicRequest.post(`/customPlans/plans`,plansService);
      return response.data;
    } catch (error) {
      console.error(`Error in creation plan service with  ${plansService.id}:`, error);
      throw error;
    }
};
  
export const updateCustomPlanService = async (id: number, plansService: CustomPlansAttributes) => {
    try {
        const response = await publicRequest.put(`/customPlans/plans/${id}`, plansService);
        return response.data;
    } catch (error) {
        console.error('Error updating promotion:', error);
        throw error;
    }
};
  
export const deleteCustomPlanService= async (id:number) => {
    try {
        const response = await publicRequest.delete(`/customPlans/plans/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting promotion:', error);
        throw error;
    }
};

//Custom Plan Data
export const getCustomDataPlanServices = async () => {
    try {
      const response = await publicRequest.get('/customPlans/dataplans');
      return response.data;
    } catch (error) {
      console.error('Error fetching plan services:', error);
      throw error;
    }
};

export const getCustomDataPlanServiceById = async (id: string) => {
    try {
      const response = await publicRequest.get(`/customPlans/dataplans/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching plan service with id ${id}:`, error);
      throw error;
    }
};
  
  
export const createCustomDataPlanService= async (plansService: CustomDataPlansAttributes) => {
    try {
      const response = await publicRequest.post(`/customPlans/dataplans`,plansService);
      return response.data;
    } catch (error) {
      console.error(`Error in creation plan service with  ${plansService.id}:`, error);
      throw error;
    }
};
  
export const updateCustomDataPlanService = async (id: number, plansService: CustomDataPlansAttributes) => {
    try {
        const response = await publicRequest.put(`/customPlans/dataplans/${id}`, plansService);
        return response.data;
    } catch (error) {
        console.error('Error updating promotion:', error);
        throw error;
    }
};
  
export const deleteCustomDataPlanService= async (id:number) => {
    try {
        const response = await publicRequest.delete(`/customPlans/dataplans/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting promotion:', error);
        throw error;
    }
};