import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import { CardCategories, CardCategoriesInterface } from './CardData';

interface CardBoxGrowProps {
  category: string;
  count: any; // Ensure count is of type number
}

const CardBoxGrow: React.FC<CardBoxGrowProps> = ({ category, count }) => {
  const [currentCategory, setCurrentCategory] = useState<CardCategoriesInterface>();

  useEffect(() => {
    const matchedCategory = CardCategories.find(item => item.category === category);
    setCurrentCategory(matchedCategory);
  }, [category]);

  return (
    <Card className='!rounded-2xl w-[170px] h-[170px]'>
      <CardContent className='flex flex-col gap-2 !p-3'>
        <Box className='flex justify-between items-center'>
          <Box className={`w-12 h-12 ${currentCategory?.color} rounded-xl flex justify-center items-center`}>
            {currentCategory?.icon}
          </Box>
        </Box>
        <Box className='flex flex-col'>
          <h6 className='text-gray-500 text-sm leading-5'>{currentCategory?.title}</h6>
          <Typography key={category} variant='h6' className='text-gray-600'>{count}</Typography> {/* Render count directly */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardBoxGrow;