export const MenuItems =[
    {
        title:'Inicio',
        url:'/'
    },
    {
        title:'Tienda',
        url:'/products'
    },
    {
        title:'Planes',
        url:'/services'
    },
    {
        title:'Promociones',
        url:'/promotions'
    },
    {
        title:'Contacto',
        url:'/contact'
    },
    {
        title:'Mis Promociones',
        url:'/promotions/company/admin'
    },
    {
        title:'Mi Cuenta',
        url:'/login'
    },
]

export const ServiceItems =[
    {
        title:'Planes',
        icon:'../assets/icons/NetworkIcon.svg',
        url:'/services'
    },
    {
        title:'Sim´s',
        icon:'../assets/icons/SimIcon.svg',
        url:'/'
    },
    {
        title:'Telefonos',
        icon:'../assets/icons/PhoneIcon.svg',
        url:'/products'
    },
    {
        title:'Servicios',
        icon:'../assets/icons/ContactIcon.svg',
        url:'/services'
    },
]