import axios from "axios";

const BASE_URL = process.env.REACT_APP_SPRING_URL;
const BASE_URL_AUTH = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    'x-api-key': API_KEY,
  },
});

export const publicHeaderRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const userRequestByParams = (params = {}) => axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    'x-api-key': API_KEY,
  },
  params: params,
});

export const userRequestBy3Params = (params) =>
  axios.create({
    baseURL: BASE_URL,
    params: {
      params,
    },
  });

export const createAxiosInstance = (params) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000, // Set your desired timeout
    params: params, // Use the dynamic params here
  });

  return instance;
}


export const createInstance = (params) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    params: params,
    headers: {
      'Content-Type': 'application/json', // Set your desired content type
    },
  });
  return instance;
};