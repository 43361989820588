import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Skeleton } from '@mui/material';
import CustomNav from '../../components/Admin/NavBar/AdminNavBar';
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar';
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar';
import CardBoxGrow from '../../components/Admin/Cards/CardBoxGrow';
import { getAllCompaniesCount } from '../../services/companies';
import { getAllCompanyUsersCount } from '../../services/companyUsers';
import { getAllSmarthphonesCount } from '../../services/products';
import { getAllPromotionsCount } from '../../services/promotions';

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [counts, setCounts] = useState<{
    companiesCount: number;
    userCount: number;
    promotionsCount: number;
    smartphonesCount: number;
  }>({
    companiesCount: 0,
    userCount: 0,
    promotionsCount: 0,
    smartphonesCount: 0,
  });

  useEffect(() => {
    getInfoCounts();
  }, []);

  const getInfoCounts = async () => {
    setIsLoading(true); // Start loading
    try {
      // Fetch all counts simultaneously
      const [companiesData, usersData, smartphonesData, promotionsData] = await Promise.all([
        getAllCompaniesCount(),
        getAllCompanyUsersCount(),
        getAllSmarthphonesCount(),
        getAllPromotionsCount(),
      ]);
  
      // Extract the count values from the returned objects
      const companiesCount = companiesData.count || 0; // Fallback to 0 if undefined
      const userCount = usersData.count || 0;         // Fallback to 0 if undefined
      const smartphonesCount = smartphonesData.count || 0; // Fallback to 0 if undefined
      const promotionsCount = promotionsData.count || 0;   // Fallback to 0 if undefined
  
      // Set the counts in the state
      setCounts({ companiesCount, userCount, promotionsCount, smartphonesCount });
    } catch (error) {
      console.error('Error fetching counts:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const renderCard = (category:string, count:number) => {
    const countValue = count;
    return(
    <Grid item xs={12} sm={6} lg={6} className='flex justify-center items-center'>
      {isLoading ? (
        <Skeleton variant="rectangular" width={170} height={170} />
      ) : (
        <CardBoxGrow category={category} count={Number.isInteger(count) ? count : 0} />
      )}
    </Grid>
    )
};

  return (
    <section className='relative flex flex-col md:flex-row'>
      <div className="sticky top-0 hidden md:block">
        <VerticalNavBar />
      </div>
      <div className="block md:hidden">
        <MobileAdminNavBar />
      </div>
      <Container className='h-[100vh] overflow-scroll' maxWidth='xl'>
        <Box sx={{ padding: 5 }} className='flex justify-center'>
          <Grid container spacing={3} justifyContent="center">
            {renderCard('Promotion', counts.promotionsCount)}
            {renderCard('Users', counts.userCount)}
            {renderCard('Companies', counts.companiesCount)}
            {renderCard('Products', counts.smartphonesCount)}
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default DashboardPage;