import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetailsState,UserInfoDetails } from '../constant/UserInterfaces';

const initialState: UserDetailsState = {
    userDetails: null,
  };

  const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        setUserDetails: (state, action: PayloadAction<UserInfoDetails>) => {
        state.userDetails = action.payload;
      },
      clearUser: (state) => {
        state.userDetails = null;
      },
    },
  });

export const { setUserDetails, clearUser } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
