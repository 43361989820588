import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Visibility, VisibilityOff, PersonAdd, Save, Cancel, Close } from '@mui/icons-material';

interface AdminUserCreateModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (newUser: AdminUserInterface) => Promise<void>;
}

interface AdminUserInterface {
    name: string;
    lastname: string;
    email: string;
    password: string; 
  }

const AdminUserCreateModal: React.FC<AdminUserCreateModalProps> = ({ open, onClose, onCreate }) => {
  const [formData, setFormData] = useState<AdminUserInterface>({ name: '', lastname: '', email: '', password: '' });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [feedback, setFeedback] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const validateForm = () => {
    let newErrors: Record<string, string> = {};
    if (!formData.name) newErrors.name = 'El nombre es obligatorio';
    if (!formData.lastname) newErrors.lastname = 'El apellido es obligatorio';
    if (!formData.email) newErrors.email = 'El correo electrónico es obligatorio';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Formato de correo inválido';
    if (!formData.password) newErrors.password = 'La contraseña es obligatoria';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setConfirmDialogOpen(false);
    setLoading(true);
    setFeedback(null);
    try {
      await onCreate(formData);
      setFeedback('Usuario creado exitosamente');
      setTimeout(() => {
        setFeedback(null);
        onClose(); // Cerrar el modal después de crear con éxito
      }, 1500);
    } catch (error) {
      setFeedback('Error al crear el usuario. Intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConfirmDialog = () => {
    if (validateForm()) {
      setConfirmDialogOpen(true);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ p: 4, width: '100%', maxWidth: 450, bgcolor: 'background.paper', margin: '100px auto', borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Crear Nuevo Usuario
        </Typography>

        <Stack spacing={2}>
          <TextField
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
          />
          <TextField
            label="Apellido"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            error={!!errors.lastname}
            helperText={errors.lastname}
            fullWidth
          />
          <TextField
            label="Correo Electrónico"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
          />
          <TextField
            label="Contraseña"
            name="password"
            value={formData.password}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            error={!!errors.password}
            helperText={errors.password}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
        </Stack>

        {feedback && (
          <Alert severity={feedback.includes('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
            {feedback}
          </Alert>
        )}

        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={24} /> : <Save />}
            onClick={handleOpenConfirmDialog}
            disabled={loading}
          >
            Crear
          </Button>
          <Button variant="outlined" color="error" startIcon={<Cancel />} onClick={onClose}>
            Cancelar
          </Button>
        </Stack>

        {/* Confirm Dialog */}
        <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
          <DialogTitle>Confirmar Creación</DialogTitle>
          <DialogContent>
            <DialogContentText>¿Estás seguro de que quieres crear este usuario?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Confirmar
            </Button>
            <Button onClick={handleCloseConfirmDialog} color="error">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default AdminUserCreateModal;