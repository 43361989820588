import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TerminosYCondiciones: React.FC = () => {
  return (
    <Box className="p-4 bg-gray-100 rounded shadow-md h-96 overflow-y-scroll">
      <Typography variant="h6" className="mb-2">
        Términos y Condiciones
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Servicios incluidos en el paquete:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        Minutos y mensajes SMS incluidos.

        Los minutos incluidos aplican para llamar a cualquier número Telcel, fijo o de otros operadores móviles estando dentro de México, Estados Unidos (incluye Puerto Rico e Islas Vírgenes Americanas) y Canadá con destino hacia México, Estados Unidos y Canadá. No incluye cobertura marítima ni aérea aun y cuando la embarcación sea de bandera Mexicana, Estadounidense o Canadiense o la embarcación o aeronave se encuentre en mar territorial o espacio aéreo de cualquiera de los 3 países.

        No incluye llamadas “Premium” a marcaciones de servicios especiales, ni a número satelitales, videollamadas, ni su uso estando fuera de México, Estados unidos y Canadá (Cobertura Sin Frontera).

        Las llamadas al 911 y otros servicios de emergencia son sin costo y aplica únicamente estando dentro de México.

        Los minutos incluidos no aplica para llamar a números 800. Las llamadas a números 900 se cobrará conforme a la tarifa aplicable al servicio asociado.

        Los casos de tráfico no especificados se cobrará de acuerdo al esquema de cobro o plan controlado que tengas activado.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Mensajes SMS incluidos:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        Los SMS incluidos aplican para enviar mensajes de texto a cualquier número Telcel, fijo o de otros operadores móviles estando dentro de México, Estados Unidos (incluye Puerto Rico e Islas Vírgenes Americanas) y Canadá con destino hacia México, Estados Unidos y Canadá. No incluye cobertura marítima ni aérea aun y cuando la embarcación sea de bandera Mexicana, Estadounidense o Canadiense o la embarcación o aeronave se encuentre en mar territorial o espacio aéreo de cualquiera de los 3 países.
        Los SMS incluidos en el paquete no aplican para el envío de Mensajes Multimedia (MMS); el envío de SMS a marcaciones cortas, ni para participar en trivias y/o concursos.

        No aplica para el envío de SMS a número satelitales ni su uso estando fuera de la Cobertura Sin Frontera.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Datos incluidos para navegación libre:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        Los Megabytes (MB) incluidos son para navegación libre estando dentro de México, Estados Unidos y Canadá. No aplica en cobertura Marítima ni Aérea aun y cuando la embarcación sea de bandera Mexicana, Estadounidense o Canadiense o la embarcación o aeronave se encuentre en mar territorial o espacio aéreo de cualquiera de los 3 países. El uso de los MB incluidos estando en Canadá está limitado a 50 MB (para los Paquetes 10, 20 y 30), a 100 MB (para los Paquetes 50 y 80), a 200 MB (para los Paquetes 100 y 150), a 500 MB (para los Paquetes 200, 300 y 500), de los incluidos para navegación libre. Una vez alcanzado el límite de uso de datos estando en Canadá los MB consumidos estando en Canadá se debitarán del paquete de datos adicionales que tengas contratado o, en su defecto, bajo demanda conforme a la tarifa por KB adicional establecida más adelante para el Esquema de cobro.

        El uso de datos estando fuera de México se cobrará conforme a las tarifas para consumo de datos estando en Roaming Internacional vigentes. Para los escenarios de tráfico no señalados se aplicarán las tarifas de Roaming Internacional vigentes aplicables al país visitado. Los servicios de Roaming Internacional se debitarán de la cuenta Saldo Amigo en todos los escenarios de tráfico. Consulta tarifas de Roaming Internacional en Telcel Viajero.

        Al término de la vigencia del Paquete, los Megabytes (MB) que no hayas consumido se perderán. Al término de los MB incluidos o de la vigencia del Paquete se comenzarán a cobrar las tarifas estándar vigentes para cada servicio (voz, SMS y datos) conforme al Esquema de Cobro o Plan Controlado que tengas contratado.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Datos incluidos para el uso de redes sociales y mensajería instantánea:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        La aplicación WhatsApp podrá ser utilizada estando dentro de México, Estados Unidos y Canadá.
        APLICA POLÍTICA DE USO DE REDES SOCIALES Y MENSAJERÍA INSTANTÁNEA.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Medios de activación:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        Los paquetes Amigo Sin Límite puede ser activados: a) mediante el envío de un mensaje SMS con la palabra SL y el monto del paquete a la marcación 5050. Por ejemplo: SL10, SL20, etc. En caso de recurrencia deberás agregar la letra R después del monto. Por ejemplo: SL10R, SL20R, etc.; en Centros de Atención a Cliente; a través de Distribuidores Autorizados, llamando al *264 desde tu Telcel y a través de cualquier otro canal que Telcel haga disponible.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Activación recurrente:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        Cuando el Paquete haya sido contratado de manera recurrente el cargo se aplicará de forma automática conforme a la vigencia del paquete contratado con cargo al Saldo Amigo. La activación del paquete se hará conforme a las condiciones comerciales (MB incluidos, vigencia, precio, beneficios, etc.), de la oferta comercial vigente a la fecha de la aplicación del paquete, no de la solicitud de activación con cargo recurrente.
        Si no es posible debitar el precio del Paquete por falta de saldo podrás seguir usando los servicios bajo demanda conforme a la tarifa aplicable a tu Esquema de Cobro o Plan Tarifario en modalidad Controlado.
        Podrás adquirir un Paquete Bajo Demanda antes del término de la vigencia del Paquete Recurrente. Los servicios incluidos en el Paquete contratado se sumarán a los que resten del Paquete Recurrente. La fecha de vigencia de los servicios acumulados se extenderá a la del Paquete con mayor vigencia.
        La fecha de activación de un nuevo Paquete Recurrente no se ve afectada por la contratación de un Paquete Bajo Demanda.
        Solo se podrá tener un Paquete Recurrente contratado. El Paquete Recurrente puede convivir con el Paquete Bajo Demanda.
        El Paquete Bajo Demanda puede convivir con otro Paquete Bajo Demanda. En caso de adquirir 2 o más Paquetes de cualquier denominación, los MB incluidos se sumarán y se aplicará la vigencia de mayor duración.
        Al Contratar un Paquete Recurrente distinto al que se había contratado inicialmente provocará que en lo subsecuente se abonen recurrentemente los eventos otorgados por el Paquete nuevo.
        Para cancelar la activación recurrente envía BAJA SL seguido del monto y la letra R a la marcación 5050. Por ejemplo: BAJA SL10R.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Condiciones generales:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        La tasación de los servicios de voz se realiza por segundo estando en México, Estados Unidos o Canadá.
        La tarifa por Megabyte (MB) adicional aplicará cuando el usuario se encuentre en México, Estados Unidos o Canadá.
        Si activas un Paquete Amigo Sin Límite, la vigencia del Saldo Promocional y Redes Sociales se ajustará a la vigencia del Paquete, en caso de que la vigencia del Saldo Promocional y Redes Sociales sea menor a la vigencia el Paquete, esta no cambiará.
        No disponible para usuarios Tip o con equipos terminales Blackberry.
        Para consumos adicionales de alguno de los servicios agotados del Paquete Amigo Sin Límite se deberán realizar recargas de Saldo Amigo.
        Al consumir la totalidad de algún tipo de evento incluido (por ejemplo: MB), el servicio se continuará prestando bajo demanda conforme a la tarifa aplicable a tu Esquema de Cobro o Plan Tarifario Controlado.
        Al término de la vigencia del paquete se podrán adquirir Paquetes Bajo Demanda y/o Paquetes Recurrentes.
        En caso de tener un paquete adicional contratado se actualizará la vigencia por la del paquete contratado.
        Recibirás notificaciones a través de mensaje SMS en los siguientes casos: activación exitosa; confirmación de recurrencia; cobro no exitoso y cancelación de recurrencia.
        Políticas de uso.
        Política de Uso de Redes Sociales y Mensajería Instantánea incluidas.
        Cuando el usuario haga uso de alguna funcionalidad no listada como incluida o expresamente excluida, el consumo de datos que se genere se debitará de los datos incluidos para navegación libre, del paquete adicional contratado o bajo demanda por Megabyte (MB) adicional.
      </Typography>

      <Typography variant="h6" className="mt-4 mb-2">
        Política de Uso de Redes Sociales y Mensajería Instantánea incluidas:
      </Typography>
      <Typography variant="body1" className="whitespace-pre-wrap">
        a) Facebook y Messenger (aplica sólo en México).
        El uso de las aplicaciones móviles oficiales que Meta Platforms, Inc. (Meta), ha liberado bajo su propia marca tales como: Facebook; Facebook lite; Administrador de páginas y Messenger; así como, los dominios www.facebook.com y m.facebook.com; serán sin costo adicional, siempre que se utilicen las siguientes funcionalidades:
        • Visualización del muro personal o de cualquier otra persona o grupo;
        • Publicar un “estado” o comentar el “estado” de otra persona o grupo;
        • Publicar o cargar fotos y video en el muro utilizando las Apps oficiales de Facebook;
        • Guardar fotos del propio muro o de cualquier persona o grupo;
        • Reaccionar a una historia personal o de otra persona o grupo (No está incluido la visualización ni la acción de reaccionar a historias “En vivo” o “Live”);
        • Publicar una historia en el muro personal o de otra personas o grupo (No está incluido la publicación y/o visualización de historias “En vivo” o “Live”);
        • Comentar una historia personal o de cualquier otra persona o grupo (No está incluido la visualización ni la acción de comentar una historia “En vivo” o “Live”);
        • Mensajear (enviar textos) a través de Messenger (No está incluido la realización de llamadas y/o video llamadas y/o Salas/Rooms, a través de la aplicación móvil y/o sitio en Internet: messenger.com);
        • Compartir imágenes a través de Messenger y
        • Recibir notificaciones desde las aplicaciones oficiales de Facebook.

        b) WhatsApp (aplica estando en México, Estados Unidos y Canadá).
        El uso de la aplicación móvil oficial que WhatsApp, Inc. (WhatsApp) ha liberado bajo su propia marca, será sin costo adicional siempre que se utilicen las siguientes funcionalidades:
        • Envío de textos (mensajear);
        • Envío de notas de voz (icono de micrófono);
        • Enviar o compartir fotos y/o videos a través de la aplicación, que hayan sido capturados y estén alojados en el carrete de la cámara o álbumes del equipo terminal (No aplica para contenidos compartidos desde fuera de la aplicación ni la consulta de contenidos y/o páginas alojadas fuera de la aplicación);
        • Descargar o guardar fotos y videos;
        • Enviar o compartir contactos de la agenda (No incluye el compartir la geolocalización/ubicación actual ni en tiempo real);
        • Publicar y visualizar “Mi estado” o el “Estado” de otra persona o grupo (No incluye la publicación ni visualización de estados “En vivo” o “Live”);
        • Recibir notificaciones desde la aplicación oficial de WhatsApp;
        • Llamadas y Llamadas grupales (ícono de teléfono) – APLICA SÓLO EN MÉXICO, y
        • Videollamadas y Videollamadas grupales (ícono de videocámara) – APLICA SÓLO EN MÉXICO.

        c) X, antes Twitter (aplica sólo en México).
        El uso de la aplicación móvil oficial que X Corp. (X, antes Twitter) haya liberado bajo su propia marca; así como, los dominios www.twitter.com, www.x.com y m.twitter.com, será sin costo adicional, siempre que se utilicen las siguientes funcionalidades:
        • Visualizar el timeline personal o de terceros;
        • Publicar un tweet;
        • Comentar un tweet;
        • Dar favorito a un tweet;
        • Retwitter un tweet;
        • Citar un tweet;
        • Enviar un mensaje directo;
        • Recibir notificaciones;
        • Publicar o cargar fotos en tu timeline, y
        • Descargar fotos.

        d) Instagram (aplica sólo en México).
      </Typography>
    </Box>
  );
};

export default TerminosYCondiciones;
