const sliderPrincipalImg = require ('../assets/images/icons/DashboardAdmin/banners.webp');
const promocionesInicialesImg = require('../assets/images/icons/DashboardAdmin/promocion.webp');
const sliderServiciosImg = require('../assets/images/icons/DashboardAdmin/galeria-de-fotos.webp');
const promocionesVipImg = require('../assets/images/icons/DashboardAdmin/clasificacion.webp');

export const Options = [
  {
    title: 'Slider Principal',
    icon: sliderPrincipalImg,
  },
  {
    title: 'Promociones Iniciales',
    icon: promocionesInicialesImg,
  },
  {
    title: 'Slider Planes',
    icon: sliderServiciosImg,
  },
  {
    title: 'Promociones Vip',
    icon: promocionesVipImg,
  }
];


// types/webPage.ts

export interface WebPageInterface {
    id: number;
    imgUrl: string;
    title: string;
    category: string;
    startDay: string;
    endDay: string;
    isActive: boolean;
  }
  

  export interface WebSliderInterface {
    id?: number;
    imgUrl: string;
    title: string;
    category: string;
    startDay: Date;
    endDay: Date;
    isActive: boolean;
    createdAt: Date;
    updatedAt: Date;
  }