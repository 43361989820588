import React, {useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './CapacitySliderStyle.css'

// import required modules
import { Navigation } from 'swiper/modules';
import { Typography } from '@mui/material';
import ProductColorBox from '../Product/ProductColorBox';

interface ColorProps{
    name:string;
    imgUrl:string;
}
interface PhoneColorsSliderProps{
    productName:string;
}

const PhoneColorsSlider:React.FC<PhoneColorsSliderProps> = ({productName}) => {
    const [currentColor,setCurrentColor]=useState(productName);
    const handleCurrentColor = (color: ColorProps) => {
        console.log('Image clicked Parent:', color);
        setCurrentColor(productName);
        // You can perform any actions here with the received imgUrl
    };


  return (
    <div>
    <Typography variant='h6'>
        Color: <b>{currentColor}</b>
      </Typography>
        <Swiper
        slidesPerView={5}
        spaceBetween={10}
        pagination={{
        clickable: true,
        }}
        modules={[Navigation]}
        className="CapacitySwiper"
    >
        
        {/*color.map((color,index)=>
            <SwiperSlide key={index} className='relative'>
                <ProductColorBox name={color.name} imgUrl={color.imgUrl} onClick={handleCurrentColor}/>
            </SwiperSlide>
        )*/}

    </Swiper>
</div>
  )
}

export default PhoneColorsSlider