import React from 'react';
import {
  TextField,
  IconButton,
  Box,
  Button,
  Typography
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

const SpecificationsInput: React.FC<{ specifications: Record<string, any>, onChange: (newSpecs: Record<string, any>) => void }> = ({ specifications, onChange }) => {
  // Convert specifications to an array of key-value pairs for easier mapping
  const specEntries = Object.entries(specifications || {});

  // Handle change in a specific key-value pair
  const handleSpecChange = (index: number, field: 'key' | 'value', value: string) => {
    const updatedSpecs = { ...specifications };
    const keys = Object.keys(updatedSpecs);
    const key = keys[index];
    
    if (field === 'key') {
      const newKey = value;
      const currentValue = updatedSpecs[key];
      delete updatedSpecs[key]; // Remove the old key
      updatedSpecs[newKey] = currentValue; // Set the new key
    } else {
      updatedSpecs[key] = value; // Update the value
    }

    onChange(updatedSpecs);
  };

  // Add a new blank specification entry
  const handleAddSpecification = () => {
    const updatedSpecs = { ...specifications, [`newKey${specEntries.length}`]: '' };
    onChange(updatedSpecs);
  };

  // Remove a specification entry
  const handleRemoveSpecification = (key: string) => {
    const updatedSpecs = { ...specifications };
    delete updatedSpecs[key];
    onChange(updatedSpecs);
  };

  return (
    <Box sx={{ mb: 2, width:'100%'}}>
      <Typography variant="body1"><strong>Especificaciones:</strong></Typography>

      {specEntries.map(([key, value], index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1, mt:2 }}>
          {/* Input for Specification Key */}
          <TextField
            label="Nombre"
            value={key}
            onChange={(e) => handleSpecChange(index, 'key', e.target.value)}
            sx={{ mr: 2 }}
          />
          
          {/* Input for Specification Value */}
          <TextField
            label="Valor"
            value={value}
            fullWidth
            onChange={(e) => handleSpecChange(index, 'value', e.target.value)}
            sx={{ mr: 2 }}
          />
          
          {/* Button to remove the specification */}
          <IconButton onClick={() => handleRemoveSpecification(key)} color="error">
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}

      {/* Button to add new specification */}
      <Button
        variant="outlined"
        startIcon={<AddCircleOutline />}
        onClick={handleAddSpecification}
        sx={{ mt: 2 }}
      >
        Añadir Especificación
      </Button>
    </Box>
  );
};

export default SpecificationsInput;

