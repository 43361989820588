import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ImageBoxSm from '../Product/ImageBoxSm';
import Skeleton from '@mui/material/Skeleton';
import useImageValidation from '../../utils/ImageValidator';

interface PhoneGalleryProps {
    productName: string;
    productImage: string;
    productImages: string[];
}

const PhoneGallery: React.FC<PhoneGalleryProps> = ({ productImages, productImage, productName }) => {
    const { imgUrlIsValid, isLoading: isMainImageLoading } = useImageValidation(productImage);
    const [currentImage, setCurrentImage] = useState(productImage);
    const [isImagesLoading, setImagesLoading] = useState(true);

    const handleCurrentImage = (imgUrl: string) => {
        setCurrentImage(imgUrl);
        console.log(productImage);
    };

    // Simulating loading for productImages
    useEffect(() => {
        if (productImages.length > 0) {
            // Simulate image loading delay
            const timer = setTimeout(() => {
                setImagesLoading(false);
            }, 1000); // Adjust this time to simulate the loading duration

            return () => clearTimeout(timer); // Cleanup timer on unmount
        } else {
            setImagesLoading(false);
        }
    }, [productImages]);

    return (
        <>
            <Box className="flex flex-col-reverse md:flex-row justify-center items-center gap-2 md:gap-10">
                <div className="slider flex flex-row md:flex-col gap-5">
                    <ImageBoxSm imgUrl={productImage} name="Iphone-2" onClick={handleCurrentImage} />
                    {isImagesLoading ? (
                        Array.from({ length: 3 }, (_, index) => (
                            <Skeleton 
                                key={index} 
                                variant="rectangular" 
                                width={100} 
                                height={100} 
                                animation="wave" 
                            />
                        ))
                    ) : (
                        productImages.map((item, index) => (
                            <ImageBoxSm key={index} imgUrl={item} name="Iphone-1" onClick={handleCurrentImage} />
                        ))
                    )}
                </div>
                <div className="flex justify-center w-full h-auto">
                    {isMainImageLoading ? (
                        <Skeleton 
                            variant="rectangular" 
                            width={500} // Adjust width to match final image width
                            height={600} // Match the height to the final image height
                            animation="wave" 
                            sx={{ borderRadius: 1 }} // Optional: add border radius to match the image
                        />
                    ) : imgUrlIsValid ? (
                        <img 
                            className="w-auto h-[600px] p-1 object-contain" 
                            src={currentImage} 
                            alt={productName} 
                        />
                    ) : null}
                </div>
            </Box>
        </>
    );
};

export default PhoneGallery;