import React, { useEffect, useState } from 'react';
// Import Swiper React components and modules
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css'; // core Swiper
import 'swiper/css/effect-fade'; // fade effect
import 'swiper/css/pagination'; // pagination module
import './PrincipalSlidertStyle.css'; // corrected file name if it was a typo
import SmallProductBox from '../Product/SmallProductBox';
import { phonesList } from '../../pages/Data/PhonesData';
import { SmartphoneInterface } from '../../constant/PhoneModel';
import { getTopRatedSmartphones } from '../../services/products';




const RecommendProductSlider: React.FC = () => {
  const [topPhoneList,setTopPhonesList] = useState<SmartphoneInterface[]>();

  useEffect(()=>{
    fetchTopRatedProducts();
  },[])


  const fetchTopRatedProducts = async () => {
    try {
      const res:any[] = await getTopRatedSmartphones(10);
      const filteredItems = res.filter((phone) => phone.price > 0);

      setTopPhonesList(filteredItems);
    } catch (error) {
      console.error('Error fetching smartphones:', error);
    }
  }


  return (
    <div style={{ height: '350px', width: 'auto' }}>
      <Swiper
        modules={[Navigation,Pagination]}
        spaceBetween={10}
        slidesPerView={2}
        pagination={{ clickable: true }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        onSlideChange={() => console.log('slide change')}
        breakpoints={{
          450: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: false,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 20,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 30,
            centeredSlides: false,
          },
        }}
      >
        {topPhoneList && topPhoneList.map((item,index)=>
        <SwiperSlide key={index} className='relative'>
            <SmallProductBox
            productId={item.id}
            productName={item.productName}
            price={item.price}
            shortDesc={item.shortDesc}
            imgUrl={item.imgUrl}
            rating={item.rating} />
        </SwiperSlide>
        
        )}
      </Swiper>
    </div>
  );
};

export default RecommendProductSlider