// src/services/planService.ts
import { publicRequest, userRequestByParams } from '../requestMethods/requestMethods';
import { PlanServiceInterface } from '../constant/ServicesInterfaces';

interface UpdatePlansServiceStatusParams {
  id: string;
  isActive: boolean;
}

export const getAllPlanServices = async () => {
  try {
    const response = await publicRequest.get('/plan-services');
    return response.data;
  } catch (error) {
    console.error('Error fetching plan services:', error);
    throw error;
  }
};

export const getAllPlanServicesByType = async (planType:string) => {
  try {
    const response = await publicRequest.get(`/plan-services/type/${planType}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching plan services:', error);
    throw error;
  }
};


export const getPlanServiceById = async (id: string) => {
  try {
    const response = await publicRequest.get(`/plan-services/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching plan service with id ${id}:`, error);
    throw error;
  }
};


export const createPlanService= async (plansService: PlanServiceInterface) => {
  try {
    const response = await publicRequest.post(`/plan-services/`,plansService);
    return response.data;
  } catch (error) {
    console.error(`Error in creation plan service with  ${plansService.planName}:`, error);
    throw error;
  }
};

export const updatePlanService = async (id: number, plansService: PlanServiceInterface) => {
  try {
    const response = await publicRequest.put(`/plan-services/${id}`, plansService);
    return response.data;
  } catch (error) {
    console.error('Error updating promotion:', error);
    throw error;
  }
};

export const updatePlanServiceStatusById = async ({ id, isActive }: UpdatePlansServiceStatusParams): Promise<any> => {
  try {
    const params = {isActive:isActive}
    const response = await userRequestByParams(params).put(`/plan-services/update-status/${id}`, {
    });

    return response.data;
  } catch (error) {
    console.error('Error updating web page status:', error);
    throw error; // Re-throw the error if you want to handle it in the calling function
  }
};

// Delete a slider
export const deletePlanService= async (id:number) => {
  try {
    const response = await publicRequest.delete(`/plan-services/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting promotion:', error);
    throw error;
  }
};