import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SmartphoneDetailsInterface } from '../../constant/PhoneModel';
import { Grid, Rating } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import StorageIcon from '@mui/icons-material/Storage';
import WifiIcon from '@mui/icons-material/Wifi';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';


interface SpecificationProps {
  productName: string;
  phoneDetails: SmartphoneDetailsInterface;
}

const ProductSpecification: React.FC<SpecificationProps> = ({
  productName,
  phoneDetails
}) => {

  return (
    <Box className="p-6 bg-gray-100 rounded-lg shadow-md">
      <Typography variant="h4" className="text-blue-500 py-5">{productName}</Typography>
      <Typography variant="h6" sx={{fontWeight:'bold'}} className="text-gray-700 font-bold">Descripción:</Typography>
              <Grid container spacing={2} className="mt-4">
          {/* Sistema Operativo */}
          <Grid item xs={12} sm={6} className="flex items-center">
            <PhoneAndroidIcon className="mr-2" />
            <Typography variant="body2">Sistema Operativo: {phoneDetails.opSystem}</Typography>
          </Grid>

          {/* Tamaño de Pantalla */}
          <Grid item xs={12} sm={6} className="flex items-center">
            <ScreenSearchDesktopIcon className="mr-2" />
            <Typography variant="body2">Pantalla: {phoneDetails.screenSize}"</Typography>
          </Grid>

          {/* Capacidad */}
          <Grid item xs={12} sm={6} className="flex items-center">
            <StorageIcon className="mr-2" />
            <Typography variant="body2">Capacidad: {phoneDetails.capacity}GB</Typography>
          </Grid>

          {/* Cámara */}
          <Grid item xs={12} sm={6} className="flex items-center">
            <CameraAltIcon className="mr-2" />
            <Typography variant="body2">Cámara: {phoneDetails.camera}</Typography>
          </Grid>

          {/* Color */}
          <Grid item xs={12} sm={6} className="flex items-center">
            <ColorLensIcon className="mr-2" />
            <Typography variant="body2">Color: {phoneDetails.color}</Typography>
          </Grid>

          {/* Memoria RAM */}
          <Grid item xs={12} sm={6} className="flex items-center">
            <MemoryIcon className="mr-2" />
            <Typography variant="body2">RAM: {phoneDetails.memoryRam}</Typography>
          </Grid>

          {/* Conectividad (si está disponible) */}
          {phoneDetails.connectivity && (
            <Grid item xs={12} sm={6} className="flex items-center">
              <WifiIcon className="mr-2" />
              <Typography variant="body2">Conectividad: {phoneDetails.connectivity}</Typography>
            </Grid>
          )}

          {/* Descripción */}
          <Grid item xs={12}>
            <Typography variant="body2" className="mt-4">
              {phoneDetails.description}
            </Typography>
          </Grid>
        </Grid>
        <ul className="list-disc list-inside mb-4">
          { phoneDetails.features  && Object.keys(phoneDetails.features).length > 0  &&
            (<Typography variant="h6" sx={{fontWeight:'bold'}} className="text-gray-700 font-bold">Características:</Typography>)
            }
      {phoneDetails.features  && Object.keys(phoneDetails.features).length > 0 ? (
          <ul>
            {Object.entries(phoneDetails.features).map(([key, value]) => (
              <li key={key}>
                {`${key}: `}
                {typeof value === 'object' && value !== null ? (
                  JSON.stringify(value)
                ) : (
                  value?.toString()
                )}
              </li>
            ))}
          </ul>
        ) : (
          null
        )
      }
      </ul>
      
    </Box>
  );
};

export default ProductSpecification;
