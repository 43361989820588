import React from 'react';
import { Box, Typography } from '@mui/material';

interface CapacityBoxProps {
    capacity:number;
    onClick: (capacity: number) => void;
}

const CapacityBox:React.FC<CapacityBoxProps> = ({capacity,onClick}) => {
    const handleImageClick = () => {
        onClick(capacity);
    };


  return (
    <Box
    className="flex justify-center items-center p-0.5 cursor-pointer border-2 border-slate-500"
    sx={{
      width: 70,
      height: 40,
      borderRadius: 2,
      bgcolor: '#FFFFFF',
      transition: 'background-color 0.3s ease', // Smooth transition for background-color change
      '&:hover': {
          bgcolor: '#E0E0E0', 
      },
    }}
    onClick={handleImageClick}
    >
      <Typography variant='body2' fontWeight={'bold'}>
        {capacity/1048 >= 1 ? `${(capacity/1048).toFixed(0)} TB`:`${capacity} GB`}
      </Typography>
    </Box>
  )
}

export default CapacityBox