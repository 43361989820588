import {Avatar, Button, Box, Fab, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import PrincipalSlider from '../components/Sliders/PrincipalSlider';
import ServiceIconNavBar from '../components/NavBar/ServiceIconNavBar';
import PromotionPrincipal from '../components/Gallery/PromotionPrincipal';
import RecommendProductSlider from '../components/Sliders/RecommendProductSlider';
import WhatsAppButton from '../components/Buttons/WhatsAppButton';
import ServicePromCardSlider from '../components/Sliders/ServicePromCardSlider';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { handleWhatsAppClick } from '../utils/WhatsAppUtils';
import CompanyLogosGrid from '../components/Grid/CompaniesLogoGrid';

const FrontPage = () => {

  useEffect(() => {
    AOS.init({
      once:true,
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div>
      <div className='slider'>
        <PrincipalSlider/>
      </div>
      <ServiceIconNavBar/>
      <section className='my-16'>
      <div className="flex flex-wrap gap-5 md:gap-0 justify-around mx-auto max-w-[1200px] my-16">
          <Box  data-aos="fade-right" className='w-full md:w-1/2 flex flex-col h-auto justify-center items-center md:justify-start gap-5'>
          <Typography variant='h3' component='h1' className='w-10/12 md:w-3/4'>
              Evolucionando tu Empresa
            </Typography>
            <Typography className='w-10/12 md:w-3/4 text-justify'>
              <b>Quatro Evolución</b> es una empresa familiar que se ha fortalecido con el 
              paso de los años, derivado de diversos cambios a través de la historia, 
              estamos en constante evolución, acumulando fortalezas y siempre alineados
               a las políticas que nos marca nuestro socio comercial <b>Radiomovil Dipsa S.A. de C.V.</b>,
                contando con un equipo robusto y capacitado para dar cumplimiento a las metas y objetivos que se fijen.
            </Typography>
            <Button className='w-fit' sx={{borderRadius:'30px', padding:'10px 30px'}} color='success' variant="contained" onClick={handleWhatsAppClick}>Contactanos</Button>
          </Box>
          <Box  data-aos="fade-left" className='flex justify-center w-full md:w-1/2'>
            <div className="image-container w-10/12 md:w-full max-w-[600px]">
              <img 
                className='object-cover w-full h-full' 
                src={require('../assets/images/graphics/Group-1.webp')} 
                alt="Quatro Evolución - Soluciones para Empresas"  
                loading="lazy"/>
            </div>
          </Box>
        </div>
        <div className="flex flex-wrap-reverse justify-around mx-auto max-w-[1200px] my-16">
          <Box className="flex justify-center md:justify-left items-center w-full md:w-1/2">
            <Card sx={{ width:{xs:300, md:450} , height:{xs:340, md:680} }}>
              <CardMedia
                sx={{ height: '100%' }}
                image={'../../assets/images/web/4-evolucion-5.webp'}
                title="Promotion"
              />
            </Card>
          </Box>
          <Box className='flex flex-col h-auto justify-start px-2 md:px-10 gap-5 w-full md:w-1/2 my-5'> 
            <Typography variant='h3' component='h2' sx={{fontSize:{xs:'2rem', md:'3rem'}}} className='text-center md:text-left w-full'>
              La mejor opción de telefonia para empresas
            </Typography>
            <Box data-aos="zoom-in" className='flex flex-col gap-5  justify-center items-center grow'>
              <Box className='flex flex-col md:flex-row  justify-center items-center md:items-start gap-10 p-5'>
                <Avatar sx={{ width: 56, height: 56, bgcolor:'#0061AD' }} >
                  <Typography variant='h5'>1</Typography>
                </Avatar>
                <Typography className='text-left'>
                   Soluciones flexibles que aseguran una comunicación constante y eficiente. <b className='text-lg'> Adaptadas a las necesidades de cada empresa.</b>
                </Typography>
              </Box>
              <Card  data-aos="zoom-in" className='flex flex-col md:flex-row  justify-center items-center md:items-start p-5 gap-10 shadow-blueShadow' sx={{boxShadow:'10px 7px 119px 4px rgba(0,97,173,0.45)', borderRadius:'20px'}}>
              <Avatar sx={{ width: 56, height: 56, bgcolor:'#0061AD' }} >
                  <Typography variant='h5'>2</Typography>
                </Avatar>
                <Typography className='text-left'>
                  <b className='text-lg'>Tecnología avanzada que permite administrar y controlar </b> todos los servicios de comunicación de manera rápida y transparente.
                </Typography>
              </Card>
              <Box  data-aos="zoom-in" className='flex flex-col md:flex-row  justify-center items-center md:items-start gap-10 p-5'>
              <Avatar sx={{ width: 56, height: 56, bgcolor:'#0061AD' }} >
                  <Typography variant='h5'>3</Typography>
                </Avatar>
                <Typography className='text-left'>
                  Servicios diseñados para mejorar la eficiencia operativa. <b className='text-lg'>Garantizando una conectividad segura y confiable</b> para las empresas.
                </Typography>
              </Box>
              <Card  data-aos="zoom-in" className='flex flex-col md:flex-row  justify-center items-center md:items-start p-5 gap-10 shadow-blueShadow' sx={{boxShadow:'10px 7px 119px 4px rgba(0,97,173,0.45)', borderRadius:'20px'}}>
              <Avatar sx={{ width: 56, height: 56, bgcolor:'#0061AD' }} >
                  <Typography variant='h5'>4</Typography>
                </Avatar>
                <Typography className='text-left'>
                   Ofrecemos <b className='text-lg'>soluciones en localización vehicular, gestión de personal, seguridad y conectividad </b> para optimizar operaciones y aumentar la eficiencia de su empresa.
                </Typography>
              </Card>
            </Box>
          </Box>
        </div>
    
      </section>
      <section className='flex flex-col justify-center p-0 md:p-20 text-center'>
      <Typography variant='h3' component='h4' sx={{fontSize:{xs:'2rem', md:'3rem'}}}  className='p-2 md:p-10'>
              Apoyamos a diferentes industrias
        </Typography>
        <CompanyLogosGrid/>
      </section>
      <section>
      <Box className='pt-10'>
        <ServicePromCardSlider/>
      </Box>
      <div className="mx-auto max-w-[1200px] my-16">
        <Typography variant='h4' className='text-center md:text-left'>
          Productos Recomendados
        </Typography>
        <RecommendProductSlider/>
      </div>
      </section>
    </div>
  )
}

export default FrontPage