// src/store/imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImageFile {
  key: string;     // Unique key for each image
  file: File;      // The image file itself
}

interface ImageState {
  imageFiles: ImageFile[];  // Array to store image files with keys
}

const initialState: ImageState = {
  imageFiles: [],
};

const imageSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    addImage: (state, action: PayloadAction<ImageFile>) => {
      state.imageFiles.push(action.payload);  // Add new image with key
    },
    removeImage: (state, action: PayloadAction<string>) => {
      state.imageFiles = state.imageFiles.filter(image => image.key !== action.payload);  // Remove by key
    },
  },
});

export const { addImage, removeImage } = imageSlice.actions;
export default imageSlice.reducer;