import {Box, Container, Grid } from '@mui/material'
import VerticalNavBar from '../../components/Admin/NavBar/VerticalNavBar'
import CompanyUserTable from '../../components/Admin/Tables/CompanyUsersTable'
import MobileAdminNavBar from '../../components/Admin/NavBar/MobileAdminNavBar'
import { DashboardCardType1 } from '../../components/Admin/Cards/DashboardCards/DashboardCardType1'
import { useState } from 'react'
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';

const CompanyUserAdminPage = () => {
  const [totalUsers, setTotalUsers] = useState<number | null>(null);
  const [activeUsers, setActiveUsers] = useState<number | null>(null);
  const [UsersLastMonth, setUsersLastMonth] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);


    // Update these states via child component actions
    const updateDashboardData = (total: number, active: number, lastMonth: number) => {
      setTotalUsers(total);
      setActiveUsers(active);
      setUsersLastMonth(lastMonth);
      setLoading(false); // Assume data is fetched and loading is finished
    };

  return (<>
    <section className='relative flex flex-col md:flex-row'>
      <div className="hidden md:block">
              <VerticalNavBar />
            </div>
            <div className="block md:hidden">
              <MobileAdminNavBar />
            </div>
    <Container>
    <Grid container spacing={3} className="p-6">
      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Total de Usuarios"
          value={totalUsers}
          description="El número total de empresas registradas."
          icon={<GroupsIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Usuarios Activas"
          value={activeUsers}
          description="Número de usuarios de Empresas."
          icon={<BusinessIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <DashboardCardType1
          title="Usuarios Agregadas"
          value={UsersLastMonth}
          description="Número de Usuarios agregadas el mes."
          icon={<EventIcon sx={{ fontSize: 40 }} />}
          loading={loading}
        />
      </Grid>
    </Grid>
        <CompanyUserTable updateDashboardData={updateDashboardData}/>
    </Container>
</section>
  </>
  )
}

export default CompanyUserAdminPage