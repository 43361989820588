// src/redux/userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserState, UserLoginInterface } from '../constant/UserInterfaces';

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserLoginInterface>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
