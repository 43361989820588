import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton, Typography, SelectChangeEvent, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getProductImagesUrl } from '../../../../../services/gmsService';
import {isValidImageUrl} from '../../../../../utils/ImageValidator';
import CheckboxTile from '../../../Cards/CheckboxTile';
import CheckboxProduct from './CheckBoxProduct';

interface ImagesInputProps {
    productName: string;
    detail?: string;
    onChange: (checked: boolean, imgSrc: string) => void; 
}

const ImagesInput: React.FC<ImagesInputProps> = ({ productName, onChange }) => {
    const [imageList, setImageList] = useState<string[]>([]);
    const [validImageList, setValidImageList] = useState<string[]>([]);
    const [imageValidation,setImageValidation] = useState(true);
    const [loading, setLoading] = useState<boolean>(false); // Loading state, initially false
    const [fetched, setFetched] = useState<boolean>(false); // Whether the images have been fetched or not
    const [selectedImages, setSelectedImages] = useState<string[]>([]); // State to track selected images

  // Handle image fetching when button is clicked
const fetchImagesUrl = async () => {
    if (!productName) {
        console.warn('Product name is required to fetch images.');
        return; // Exit if productName is not provided
    }

    setLoading(true); // Start loading
    setFetched(false); // Ensure fetching status is reset if retrying
    try {
        const res = await getProductImagesUrl(productName);

        if (res && res.data) {
            console.log('Fetched Images:', res.data);
            setImageList(res.data); // Set the images array from response
        } else {
            console.warn('No images returned from the server.');
            setImageList([]); // Set empty array if no data is returned
        }

        setFetched(true); // Mark as fetched
    } catch (error: any) {
        console.error('Error fetching product images:', error.message || error);

        if (error.response) {
            console.error('Server responded with:', error.response.data);
        } else {
            console.error('Unexpected error during image fetch.');
        }
    } finally {
        setLoading(false); // Stop loading after fetch
    }
};

const validateImages = async (imageList: string[]): Promise<string[]> => {
    const validImages: string[] = [];
    setImageValidation(true);
    console.log("valiudationImages",imageList);

    try {
        // Validate all image URLs concurrently using Promise.all
        const validationResults = await Promise.all(
            imageList.map(async (imageUrl) => {
                const isValid = await isValidImageUrl(imageUrl); // Use utility function for validation
                console.log("is Valid:",imageUrl);
                console.log("is Valid:",isValid);
                return isValid ? imageUrl : null; // Return URL if valid, otherwise null
            })
        );

        // Filter out null values (invalid URLs)
        return validationResults.filter((url): url is string => url !== null);
    } catch (error) {
        console.error('Error validating images:', error);
        return validImages; // Return an empty array if validation fails
    } finally{
        setImageValidation(false);
    }
};

useEffect(() => {
    if (imageList.length > 0) {
        const validateAndSetImages = async () => {
            const validImages = await validateImages(imageList);
            setValidImageList(validImages); // Set the list of valid images
        };

        validateAndSetImages(); // Trigger validation on image list
    }
}, [imageList]);

const handleImageSelect = (event:boolean, imgSrc: string) => {
    const checked  = event; // Destructure checked from the event target

    setSelectedImages((prevSelected) => {
        const selectedSet = new Set(prevSelected); // Use a Set for performance optimization

        // Add or remove imgSrc based on the checked state
        checked ? selectedSet.add(imgSrc) : selectedSet.delete(imgSrc);

        return Array.from(selectedSet); // Convert Set back to array for state update
    });

    // Optionally check if imgSrc is valid before calling onChange
    if (imgSrc) {
        onChange(checked, imgSrc); // Pass both checked state and imgSrc to the parent
    } else {
        console.warn("Invalid image source provided:", imgSrc);
    }
};

    

    return (
        <Box className='flex flex-col gap-5'>
            {/* Fetch images button */}
            {!fetched && (
                <Button variant="contained" color="primary" disabled={productName ==''} onClick={fetchImagesUrl}>
                    Buscar Imagenes
                </Button>
            )}

            {/* Display loading skeleton while fetching images */}
            {loading ? (
                <Box display="flex" flexWrap="wrap" gap={2}>
                    {[...Array(3)].map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={100} height={100} />
                    ))}
                </Box>
            ) : (
                fetched && (
                    <Box className="flex flex-wrap gap-5 justify-center my-5">
                    {!imageValidation && validImageList.length > 0 && 
                        validImageList.map((option, index) => (
                            <CheckboxProduct
                            key={index}
                            imgSrc={option}
                            isChecked={selectedImages.includes(option)} // Check if this image is selected
                            onChange={(event) => handleImageSelect(event, option)}  // Pass the event and the imgSrc
                        />
                        ))}
                    </Box>
                )
            )}

            {/* Select dropdown for choosing an image */}
                    {/* Category Selection */}
        
        </Box>
    );
};

export default ImagesInput;