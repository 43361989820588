import { publicRequest } from "../requestMethods/requestMethods";
import { Company } from "../constant/CompanyInterface";


export const getAllCompanies = async () => {
    try {
      const response = await publicRequest.get('/company');
      return response.data;
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error;
    }
};

export const getAllCompaniesCount = async () => {
  try {
    const response = await publicRequest.get('/company/count');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};

export const createCompany = async (company:Company) => {
  try {
    const response = await publicRequest.post('/company/',company);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};

export const updateCompanyById = async (id: string, company: Company) => {
  try {
    const response = await publicRequest.put(`/company/${id}`, company);
    return response.data;
  } catch (error) {
    console.error('Error updating promotion:', error);
    throw error;
  }
};

export const getCompanyById = async (id:string) =>{
  try {
    const response = await publicRequest.get(`/company/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
}

export const deleteCompany = async (companyId:string) => {
  try {
    const response = await publicRequest.delete(`/company/${companyId}`);;
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};