import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ProductDescriptionProps {
  description: string;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ description }) => {
  return (
    <Box className="p-6 bg-gray-100 rounded-lg shadow-md">
      <Typography variant="h6" className="text-gray-700 mb-4">
        Descripción de Producto
      </Typography>
      <Typography className="text-gray-600">{description}</Typography>
    </Box>
  );
};

export default ProductDescription;
