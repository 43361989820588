import React from 'react';
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

interface PlanTypeInputProps {
  planType: string;
  onChange: (newPlanType: string) => void;
}

const PlanTypeInput: React.FC<PlanTypeInputProps> = ({ planType, onChange }) => {
  const handlePlanTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newPlanType: string | null
  ) => {
    if (newPlanType !== null) {
      onChange(newPlanType);
    }
  };

  return (
    <div className='flex justify-center items-center'>
      <Typography variant="body1" width={150}>
        <strong>Tipo de plan:</strong>
      </Typography>
      <FormControl fullWidth sx={{ mt: 1 }}>
        <ToggleButtonGroup
          value={planType}
          exclusive
          onChange={handlePlanTypeChange}
          aria-label="tipo de plan"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <ToggleButton
            value="Personal"
            aria-label="personal plan"
            selected={planType === "Personal"}
            sx={{
              color: planType === "Personal" ? 'white' : 'inherit',
              backgroundColor: planType === "Personal" ? '#0061AD' : 'transparent',
              '&.Mui-selected': {
                color: 'white',
                backgroundColor: '#0061AD',
              },
              '&:hover': {
                backgroundColor: planType === "Personal" ? '#0061AD' : 'lightgray',
              },
            }}
          >
            <PersonIcon sx={{ mr: 1 }} />
            Personal
          </ToggleButton>
          <ToggleButton
            value="Empresarial"
            aria-label="business plan"
            selected={planType === "Empresarial"}
            sx={{
              color: planType === "Empresarial" ? 'white' : 'inherit',
              backgroundColor: planType === "Empresarial" ? '#0061AD' : 'transparent',
              '&.Mui-selected': {
                color: 'white',
                backgroundColor: '#0061AD',
              },
              '&:hover': {
                backgroundColor: planType === "Empresarial" ? '#0061AD' : 'lightgray',
              },
            }}
          >
            <BusinessCenterIcon sx={{ mr: 1 }} />
            Negocios
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </div>
  );
};

export default PlanTypeInput;