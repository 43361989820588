import { publicRequest } from "../requestMethods/requestMethods";

interface AdminUserInterface {
  name: string;
  lastname: string;
  email: string;
  password: string; 
}
// Define the request payload type
interface LoginPayload {
  email: string;
  password: string;
}

// Define the response type based on what the API returns
interface AdminUserResponse {
  message: string;
  user: {
    id: number;
    name: string;
    lastName: string;
    email: string;
  };
}


export const getAllAdminUsers = async () => {
  try {
    const response = await publicRequest.get('/admin-user');
    return response.data;
  } catch (error:any) {
      console.error('Unexpected error:', error);
      throw error;
  }
};


// Define the function with proper type annotations
export const accessAdminUser = async (accessInfo: LoginPayload): Promise<AdminUserResponse> => {
  try {
    const response = await publicRequest.post('/admin-user/login',accessInfo );
    return response.data;
  } catch (error:any) {
      console.error('Unexpected error:', error);
      throw error;
  }
};



export const createAdminUser = async (user:AdminUserInterface) =>{
  try {
    console.log(user);
    const response = await publicRequest.post('/admin-user/register',user);
    return response.data;
  } catch (error) {
    console.error('Error en la creación de usuario:', error);
    throw error;
  }
}


export const updateAdminUser = async (id:number, user:AdminUserInterface) =>{
  try {
    const response = await publicRequest.put(`/admin-user/${id}`,user);
    return response.data;
  } catch (error) {
    console.error('Error en la creación de usuario:', error);
    throw error;
  }

}

export const deleteAdminUser = async (id:number) => {
  try {
    const response = await publicRequest.delete(`/admin-user/${id}`);;
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
  }
};