import React, { useEffect, useState } from 'react';
import ProductBox from '../components/Product/ProductBox';
import {brandList } from './Data/PhonesData';
import SearchBar from '../components/SearchBars/SearchBar';
import { Box, CircularProgress, Container, Drawer, IconButton, Pagination, useMediaQuery } from '@mui/material';
import FiltersDrawer from '../components/NavBar/FiltersDrawer';
import BrandBox from '../components/Product/BrandBox';
import FilterPill from '../components/Pills/FilterPills';
import { convertToMB } from '../utils/ConvertApp';
import { UseDispatch, useDispatch } from 'react-redux';
import { resetShopCart } from '../redux/shopCartRedux';
import { clearUser } from '../redux/userInfoDetails';
import { getAllSmarthphones } from '../services/products';
import { SmartphoneInterface } from '../constant/PhoneModel';
import { phonesList } from './Data/PhonesData';
import FilterListIcon from '@mui/icons-material/FilterList';


interface Filters {
  priceRange: [number, number];
  selectedBrands: string[];
  selectedCapacities: number[];
  selectedRatings: number[];
}
  

const ProductsPage = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedBrand, setSelectedBrand] = useState<string>('');
    const [phonesList,setPhonesList] =useState<SmartphoneInterface[]>([])
    const [filteredPhones,setFilteredPhones] =useState<SmartphoneInterface[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<any>({
        priceRange: [0, 60000],
        selectedBrands: [],
        selectedCapacities: [],
        selectedRatings: [],
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const phonesPerPage = 16; // Customize the number of phones per page
    const [mobileOpen, setMobileOpen] = useState(false);

    // Media query to check if the screen size is mobile
    const isMobile = useMediaQuery('(max-width: 600px)');

    // Toggle drawer visibility
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const dispatch = useDispatch();

    useEffect(()=>{
        getAllSmartphonesInfo();
    },[])

      
    useEffect(() => {
        const res = filterPhones(phonesList, searchQuery, filters);
        setFilteredPhones(res);
      }, [phonesList, searchQuery, filters]);

      const handleBrandClick = (brandName: string) => {
        setFilters((prevFilters: Filters) => {
          // Check if the brand is already selected
          const isBrandSelected = prevFilters.selectedBrands.includes(brandName);
      
          // Add or remove the brand from the filter
          return {
            ...prevFilters,
            selectedBrands: isBrandSelected
              ? prevFilters.selectedBrands.filter((brand) => brand !== brandName)
              : [...prevFilters.selectedBrands, brandName],
          };
        });
      };

    const getAllSmartphonesInfo = async () => {
        setLoading(true);
        try {
          const items:any[] = await getAllSmarthphones();
          // Remove duplicate phones based on name
        const uniquePhones = items.reduce((acc: any[], phone: any) => {
            if (!acc.some((item) => item.productName === phone.productName)) {
                acc.push(phone);
            }
            return acc;
        }, []);

        // Filter phones with price greater than 0
        const filteredItems = uniquePhones.filter((phone) => phone.price > 0);
          setPhonesList(filteredItems);
          setFilteredPhones(items);
        } catch (error) {
          console.error('Error fetching smartphones:', error);
        } finally{
            setLoading(false);
        }
      };

    const handleRemoveFilter = (filterType: string, filterValue: string | number) => {
        setFilters((prevFilters: any) => ({
          ...prevFilters,
          [filterType]: prevFilters[filterType].filter((value: string | number) => value !== filterValue),
        }));
      };
      

    const handleFilterChange = (newFilters: any) => {
        setFilters(newFilters);
    };

    const filterPhones = (
        phones: SmartphoneInterface[],
        searchQuery: string,
        filters: Filters
      ): SmartphoneInterface[] => {
        const query = searchQuery.trim().toLowerCase();
        const { selectedBrands, selectedCapacities, selectedRatings, priceRange } = filters;
        const phoneList = [];

        for (const phone of phones) {
            const { productName, brand, price, rating, capacity } = phone;
        
            const criteriaResults = [];

            const matchesSearchQuery = !query || productName.toLowerCase().includes(query);
            criteriaResults.push(matchesSearchQuery);
        

            // Brand Match: Ensure brand filtering is applied
            const matchesBrand = selectedBrands.length === 0 || selectedBrands.includes(brand);
            criteriaResults.push(matchesBrand);
            // Rating Match
            const matchesRating = selectedRatings.length === 0 || selectedRatings.includes(Math.round(rating));
            criteriaResults.push(matchesRating);

            let matchesPriceRange = false;
            if(price){
                matchesPriceRange = price >= priceRange[0] && price <= priceRange[1];
                criteriaResults.push(matchesPriceRange);
            }else{
                matchesPriceRange = true;
                criteriaResults.push(matchesPriceRange)
            }

            let matchesCapacity = false;
            if(capacity){
                matchesCapacity = selectedCapacities.length === 0 || selectedCapacities.includes(capacity*1024);
                criteriaResults.push(matchesCapacity);
            }else{
                matchesCapacity = false;
                criteriaResults.push(matchesCapacity)
            }
        
        
            // Add the phone to the list if it matches the brand filter
            if (criteriaResults.every(Boolean)) {
                phoneList.push(phone);
            }
        }
        
        return phoneList;
      };

      const indexOfLastPhone = currentPage * phonesPerPage;
      const indexOfFirstPhone = indexOfLastPhone - phonesPerPage;
      const currentPhones = filteredPhones.slice(indexOfFirstPhone, indexOfLastPhone);
    

      const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
      };

      
     
    
    return (
        <Container  style={{ maxWidth: '1600px', marginTop:'5rem'}}>
            <Box sx={{ display: 'flex' }}>
                            {/* Drawer for mobile view */}
            {/* Static filters for larger screens */}
            {!isMobile && (
                <div className="hidden sm:block sticky top-28 w-fit h-fit py-5">
                    <FiltersDrawer filters={filters} onFilterChange={handleFilterChange} />
                </div>
            )}
                <Box className='px-5 w-full'>
                    <div className='mb-5 flex flex-wrap gap-5 justify-around'>
                    {brandList.map((brand) => (
                        <BrandBox
                            key={brand.brandName}
                            brandName={brand.brandName}
                            quantity={phonesList.filter((phone) => phone.brand === brand.brandName).length}
                            imgUrl={brand.imgUrl}
                            onClick={() => handleBrandClick(brand.brandName)}
                        />
                        ))}
                        
                    </div>
                    <div className='w-full'>
                        <SearchBar setSearchQuery={setSearchQuery} />
                    </div>
                    {isMobile && (
                <>
                    <Box className='flex justify-end'>
                        <IconButton onClick={handleDrawerToggle}>
                            <FilterListIcon />
                        </IconButton>
                    </Box>
                    <Drawer
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Improves performance by keeping the drawer in the DOM
                        }}
                        PaperProps={{
                            sx: { 
                                maxHeight: '100%', // Full height of the screen
                                overflow: 'auto', // Allow scrolling
                            },
                        }}
                        className='!overflow-auto'
                    >
                        <FiltersDrawer filters={filters} onFilterChange={handleFilterChange} />
                    </Drawer>
                </>
            )}
                    <div className='my-5'>
                    {filters.selectedBrands.map((brand: string) => (
                        <FilterPill
                            key={brand}
                            label={`Marca: ${brand}`}
                            onRemove={() => handleRemoveFilter('selectedBrands', brand)}
                        />
                    ))}
                    {filters.selectedCapacities.map((capacity: number) => (
                        <FilterPill
                            key={capacity}
                            label={`Capacidad: ${convertToMB(capacity)}`}
                            onRemove={() => handleRemoveFilter('selectedCapacities', capacity)}
                        />
                    ))}
                    {filters.selectedRatings.map((rating: number) => (
                        <FilterPill
                            key={rating}
                            label={`Rating: ${rating} estrellas`}
                            onRemove={() => handleRemoveFilter('selectedRatings', rating)}
                        />
                    ))}
                    {(filters.priceRange[0] > 0 || filters.priceRange[1] < 60000) && (
                        <FilterPill
                            label={`Precio: ${filters.priceRange[0]} - ${filters.priceRange[1]}`}
                            onRemove={() => setFilters((prevFilters: any) => ({
                            ...prevFilters,
                            priceRange: [0, 60000],
                            }))}
                        />
                    )}
                    </div>
                    <div className='flex justify-around flex-wrap gap-5 my-5 mx-auto'>
                    {loading ? (
                        <CircularProgress />
                        ) : (
                        currentPhones.map((phone, index) => (
                            <ProductBox
                            key={index}
                            id={phone.id}
                            productName={phone.productName}
                            price={phone.price ? phone.price : 10000}
                            shortDesc={phone.shortDesc}
                            imgUrl={phone.imgUrl}
                            rating={phone.rating}
                            />
                        ))
                        )}
                    </div>
                    <Box m={4} display="flex" justifyContent="center">
                        <Pagination
                        count={Math.ceil(filteredPhones.length / phonesPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default ProductsPage;
