import React from 'react'
import LoginAdminForm from '../../components/Forms/Admin/LoginAdminForm'
import { Box } from '@mui/material'

const AdminLogin = () => {
  return (
    <Box 
        sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh', 
            overflow: 'hidden', 
            position: 'relative'
        }}
    >
        <Box 
            sx={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%',  
                background: `url('https://img.freepik.com/free-vector/abstract-blue-polygon-technology-background_1035-17380.jpg?w=900&t=st=1717573310~exp=1717573910~hmac=72cbae20da7705bdbaf9eeb4920fd47bc624b604f37504b50e5f8a7cc750f169') no-repeat center center`, 
                backgroundSize: 'cover',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <Box 
                sx={{ 
                    width: '100%', 
                    maxWidth: '400px', 
                    padding: '20px', 
                    bgcolor: 'rgba(255, 255, 255, 0.5)', // Background color with opacity
                    backdropFilter: 'blur(10px)', // Glass morphism effect
                    border: '2px solid rgba(255, 255, 255, 0.1)', // Border color with opacity
                    borderRadius: '10px', // Border radius
                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.64)', // Shadow
                }}
            >
                <LoginAdminForm/>
            </Box>
        </Box>
</Box>
  )
}

export default AdminLogin