import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import LoginForm from '../components/Forms/LoginForm';
import RegisterForm from '../components/Forms/RegisterForm';


const LoginRegister: React.FC = () => {
    const [showRegister, setShowRegister] = useState(false);

    const handleToggleForm = () => {
        setShowRegister(!showRegister);
    };

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                overflow: 'hidden', 
                position: 'relative'
            }}
        >
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: showRegister ? '-100%' : 0, 
                    width: '100%', 
                    height: '100%', 
                    transition: 'left 0.5s ease-in-out', 
                    background: `url('https://img.freepik.com/free-vector/abstract-blue-polygon-technology-background_1035-17380.jpg?w=900&t=st=1717573310~exp=1717573910~hmac=72cbae20da7705bdbaf9eeb4920fd47bc624b604f37504b50e5f8a7cc750f169') no-repeat center center`, 
                    backgroundSize: 'cover'
                }}
            />
            <Box 
                sx={{ 
                    position: 'absolute', 
                    top: 0, 
                    right: showRegister ? 0 : '-100%', 
                    width: '100%', 
                    height: '100%', 
                    transition: 'right 0.5s ease-in-out', 
                    background: `url('https://t4.ftcdn.net/jpg/03/37/97/17/360_F_337971775_faSzhdqpZl5IKBTDJiC1E4HsWEZCjhdE.jpg') no-repeat center center`, 
                    backgroundSize: 'cover'
                }}
            />
            <Box 
                sx={{ 
                    width: '100%', 
                    maxWidth: '400px', 
                    padding: '20px', 
                    position: 'absolute', 
                    left: showRegister ? '-100%' : '50%', 
                    transition: 'left 0.5s ease-in-out',
                    transform: 'translateX(-50%)',
                    bgcolor: 'rgba(255, 255, 255, 0.8)', // Background color with opacity
                    backdropFilter: 'blur(10px)', // Glass morphism effect
                    border: '2px solid rgba(255, 255, 255, 0.1)', // Border color with opacity
                    borderRadius: '10px', // Border radius
                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)', // Shadow
                }}
            >
                <LoginForm handleToggleForm={handleToggleForm} />
            </Box>
            <Box 
                sx={{ 
                    width: '100%', 
                    maxWidth: '400px', 
                    padding: '20px', 
                    position: 'absolute', 
                    right: showRegister ? '50%' : '-100%', 
                    transition: 'right 0.5s ease-in-out',
                    transform: 'translateX(50%)',
                    bgcolor: 'rgba(255, 255, 255, 0.8)', // Background color with opacity
                    backdropFilter: 'blur(10px)', // Glass morphism effect
                    border: '2px solid rgba(255, 255, 255, 0.1)', // Border color with opacity
                    borderRadius: '10px', // Border radius
                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)', // Shadow
                }}
            >
                <RegisterForm handleToggleForm={handleToggleForm} />
            </Box>
        </Box>
    );
};

export default LoginRegister;
