import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, useField } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, CircularProgress, Typography, Box, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { updatePasswordWithToken } from '../services/companyUsers';

interface ResetPasswordPageFormValues {
  password: string;
  confirmPassword: string;
}

const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password visibility

  // Extract the token from the URL
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('token');

  // Redirect if no token is present
  if (!resetToken) {
    navigate('/error'); // Replace with your actual error handling route
  }

  // Form validation schema
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'La contraseña debe tener al menos 8 caracteres')
      .required('La contraseña es obligatoria'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir')
      .required('Confirma tu contraseña')
  });

  // Handle password reset form submission
  const handleSubmit = async (values: ResetPasswordPageFormValues) => {
    setLoading(true);
    setResponseMessage(null);

    try {
      const response = await updatePasswordWithToken(values.password, resetToken!); // API call to reset password
      setResponseMessage(response.message || 'Contraseña cambiada exitosamente.');
      setTimeout(() => {
        navigate('/login'); // Redirect to login after success
      }, 2000);
    } catch (error: any) {
      const message = error.response?.data?.message || 'Error al cambiar la contraseña. Inténtalo de nuevo.';
      setResponseMessage(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        background: `url('https://img.freepik.com/free-vector/abstract-blue-polygon-technology-background_1035-17380.jpg?w=900&t=st=1717573310~exp=1717573910~hmac=72cbae20da7705bdbaf9eeb4920fd47bc624b604f37504b50e5f8a7cc750f169') no-repeat center center`,
        backgroundSize: 'cover',
      }}
      className="flex justify-center items-center h-screen"
    >
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <Typography variant="h5" className="text-center mb-4">
          Cambiar Contraseña
        </Typography>

        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field name="password">
                {({ field }: any) => (
                  <TextField
                    {...field}
                    label="Nueva Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    margin="normal"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className="mb-4"
                  />
                )}
              </Field>

              <Field name="confirmPassword">
                {({ field }: any) => (
                  <TextField
                    {...field}
                    label="Confirma la Contraseña"
                    type={showConfirmPassword ? 'text' : 'password'}
                    fullWidth
                    margin="normal"
                    error={touched.confirmPassword && !!errors.confirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className="mb-4"
                  />
                )}
              </Field>

              {responseMessage && (
                <Typography
                  variant="body2"
                  color={responseMessage.includes('error') ? 'error' : 'success'}
                  className="text-center my-2"
                >
                  {responseMessage}
                </Typography>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting || loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
                className="mt-4"
              >
                {loading ? 'Cambiando...' : 'Cambiar Contraseña'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Box>
  );
};

export default ResetPasswordPage;