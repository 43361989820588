import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ProductCheckoutBox from './ProductCheckoutBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatPrice } from '../../utils/ConvertApp';
import { SmartphoneInterface } from '../../constant/PhoneModel';
import { getAllSmarthphones } from '../../services/products';

const CheckOutSideBar = () => {
  const cartItems = useSelector((state: RootState) => state.shopCart.items);
  const [checkoutList, setCheckoutList] = useState<SmartphoneInterface[]>([]);
  const [phonesList, setPhonesList] = useState<SmartphoneInterface[]>([]);
  const [activeCupon, setActiveCupon] = useState<boolean>(false);
  const [subtotal, setSubtotal] = useState<number>(0);
  const shippingType = useSelector((state: RootState) => state.shopCart.shipping);

  const calculateSubtotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  useEffect(() => {
    const fetchSmartphones = async () => {
      try {
        const items = await getAllSmarthphones();
        setPhonesList(items);
      } catch (error) {
        console.error('Error fetching smartphones:', error);
      }
    };

    fetchSmartphones();
  }, []);

  useEffect(() => {
    const cartItemIds = new Set(cartItems.map(item => item.id));
    const filterPhones = phonesList.filter(phone => cartItemIds.has(phone.id));
    setCheckoutList(filterPhones);
  }, [cartItems, phonesList]);

  useEffect(() => {
    setSubtotal(calculateSubtotal());
  }, [cartItems]);

  return (
    <Box className='w-1/3 min-w-[300px] h-[90vh] flex flex-col p-5 bg-csecondary-100'>
      <Typography variant='h6'>
        Carrito de Compras
      </Typography>
      <Divider />
      {checkoutList.map((item, index) => {
        const cartItem = cartItems.find(cartItem => cartItem.id === item.id);
        const quantity = cartItem ? cartItem.quantity : 0;
        return (
          <ProductCheckoutBox
            key={index}
            productId={item.id}
            productName={item.productName}
            imgUrl={item.imgUrl}
            quantity={quantity}
            price={item.price}
          />
        );
      })}
      <Divider />
      <Box className='my-5'>
        <Box className='flex justify-between'>
          <Typography variant='body1' className='text-csecondary-200'>
            Tienes un Cupon
          </Typography>
          {activeCupon ? (
            <ExpandLessIcon onClick={() => setActiveCupon(false)} className='text-csecondary-200 cursor-pointer' />
          ) : (
            <ExpandMoreIcon onClick={() => setActiveCupon(true)} className='text-csecondary-200 cursor-pointer' />
          )}
        </Box>
        <Box className={`flex justify-between overflow-hidden ${activeCupon ? 'h-fit pt-5' : 'h-[0px]'} ease-in-out`}>
          <TextField
            className={`${activeCupon ? 'h-fit' : 'h-[0px]'}`}
            id="outlined-basic"
            label="Cupon"
            variant="outlined"
          />
          <Button>Aplicar</Button>
        </Box>
      </Box>
      <Divider />
      <Box className='my-5'>
        <Box className="flex justify-between">
          <Typography variant='body1' className='text-csecondary-200 font-[Avenir]'>Subtotal</Typography>
          <Typography variant='body1' fontWeight='bold' className='text-gray-500 font-[Avenir]'>${formatPrice(subtotal)}</Typography>
        </Box>
        <Box className="flex justify-between">
          <Typography variant='body1' className='text-csecondary-200 font-[Avenir]'>Envio</Typography>
          <Typography variant='body1' fontWeight='bold' className='text-gray-500 font-[Avenir]'>{shippingType ? '$150' : 'Gratis'}</Typography>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default CheckOutSideBar;