import { Box, Container, Typography, Skeleton, Card, CardMedia } from '@mui/material';
import { Link, useMatch } from "react-router-dom";
import { useEffect, useState } from 'react';
import PhoneGallery from '../components/Gallery/PhoneGallery';
import CapacitySlider from '../components/Sliders/CapacitySlider';
import PhoneColorsSlider from '../components/Sliders/PhoneColorsSlider';
import QuantityInput from '../components/Buttons/QuantityButton';
import ButtonWIcon from '../components/Buttons/ButtonWIcon';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Button from '@mui/material/Button';
import ProductDetailsTabs from '../components/Tabs/ProductDeatilsTab';
import ProductSpecification from '../components/Product/ProductSpecification';
import ProductDescription from '../components/Product/ProductDescription';
import AdditionalInformation from '../components/Product/AdditionalInformation';
import RecommendProductSlider from '../components/Sliders/RecommendProductSlider';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { addItem } from '../redux/shopCartRedux';
import { useDispatch } from 'react-redux';
import { getSmarthphonesById, getSmarthphoneDeatilById, getSmarthphonePriceById, getSmarthphonesByName } from '../services/products';
import { SmartphoneInterface, SmartphoneDetailsInterface, SmartphonePriceInterface } from '../constant/PhoneModel';
import { useSpring, animated } from '@react-spring/web';
import ProductRelatedBox from '../components/Product/ProductRelatedBox';
import ProductDetailsSkeleton from '../components/Product/Skeletons/ProductDetailsSkeleton';


const ProductDetailsPage = () => {
  const [smartphone, setSmartphone] = useState<SmartphoneInterface>();
  const [smartphoneRelated, setSmartphoneRelated] = useState<SmartphoneInterface[]>();
  const [currentPrice, setCurrentPrice] = useState(smartphone?.price);
  const [phoneDetails, setPhoneDetails] = useState<SmartphoneDetailsInterface>();
  const [pricesDetails, setPricesDetails] = useState<SmartphonePriceInterface[]>();
  const [capacities, setCapacities] = useState<number[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const match = useMatch("/:firstRoute/:secondRoute/*");
  

  useEffect(() => {

    if (match) {
      const { secondRoute } = match.params;
      fetchSmartphonesInfo(secondRoute);
    }
  }, [match?.params.secondRoute]);

  const fetchSmartphonesInfo = async (secondRoute: string | undefined) => {
    if (secondRoute) {
      try {
        const res = await getAllSmartphonesInfo(secondRoute);
      } catch (error) {
        console.error("Error fetching smartphone info:", error);
      }
    } else {
      console.error("No secondRoute provided.");
    }
  };



  const handleAddToCart = () => {
    if (smartphone && currentPrice) {
      dispatch(addItem({
        id: smartphone.id,
        name: smartphone.productName,
        price: currentPrice,
        quantity: 1,
        imgUrl: smartphone.imgUrl
      }));
    }
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  const getAllSmartphonesInfo = async (phoneId: string): Promise<void> => {
    try {
      setLoading(true);
      
      // Fetch smartphone info by ID
      const smartphone = await getSmarthphonesById(phoneId);
      if (!smartphone) {
        throw new Error(`Smartphone with ID ${phoneId} not found`);
      }
  
      // Fetch smartphone details
      const phoneDetails = await getSmarthphoneDeatilById(phoneId);
      await getAllSmarthphonesByName(smartphone.productName);
      
      // Fetch price details separately
      await getSmarthphonePriceDetails(phoneId);
      
      // Update state with fetched data
      setSmartphone(smartphone);
      setPhoneDetails(phoneDetails);
      
    } catch (error) {
      console.error('Error fetching smartphone info:', error);
    } finally {
      setLoading(false);
    }
  };


  const getSmarthphonePriceDetails = async (phoneId: string) => {
    try {
      setLoading(true);
      const smartphonePrices: SmartphonePriceInterface[] = await getSmarthphonePriceById(phoneId);
      setPricesDetails(smartphonePrices);
      setCapacities(Array.from(new Set(smartphonePrices.map(smartphone => smartphone.capacity))));
    } catch (error) {
      console.error('Error fetching smartphone prices:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAllSmarthphonesByName = async (productName:string) =>{
    try {
      const res:any = await getSmarthphonesByName(productName);
      console.log(res);
      setSmartphoneRelated(res);
    } catch (error) {
      console.error('Error fetching smartphone prices:', error);
    }
  }

  if (loading) {
    return (
      <Box className='flex flex-wrap py-5'>
        <Box className='w-full flex flex-wrap gap-5 justify-center'>
          <Box className="slider flex flex-row md:flex-col gap-5">
              <Skeleton variant="rectangular" width={100} height={100} />
              <Skeleton variant="rectangular" width={100} height={100} />
              <Skeleton variant="rectangular" width={100} height={100} />
          </Box>
          <Skeleton variant="rectangular" width={500} height={600} />
          <ProductDetailsSkeleton/>
        </Box>
        <Box className='w-full px-5 flex flex-col'>
          <Skeleton variant="text" width="80%" height={50} />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
      </Box>
    );
  }

  if (!smartphone) {
    return <div>No phone data available</div>;
  }

  return (
    <Container>
      <Box>
        <Link to={"/products"}>
          <Button className='cursor-pointer'>
            <KeyboardBackspaceIcon />
            Regresar
          </Button>
        </Link>
      </Box>
      <Box className='flex flex-col md:flex-row gap-5 md:gap-0'>
        <Box className='w-full md:w-1/2'>
          {phoneDetails && smartphone && (
            <PhoneGallery 
              productImages={phoneDetails.productImages} 
              productName={smartphone.productName} 
              productImage={smartphone.imgUrl}
            />
          )}
        </Box>
        <Box className='w-full md:w-1/2 px-5 py-0 flex flex-col'>
          <Typography variant='h4'>{smartphone.productName}</Typography>
          <Box className='flex flex-col justify-start gap-5 my-5'>
            {capacities && <CapacitySlider capacites={capacities} />}
            {phoneDetails && phoneDetails.color && <PhoneColorsSlider productName={phoneDetails.color} />}
            {smartphoneRelated && <ProductRelatedBox 
            smartphoneRelated={smartphoneRelated}
            onPhoneSelect={getAllSmartphonesInfo}
            />}
            <Box className='flex flex-col my-0 md:my-5 gap-5'>
            <Box className='flex flex-col justify-start gap-5'>
              {pricesDetails && pricesDetails.length > 1 ? (
                <Box className='flex flex-col justify-start gap-5'>
                  <Box className='flex flex-col justify-start gap-5'>
                    <Typography variant='h6' fontWeight={'bold'} className='text-cblue-600'>Desde con Plan</Typography>
                    <Box className='flex flex-row justify-start gap-5'>
                      <Typography variant='h6' fontWeight={'bold'} className='text-cblue-400'>
                        ${pricesDetails[1]?.price.toLocaleString('en', { minimumFractionDigits: 2 })}
                      </Typography>
                      <Button className='hidden md:block' color='success' variant="contained" sx={{ borderRadius: '40px' }} onClick={handleWhatsAppClick}>
                        Contratar Plan +
                      </Button>
                    </Box>
                  </Box>
                  <Box className='flex flex-col justify-start gap-5'>
                    <Typography variant='h6' fontWeight={'bold'} className='text-cblue-600'>Precio Final</Typography>
                    <Typography variant='h6' fontWeight={'normal'} className='text-gray-800'>
                      ${pricesDetails[0]?.price.toLocaleString('en', { minimumFractionDigits: 2 })}
                    </Typography>
                    </Box>
                </Box>
              ) : (
                /* If pricesDetails has only one price, check the contractMode */
                <Box className='flex flex-col justify-start gap-5'>
                    <Typography variant='h6' fontWeight={'bold'} className='text-gray-800'>

                    {pricesDetails && pricesDetails[0]?.contractMode=='TARIFARIOS' ? (
                      <Typography variant='h6'>Precio con Plan</Typography>
                    ) : (
                      <Typography variant='h6'>Precio con Amigo Kit</Typography>
                    )}
                  </Typography>
                  <Box className='flex flex-row justify-start gap-5'>
                    <Typography variant='h6' fontWeight={'bold'} className='text-cblue-400'>
                      {pricesDetails ? (
                        `$${pricesDetails[0]?.price.toLocaleString('en', { minimumFractionDigits: 2 })}`
                      ) : (
                        `Contract price not available`
                      )}
                    </Typography>
                    <Button className='hidden md:block' color='success' variant="contained" sx={{ borderRadius: '40px' }} onClick={handleWhatsAppClick}>
                      Contratar Plan +
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
            <Typography variant='h6'>Cantidad</Typography>
            <QuantityInput />
            <Button color='primary' variant="contained" sx={{ borderRadius: '40px' }} onClick={handleWhatsAppClick}>Contratación</Button>
           {/* <Box className='flex flex-wrap gap-5 justify-start'>
              <ButtonWIcon onClick={handleAddToCart} className="grow h-12 !rounded-none" buttonText="Agregar" icon={<AddShoppingCartIcon />} color="secondary" />
              <ButtonWIcon className="grow h-12 !rounded-none !m-0" buttonText="Comprar" icon={<LocalMallIcon />} color="primary" />
            </Box>*/}
          </Box>
        </Box>
      </Box>
      <Box>
        {phoneDetails && smartphone && (
          <ProductDetailsTabs
            descriptionContent={<ProductDescription description={smartphone.shortDesc} />} 
            specificationsContent={<ProductSpecification phoneDetails={phoneDetails} productName={smartphone.productName} />} 
            additionalInfoContent={<AdditionalInformation />}
          />
        )}
      </Box>
      <Box className='text-center'>
            <Typography variant="body2" component="h5" color="textSecondary" gutterBottom>
              *Los precioa estan sujetos a cambios sin previo aviso.
            </Typography>
            </Box>
      <section>
        <div className="mx-auto my-16">
          <Typography variant='h4'>Productos Recomendados</Typography>
          <RecommendProductSlider />
        </div>
      </section>
    </Container>
  );
}

export default ProductDetailsPage;
