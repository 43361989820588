export const phonesList = [
  {
    id: '1',
    productName: "Apple iPhone X",
    brand: "Apple",
    price: 15000,
    shortDesc: "Producto Exclusivo - 4 Evolucion",
    imgUrl: "https://m.media-amazon.com/images/I/81ebvO7EZxL._AC_SL1500_.jpg",
    capacities: [128000],
    productImages:[
      "https://m.media-amazon.com/images/I/31-dbiAq-iL._AC_SL1000_.jpg",
      "https://m.media-amazon.com/images/I/61QKfpe4m9L._AC_SL1500_.jpg",
      "https://m.media-amazon.com/images/I/71aHD9IjrpL._AC_SL1500_.jpg",
      "https://m.media-amazon.com/images/I/51k8c06WvdL._AC_SL1000_.jpg",
    ],
    rating: 4.4
  },
  {
    id: '2',
    productName: "Samsung Galaxy S20",
    brand: "Samsung",
    price: 12000,
    shortDesc: "Super AMOLED Display - 5G Ready",
    imgUrl: "https://m.media-amazon.com/images/I/51mEpAA7KfL._AC_SL1000_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.6
  },
  {
    id: '3',
    productName: "Google Pixel 5",
    brand: "Google",
    price: 10000,
    shortDesc: "Pure Android Experience - Outstanding Camera",
    imgUrl: "https://m.media-amazon.com/images/I/61ruKkvVIxL._AC_SL1000_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.5
  },
  {
    id: '4',
    productName: "OnePlus 9 Pro",
    brand: "OnePlus",
    price: 13000,
    shortDesc: "Fluid AMOLED Display - Hasselblad Camera",
    imgUrl: "https://m.media-amazon.com/images/I/6144kchBW3L._AC_SL1020_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.7
  },
  {
    id: '5',
    productName: "Xiaomi Mi 11",
    brand: "Xiaomi",
    price: 9000,
    shortDesc: "Snapdragon 888 - 108MP Camera",
    imgUrl: "https://m.media-amazon.com/images/I/61LEkX0bFmL._AC_SL1302_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.3
  },
  {
    id: '6',
    productName: "Huawei P40 Pro",
    brand: "Huawei",
    price: 11000,
    shortDesc: "Leica Quad Camera - Kirin 990",
    imgUrl: "https://m.media-amazon.com/images/I/31iIKPxq5gL._AC_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.4
  },
  {
    id: '7',
    productName: "Sony Xperia 1 II",
    brand: "Sony",
    price: 14000,
    shortDesc: "Cinematic 4K OLED Display - Zeiss Optics",
    imgUrl: "https://m.media-amazon.com/images/I/71qNvhdCtKL._AC_SL1500_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.5
  },
  {
    id: '8',
    productName: "LG V60 ThinQ",
    brand: "LG",
    price: 8000,
    shortDesc: "Dual Screen - Snapdragon 865",
    imgUrl: "https://m.media-amazon.com/images/I/61gRhL5BCiL._AC_SL1500_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.3
  },
  {
    id: '9',
    productName: "Motorola Edge+",
    brand: "Motorola",
    price: 9500,
    shortDesc: "90Hz OLED Display - 108MP Camera",
    imgUrl: "https://m.media-amazon.com/images/I/71d9+ePUrcL._AC_SL1500_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.2
  },
  {
    id: '10',
    productName: "Nokia 9 PureView",
    brand: "Nokia",
    price: 7000,
    shortDesc: "Penta Camera - Pure Android",
    imgUrl: "https://m.media-amazon.com/images/I/51XGZx2Ul6L._AC_SL1500_.jpg",
    capacities: [128000],
    productImages:[],
    rating: 4.0
  }
];


export const brandList = [
  {
    brandName: 'Apple',
    quantity: 10,
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/814px-Apple_logo_black.svg.png'
  },

  {
    brandName: 'Samsung',
    quantity: 8,
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Samsung_Logo.svg/1600px-Samsung_Logo.svg.png'
  },

  {
    brandName: 'Nokia',
    quantity: 5,
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Nokia_wordmark.svg/1280px-Nokia_wordmark.svg.png'
  },

  {
    brandName: 'ZTE',
    quantity: 7,
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/ZTE-logo.svg/320px-ZTE-logo.svg.png'
  },

  {
    brandName: 'Xiaomi',
    quantity: 6,
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Xiaomi_logo_%282021-%29.svg/512px-Xiaomi_logo_%282021-%29.svg.png'
  }

];
  