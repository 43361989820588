import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Card, Skeleton, CardMedia, Alert } from '@mui/material';
import { SmartphoneInterface } from '../../../../constant/PhoneModel';
import useImageValidation from '../../../../utils/ImageValidator';

interface DeleteProductModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: (id: string)  => void;
  product:SmartphoneInterface; // Adjust this type based on your needs
}

const DeleteProductModal: React.FC<DeleteProductModalProps> = ({ open, onClose, onDelete, product }) => {
  const { imgUrlIsValid, isLoading } = useImageValidation(product.imgUrl || '');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Estado para manejar el segundo diálogo


  const handleDeleteConfirmation = () => {
    setConfirmDialogOpen(true); // Abrir el segundo diálogo
  };

  const handleDelete = () => {
    setConfirmDialogOpen(false); // Cerrar el segundo diálogo
    if(product.id){
      onDelete(product.id);
    }  // Ejecutar la acción de eliminación pasando el ID del plan
  };

  return (
    <>
    <Dialog open={open} onClose={onClose}>
      <Box className="flex flex-col justify-center items-center gap-5 p-5">
          <Card>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={200}
                animation="wave"
                sx={{ borderRadius: '4px' }}  // Añadir estilo consistente
              />
            ) : imgUrlIsValid ? (
              <CardMedia
                component="img"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '200px',
                  display: 'block',
                  margin: 'auto',
                  objectFit: 'cover',
                }}
                image={product.imgUrl} // Usar la imagen del plan
              />
            ) : (
              <div className="flex justify-center items-center">
                <img
                  src={'../assets/icons/DashboardAdmin/folder.png'}  // Ajustar la ruta de la imagen
                  alt="Fallback Icon"
                  style={{
                    width: 'auto',
                    height: '190px',
                    maxHeight: '200px',
                    padding: '1em',
                    objectFit: 'contain',
                  }}
                />
              </div>
            )}
          </Card>
        </Box>
        <DialogTitle className="text-xl text-center font-bold">Eliminar el telfono {product.productName}</DialogTitle>
        <DialogContent className="flex flex-col gap-5">
          <Alert severity="warning">Esta acción es irreversible.</Alert>
          <Typography>
            ¿Estás seguro que deseas eliminar <b>{product.productName}</b>? 
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
    </Dialog>
     {/* Segundo diálogo para confirmar la eliminación */}
     <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
     <DialogTitle className="text-xl font-bold">Confirmar Eliminación</DialogTitle>
     <DialogContent>
       <Alert severity="error">Esta acción no se puede deshacer.</Alert>
       <Typography>
         ¿Estás absolutamente seguro de que deseas eliminar permanentemente <b>{product.productName}</b>?
       </Typography>
     </DialogContent>
     <DialogActions>
       <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
       <Button onClick={handleDelete} color="error" variant="contained">
         Confirmar
       </Button>
     </DialogActions>
   </Dialog>
   </>
  );
};

export default DeleteProductModal;