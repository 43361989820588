import React, { useState, useEffect } from 'react';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Skeleton,
  DialogTitle,
  DialogActions,
  Alert,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Title, Category, CalendarToday, CheckCircle, Cancel } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { uploadImage } from '../../../../services/gcpService';

// Interface for Promotion (from your provided interface)
interface Promotion {
  id: number;
  imgUrl: string;
  title: string;
  description: string;
  shortDesc?: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
  isExclusive: boolean;
}

// Props for the Modal Component
interface PromotionDetailsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (promotion: Promotion) => void;
  promotion: Promotion;
}

const PromotionDetailsModal: React.FC<PromotionDetailsModalProps> = ({
  open,
  onClose,
  onSave,
  promotion,
}) => {
  // State management
  const [editMode, setEditMode] = useState(false);
  const [updatedPromotion, setUpdatedPromotion] = useState<Promotion>(promotion);
  const [imageFile, setImageFile] = useState<File | null>(null);

  // Handle input changes for text fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedPromotion((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle date changes
  const handleDateChange = (name: keyof Promotion, newValue: Dayjs | null) => {
    setUpdatedPromotion((prevState) => ({
      ...prevState,
      [name]: newValue ? newValue.toISOString() : prevState[name],
    }));
  };

  // Handle image upload
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdatedPromotion((prevState) => ({ ...prevState, imgUrl: reader.result as string }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Toggle the active state
  const handleActiveToggle = () => {
    setUpdatedPromotion((prevState) => ({ ...prevState, isActive: !prevState.isActive }));
  };

    // Toggle the active state
    const handleExlusiveToggle = () => {
      setUpdatedPromotion((prevState) => ({ ...prevState, isExclusive: !prevState.isExclusive }));
    };

  // Save the promotion with the new data
  const handleSave = async () => {
    try {
      let imgUrl = '';

      if (imageFile) {
        imgUrl = await uploadImage(imageFile, 'Promotions'); // Adjust folder path as needed
      }

      const updatedPromotionData = {
        ...updatedPromotion,
        imgUrl: imgUrl || updatedPromotion.imgUrl,
      };

      onSave(updatedPromotionData);
      setEditMode(false);
    } catch (error) {
      console.error('Error al guardar la promoción:', error);
    }
  };

  const handleEditToggle = () => {
    setEditMode(true);
  };

  // Sync the modal state with the passed promotion prop
  useEffect(() => {
    if (promotion) {
      setUpdatedPromotion(promotion);
    }
  }, [promotion]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Card>
            {updatedPromotion.imgUrl ? (
              <CardMedia
                component="img"
                sx={{ width: 'auto', height: 200, margin: 'auto', objectFit: 'contain' }}
                image={updatedPromotion.imgUrl}
                title={updatedPromotion.title}
              />
            ) : (
              <Skeleton variant="rectangular" width="60%" height={200} animation="wave" />
            )}
          </Card>

          {/* Image Upload Input */}
          {editMode && (
            <Box className='relative'>
              <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="upload-image"
                />
                <label htmlFor="upload-image">
                  <IconButton component="span">
                    <EditIcon />
                  </IconButton>
                </label>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            {editMode ? (
              <>
                <TextField
                  label="Título"
                  name="title"
                  fullWidth
                  value={updatedPromotion.title}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Descripción"
                  name="description"
                  fullWidth
                  multiline
                  rows={4}
                  value={updatedPromotion.description}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DatePicker
                      label="Fecha de Inicio"
                      value={dayjs(updatedPromotion.startDay)}
                      onChange={(newValue) => handleDateChange('startDay', newValue)}
                      sx={{ mb: 2 }}
                    />
                    <DatePicker
                      label="Fecha de Finalización"
                      value={dayjs(updatedPromotion.endDay)}
                      onChange={(newValue) => handleDateChange('endDay', newValue)}
                      sx={{ mb: 2 }}
                    />
                  </Box>
                </LocalizationProvider>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleActiveToggle}
                      checked={updatedPromotion.isActive}
                      color="success"
                    />
                  }
                  label="Promoción Activa"
                />
                 <FormControlLabel
                  control={
                    <Switch
                    onChange={handleExlusiveToggle}
                    checked={updatedPromotion.isExclusive}
                    color="success"
                  />
                  }
                  label="Promoción Exclusiva"
                />
              </>
            ) : (
              <Box className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
                <Typography variant="h6">
                  <Title className="mr-2" /> {updatedPromotion.title}
                </Typography>
                <Typography>
                  <strong>Descripción:</strong> {updatedPromotion.description}
                </Typography>
                <Typography>
                  <CalendarToday className="mr-2" />
                  <strong>Fecha de Inicio:</strong> {new Date(updatedPromotion.startDay).toLocaleDateString()}
                </Typography>
                <Typography>
                  <CalendarToday className="mr-2" />
                  <strong>Fecha de Finalización:</strong> {new Date(updatedPromotion.endDay).toLocaleDateString()}
                </Typography>
                <Typography>
                  {updatedPromotion.isActive ? (
                    <CheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <Cancel className="text-red-500 mr-2" />
                  )}
                  <strong>Promoción Activa:</strong> {updatedPromotion.isActive ? 'Sí' : 'No'}
                </Typography>
                <Typography>
                  {updatedPromotion.isExclusive ? (
                    <CheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <Cancel className="text-red-500 mr-2" />
                  )}
                  <strong>Es Exclusiva:</strong> {updatedPromotion.isActive ? 'Sí' : 'No'}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={onClose} color="error" variant="contained">
              Cerrar
            </Button>
            {editMode ? (
              <Button onClick={handleSave} color="primary" variant="contained">
                Guardar
              </Button>
            ) : (
              <Button onClick={handleEditToggle} color="primary" variant="contained">
                Editar
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PromotionDetailsModal;