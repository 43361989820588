import React from "react";
import { styled } from "@mui/system";

interface IconProps {
  color?: string;
}

const StyledSvg = styled('svg')({
  width: 26,
  height: 25,
  viewBox: '0 0 26 25',
});

const Icon: React.FC<IconProps> = ({ color = "#000" }) => {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" transform="translate(-129 -218)">
          <g transform="translate(20 52)">
            <g transform="translate(0 138)">
              <g transform="translate(0 28)">
                <g transform="translate(109.832)">
                  <path d="M12.5 0C5.597 0 0 5.181 0 11.573c0 3.643 1.817 6.891 4.658 9.013V25l4.256-2.336c1.136.314 2.34.484 3.586.484 6.903 0 12.5-5.18 12.5-11.573C25 5.183 19.403 0 12.5 0zm1.242 15.586L10.56 12.19l-6.21 3.395 6.832-7.253 3.261 3.395 6.133-3.395-6.833 7.253z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
}

export default Icon;
