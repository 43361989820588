import React from 'react';
import { Card, CardContent, Typography, Link, Divider } from '@mui/material';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Card sx={{ maxWidth: 800, margin: '20px auto', padding: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Aviso de Privacidad - Quatro Evolución, S.A. de C.V.
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Typography variant="body1" paragraph>
          Quatro Evolución, S.A. de C.V., con domicilio en Guadalupe Victoria 916 Local B2, colonia Barrio Coaxustenco, Metepec, Estado de México, CP 52140, es responsable del tratamiento de los datos personales que nos proporcione, los cuales serán protegidos conforme a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de Particulares, y demás normatividad que resulte aplicable.
        </Typography>

        <Typography variant="body1" paragraph>
          Esta Declaración de Privacidad rige la información personal que recopilamos de los clientes a través de los diversos medios de contacto tanto físico como digital, con el fin de llevar a cabo las relaciones comerciales; atención y prestación de servicios o productos relacionados con los objetivos de esta empresa; así como la venta de servicios o productos que en su caso requiera.
        </Typography>

        <Typography variant="body1" paragraph>
          La información personal que se recaba puede incluir: nombre completo, domicilio fiscal y/o de entrega, número de teléfono, dirección de correo electrónico, fecha de nacimiento, Registro Federal de Contribuyentes, identificación oficial, firma, número de tarjeta de crédito y datos de facturación.
        </Typography>

        <Typography variant="body1" paragraph>
          Quatro Evolución tratará sus datos personales para llevar a cabo, en su caso, la finalidad secundaria consistente en: contactarle y enviarle información de carácter publicitario, promocional y/o informativo que consideramos pueda resultar de su interés.
        </Typography>

        <Typography variant="body1" paragraph>
          Usted podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus Datos Personales (Derechos ARCO), de conformidad con la legislación aplicable, mediante el envío de su “Solicitud de Ejercicio de los Derechos ARCO y Revocación del Consentimiento para el Tratamiento de Datos Personales”. La solicitud del formato, así como la gestión correspondiente deberá ser canalizada al correo electrónico:&nbsp;
          <Link href="mailto:masivos@4evolucion.com">masivos@4evolucion.com</Link>.
        </Typography>

        <Typography variant="body1" paragraph>
          Quatro Evolución, S.A. de C.V. podrá transferir sus datos personales e información en términos de lo establecido en los artículos 36 y 37, de la Ley Federal de Protección de Datos Personales en Posesión de Particulares.
        </Typography>

        <Typography variant="body1" paragraph>
          En cualquier momento Quatro Evolución, S. A. de C.V. podrá modificar y/o actualizar este Aviso de Privacidad, garantizándole que la versión actualizada siempre se encontrará disponible en nuestra Tienda y a través de la página&nbsp;
          <Link href="https://www.4evolucion.com.mx" target="_blank" rel="noopener">
            www.4evolucion.com.mx
          </Link>.
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
          Fecha de última modificación: Octubre 2, 2023.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PrivacyPolicyPage;