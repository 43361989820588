import React, { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  IconButton,
  Tooltip,
  TextField,
  Skeleton,
} from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import StarRateIcon from '@mui/icons-material/StarRate';
import { SmartphoneDetailsInterface } from '../../../../constant/PhoneModel';
import { isValidImageUrl } from '../../../../utils/ImageValidator';
import CheckboxProduct from './ImagesInput/CheckBoxProduct';
import ImageUploadGrid from './ImagesInput/ImageUploadGrid';
import { useDispatch } from 'react-redux';
import { addImage, removeImage } from '../../../../redux/imageSlice';
import { v4 as uuidv4 } from 'uuid';
import { isDeepEqual } from '@mui/x-data-grid/internals';

interface SmartphoneDetailsBoxProps {
  details: SmartphoneDetailsInterface;
  productName:string;
  isEditMode: boolean; // Prop for edit mode
  onDetailsChange: (updatedDetails: SmartphoneDetailsInterface) => void; // Prop to handle changes
}

const SmartphoneDetailsBox: React.FC<SmartphoneDetailsBoxProps> = ({
  details,
  isEditMode,
  productName,
  onDetailsChange,
}) => {
  const [localDetails, setLocalDetails] = useState(details);
  const [validImageList, setValidImageList] = useState<string[]>([]);
  const [validEditImageList, setValidEditImageList] = useState<string[]>([]);
  const [imageValidation,setImageValidation] = useState(true);
  const [loading, setLoading] = useState<boolean>(false); // Loading state, initially false
  const [fetched, setFetched] = useState<boolean>(false); // Whether the images have been fetched or not
  const [selectedImages, setSelectedImages] = useState<string[]>([]); // State to track selected images
  const dispatch = useDispatch();

  // Update local state if the parent details change or edit mode becomes active
  useEffect(()=>{
    let res = validateImages(details.productImages);
  },[details]);

  useEffect(() => {
    if (isEditMode) {
      setLocalDetails(details);
      setValidEditImageList(validImageList);
    }
  }, [isEditMode, details]);

  
  // Handle changes for text fields
  const handleChange = (field: keyof SmartphoneDetailsInterface, value: string | number) => {
    const updatedDetails = { ...localDetails, [field]: value };
    setLocalDetails(updatedDetails);
    onDetailsChange(updatedDetails); // Notify parent about the change
  };

  const handleImageSelectEdit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
  
    if (files) {
      const imageKey = uuidv4();  // Generate a unique key for the set of images
  
      // Use Promise.all to process all files asynchronously
      const newImageList = await Promise.all(
        Array.from(files).map((file) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const imageUrl = reader.result as string;
              resolve(imageUrl);  // Resolve the promise with the image URL
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
  
      // Dispatch each image to Redux after all images are loaded
      newImageList.forEach((imageUrl, index) => {
        dispatch(addImage({ key: `${imageKey}-${index}`, file: files[index] }));
      });
  
      // Update the state in a single call after all images are loaded
      setValidEditImageList((prevList) => [...prevList, ...newImageList]);
    }
  };

  const validateImages = async (imageList: string[]): Promise<string[]> => {
    const validImages: string[] = [];
    setImageValidation(true);

    try {
        // Validate all image URLs concurrently using Promise.all
        const validationResults = await Promise.all(
            imageList.map(async (imageUrl) => {
                const isValid = await isValidImageUrl(imageUrl); // Use utility function for validation
                return isValid ? imageUrl : null; // Return URL if valid, otherwise null
            })
        );
        const res = validationResults.filter((url): url is string => url !== null);

        setValidImageList(res);
        // Filter out null values (invalid URLs)
        return validationResults.filter((url): url is string => url !== null);
    } catch (error) {
        console.error('Error validating images:', error);
        return validImages; // Return an empty array if validation fails
    } finally{
        setImageValidation(false);
        setLoading(false);
    }
};

const handleImageSelect = (isChecked: boolean, imgSrc: string) => {
    setSelectedImages((prevSelected) => {
      if (isChecked) {
        return [...prevSelected, imgSrc]; // Add image if checked
      } else {
        return prevSelected.filter((img) => img !== imgSrc); // Remove image if unchecked
      }
    });
  };


  return (
    <Card className="w-full shadow-lg mt-4">
      <CardContent>
        <Typography variant="h5" fontWeight={'bold'} className="font-semibold mb-4 text-left py-5">
          Detalles de Teléfono {isEditMode && '(Editando)'}
        </Typography>

        <Grid container spacing={2}>
          {/* Capacidad */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Capacidad">
                <IconButton>
                  <MemoryIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Capacidad (GB)"
                  type="number"
                  value={localDetails.capacity}
                  onChange={(e) => handleChange('capacity', parseInt(e.target.value, 10))}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Capacidad: <strong>{localDetails.capacity} GB</strong>
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Tamaño de Pantalla */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Tamaño de Pantalla">
                <IconButton>
                  <ScreenSearchDesktopIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Tamaño de Pantalla (pulgadas)"
                  type="number"
                  value={localDetails.screenSize}
                  onChange={(e) => handleChange('screenSize', parseFloat(e.target.value))}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Tamaño de Pantalla: <strong>{localDetails.screenSize}"</strong>
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Cámara */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Cámara">
                <IconButton>
                  <CameraAltIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Cámara"
                  value={localDetails.camera}
                  onChange={(e) => handleChange('camera', e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Cámara: <strong>{localDetails.camera}</strong>
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Color */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Color">
                <IconButton>
                  <ColorLensIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Color"
                  value={localDetails.color}
                  onChange={(e) => handleChange('color', e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Color: <strong>{localDetails.color}</strong>
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Conectividad */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Conectividad">
                <IconButton>
                  <SignalCellularAltIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Conectividad"
                  value={localDetails.connectivity}
                  onChange={(e) => handleChange('connectivity', e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Conectividad: <strong>{localDetails.connectivity || 'N/A'}</strong>
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Memoria RAM */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Memoria RAM">
                <IconButton>
                  <MemoryIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Memoria RAM"
                  value={localDetails.memoryRam}
                  onChange={(e) => handleChange('memoryRam', e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Memoria RAM: <strong>{localDetails.memoryRam}</strong>
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Calificación */}
          <Grid item xs={6}>
            <Box className="flex items-center">
              <Tooltip title="Calificación">
                <IconButton>
                  <StarRateIcon />
                </IconButton>
              </Tooltip>
              {isEditMode ? (
                <TextField
                  label="Calificación"
                  type="number"
                  value={localDetails.rating}
                  onChange={(e) => handleChange('rating', parseFloat(e.target.value))}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">
                  Calificación: <strong>{localDetails.rating || 'N/A'}</strong>
                </Typography>
              )}
            </Box>
          </Grid>
          {!isEditMode && localDetails.productImages && (
           <Grid item xs={12}>
               {loading ? (
                <Box display="flex" flexWrap="wrap" gap={2}>
                    {[...Array(3)].map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={100} height={100} />
                    ))}
                </Box>
            ) : (
                localDetails.productImages && (
                    <Box className="flex flex-wrap gap-5 justify-center my-5">
                    {!imageValidation && validImageList.length > 0 && 
                        validImageList.map((option, index) => (
                            <CheckboxProduct
                            key={index}
                            imgSrc={option}
                            isChecked={false} // Check if this image is selected
                            onChange={()=> console.log('')}  // Pass the event and the imgSrc
                        />
                        ))}
                    </Box>
                )
            )}
          </Grid>
        )}    

           {isEditMode && (
            <ImageUploadGrid
                validImageList={validEditImageList}
                setValidImageList={setValidEditImageList}
                handleImageSelectEdit={handleImageSelectEdit}
            />
        )}      
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SmartphoneDetailsBox;