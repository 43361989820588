import React, { useState } from 'react';
import {Box, Button, TextField, Checkbox, FormControlLabel, Typography, IconButton, InputAdornment, Divider, CircularProgress } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import * as Yup from 'yup';
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import TermsAndConditionModal from '../Modal/TermsAndConditionModal';
import { createCompanyUser } from '../../services/companyUsers';

interface RegisterFormProps {
    handleToggleForm: () => void;
}

interface FormValues {
    name: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    promoCode: string | null;
    terms: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ handleToggleForm }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [activeCupon,setActiveCupon] = useState<boolean>(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);    

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };

      const handleSubmit = async (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        setLoading(true); // Set loading state to true
        setResponseMessage(null); // Clear previous response messages

        try {
            // Replace with actual API call
            const res = await createCompanyUser(values);
            // On success
            setResponseMessage(res.message);
            setSuccess(true);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setResponseMessage(error.response.data.message);
            } else {
                setResponseMessage('Ocurrio un error por favor intenta de nuevo');
            }
        } finally {
            setLoading(false); // Set loading state to false
            setSubmitting(false); // Set submitting state to false
        }
    };

    
    const validationSchema = Yup.object({
        name: Yup.string().required('El nombre es obligatorio'),
        lastName: Yup.string().required('El apellido es obligatorio'),
        username: Yup.string().required('El usuario es obligatorio'),
        email: Yup.string()
            .email('Debe ingresar un correo electrónico válido')
            .required('El correo electrónico es obligatorio'),
        password: Yup.string().required('La contraseña es obligatoria').min(6, 'La contraseña debe tener al menos 6 caracteres'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'Las contraseñas deben coincidir')
            .required('La confirmación de la contraseña es obligatoria'),
        promoCode: Yup.string(),
        terms: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones')
    });

    return (<>

        <TermsAndConditionModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={''}
        img=''
        />
        <Formik
            initialValues={{
                name: '',
                lastName: '',
                username:'',
                email:'',
                password: '',
                confirmPassword: '',
                promoCode: '',
                terms: false
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({setFieldValue, resetForm, errors, touched }) => (
                <Form>
                     {!success ? (
                        <>
                    <Typography variant="h5" mb={2}>Registro</Typography>
                    <Field
                        as={TextField}
                        name="name"
                        label="Nombre"
                        fullWidth
                        margin="normal"
                        error={touched.name && !!errors.name}
                        helperText={<ErrorMessage name="name" />}
                    />
                    <Field
                        as={TextField}
                        name="lastName"
                        label="Apellido"
                        fullWidth
                        margin="normal"
                        error={touched.lastName && !!errors.lastName}
                        helperText={<ErrorMessage name="lastName" />}
                    />
                    <Field
                        as={TextField}
                        name="username"
                        label="Nombre de Usario"
                        fullWidth
                        margin="normal"
                        error={touched.username && !!errors.username}
                        helperText={<ErrorMessage name="username" />}
                    />
                    <Field
                        as={TextField}
                        name="email"
                        label="Correo"
                        fullWidth
                        margin="normal"
                        error={touched.email && !!errors.email}
                        helperText={<ErrorMessage name="email" />}
                    />
                    <Field
                        as={TextField}
                        name="password"
                        label="Contraseña"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        error={touched.password && !!errors.password}
                        helperText={<ErrorMessage name="password" />}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        as={TextField}
                        name="confirmPassword"
                        label="Confirmar Contraseña"
                        type={showConfirmPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        error={touched.confirmPassword && !!errors.confirmPassword}
                        helperText={<ErrorMessage name="confirmPassword" />}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Divider/>
                     <Box className='flex justify-between mt-5'>
                        <Typography variant='body1' className='text-csecondary-800'>
                            Tienes Codigo de Empresarial
                        </Typography>
                        {activeCupon ?  <ExpandLessIcon onClick={() => {
                            setActiveCupon(false);
                            setFieldValue('promoCode', ''); // Set promoCode to null when activeCupon is false
                        }} className='text-csecondary-200 cursor-pointer'/>: <ExpandMoreIcon onClick={()=> setActiveCupon(true)} className='text-csecondary-200 cursor-pointer'/>}
                    </Box>
                    <Box className={`flex justify-between overflow-hidden ${activeCupon ? 'h-fit':'h-[0px]'} ease-in-out`}>
                        <Field
                            as={TextField}
                            name="promoCode"
                            label="Código Promocional"
                            fullWidth
                            margin="normal"
                            error={touched.promoCode && !!errors.promoCode}
                            helperText={<ErrorMessage name="promoCode" />}
                        />
                    </Box>
                    <div className='flex items-center'>
                        <FormControlLabel
                            control={<Field as={Checkbox} name="terms" />}
                            label=""
                        />
                        <Typography onClick={handleOpenModal}   
                        className='cursor-pointer text-blue-600 underline'variant="body2">
                            Acepto los términos y condiciones
                        </Typography>
                    </div>
                
                
                    <Typography color="error" variant="caption">
                        <ErrorMessage name="terms" />
                    </Typography>

                    {loading && <CircularProgress />} {/* Show loading spinner while submitting */}

                    {responseMessage && (
                        <Typography
                            color={responseMessage.includes('error') ? 'error' : 'success'}
                            variant="body2"
                            mt={2}
                        >
                            {responseMessage}
                        </Typography>
                    )}

                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        fullWidth 
                        sx={{ mt: 2 }}
                        disabled={loading}
                    >
                        Registrarse
                    </Button>
                    <Button 
                        type="button" 
                        variant="text" 
                        color="primary" 
                        fullWidth 
                        sx={{ mt: 2 }}
                        onClick={()=>{
                            resetForm();
                            handleToggleForm();
                        }}
                    >
                        Ya tengo Cuenta
                    </Button></>):(
                        <Box textAlign="center" mt={4}>
                            <CheckCircle color="success" sx={{ fontSize: 60 }} />
                            <Typography variant="h6" mt={2}>
                                Registro Exitoso
                            </Typography>
                            <Typography variant="body1" mt={1}>
                                Tu cuenta ha sido creada exitosamente. Puedes iniciar sesión con tus credenciales.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={()=>{
                                    resetForm();
                                    setSuccess(false);
                                    handleToggleForm();
                                }}// Navigate to login page
                            >
                                Ir a Iniciar Sesión
                            </Button>
                        </Box>
                    )}
                </Form>
            )}
        </Formik>
        </>
    );
};

export default RegisterForm;
