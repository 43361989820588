import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

interface ButtonType2Props {
  content: any;
  active: boolean;
  onClick?: () => void;
}

const CustomButton = styled(Button)<{ active: boolean }>(({ theme, active }) => ({
  borderColor: active ? '#1E88E5' : '#B0BEC5', // Blue border color if active, otherwise gray
  backgroundColor: active ? 'rgba(30, 136, 229, 0.2)' : '#FFFFFF', // Semi-transparent blue background when active
  color: active ? '#1E88E5' : '#37474F', // Blue text color when active, otherwise default
  borderWidth: 2,
  width: 'fit-content',
  padding: '0.5rem 1rem',
  cursor: 'pointer',
  boxShadow: active ? '0 4px 12px rgba(30, 136, 229, 0.2)' : 'none', // Subtle shadow when active
  transition: 'all 0.3s ease', // Smooth transition
  '&:hover': {
    borderColor: '#1E88E5', // Blue border color on hover
    backgroundColor: 'rgba(30, 136, 229, 0.1)', // Lighter transparent blue on hover when inactive
  },
}));
const ButtonType2: React.FC<ButtonType2Props> = ({ content, active, onClick }) => {

    const handleClick = () => {
        if (onClick) {
            const result = onClick();
            return result;
          }
      };

  return (
    <CustomButton
      variant="outlined"
      active={active}
      className="hover:border-blue-500 focus:border-blue-500"
      onClick={handleClick}
    >
      {content}
    </CustomButton>
  );
};

export default ButtonType2;
