import AntenaIcon from "../../../assets/images/icons/Faq/antena";
import ContactIcon from "../../../assets/images/icons/Faq/contact";
import GiftIcon from "../../../assets/images/icons/Faq/gift";
import GlobalIcon from "../../../assets/images/icons/Faq/global";
import PhoneIcon from "../../../assets/images/icons/Faq/phone";
import NetworkIcon from "../../../assets/images/icons/Faq/network";
import SimIcon from "../../../assets/images/icons/SimIcon"
import RedIcon from "../../../assets/images/icons/Faq/red";

interface FaqBoxProps{
    title:string;
    content:string;
    icon?: React.ComponentType | string | null;
}


export const faqQuestions: FaqBoxProps[] = [
    {
        title: 'Preguntas Frecuentes de Planes',
        content: '¿Cuáles son los beneficios de los diferentes planes disponibles? Nuestros planes incluyen datos, llamadas y SMS, y están diseñados para ofrecer la mejor experiencia en conectividad móvil según tus necesidades. Puedes consultar los detalles específicos de cada plan en nuestra página de planes.',
        icon: RedIcon
    },
    {
        title: 'Preguntas Frecuentes de Equipos',
        content: '¿Qué equipos son compatibles con la red 5G? Todos los equipos homologados por Telcel son compatibles con nuestras redes. Para disfrutar de la red 5G, tu equipo debe contar con las capacidades técnicas necesarias y una tarjeta SIM compatible.',
        icon: PhoneIcon
    },
    {
        title: 'Preguntas Frecuentes de Servicios',
        content: '¿Cómo puedo activar servicios adicionales como roaming o llamadas internacionales? Puedes activar servicios adicionales directamente desde la app de Mi Telcel o llamando al servicio de atención al cliente.',
        icon: ContactIcon
    },
    {
        title: 'Preguntas Frecuentes de SIM',
        content: '¿Cómo puedo solicitar un Chip Inteligente (SIM Card) compatible con 5G? Puedes solicitar una nueva SIM Card 5G en cualquier Centro de Atención a Clientes o tienda autorizada, o pedirla en línea a través de nuestro sitio web.',
        icon: SimIcon
    },
    {
        title: 'Preguntas Frecuentes de Promociones',
        content: '¿Cómo puedo acceder a las promociones actuales? Puedes ver todas nuestras promociones activas en la sección de Promociones de nuestra página web, y también recibir notificaciones de ofertas exclusivas a través de la app de Mi Telcel.',
        icon: GiftIcon
    },
    {
        title: 'Preguntas Frecuentes de Conectividad',
        content: '¿Qué debo hacer si tengo problemas de conectividad? Si experimentas problemas de conectividad, primero verifica que tu equipo esté configurado correctamente para usar los datos móviles. Si el problema persiste, comunícate con nuestro servicio técnico.',
        icon: AntenaIcon
    },
    {
        title: 'Preguntas Frecuentes de Cobertura',
        content: '¿Dónde puedo verificar la cobertura de la red 5G? Puedes consultar los mapas de cobertura actualizados en nuestra página de cobertura. Recuerda que la red 5G está disponible en zonas seleccionadas y se irá ampliando progresivamente.',
        icon: GlobalIcon
    },
    {
        title: 'Preguntas Frecuentes de Empresa',
        content: '¿Cómo puede mi empresa acceder a los servicios corporativos de Telcel? Ofrecemos soluciones empresariales personalizadas para satisfacer las necesidades de conectividad y telecomunicaciones de tu negocio. Para más información, visita nuestra página de Servicios Empresariales.',
        icon: NetworkIcon
    }
];