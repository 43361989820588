import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Avatar, Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AdminMenuItems } from './MenuData';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { clearUser } from '../../../redux/userAdminSlice';
import { useDispatch } from 'react-redux';


const categories = Array.from(new Set(AdminMenuItems.map(item => item.category)));

const VerticalNavBar: React.FC = () => {
  const [extendMenu,setExtendMenu] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearUser()); // Dispatch clearUser action
    navigate('/');
  };

  return (
    <Box className='sticky top-0 '>
          <Box
          className={`absolute top-5 z-10 ${
            extendMenu ? 'left-[310px]' : 'left-[100px]'
          } transition-all duration-700 ease-in-out`}
        >
          <Box
            className={`flex justify-center items-center rounded-full bg-blue-500 h-10 w-10 cursor-pointer transform ${
              extendMenu ? 'rotate-0' : 'rotate-180'
            } transition-transform duration-700 ease-in-out`}
          >
            <KeyboardArrowLeftIcon
              className="text-white"
              onClick={() => setExtendMenu(!extendMenu)}
            />
          </Box>
        </Box>
    <Box
        className={`min-w-[50px] max-w-[330px] sticky top-0 ${
          extendMenu ? 'w-[380px]' : 'w-[120px]'
        } shadow-custom h-[100vh] static overflow-x-hidden overflow-y-auto block transition-all duration-700 ease-in-out`}
      >
        <Box className='flex justify-start p-5 w-full'>
          <img
            src={require("../../../assets/images/icons/Logo.webp")}
            alt="Logo"
            className={`${extendMenu ? 'h-16':'h-10'} transition-all duration-700 ease-in-out`}
          />
        </Box>
    
      <List>
        {categories.map((category) => (
          <React.Fragment key={category}>
            <Typography variant="h6" style={{ margin: '5px 0', fontSize:'16px' }} className='flex items-center gap-2 font-sans text-gray-400'>
              <Divider sx={{ borderBottomWidth: 2 }} className='w-5'/>{category}
            </Typography>
            {AdminMenuItems.filter(item => item.category === category).map((item, index) => {
              if (item.category === 'Exit') {
                return (
                  <div onClick={handleLogout} key={index}>
                    <ListItem button>
                      <ListItemIcon className="flex justify-center items-center">
                        <item.icon />
                      </ListItemIcon>
                      {extendMenu ? <ListItemText primary={item.title} /> : null}
                    </ListItem>
                  </div>
                );
              }

              return (
                <Link to={item.url} key={index}>
                  <ListItem button>
                    <ListItemIcon className="flex justify-center items-center">
                      <item.icon />
                    </ListItemIcon>
                    {extendMenu ? <ListItemText primary={item.title} /> : null}
                  </ListItem>
                </Link>
              );
            })}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
    </Box>
  );
};

export default VerticalNavBar