import React from "react";

function GlobalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <circle cx="24" cy="24" r="24"></circle>
        <path d="M42.6 8.8c-.2.1-.4.1-.6.1-1.1.3-2.2.1-3.2-.4-.3-.2-.6-.4-.9-.3-.2.1-.3.2-.4.4-1.2 1.5-3.2 1.9-4.9 1-.3-.2-.7-.4-1-.3-.3.1-.5.4-.7.7-.7 1.1-1.2 2.3-1.4 3.5-.2 1.3.2 2.6 1.1 3.5.3.2.5.4.7.6.2.3.4.6.5.9.9 1.7 3 2.3 4.7 1.4.2-.1.4-.3.6-.4.2-.3.6-.6.9-.7.7-.2 1.2.4 1.7 1 .6.6 1 1.4 1 2.3-.1.8-.6 1.6-.2 2.2.2.2.4.4.7.6 1.1.8.9 2.5.9 3.8 0 1 .2 2 .5 3 .4 1.3 1.3 2.4 2.5 3 .1.1.2.1.4.1M25.5 0c.1.8-.2 1.6-.9 2-.7.5-1.6.7-2.4.7-.7-.1-1.4-.1-2.1.1-1.1.5-1.2 2.3-2.4 2.9-.3.1-.7.1-1 .3-1.1.5-1 2.1-1.9 3-.8.7-1.8.9-2.8.7-1-.2-2-.3-3-.1-1 .3-1.8 1.4-1.4 2.3.4.9 1.6 1 2.5.9.9-.1 2-.2 2.7.4.7.6.6 2.3 1.5 3 .8.5 1.7.5 2.5 0s1.6-.7 2.5-.8c2.3.1 3.2 3.2 5.4 3.9.9.3 1.9.1 2.7.4 1.6.6 2.2 2.8 1.5 4.4-.3.6-.6 1.2-.8 1.8-.2.8.1 1.6-.1 2.4-.4 1.5-2.5 2.2-2.5 3.8.1.4.1.9 0 1.3-.2.3-.5.6-.8.8-1.7 1.1-3 2.7-3.8 4.6-.1.3-.2.5-.2.8.1.4.2.7.5 1 .4.5.7 1.1.7 1.8s-.5 1.3-1.2 1.3c-.4-.1-.8-.4-1-.7-.5-.5-1-1.1-1.3-1.8-.2-.8-.1-1.8-.5-2.5-.4-.4-.7-.9-1.1-1.3-1.3-1.7.3-4.3-.5-6.2-.7-1.6-2.8-2.4-3.1-4.1 0-.7 0-1.3.2-1.9 0-1.8-.4-3.6-1.3-5.1-.2-.5-.5-.9-.8-1.2-.6-.4-1.5-.3-2.1-.7-.5-.4-.9-.9-1.2-1.5-.4-.8-1-1.4-1.8-1.8-.8-.4-1.7-.3-2.4.1-.4.3-.6.7-1 .9-.4.3-1 .4-1.5.4"></path>
      </g>
    </svg>
  );
}

export default GlobalIcon;
