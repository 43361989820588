import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography
} from '@mui/material';
import PlanTypeInput from '../../Inputs/PlanTypeInput';
import { CustomDataPlansAttributes } from '../../../../../constant/ServicesInterfaces';

interface AddCustomPlanModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (newPlan: CustomDataPlansAttributes) => Promise<void>;
}

const AddCustomPlanModal: React.FC<AddCustomPlanModalProps> = ({ open, onClose, onSave }) => {
  const [newPlan, setNewPlan] = useState<CustomDataPlansAttributes>({
    planType: '',
    dataInclude: 0,
    pricePerMonth: 0
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewPlan((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePlanTypeChange = (newPlanType: string) => {
    setNewPlan((prevState) => ({ ...prevState, planType: newPlanType }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(newPlan);
      onClose();
    } catch (error) {
      console.error('Error saving plan:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Añadir Nuevo Opción</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }} className="flex flex-col gap-5">
          <Box className="flex flex-wrap justify-between gap-5 w-full">
            <PlanTypeInput planType={newPlan.planType} onChange={handlePlanTypeChange} />
          </Box>
          <Box className="flex flex-wrap gap-5">
            <TextField
              label="Datos Incluidos (GB)"
              name="dataInclude"
              type="number"
              value={newPlan.dataInclude}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Precio"
              name="pricePerMonth"
              type="number"
              value={newPlan.pricePerMonth}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
              inputProps={{ min: 0 }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" disabled={isSaving}>
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={isSaving}>
          {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Guardar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomPlanModal;