import React from "react";

function AntenaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.008"
      height="29.717"
      viewBox="0 0 30.008 29.717"
    >
      <g fill="#000" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path d="M24.896 25.087a1 1 0 01-.08-1.37 12.94 12.94 0 003.08-9.7c-.82-6.755-6.71-11.734-13.507-11.42-6.797.316-12.2 5.818-12.393 12.62a12.91 12.91 0 003.2 8.5 1 1 0 01-.06 1.34 1 1 0 01-1.48-.07 14.9 14.9 0 01-3.65-10.2C.125 6.502 6.936-.117 15.221.002c8.284.118 14.904 6.93 14.785 15.215a14.89 14.89 0 01-3.65 9.8 1 1 0 01-1.46.07z"></path>
        <path d="M14.996 18.717a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 100 4 2 2 0 000-4z"></path>
        <path d="M14.996 29.217a1 1 0 01-1-1v-10h2v10a1 1 0 01-1 1z"></path>
        <rect width="10" height="2" x="9.896" y="27.717" rx="1"></rect>
        <path d="M7.426 21.367a10 10 0 1115.33-.46 1 1 0 01-1.5.09 1 1 0 01-.08-1.28 8 8 0 10-12.28.33 1 1 0 010 1.32 1 1 0 01-1.47 0z"></path>
      </g>
    </svg>
  );
}

export default AntenaIcon;