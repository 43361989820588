import React, {useCallback, useState, useEffect} from 'react';
import ServicesSlider from '../components/Sliders/ServicesSlider';
import {Button, Box, Container, Typography, Divider, TextField} from '@mui/material';
import ServiceDetailsTabs from '../components/Tabs/ServiceDetailsTab';
import ServiceDescription from '../components/Services/ServiceDescription';
import AdditionalInformation from '../components/Services/AdditionalInformation';
import ServiceSpecification from '../components/Services/ServiceSpecification';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {formatPrice} from '../utils/ConvertApp';
import ButtonType2 from '../components/Buttons/ButtonType2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import SnapChatIcon from '../assets/images/icons/snapchat';
import MessengerIcon from '../assets/images/icons/message';
import UberIcon from '../assets/images/icons/uberIcon';
import XIcon from '../assets/images/icons/xIcon';
import SimIcon from '../assets/images/icons/SimIcon';
import {styled} from '@mui/system';
import { handleWhatsAppClick } from '../utils/WhatsAppUtils';
import { CustomDataPlansAttributes, CustomPlansAttributes, PlanServiceInterface, PlanType } from '../constant/ServicesInterfaces';
import { getCustomDataPlanServices, getCustomPlanServices } from '../services/customPlanServices';

const cotizadorDescripcion: string = `Aquí podrás seleccionar diferentes opciones para crear tu plan ideal para tu teléfono. Ingresa tus necesidades, como consumo de datos, minutos de llamadas y mensajes de texto, y el cotizador te mostrará una lista de planes que se adaptan a lo que buscas.

Nuestra herramienta es fácil de usar y te proporciona información clara sobre tarifas, beneficios adicionales (como roaming o servicios de streaming) y condiciones de cada plan. Además, puedes usar filtros para priorizar lo que más te importa: precio, duración del contrato o cobertura de la red.

Con este cotizador, ahorrarás tiempo y tomarás decisiones informadas para encontrar el plan perfecto que se ajuste a tus necesidades y presupuesto.`;



const socialMediaIcons = [
    {available: <WhatsAppIcon sx={{color: '#000'}}/>, unavailable: <WhatsAppIcon sx={{color: '#000'}}/>},
    {available: <FacebookIcon sx={{color: '#000'}}/>, unavailable: <FacebookIcon sx={{color: '#000'}}/>},
    {available: <XIcon/>, unavailable: <XIcon/>},
    {available: <InstagramIcon sx={{color: '#000'}}/>, unavailable: <InstagramIcon sx={{color: '#000'}}/>},
    {available: <SnapChatIcon sx={{color: '#000'}}/>, unavailable: <SnapChatIcon sx={{color: '#000'}}/>},
    {available: <MessengerIcon/>, unavailable: <MessengerIcon color="disabled"/>},
    {available: <UberIcon/>, unavailable: <UberIcon/>},
];

const simType = [
    {
        icon: <div className='flex gap-2 justify-center items-center'><SimIcon/><p className='text-lg font-bold'>SIM</p></div>,
        value: 'sim'
    },
    {
        icon: <div className='flex gap-2 justify-center items-center'><img src={require('../assets/images/icons/esim.png')} width={56} height={56}/><p className='text-lg font-bold'>eSIM</p></div>,
        value: 'eSim'
    }
];

const InclinedLine = styled('b')({
    position: 'relative',
    display: 'inline-block',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: 'red',
        transform: 'rotate(-10deg)',
        transformOrigin: 'center',
    },
});


const ServicesCustomPage = () => {

    const plansList = [
        {duration: 12, salePercentage: 0.1},
        {duration: 18, salePercentage: 0.15},
        {duration: 24, salePercentage: 0.2}
    ];
    
    const [planServices, setPlanServices] = useState<CustomPlansAttributes[]>([]);
    const [planDataServices, setDataPlanServices] = useState<CustomDataPlansAttributes[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [currentPlan, setCurrentPlan] = useState(plansList[0]);
    const [currentData, setCurrentData] = useState<CustomDataPlansAttributes>();
    const [currentSimType, setCurrentSimType] = useState(simType[0].value);
    const [cantidad, setCantidad] = useState(1); // New state for quantity
    const [planType,setPlanType] = useState<PlanType>(PlanType.Personal)
    const [defaultPlan,setDefaultPlan] = useState<PlanServiceInterface>({
        planName: "Personalizado",
        planType: planType,
        dataIncludedGB: 3,
        minutesIncluded: 9999,
        smsIncluded: 9999,
        coverImgUrl: "https://storage.googleapis.com/4-evolucion-cloud-storage/WebSite/7417f1-service-01.webp",
        additionalCostPerKB: 0.000244,
        price: 0,
        specifications: {
        description: `Disfruta de 3 GB de navegación a alta velocidad, minutos ilimitados para hablar con quien quieras y SMS ilimitados para mantenerte siempre en contacto.
         Perfecto para aquellos que buscan una experiencia completa y sin restricciones. 
         ¡Conéctate y explora todo lo que te ofrece Telcel!`
        },
        isActive: true,
        socialMediaIncluded: [
        { name: "Facebook" },
        { name: "Instagram" },
        { name: "Snapchat" },
        { name: "WhatsApp" },
        { name: "Messenger" },
        { name: "Uber" },
        { name: "X" }
        ],
        premiumSocialMediaIncluded: []
    });

    const handleToogleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: PlanType,
      ) => {
    
        setPlanType(newAlignment);
        const plan = planDataServices.filter(data => data.planType === planType);
        console.log(plan);
        setCurrentData(plan[0]);
      };


      useEffect(() => {
        if(currentData){
            const totalPrice = currentData.pricePerMonth * cantidad; // Adjusted total price based on quantity
            setCurrentPrice(totalPrice);
            setDefaultPlan( {
                planName: "Personalizado",
                planType: planType,
                dataIncludedGB: currentData.dataInclude,
                minutesIncluded: 9999,
                smsIncluded: 9999,
                coverImgUrl: "https://storage.googleapis.com/4-evolucion-cloud-storage/WebSite/7417f1-service-01.webp",
                additionalCostPerKB: 0.000244,
                price: totalPrice,
                specifications: {
                description: `Disfruta de ${currentData.dataInclude} GB de navegación a alta velocidad, minutos ilimitados para hablar con quien quieras y SMS ilimitados para mantenerte siempre en contacto.
                 Perfecto para aquellos que buscan una experiencia completa y sin restricciones. 
                 ¡Conéctate y explora todo lo que te ofrece Telcel!`
                },
                isActive: true,
                socialMediaIncluded: [
                { name: "Facebook" },
                { name: "Instagram" },
                { name: "Snapchat" },
                { name: "WhatsApp" },
                { name: "Messenger" },
                { name: "Uber" },
                { name: "X" }
                ],
                premiumSocialMediaIncluded: []
            });
        }
    }, [planServices,currentPlan, currentData, cantidad]);
      
    useEffect(() => {
        fetchPlanServices();
      }, []);

      const fetchPlanServices = async () => {
        try {
          setLoading(true);
          const customPlans = await getCustomPlanServices();
          const customDataPlans = await getCustomDataPlanServices();
    
          customDataPlans.sort((a:any, b:any) => {
            if (a.planType < b.planType) return -1;
            if (a.planType > b.planType) return 1;
            return a.dataInclude - b.dataInclude;
          });

          customPlans.sort((a:any, b:any) => {
            if (a.duration < b.duration) return -1;
            return a.duration - b.duration;
          });

        setDefaultPlan( {
            planName: "Personalizado",
            planType: planType,
            dataIncludedGB: customDataPlans[0].dataInclude,
            minutesIncluded: 9999,
            smsIncluded: 9999,
            coverImgUrl: "https://storage.googleapis.com/4-evolucion-cloud-storage/WebSite/7417f1-service-01.webp",
            additionalCostPerKB: 0.000244,
            price: currentPrice,
            specifications: {
            description: `Disfruta de ${customDataPlans[0].dataInclude} GB de navegación a alta velocidad, minutos ilimitados para hablar con quien quieras y SMS ilimitados para mantenerte siempre en contacto.
             Perfecto para aquellos que buscan una experiencia completa y sin restricciones. 
             ¡Conéctate y explora todo lo que te ofrece Telcel!`
            },
            isActive: true,
            socialMediaIncluded: [
            { name: "Facebook" },
            { name: "Instagram" },
            { name: "Snapchat" },
            { name: "WhatsApp" },
            { name: "Messenger" },
            { name: "Uber" },
            { name: "X" }
            ],
            premiumSocialMediaIncluded: []
        });
    
          setCurrentData(customDataPlans[0])
          setPlanServices(customPlans);
          setDataPlanServices(customDataPlans);
          handlePriceChange();
          // Update the dashboard data with the computed values
        } catch (error) {
          console.error('Error fetching smartphones:', error);
        } finally {
          setLoading(false);
        }
      };

    const handlePriceChange= () => {
        if(currentData){
            const totalPrice = (1 - currentPlan.salePercentage) * currentData.pricePerMonth * cantidad; // Adjusted total price based on quantity
            setCurrentPrice(totalPrice);
        }
    };


    const handleCantidadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCantidad = Math.max(1, parseInt(event.target.value) || 1); // Ensure quantity is at least 1
        setCantidad(newCantidad);
    };
    

    return (
        <>
            <ServicesSlider/>
            <Container maxWidth='xl'>
                <Box className='my-5'>
                    <Typography variant='h2' component='h2' sx={{fontWeight: 'bold'}}>
                        Encuentra el Plan ideal
                    </Typography>
                    <Typography variant='h5' component='h5'>
                        En nuestra empresa, la excelencia en la comunicación es nuestra máxima prioridad.
                    </Typography>
                </Box>
                <Box className='flex justify-center items-center p-5'>
                    <ToggleButtonGroup
                        color="primary"
                        value={planType}
                        exclusive
                        onChange={handleToogleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value={PlanType.Business}>{PlanType.Business}</ToggleButton>
                        <ToggleButton value={PlanType.Personal}>{PlanType.Personal}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box className="p-10 flex flex-col gap-5">
                    {/* Meses de Servicio Section */}
                    <Box className="flex flex-col gap-2">
                        <Typography variant="h5">Meses de Servicio</Typography>
                        <Box className="flex flex-wrap gap-5">
                        {planServices.map((plan, index) => (
                            <ButtonType2
                            key={index}
                            content={`${plan.duration} Meses`}
                            active={plan.duration === currentPlan.duration} // Ensure that comparison is accurate
                            onClick={() => setCurrentPlan(plan)} // Ensure state updates correctly
                            />
                        ))}
                        </Box>
                    </Box>

                    {/* Datos Incluidos Section */}
                    <Box className="flex flex-col gap-2">
                        <Typography variant="h5">Datos Incluidos</Typography>
                        <Box className="flex flex-wrap gap-5">
                        {planDataServices.length > 0 && currentData && planDataServices
                            .filter((data) => data.planType === planType) // Replace 'YOUR_PLAN_TYPE' with the desired plan type
                            .map((data, index) => (
                                <ButtonType2
                                    key={index}
                                    content={`${data.dataInclude > 100 ? 'Ilimitados' : data.dataInclude + 'GB'}`}
                                    active={data.dataInclude === currentData.dataInclude} // Accurate comparison
                                    onClick={() => setCurrentData(data)} // Correct state update
                                />
                            ))}
                        </Box>
                    </Box>

                    {/* Cantidad Section */}
                    <Box className="flex flex-col gap-2">
                        <Typography variant="h5">Cantidad</Typography>
                        <TextField
                        type="number"
                        value={cantidad}
                        onChange={handleCantidadChange}
                        inputProps={{ min: 0 }}
                        sx={{ width: '100px' }}
                        />
                    </Box>

                    {/* Redes Sociales Section */}
                    <Box className="flex flex-col gap-2">
                        <Typography variant="h5">Redes Sociales*</Typography>
                        <div className="flex justify-start space-x-2">
                        {socialMediaIcons.map((icons, index) => (
                            <div key={index}>
                            {icons.available ? icons.available : icons.unavailable}
                            </div>
                        ))}
                        </div>
                    </Box>

                    {/* Minutos y SMS Section */}
                    <Box className="flex flex-col gap-2">
                        <Typography variant="h5">Minutos y SMS</Typography>
                        <ButtonType2 content={'Ilimitados'} active={true} />
                    </Box>

                    {/* Tipo Sim Section */}
                    <Box className="flex flex-col gap-2">
                        <Typography variant="h5">Tipo Sim</Typography>
                        <Box className="flex gap-2">
                        {simType.map((sim, index) => (
                            <ButtonType2
                            key={index}
                            content={sim.icon}
                            active={sim.value === currentSimType} // Accurate comparison for active state
                            onClick={() => setCurrentSimType(sim.value)} // Ensure correct state update
                            />
                        ))}
                        </Box>
                    </Box>
                    </Box>
                <Box className='my-5 bg-white p-3 border-t border-gray-300 flex flex-wrap justify-between'>
                    <Typography variant="h5" className='flex justify-center items-center gap-2'>
                        Subtotal:
                        {formatPrice(currentPrice)}
                    </Typography>
                    <Box>
                        <Button className='w-[300px] pointer' sx={{borderRadius: 20}} onClick={handleWhatsAppClick} variant="contained">Contratar</Button>
                    </Box>
                </Box>
                <ServiceDetailsTabs
                    descriptionContent={<ServiceDescription description={cotizadorDescripcion}/>}
                    specificationsContent={<ServiceSpecification planService={defaultPlan}/>}
                    additionalInfoContent={<AdditionalInformation/>}/>
            </Container>
        </>
    );
};

export default ServicesCustomPage;