import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Box, TextField, Typography, Button } from '@mui/material';
import { userDetailsSchema } from '../../utils/ValidationsSchema';
import { RootState } from '../../redux/store';
import { setUserDetails} from '../../redux/userInfoDetails'; // Adjust path as per your slice file
import WarningAlert from '../Alerts/WarningAlert';

interface UserInfoFormProps {
  onNext: (valid: boolean) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  state: string;
  city: string;
  postalCode: string;
  phone: string;
}

const UserDetailsForm: React.FC<UserInfoFormProps> = ({ onNext }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.userDetails.userDetails);
  const [isValid, setIsValid] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      email: userDetails?.email || '',
      address: userDetails?.address || '',
      state: userDetails?.state || '',
      city: userDetails?.city || '',
      postalCode: userDetails?.postalCode || '',
      phone: userDetails?.phone || '',
    },
    validationSchema: userDetailsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await formik.validateForm();
        if (formik.isValid) {
          setIsValid(true);
        } else {
          setIsValid(false);
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setIsValid(false);
        setSnackbarOpen(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Update user details on form values change
  useEffect(() => {
    if (formik.values.firstName !== '' &&
        formik.values.lastName !== '' &&
        formik.values.email !== '' &&
        formik.values.address !== '' &&
        formik.values.state !== '' &&
        formik.values.city !== '' &&
        formik.values.postalCode !== '' &&
        formik.values.phone !== '') {
        dispatch(setUserDetails(formik.values))
        setIsValid(true);
    }
  }, [formik.values, userDetails, dispatch]);

  useEffect(() => {
    onNext(isValid);
  }, [isValid, onNext]);

  const renderTextField = <K extends keyof FormValues>(
    name: K,
    label: string,
    type: string = 'text',
    fullWidth: boolean = false
  ) => (
    <Box className={`flex flex-col ${fullWidth ? 'w-full' : 'w-[48%] min-w-[280px]'} gap-2`}>
      <Typography variant='body2' fontWeight='bold'>{label}</Typography>
      <TextField
        name={name}
        label={label}
        variant="outlined"
        type={type}
        fullWidth={fullWidth}
        margin='dense'
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[name] && Boolean(formik.errors[name])}
      />
    </Box>
  );

  return (
    <Box className='flex flex-col gap-5'>
      <Typography className='font-[Avenir]' variant='h5'>Información de Usuario</Typography>
      <form className='flex flex-wrap gap-5'>
        {renderTextField('firstName', 'Nombre')}
        {renderTextField('lastName', 'Apellido')}
        {renderTextField('email', 'Correo', 'email')}
        {renderTextField('phone', 'Teléfono')}
        {renderTextField('address', 'Dirección', 'text', true)}
        {renderTextField('state', 'Estado')}
        {renderTextField('city', 'Ciudad')}
        {renderTextField('postalCode', 'Código Postal')}
      </form>

    </Box>
  );
};

export default UserDetailsForm;
