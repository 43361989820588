import React, { useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RootState } from '../../redux/store';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { UserInfoDetails } from '../../constant/UserInterfaces';
import { ShippingType } from '../../constant/ShopCartInterfaces';
import { updateShipping } from '../../redux/shopCartRedux';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 2,
  },
}));

interface ShippingDetailsFormProps {
  onNext: (valid: boolean) => void; 
}

const ShippingDetailsForm: React.FC<ShippingDetailsFormProps> = ({ onNext }) => {

  const [selectedValue, setSelectedValue] = useState<ShippingType | null>(ShippingType.Free);
  const userDetails = useSelector((state: RootState) => state.userDetails.userDetails);

  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(Number(event.target.value) as ShippingType);
    dispatch(updateShipping(Number(event.target.value) as ShippingType));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Validate form fields if needed
    onNext(true);
  };

  const keysToSkip: (keyof UserInfoDetails)[] = ['email', 'phone']; 

  return (
    <Box className='flex flex-col gap-5'>
      <Typography variant='h5' className='font-[Avenir]'>Información de Envio</Typography>
      <Card>
        <CardContent className='flex flex-wrap'>
            <Box className='w-1/2 p-5 border-r-2'>
                {userDetails ? (
                Object.entries(userDetails)
                .filter(([key]) => !keysToSkip.includes(key as keyof UserInfoDetails))
                .map(([key, value], index) => (
                  <Box key={index} className='user-detail'>
                    <Typography variant='body1' className='font-[Avenir] text-csecondary-200 text-justify'>{value}</Typography>
                  </Box>
                ))
              ) : (
                <Typography variant='body1'>No user details available.</Typography>
              )}
            </Box>
            <Box className='flex justify-center items-center gap-5 w-1/2'>
              <img src={require("../../assets/images/icons/Logo.webp")} alt="Logo" style={{ height: 120 }} />
            </Box>
        </CardContent>
      </Card>
      <Typography variant='h5' className='font-[Avenir]'>Metodo de Envio</Typography>
        <RadioGroup row value={selectedValue}  onChange={handleChange} className='flex flex-wrap gap-5 justify-between items-center mt-6'>
          <Card className='w-5/12 min-w-[300px]'>
              <CardActions className='flex justify-between p-5'>
                <FormControlLabel value={ShippingType.Free} control={<Radio />} label="Envio Estandar" />
                <Typography variant='body1' fontWeight='bold' className='font-[Avenir] text-justify'> Gratis</Typography>
              </CardActions>
              <CardContent>
                <Typography variant='body1' className='font-[Avenir] text-csecondary-200 text-justify'>
                   Envío estimado entre 14 y 20 días (es posible que se deban pagar impuestos al momento de la entrega)
                </Typography>
              </CardContent>
          </Card>
        {/* <Card className='w-5/12 min-w-[300px]'>
              <CardActions className='flex justify-between p-5'>
                <FormControlLabel value={ShippingType.Express}  control={<Radio />} label="Envio Express" />
                <Typography variant='body1' fontWeight='bold' className='font-[Avenir] text-justify'> $150</Typography>
              </CardActions>
              <CardContent>
                <Typography variant='body1' className='font-[Avenir] text-csecondary-200 text-justify'>
                   Envío estimado entre 5 y 10 días (es posible que se deban pagar impuestos al momento de la entrega)
                </Typography>
              </CardContent>
          </Card>*/}
        </RadioGroup>
  </Box>
  );
};

export default ShippingDetailsForm;
