// src/components/CompanyTable.tsx
import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowHeightParams } from '@mui/x-data-grid';
import {Skeleton, Box, IconButton, Menu, MenuItem, Stack, Button, Card } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { createCompany, deleteCompany, getAllCompanies, updateCompanyById } from '../../../services/companies';
import { Company } from '../../../constant/CompanyInterface';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import DeleteCompanyModal from '../Modal/Company/DeleteCompanyModal';
import CompanyDetailsModal from '../Modal/Company/CompanyDetailsModal';
import { display } from '@mui/system';
import AddCompanyModal from '../Modal/Company/AddCompanyModal';
import { CustomToolbar } from './CustomToolBar';

interface DashboardDataProps {
  updateDashboardData: (total: number, active: number, lastMonth: number) => void;
}

const CompaniesTable: React.FC<DashboardDataProps> = ({ updateDashboardData }) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [currentCompany,setCurrentCompany]= useState<Company|null>();
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();


  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
        setLoading(true);
        const data: Company[] = await getAllCompanies(); // Specify the type for data
        setCompanies(data);
        
        const totalCompanies: number = data.length;  // Total companies fetched from API
        const activeCompanies: number = data.filter((company: Company) => company.promotionCode).length; 
        
        const currentMonth: number = new Date().getMonth(); // Get the current month (0-11)
        const currentYear: number = new Date().getFullYear(); // Get the current year

        const createdThisMonthCount: number = data.filter((company: Company) => {
            const createdAt = new Date(company.createdAt); // Convert createdAt to Date object
            return createdAt.getMonth() === currentMonth && createdAt.getFullYear() === currentYear; // Check if month and year match
        }).length; // Count of companies created this month

        // Update the dashboard data with the computed values
        updateDashboardData(totalCompanies, activeCompanies, createdThisMonthCount);
    } catch (error) {
        console.error('Error fetching companies:', error);
    } finally {
        setLoading(false);
    }
};


  const handleEdit = (company: Company) => {
    setCurrentCompany(company);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (company: Company) => {
    try {
      await deleteCompany(company.id);
      showSnackbar('Promotion deleted successfully!', 'success');
      fetchCompanies();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setCurrentCompany(null);
  };

  const handleSaveCompany = async (company: Company) => {
    // Handle saving the promotion data here
    try {
      console.log('handleSavePromotion');
        const res = await createCompany(company);
        console.log(res);
        showSnackbar('Se Agregado una empresa de forma extiosa!', 'success');
        fetchCompanies();
    } catch (error) {
      console.log(error);
    }

    console.log('Saved promotion:', company);
  };


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'companyName', headerName: 'Nombre', width: 150 },
    {
      field: 'companyLogo',
      headerName: 'Logo',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Box className='flex justify-center items-center'>
          <img src={params.value} alt="WebPage" width="100" />
        </Box>
      ),
    },
    { field: 'promotionCode', headerName: 'Codigo', width: 200 },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
          <IconButton onClick={() => handleEdit(params.row as Company)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => {
            setCurrentCompany(params.row as Company);
            setIsDeleteModalOpen(true);
          }}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Card className='shadow-custom w-full py-5 px-10' style={{ height: 600, marginTop:'2rem', display:'flex', justifyContent:'center', flexDirection:'column' }}>
        <Box className='flex justify-end items-center gap-5 my-5'>
          <Button onClick={() => setModalOpen(true)} variant="contained" color="primary" className='!rounded-full'>
            Agregar Empresa
          </Button>
          <AddCompanyModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSaveCompany}
          />
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={600} />
      ) : (
        <DataGrid
          rows={companies}
          columns={columns}
          getRowHeight={() => 'auto'}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          localeText={{
            toolbarExport: 'Exportar',
            toolbarExportLabel: 'Exportar',
            toolbarExportCSV: 'Descargar como CSV',
            toolbarExportPrint: 'Imprimir',
            toolbarExportExcel: 'Descargar como Excel',
          }}
          slots={{ toolbar: CustomToolbar }}
          pagination
        />
      )}

      {currentCompany && isDeleteModalOpen && (
        <DeleteCompanyModal
          open={isDeleteModalOpen}
          onClose={handleModalClose}
          onDelete={() => handleDelete(currentCompany)}
          company={currentCompany}
        />
      )}

    {currentCompany && isEditModalOpen && (
      <CompanyDetailsModal
        open={isEditModalOpen}
        onClose={handleModalClose}
        onSave={async (updatedCompany:Company) => {
          try {
            if(updatedCompany.id){
              await updateCompanyById(updatedCompany.id, updatedCompany);
              showSnackbar('Empresa se actualizo de forma exitosa!', 'success');
              fetchCompanies();
              handleModalClose();
            }else{
              showSnackbar('Empresa sin Id!', 'error');
            }
          } catch (error) {
            console.error('Error actualizando Empresa:', error);
            showSnackbar('Error actualizando Empresa', 'error');
          }
        }}
        company={currentCompany}
      />
    )}
    </Card>
  );
};

export default CompaniesTable;
